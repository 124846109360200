import { takeLatest, call, put } from "redux-saga/effects"
import { FETCH_HISTORIC_LOADS_FOR_LOAD, SET_HISTORICAL_LOADS } from '../actionTypes'
import {historicalLoads} from '../loadActions'

function* workerFetchHistoricLoadsForLoadSaga(action) {
    let {load} = action

    yield put({type: SET_HISTORICAL_LOADS, loads: []})
    if (load && load.id) {
        const response = yield call(historicalLoads, load.id,load.rangeStart,load.rangeEnd)
        if (response && response.data) {
            yield put({type: SET_HISTORICAL_LOADS, loads: response.data})
        } else {
            yield put({type: SET_HISTORICAL_LOADS, loads: []})
        }
    } else {
        yield put({type: SET_HISTORICAL_LOADS, loads: []})
    }

}



export function* watchFetchHistoricLoadsForLoadSaga() {
    yield takeLatest(FETCH_HISTORIC_LOADS_FOR_LOAD, workerFetchHistoricLoadsForLoadSaga)
}