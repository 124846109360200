import {SET_AUTHENTICATED_USER} from '../actionTypes'
import axios from 'axios'
import jsonwebtoken from 'jsonwebtoken'
import * as Sentry from '@sentry/react'

//here fetch the user from the store
const initialState = localStorage.getItem('authenticatedUser')


export default (state=initialState,  payload) => {
    switch(payload.type) {
    case SET_AUTHENTICATED_USER:
        let decodedUser = null
        
        if (payload.authenticatedUser) {
            localStorage.setItem('authenticatedUser', payload.authenticatedUser)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.authenticatedUser
            decodedUser = jsonwebtoken.decode(payload.authenticatedUser)
            Sentry.setUser({email: decodedUser.email})
        } else {
            localStorage.removeItem('authenticatedUser')
            delete axios.defaults.headers.common['Authorization']
            Sentry.setUser(null)
        }
        return decodedUser
      default:
        return state
    }
}
