import { SET_TRUCK_SCHEDULES, REMOVE_TRUCK_ENTRY_TRUCK_SCHEDULE, TRUCK_SCHEDULE_REFRESHED } from '../actionTypes'

const _ = require('lodash')
const initialState = []

export default (state=initialState,  action) => {
    let schedules

    switch(action.type) {
      case SET_TRUCK_SCHEDULES:
        return action.truckSchedules
      case REMOVE_TRUCK_ENTRY_TRUCK_SCHEDULE:
        schedules = [...state]

        schedules = schedules.filter(s=>s.id !== action.scheduleId)

        return schedules
      case TRUCK_SCHEDULE_REFRESHED:
        schedules = [...state]

        const refreshedSchedule = action.truckSchedule
        const scheduleIndex = _.findIndex(schedules, s=>s.id === refreshedSchedule.id)
        schedules[scheduleIndex] = refreshedSchedule
        
        return schedules
      default:
        return state
  }
    
}