import {OPEN_NOTE_MODAL, CLOSE_NOTE_MODAL} from '../actionTypes'

const initialState = {
    open: false
}

export default (state=initialState,  action) => {
    switch(action.type) {
        case OPEN_NOTE_MODAL:
            return { open: true }
        case CLOSE_NOTE_MODAL:
          return { open: false }
        default:
            return state
     }
}
