
// based on source, and truck source
// Object of Objects
// first key is truck source, second key is accumulated source
const TRUCK_SOURCE_LOOKUP = {
    'OTR': {

    },
    'CNCT': {
        'OTR': 'OTR'
    }, 
    'SYS': {
        'OTR': 'OTR',
        'CNCT': 'CNCT'
    },
    'BOT': {
        'OTR': 'OTR',
        'CNCT': 'CNCT',
        'SYS': 'SYS'
    },
    'BRD': {
        'OTR': 'OTR',
        'CNCT': 'CNCT',
        'SYS': 'SYS'
    }
}



const convertTruckSource = (truck) => {
    let truck_source = truck?.source
    if (truck_source === 'OTR') {
        // this will go away, when the source is stored correctly in the DB
        if (truck?.user?.first_name === 'AWS') {
            truck_source = 'CNCT'
        } else if (truck?.notes?.includes('Truck auto created')) {
            truck_source = 'SYS'
        }
    //this may still stay as we want to store the trucks with the correct source, but not show them in the app
    } else if ((truck_source === 'DAT') || (truck_source === 'TS')) { 
        truck_source = 'BRD'
    }
    return truck_source
}

const convertMatchTruckSource = (source, truck) => {

    const truck_source = convertTruckSource(truck)

    const sourceConversion = TRUCK_SOURCE_LOOKUP[truck_source]

    if (sourceConversion) { 
        return sourceConversion[source] || truck_source
    }

    return source
}

module.exports = {
    convertTruckSource,
    convertMatchTruckSource
}