import React, {Component} from 'react'

import axios from 'axios'

class DrawerLinkLogout extends Component {

    handleClick = () => {
        axios.post('/api/auth/logout').then(r => {
            localStorage.removeItem('authenticatedUser')
            window.location.reload()
        }).catch(e => {
            localStorage.removeItem('authenticatedUser')
            window.location.reload()
        })
    }

    render() {
        return(
            <div className={this.props.className || "drawer-link"} onClick={this.handleClick}>
                <div>Logout</div>
            </div>
        )
    }
}

export default DrawerLinkLogout