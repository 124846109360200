import {all} from 'redux-saga/effects'

import {watchFetchLoadsSaga,watchSelectedLoadSaga, watchLoadUpdatedSaga, watchSetLoadAssignedUserSaga,
  watchSetLoadPrioritySaga, watchPostLoadSaga, watchUnpostLoadSaga, watchLoadTruckOnlySelectedSaga,
  watchFetchAllEmailsSaga, watchUpdateEmailsSaga, watchFetchSelectedLoadSaga, watchFetchConnectLogsSaga,
  watchFetchInsightDataSaga, watchFetchDatRatesSaga, watchAddQueryCounterSaga, 
  watchFetchInsightHistoricLeadsSaga,watchNextLoadsSaga, watchFetchOpportunityLoadsSaga,
  watchFetchQueryCounterSaga,  watchAddThresholdOnLoadSaga, watchSetLoadTeamSaga
} from './loadSaga'
import {watchUserLoginSaga, watchImpersonate, watchClearImpersonateUser, watchRefreshUserTokenSaga} from './authUserSaga'
import {watchFetchMatchedTrucks, watchFetchTrucksWithFilterSaga, watchMatchedTruckBidSaga, watchAddDATTruckToCarrierSaga} from './truckSaga'
import {watchAddBidSaga, watchBidAddedEventSaga, watchAddLeadBidSaga} from './bidSaga'
import {watchFetchAndSetConnectQueueStatusSaga} from './connectQueueStatusSaga'
import {
    watchFetchCarrierByMCSaga, watchFetchCarriersWithFilterSaga, 
    watchSelectedCarrierSaga,
    watchCarrierTruckSelectedSaga, 
    watchFetchMyCarrierAssignmentsSaga, watchTruckRefreshSaga,
    watchDeleteSelectedCarrierContact, watchDeleteSelectedCarrierNote,
    watchUpdatedAndShowRecentCarrierList,
    watchDeleteCarrierAssignment, watchAddCarrierAssignment,
    watchSaveFolderUpdatesSaga,
    watchFetchCarrierFoldersSaga,
    watchAddCarrierFolderSaga,
    watchUpdateCarrierFolderSaga,
    watchDeleteCarrierFolderSaga,
    watchFetchCarrierMatchingLoadsSaga,
    watchFetchCarrierNextLoadsSaga,
    watchSelectCarrierLoadSaga,
    watchAddCarrierLoadMatchEventSaga,
    watchRemoveCarrierLoadMatchEventSaga,
    watchFetchUserCarriersSaga,
    watchFetchUserOwnedCarriersSaga,
    watchAddUserCarrierSaga,
    watchDeleteUserCarrierSaga,
    watchSetCarrierDeadheadSaga,
    watchFetchCarrierOwnersSaga,
    watchRevokeCarrierOwnerSaga
} from './carrierSaga'

import {watchAddNoteSaga, watchDeleteNoteSaga} from './noteSaga'
import {watchFetchSmsQueueLogsSaga} from './smsQueueSaga'
import {
    watchFetchCustomerByIDSaga,
    watchFetchCustomersWithFilterSaga,
    watchSelectedCustomerSaga,
    watchFetchMyCustomerAssignmentsSaga,
    watchDeleteSelectedCustomerContact,
    watchDeleteSelectedCustomerNote,
    watchDeleteCustomerAssignment,
    watchAddCustomerAssignment,
    watchSaveCustomerFolderUpdatesSaga,
    watchFetchCustomersWithFilterAndLoadsSaga, 
    watchFetchCustomerLanesSaga,
    watchFetchCustomerContacts,
    watchFetchSfAccountByAljexAccountNumber
} from './customerSaga'

import {
    watchFetchUsersSaga, 
    watchFetchUsersWithActivityStatsSaga, 
    watchCallTableUserSelectedSage, 
    watchFetchActiveUsers,
    watchFetchUserLoads,
    watchFetchLoadsForAllUsers,
    watchFetchLoadSpreadForAllUsers,
    watchFetchUserActivitySaga
} from './userSaga'

import {
  watchTruckEntryCarrierSelected,
  watchTruckEntryAddTruck,
  watchTruckEntryDeleteTruck,
  watchTruckEntryDeleteMultipleTrucks,
  watchTruckEntryDeleteTruckSchedule,
  watchTruckScheduleRefreshSaga,
} from './truckEntrySaga'

import {watchFetchExternalLoadsWithFilterSaga, watchFetchExternalLoadsForLoadSaga, watchFetchMarketLoadsForLoadSaga, watchFetchMarketLoadsWithFilterSaga} from './externalLoadSaga'
import {watchEventSaga, watchProcessNewAppVersionSaga, watchUserUpdateSaga} from './eventSaga'
import {
    watchInboxNotificationAddedEventSaga, 
    watchFetchInboxNotificationsSaga, 
    watchInboxNotificationsReadSaga,
    watchSendSlackDMSaga
} from './notificationSaga'
import {watchFetchHistoricLoadsForLoadSaga} from './historicLoadSaga'
import {watchTruckOriginSelectedSaga, watchTruckDestinationSelectedSaga} from './selectedTruckSaga'
import {watchFetchHistoricLoadsForScreenSaga} from './historicLoadScreenSaga'

import {watchStatsFetchEventSaga} from './statsSaga'
import {watchFetchTasksSaga, watchTaskUpdatedSaga} from './taskSaga'

import {watchDeleteFolder, watchRenameFolder} from './foldersSaga'

import { watchFetchMatchesSaga, 
  /*watchMatchUpdatedSaga,*/ 
  watchMatchSelectedSaga, 
  watchMatchBidSaga, 
  watchMatchLockSaga, 
  watchRemoveMatchLockSaga, 
  watchRemoveMatchLockByUserSaga, 
  watchCheckLockSaga,
  watchSelectMatchForCarrierSaga
 } from './matchSaga'

import { watchLoadPostUpdateSaga, watchSetLoadPostSaga} from './loadPostSaga'
import { watchFetchAndSetEmailsSaga, watchSendEmailsSaga, watchSendBulkEmailsSaga, watchSetMcForEmails } from './emailSaga'
import {watchFetchAndSetInsightDataSaga} from './insightSaga'
import {watchFetchAndSetConnectLogsSaga } from './connectLogsSaga'
import {watchFetchAndSetLeadsSaga} from './leadsSaga'
import {watchFetchAndSetConnectMapsSaga, watchFetchAndSetConnectMapsStateSaga} from './connectMapsSaga'
import {watchFetchAndSetAdminCustomersSaga, watchAddThresholdOnTeamSaga} from './adminSaga'
import {watchFetchAndSetLoadThresholdSaga} from './loadThresholdSaga'

// For Carrier Ops
import { 
    watchFetchCarrierOpsTasksSaga, 
    watchSaveCarrierOpsTaskSaga,
    watchFetchAndSetSelectedCarrierOpsTask,
    watchSetSelectedCarrierForCarrierOps,
    watchFetchAndSetIncomingTextForCarrierOps,
    watchUpdateIncomingTextForCarrierOps,
    watchAssignCarrierOpsTasksSaga,
    watchSendCarrierOpsMMSSaga,
    watchAutoSaveCarrierOpsDataSaga,
    watchFetchSmsNotificationsSaga,
    watchMarkSmsNotificationReadSaga,
    watchFetchLoadTaskQuestionsSaga
} from './carrierOpsSaga'

import { watchFetchTeamSaga } from './teamSaga'

export default function* rootSaga() {
  yield all([
    watchFetchLoadsSaga(),
    watchSelectedLoadSaga(),
    watchUserLoginSaga(),
    watchFetchMatchedTrucks(),
    watchAddBidSaga(),
    watchAddLeadBidSaga(),
    watchBidAddedEventSaga(),
    watchFetchCarrierByMCSaga(),
    watchFetchCarriersWithFilterSaga(),
    watchFetchMyCarrierAssignmentsSaga(),
    watchSelectedCarrierSaga(),
    watchFetchCustomerByIDSaga(),
    watchFetchCustomersWithFilterSaga(),
    watchSelectedCustomerSaga(),
    watchFetchMyCustomerAssignmentsSaga(),
    watchDeleteSelectedCustomerContact(),
    watchDeleteSelectedCustomerNote(),
    watchDeleteCustomerAssignment(),
    watchAddCustomerAssignment(),
    watchSaveCustomerFolderUpdatesSaga(),
    watchAddNoteSaga(),
    watchDeleteNoteSaga(),
    watchFetchUsersSaga(),
    watchFetchUsersWithActivityStatsSaga(),
    watchFetchActiveUsers(),
    watchFetchUserLoads(),
    watchFetchLoadsForAllUsers(),
    watchCallTableUserSelectedSage(),
    watchTruckEntryCarrierSelected(),
    watchTruckEntryAddTruck(),
    watchTruckEntryDeleteTruck(),
    watchFetchTrucksWithFilterSaga(),
    watchLoadUpdatedSaga(),
    watchMatchedTruckBidSaga(),
    watchFetchExternalLoadsWithFilterSaga(),
    watchFetchExternalLoadsForLoadSaga(),
    watchEventSaga(),
    watchProcessNewAppVersionSaga(),
    watchCarrierTruckSelectedSaga(),
    watchTruckRefreshSaga(),
    watchTruckScheduleRefreshSaga(),
    watchSetLoadAssignedUserSaga(),
    watchSetLoadPrioritySaga(),
    watchAddDATTruckToCarrierSaga(),
    watchPostLoadSaga(),
    watchUnpostLoadSaga(),
    watchDeleteSelectedCarrierContact(),
    watchInboxNotificationAddedEventSaga(),
    watchFetchInboxNotificationsSaga(),
    watchInboxNotificationsReadSaga(),
    watchSendSlackDMSaga(),
    watchDeleteSelectedCarrierNote(),
    watchFetchHistoricLoadsForLoadSaga(),
    watchTruckOriginSelectedSaga(),
    watchTruckDestinationSelectedSaga(),
    watchFetchMarketLoadsForLoadSaga(),
    watchFetchMarketLoadsWithFilterSaga(),
    watchUpdatedAndShowRecentCarrierList(),
    watchFetchHistoricLoadsForScreenSaga(),
    watchDeleteCarrierAssignment(),
    watchAddCarrierAssignment(),
    watchSaveFolderUpdatesSaga(),
    watchStatsFetchEventSaga(),
    watchImpersonate(),
    watchClearImpersonateUser(),
    watchFetchTasksSaga(),
    watchTaskUpdatedSaga(),
    watchLoadTruckOnlySelectedSaga(),
    watchDeleteFolder(),
    watchRenameFolder(),
    watchFetchMatchesSaga(),
    //watchMatchUpdatedSaga(),
    watchMatchSelectedSaga(),
    watchMatchBidSaga(),
    watchFetchAllEmailsSaga(),
    watchUpdateEmailsSaga(),
    watchFetchSelectedLoadSaga(),
    watchFetchConnectLogsSaga(),
    watchFetchInsightDataSaga(),
    watchFetchDatRatesSaga(),
    watchAddQueryCounterSaga(),
    watchFetchInsightHistoricLeadsSaga(),
    watchLoadPostUpdateSaga(),
    watchSetLoadPostSaga(),
    watchNextLoadsSaga(),
    watchMatchLockSaga(),
    watchRemoveMatchLockSaga(),
    watchRemoveMatchLockByUserSaga(),
    watchCheckLockSaga(),
    watchFetchOpportunityLoadsSaga(),
    watchFetchAndSetEmailsSaga(),
    watchFetchQueryCounterSaga(),
    watchFetchAndSetInsightDataSaga(),
    watchFetchAndSetConnectLogsSaga(),
    watchFetchAndSetLeadsSaga(),
    watchFetchLoadSpreadForAllUsers(),
    watchFetchCustomersWithFilterAndLoadsSaga(),
    watchFetchSmsQueueLogsSaga(),
    watchFetchAndSetConnectMapsSaga(),
    watchFetchAndSetConnectMapsStateSaga(),
    watchFetchAndSetAdminCustomersSaga(),
    watchAddThresholdOnTeamSaga(),
    watchAddThresholdOnLoadSaga(),
    watchAddThresholdOnLoadSaga(),
    watchFetchAndSetLoadThresholdSaga(),
    watchFetchAndSetConnectQueueStatusSaga(),
    watchSendEmailsSaga(),
    watchSetMcForEmails(),
    watchSendBulkEmailsSaga(),
    watchFetchCarrierOpsTasksSaga(),
    watchSaveCarrierOpsTaskSaga(),
    watchFetchAndSetSelectedCarrierOpsTask(),
    watchSetSelectedCarrierForCarrierOps(),
    watchFetchAndSetIncomingTextForCarrierOps(),
    watchUpdateIncomingTextForCarrierOps(),
    watchAssignCarrierOpsTasksSaga(),
    watchSendCarrierOpsMMSSaga(),
    watchAutoSaveCarrierOpsDataSaga(),
    watchFetchSmsNotificationsSaga(),
    watchMarkSmsNotificationReadSaga(),
    watchFetchLoadTaskQuestionsSaga(),
    watchFetchCarrierFoldersSaga(),
    watchAddCarrierFolderSaga(),
    watchUpdateCarrierFolderSaga(),
    watchDeleteCarrierFolderSaga(), 
    watchFetchCustomerLanesSaga(),
    watchFetchCustomerContacts(),
    watchFetchSfAccountByAljexAccountNumber(),
    watchFetchCarrierMatchingLoadsSaga(),
    watchFetchCarrierNextLoadsSaga(),
    watchSelectCarrierLoadSaga(),
    watchFetchUserActivitySaga(),
    watchAddCarrierLoadMatchEventSaga(),
    watchRemoveCarrierLoadMatchEventSaga(),
    watchTruckEntryDeleteMultipleTrucks(),
    watchTruckEntryDeleteTruckSchedule(),
    watchFetchUserCarriersSaga(),
    watchFetchUserOwnedCarriersSaga(),
    watchAddUserCarrierSaga(),
    watchDeleteUserCarrierSaga(),
    watchSelectMatchForCarrierSaga(),
    watchUserUpdateSaga(),
    watchRefreshUserTokenSaga(),
    watchFetchTeamSaga(),
    watchSetLoadTeamSaga(),
    watchSetCarrierDeadheadSaga(),
    watchFetchCarrierOwnersSaga(),
    watchRevokeCarrierOwnerSaga()
  ])
}

