import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects'
import { 
    FETCH_MY_CARRIER_ASSIGNMENTS, FETCH_CARRIER_BY_MC, FETCH_CARRIERS_WITH_FILTER, CARRIER_SELECTED, 
    SET_MY_ASSIGNMENTS,
    SET_SELECTED_CARRIER, TRUCK_ENTRY_CARRIER_SELECTED,
    ADD_MESSAGE, SET_CARRIER_MATCHING_LOAD,
    CARRIER_TRUCK_SELECTED, SET_SELECTED_CARRIER_TRUCK,
    REFRESH_TRUCK, UPDATE_TRUCK_ENTRY_TRUCK_LIST,
    DELETE_SELECTED_CARRIER_CONTACT, UPDATE_SELECTED_CARRIER_CONTACTS_LIST_AFTER_DELETE,
    DELETE_SELECTED_CARRIER_NOTE, UPDATE_SELECTED_CARRIER_NOTES_LIST_AFTER_DELETE,
    APPEND_TO_RECENT_CARRIERS,
    UPDATE_AND_SHOW_RECENT_CARRIER_LIST,
    UPDATE_RECENT_CARRIER_ITEM,
    SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS, SET_CARRIER_SEARCH_LIST,
    DELETE_CARRIER_ASSIGNMENT, ADD_CARRIER_ASSIGNMENT,
    SAVE_FOLDER_UPDATES,
    RETURN_LOADS_FOR_TRUCK,
    SET_HISTORICAL_LOADS_BY_MC, SET_HISTORICAL_LOADS, FETCH_CARRIER_FOLDERS, SET_CARRIER_FOLDERS, ADD_CARRIER_FOLDER, UPDATE_CARRIER_FOLDER, UPDATE_CARRIER_FOLDERS, DELETE_CARRIER_FOLDER,
    FETCH_CARRIER_MATCHING_LOADS,
    SET_TRUCK_ENTRY_TRUCK_LIST,
    FETCH_CARRIER_NEXT_LOADS,
    SET_CARRIER_SELECTED_LOAD,
    SELECT_CARRIER_LOAD,
    FETCH_NEXT_LOADS,
    SET_CARRIER_BIDS,
    ADD_CARRIER_LOAD_MATCH_EVENT,
    REMOVE_CARRIER_LOAD_MATCH_EVENT,
    ADD_MATCH,
    REMOVE_MATCH,
    ADD_CARRIER_MATCHING_LOAD,
    REMOVE_CARRIER_MATCHING_LOAD,
    FETCH_USER_CARRIERS,
    SET_USER_CARRIERS,
    ADD_USER_CARRIER,
    FETCH_USER_OWNED_CARRIERS,
    DELETE_USER_CARRIER,
    APPEND_TO_USER_CARRIERS,
    REMOVE_FROM_USER_CARRIERS,
    APPEND_TO_CARRIER_FOLLOWERS,
    REMOVE_FROM_CARRIER_FOLLOWERS,
    SELECT_MATCH_FOR_CARRIER_MC,
    SET_CARRIER_DEADHEAD,
    SET_USER_OWNED_CARRIERS,
    FETCH_CARRIER_OWNERS,
    SET_CARRIER_OWNERS,
    REVOKE_CARRIER_OWNER,
    REMOVE_CARRIER_OWNER,
    REMOVE_USER_OWNED_CARRIERS,
    SELECTED_CARRIER_REMOVE_OWNER
} from '../actionTypes'

import { 
    fetchCarriersWithFilter,
    fetchMyCarrierAssignments,
    fetchCarrier,
    fetchCarrierByMC,
    postTruckRefresh,
    deleteCarrierContact,
    deleteCarrierNote,
    deleteCarrierAssignment,
    addCarrierAssignment,
    saveFolderUpdates,
    getCarrierFolders,
    addCarrierFolder,
    deleteCarrierFolder,
    updateCarrierFolder,
    fetchCarrierBids,
    fetchUserCarriers,
    fetchUserOwnedCarriers,
    addUserCarrier,
    deleteUserCarrier,
    patchCarrier,
    fetchCarrierOwners,
    revokeCarrierOwner
} from '../carrierActions'

import {
    getMatchesByMc,
    fetchMatches
} from '../matchesActions'

import { 
    getCurrentUser,
    getRecentCarriers,
    getMyAssignments,
    getSelectedCarrier,
    getSelectedCarrierTruck,
    getTruckEntryTruckList
} from '../reducers/rootReducer'

import { fetchNextLoads }  from '../loadActions'

import _ from 'lodash'
import moment from 'moment'

function* refreshRecentCarriersList(carrierId) {
    // Update Recent List because Icon should change
    const carrierResult = yield call(fetchCarrier, carrierId)
    yield put({ type: UPDATE_RECENT_CARRIER_ITEM, carrier: carrierResult.data})

    // // The above isn't enought to make react rerender so let's force it.
    const recentCarriers = yield select(getRecentCarriers)
    yield put({ type: SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS, recentCarriers: null})
    yield put({ type: SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS, recentCarriers: recentCarriers})
}

function* workerFetchCarrierByMCSaga(payload) {
    const {mc, selectedCarrierTruck, withTask} = payload
    try {
        // bypass mc = 1 as this is the catch all for connect and has a ton of data
        if (mc === '1') return
        yield put({type: SET_HISTORICAL_LOADS_BY_MC, loads: null})
        const response = yield call(fetchCarrierByMC, mc)

        if (response.data) {
            const carrier = response.data
            if (carrier && carrier.loads) {
                let carrierLoads = []
                carrierLoads = carrier.loads
                if (carrierLoads && carrierLoads.length > 0) {
                    yield put({type: SET_HISTORICAL_LOADS_BY_MC, loads: carrierLoads})
                } else {
                    yield put({type: SET_HISTORICAL_LOADS_BY_MC, loads: []})
                }
            } 
            yield put({type: SET_CARRIER_MATCHING_LOAD, carrierMatchingLoads: null})
            yield put({type: SET_CARRIER_BIDS, bids: null})
            yield put({type: SET_SELECTED_CARRIER, selectedCarrier: {...carrier, loads: null, trucks: null, contacts: null}})
            yield put({type: TRUCK_ENTRY_CARRIER_SELECTED, carrier: {...carrier, loads: null, trucks: null, contacts: null}})

            yield put({type: SET_SELECTED_CARRIER, selectedCarrier: carrier})
            yield put({type: TRUCK_ENTRY_CARRIER_SELECTED, carrier})

            //yield put({type: UPDATE_AND_SHOW_RECENT_CARRIER_LIST, carrier: {...carrier}})

            // fetch the carrier bids
            const bidsResponse = yield call(fetchCarrierBids, carrier.id)

            if (bidsResponse?.data) {
                yield put({type: SET_CARRIER_BIDS, bids: bidsResponse.data})
            } else {
                yield put({type: SET_CARRIER_BIDS, bids: []})
            }
            if (selectedCarrierTruck) {
                yield put({type: CARRIER_TRUCK_SELECTED, truck: selectedCarrierTruck})
            } else {
                // not selected truck sent, let just pull all loads
                // here you want to clear out the selected possible load  // TODO: Do we need this?
                yield put ({type: SET_CARRIER_SELECTED_LOAD})   
                //NOPE: MAYBE THIS IS FUCKING IT UP
                yield put({type: FETCH_CARRIER_MATCHING_LOADS})
            }
            if (withTask) {
                yield put ({type: SELECT_MATCH_FOR_CARRIER_MC, mc})
            }
        } else {
            yield put({type: ADD_MESSAGE, message: 'No matching carrier found!'})
        }

    } catch (error) {
        console.error('workerFetchCarrierByMCSaga error: ', error)
    }
}

function* workerFetchCarriersWithFilterSaga(action) {
    try {
        yield put({ type: SET_CARRIER_SEARCH_LIST, carriers: null})
        const response = yield call(fetchCarriersWithFilter, action.payload)

        if (response) {
            const carriers = response.data

            if (carriers.length === 1) {
                const carrier = carriers[0]
                yield put({type: SET_SELECTED_CARRIER, selectedCarrier: {...carrier, loads: null, trucks: null, contacts: null}})
                yield put({type: TRUCK_ENTRY_CARRIER_SELECTED, carrier: {...carrier, loads: null, trucks: null, contacts: null}})
                
                yield put({type: SET_SELECTED_CARRIER, selectedCarrier: carrier})
                yield put({type: TRUCK_ENTRY_CARRIER_SELECTED, carrier})

                // here you want to clear out the selected possible load
                yield put ({type: SET_CARRIER_SELECTED_LOAD})  
                yield put({type: FETCH_CARRIER_MATCHING_LOADS})
            }

            yield put({ type: SET_CARRIER_SEARCH_LIST, carriers})

            if (carriers.length === 0) {
                yield put({type: ADD_MESSAGE, message: 'No matching carriers found!'})
            }
        }

    } catch (error) {
        console.error('workerFetchCarriersWithFilterSaga error: ', error)
    }
}

function* workerFetchMyCarrierAssignmentsSaga() {
    try {
        const response = yield call(fetchMyCarrierAssignments)
        if (response) {
            const assignments = response.data

            yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
            yield put({ type: SET_MY_ASSIGNMENTS, assignments: assignments})
        }
    } catch(err) {
        console.error('workerFetchMyCarrierAssignmentsSaga() error: ', err)
    }
}

/**
 * So when we select a carrier, we could also have a truck and or a possible load selected as well.
 * Putting this all into one worker so that they can be in sync
 * @param {*} payload 
 */
function* workerCarrierSelectedSaga(payload) {
    try {
        yield put({type: CARRIER_TRUCK_SELECTED, truck: null})
        yield put({type: SET_CARRIER_MATCHING_LOAD, carrierMatchingLoads: null })
        yield put({type: SET_HISTORICAL_LOADS_BY_MC, loads: null})
        yield put({type: RETURN_LOADS_FOR_TRUCK, returnLoads: []})
        yield put({type: SET_HISTORICAL_LOADS, loads: []})
        yield put({type: SET_CARRIER_BIDS, bids: null})
        yield put({type: SET_CARRIER_SELECTED_LOAD})

        const carrierResponse = yield call(fetchCarrierByMC, payload?.selectedCarrier?.mc)
        if (carrierResponse.data) {
            const carrier = carrierResponse.data
            yield put({type: SET_HISTORICAL_LOADS_BY_MC, loads: carrier.loads || []})

            yield put({type: SET_SELECTED_CARRIER, selectedCarrier: carrier})

            yield put({type: TRUCK_ENTRY_CARRIER_SELECTED, carrier})

            const bidsResponse = yield call(fetchCarrierBids, carrier.id)
            if (bidsResponse?.data) {
                yield put({type: SET_CARRIER_BIDS, bids: bidsResponse.data})
            } else {
                yield put({type: SET_CARRIER_BIDS, bids: []})
            }
            if (payload.withTask) {
                yield put ({type: SELECT_MATCH_FOR_CARRIER_MC, mc: carrier.mc})
            }
        }
    } catch (err) {
        console.error('workerCarrierSelectedSaga error: ', err)
    }
}

function* workerCarrierTruckSelectedSaga({truck}) {
    yield put({ type: SET_SELECTED_CARRIER_TRUCK, truck: truck })

    // here you want to clear out the selected possible load
    yield put ({type: SET_CARRIER_SELECTED_LOAD})

    yield put({type: FETCH_CARRIER_MATCHING_LOADS})
}

function* workerTruckRefreshSaga(action) {
    try {
      const response = yield call(postTruckRefresh, action.truck)
      if (response.status !== 200) {
        yield put({type: ADD_MESSAGE, message: 'Failed to refresh'})
      }

      // here we want to update the truck
      yield put({type: UPDATE_TRUCK_ENTRY_TRUCK_LIST, truck: response.data})

    } catch (error) {
        //TODO: dispatch error on snack bar
      console.error("error", error)
  
    }
  }

function* workerDeleteSelectedCarrierContact(action) {
    yield call(deleteCarrierContact, {carrierId: action.selectedCarrier.id, contactId: action.contactId})
    yield put({ type: UPDATE_SELECTED_CARRIER_CONTACTS_LIST_AFTER_DELETE, selectedCarrier: action.selectedCarrier, contactId: action.contactId })
}

function* workerDeleteSelectedCarrierNote(action) {
    yield call (deleteCarrierNote, {carrierId: action.selectedCarrier.id, noteId: action.noteId})
    yield put({ type: UPDATE_SELECTED_CARRIER_NOTES_LIST_AFTER_DELETE, selectedCarrier: action.selectedCarrier, noteId: action.noteId })
}

function* workerUpdatedAndShowRecentCarrierList(action) {
    yield put({type: APPEND_TO_RECENT_CARRIERS, carrier: action.carrier})
    const recentCarriers = yield select(getRecentCarriers)
    yield put({type: SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS, recentCarriers: recentCarriers})
}

function* workerDeleteCarrierAssignment(action) {
    const assignment = action.payload
    const result = yield call(deleteCarrierAssignment, {assignment: assignment})

    if (result && result.data) {
        // Get My Carriers list
        let myCarriers = yield select(getMyAssignments)

        // Remove from all lists
        for (let group of Object.keys(myCarriers)) {
            for(let listName of Object.keys(myCarriers[group])) {
                myCarriers[group][listName]['assignments'] = myCarriers[group][listName]['assignments'].filter(a => a.id !== result.data.assignment.id)
            }
        }
    
        // Update the store
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: myCarriers})

        // Refresh Recent carriers list
        yield call(refreshRecentCarriersList, result.data.carrier.id)
       
        if (_.get(result, 'data.carrier', null)) {

            yield put({ type: SET_SELECTED_CARRIER, selectedCarrier: result.data.carrier })
        }
    }
}

function* workerAddCarrierAssignment(action) {    
    // Add assignment to DB
    let assignment = Object.assign({}, action.payload)
    const result = yield call(addCarrierAssignment, {assignment: assignment})
    const currentUser = yield select(getCurrentUser)

    if (currentUser.user_id === assignment.user_id) {        
        // Get assignments
        let myCarriers = yield select(getMyAssignments)

        let addToThisList = null

        if (assignment.assignment_type === 'OWNERSHIP') {
            addToThisList = myCarriers['default']['defaultOwnership']['assignments']

            //remove from lead list if there
            myCarriers['default']['defaultLeads']['assignments'] = myCarriers['default']['defaultLeads']['assignments'].filter(a => {
                if (a.carrier_id !== result.data.assignment.carrier_id || a.user_id !== result.data.assignment.user_id) {
                    return true
                } else {
                    return false
                }
            })
        } else {
            addToThisList = myCarriers['default']['defaultLeads']['assignments']
        }

        addToThisList.push(result.data.assignment)
        myCarriers['default']['defaultOwnership']['assignments'] = addToThisList

        // Update the store
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: myCarriers})

        if (_.get(result, 'data.carrier', null)) {
            yield put({ type: SET_SELECTED_CARRIER, selectedCarrier: result.data.carrier })
        }
    }
    
    // Refresh Recent carriers list
    yield call(refreshRecentCarriersList, result.data.carrier.id)
}


function* workerSaveFolderUpdates(action) {
    const result = yield call(saveFolderUpdates, action.updates)
    const myCarriers = result.data

    // Update the store
    yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
    yield put({ type: SET_MY_ASSIGNMENTS, assignments: myCarriers})
}

function* workerFetchCarrierFolders(_action) {
    const result = yield call(getCarrierFolders)
    const carrierFolders = result.data

    yield put({type: SET_CARRIER_FOLDERS, carrierFolders})
}

function* workerAddCarrierFolder(action) {
    const {folder_name, public_folder} = action

    const result = yield call(addCarrierFolder, folder_name, public_folder)

    if (result.status === 201) {
        yield put({type: UPDATE_CARRIER_FOLDERS, folder: result.data, action: 'add'})
    }
}

function* workerUpdateCarrierFolder(action) {
    const {folder_id, folder_name, public_folder} = action
    const result = yield call(updateCarrierFolder, folder_id, folder_name, public_folder)
    if (result.status === 200) {
        yield put({type: UPDATE_CARRIER_FOLDERS, folder: result.data, action: 'update'})
    }
}

function* workerDeleteCarrierFolder(action) {
    const {folder_id} = action

    const result = yield call(deleteCarrierFolder, folder_id)
    if (result.status === 200) {
        yield put({type: UPDATE_CARRIER_FOLDERS, folder: {id: folder_id}, action: 'delete'})
    } else {
        yield put({type: ADD_MESSAGE, message: result?.response?.data?.error || 'error deleting the folder'})
    }
}

function* watchFetchCarrierMatchingLoadsWorker(action) {
    const selectedCarrier = yield select(getSelectedCarrier)
    const matchingLoadsResponse = yield call(getMatchesByMc, selectedCarrier.mc)

    yield put ({type: RETURN_LOADS_FOR_TRUCK})
    if (matchingLoadsResponse.status === 200) {

        // here we want to spin through the loads if a carrier truck is selected
        const matches = matchingLoadsResponse.data
        const selectedCarrierTruck = yield select(getSelectedCarrierTruck)
        yield put({type: SET_CARRIER_MATCHING_LOAD, matches, selectedCarrierTruck})


        //TODO:  Here we want to add in the numbers to the trucks.
        const truckEntryTruckList = yield select(getTruckEntryTruckList)


        if (truckEntryTruckList?.length) {
            const truckList = []

            // This updates the trucks with the matching counts.  You should do the same when matches stream in
            for (let truck of truckEntryTruckList) {
                const matchCount = matchingLoadsResponse.data.filter(m => m.truck.truck_id === truck.truck_id).length
                truck.matching_loads_count = matchCount
                //TODO: We need to check the quality match here
                truck.exact_match = (matchCount > 0) ? 'YES' : 'NO'

                // Note: this is done to force a refresh on DSTable
                truck.updated_at = moment(truck.updated_at).add(1, 'millisecond').toISOString()
                truckList.push(truck)
            }
            yield put({type: SET_TRUCK_ENTRY_TRUCK_LIST, trucks: truckList})
        }
    } else {
        // might want to add a message
        yield put({type: SET_CARRIER_MATCHING_LOAD, carrierMatchingLoads: []})
    }
}

function* watchFetchCarrierNextLoadsWorker(action) {
    const {load_id} = action

    if (load_id) {
        const response = yield call(fetchNextLoads, load_id)
        if (response && response.data && response.data.length > 0) {
          yield put({type: RETURN_LOADS_FOR_TRUCK, returnLoads: response.data})
        } else {
          yield put({type: RETURN_LOADS_FOR_TRUCK, returnLoads: []})
        }
    } else {
        yield put({type: RETURN_LOADS_FOR_TRUCK, returnLoads: []})
    }
}

function* watchSelectCarrierLoadWorker(action) {
    yield put({type: SET_CARRIER_SELECTED_LOAD, load: action.load})
    yield put({type: FETCH_NEXT_LOADS, load: action.load})
}

function* watchAddCarrierLoadMatchWorker(action) {
    const user = yield select(getCurrentUser)
    const userId = user?.user_id ? user.user_id : null

    //TODO: add extra stuff here to update other reducers for selected match / carrier
    yield put({type: ADD_MATCH, match: action.match, userId})
    const selectedCarrierTruck = yield select(getSelectedCarrierTruck)
    const carrier = yield select(getSelectedCarrier)
    if (carrier?.mc && carrier.mc === action.match?.carrier?.mc) {
        yield put({type: ADD_CARRIER_MATCHING_LOAD, match: action.match, selectedCarrierTruck})

        //UPDATE TRUCKS from matches
        const trucks = yield select(getTruckEntryTruckList)
        const updatedTrucks = []
        for (let truck of trucks) {
            if (truck.truck_id === action.match.truck.truck_id) {
                truck.matching_loads_count = (truck.matching_loads_count) ? truck.matching_loads_count + 1 : 1
                truck.exact_match = 'YES'
                // Note: this is done to force a refresh on DSTable
                truck.updated_at = moment(truck.updated_at).add(1, 'millisecond').toISOString()
            }
            updatedTrucks.push(truck)
        }
        yield put ({type: SET_TRUCK_ENTRY_TRUCK_LIST, trucks: updatedTrucks})
    }
}

function* watchRemoveCarrierLoadMatchWorker(action) {
    yield put({type: REMOVE_MATCH, match: action.match})

    const carrier = yield select(getSelectedCarrier)
    if (action.match?.id) {
        if (carrier?.mc && carrier.mc === action.match.carrier.mc) {
            yield put({type: REMOVE_CARRIER_MATCHING_LOAD, match: action.match})
            //UPDATE the truck matches counts
            const trucks = yield select(getTruckEntryTruckList)
            const updatedTrucks = []
            for (let truck of trucks) {
                if (truck.truck_id === action.match.truck.truck_id) {
                    truck.matching_loads_count = (truck.matching_loads_count) ? truck.matching_loads_count - 1 : 0
                    truck.exact_match = (truck.matching_loads_count) ? 'YES' : 'NO'
                    // Note: this is done to force a refresh on DSTable
                    truck.updated_at = moment(truck.updated_at).add(1, 'millisecond').toISOString()
                }
                updatedTrucks.push(truck)
            }
            yield put ({type: SET_TRUCK_ENTRY_TRUCK_LIST, trucks: updatedTrucks})
        }
    }
}

function* fetchUserOwnedCarriersWorker() {
    const user = yield select(getCurrentUser)
    
    let carriers = []

    yield put({ type: SET_USER_OWNED_CARRIERS, carriers: null })
    if (user) {
        const result = yield call(fetchUserOwnedCarriers)
        carriers = result?.data ? result.data : []
    } else {
        carriers = []
    }

    yield put({ type: SET_USER_OWNED_CARRIERS, carriers})
}

function* fetchUserCarriersWorker() {
    const user = yield select(getCurrentUser)
    
    let userCarriers = []

    yield put({ type: SET_USER_CARRIERS, userCarriers: null })
    
    if (user) {
        const result = yield call(fetchUserCarriers)
        userCarriers = result?.data ? result.data : []
    } else {
        userCarriers = []
    }

    yield put({ type: SET_USER_CARRIERS, userCarriers})
}

function* addUserCarrierWorker(action) {
    const { carrier } = action

    const result = yield call(addUserCarrier, carrier.id)

    if (result?.status === 201) {
        yield put({ type: APPEND_TO_USER_CARRIERS, carrier})
        const selectedCarrier = yield select(getSelectedCarrier)

        // If following the selected carrier, we need to append to its followers.
        if (selectedCarrier.id === carrier.id) {
            const user = yield select(getCurrentUser)
            const dbUser = {
                first_name: user.name.first_name,
                last_name: user.name.last_name,
                id: user.user_id
            }

            // TODO: Here we fetch the carrier from the store because if the carrier is coming from dragged folder, it doesn't have 'users' eagered.
            // Might be able to fix it on the folder instead.
            const fetchedSelectedCarrier = yield select(getSelectedCarrier)
            yield put({ type: APPEND_TO_CARRIER_FOLLOWERS, selectedCarrier: fetchedSelectedCarrier, user: dbUser}) // Update followers
        }
    } else {
        yield put({type: ADD_MESSAGE, message: 'Failed to follow carrier'})
    }
}

function* deleteUserCarrierWorker(action) {
    const { carrierId } = action
    
    const result = yield call(deleteUserCarrier, carrierId)

    if (result?.status === 200) {
        yield put({ type: REMOVE_FROM_USER_CARRIERS, carrierId})

        const selectedCarrier = yield select(getSelectedCarrier)
        
        // If unfollowing the selected carrier, we need to remove from it's followers
        if (selectedCarrier.id === carrierId) {
            const user = yield select(getCurrentUser)
            const userId = user.user_id
            yield put({ type: REMOVE_FROM_CARRIER_FOLLOWERS, userId}) // Update followers
        }
    } else {
        yield put({type: ADD_MESSAGE, message: 'Failed to un-follow carrier'})
    }
}

function* setCarrierDeadheadWorker(action) {
    const {carrier, deadhead} = action
    const selectedCarrier = yield select(getSelectedCarrier)
    if (carrier?.id && (carrier.id === selectedCarrier?.id)) {
        const result = yield call(patchCarrier, carrier.id, {deadhead})
        if (result.data) {
            selectedCarrier.deadhead = result.data.deadhead
            selectedCarrier.updated_at = result.data.updated_at
        }
        // if the result is good then we want to add it to the carrier and set the carrier
        selectedCarrier.deadhead = deadhead
        yield put({type: SET_SELECTED_CARRIER, selectedCarrier: selectedCarrier})
        yield put({type: ADD_MESSAGE, message: 'Deadhead updated on carrier.  Rematch on Carrier initiated'})
    }
}

function* workerFetchCarrierOwners(action) {
    const {withMatches, filter = {}} = action
    yield put({type: SET_CARRIER_OWNERS, carrierOwners: null})
    const results = yield call(fetchCarrierOwners, filter)

    if ((results.status === 200) && (results.data?.length)) {
        let carrierOwners = results.data

        if (withMatches) {
            const matchResults = yield call(fetchMatches, filter)
            
            if ((matchResults.status === 200) && (matchResults.data?.length)) {
                carrierOwners = carrierOwners.map(co => {
                    co.matches = matchResults.data.filter(m => m.carrier.mc === co.mc).map(m => m.id)

                    return co
                })
            }
        }
        yield put({type: SET_CARRIER_OWNERS, carrierOwners})
    } else {
        yield put({type: SET_CARRIER_OWNERS, carrierOwners: []})
    }
}

function *workerRevokeCarrierOwner(action) {
    const {carrier} = action

    try {
        const result = yield call(revokeCarrierOwner, carrier)
        if (result.status === 200) {
            yield put({type: REMOVE_CARRIER_OWNER, carrier: result.data})
            yield put({type: REMOVE_USER_OWNED_CARRIERS, carrier: result.data})
            const selectedCarrier = yield select(getSelectedCarrier)
            if (selectedCarrier?.id === carrier?.id) {
                yield put({type: SELECTED_CARRIER_REMOVE_OWNER})
            }
        } else {
            console.log('error', result.response)
            yield put({type: ADD_MESSAGE, message: result.response.data})
        }
    } catch (e) {
        console.log('error', e.message)
        yield put({type: ADD_MESSAGE, message: e.message})
    }
}

export function* watchFetchCarrierByMCSaga() {
    yield takeLatest(FETCH_CARRIER_BY_MC, workerFetchCarrierByMCSaga)
}

export function* watchFetchCarriersWithFilterSaga() {
    yield takeLatest(FETCH_CARRIERS_WITH_FILTER, workerFetchCarriersWithFilterSaga)
}

export function* watchSelectedCarrierSaga() {
    yield takeLatest(CARRIER_SELECTED, workerCarrierSelectedSaga)
}

export function* watchFetchMyCarrierAssignmentsSaga() {
        yield takeLatest(FETCH_MY_CARRIER_ASSIGNMENTS, workerFetchMyCarrierAssignmentsSaga)
    }

export function* watchCarrierTruckSelectedSaga() {
    yield takeLatest(CARRIER_TRUCK_SELECTED, workerCarrierTruckSelectedSaga)
}

export function* watchTruckRefreshSaga() {
    yield takeEvery(REFRESH_TRUCK, workerTruckRefreshSaga)
}

export function* watchDeleteSelectedCarrierContact() {
    yield takeEvery(DELETE_SELECTED_CARRIER_CONTACT, workerDeleteSelectedCarrierContact)
}

export function* watchDeleteSelectedCarrierNote() {
    yield takeEvery(DELETE_SELECTED_CARRIER_NOTE, workerDeleteSelectedCarrierNote)
}

export function* watchUpdatedAndShowRecentCarrierList() {
    yield takeEvery(UPDATE_AND_SHOW_RECENT_CARRIER_LIST, workerUpdatedAndShowRecentCarrierList)
}

export function* watchDeleteCarrierAssignment() {
    yield takeEvery(DELETE_CARRIER_ASSIGNMENT, workerDeleteCarrierAssignment)
}

export function* watchAddCarrierAssignment() {
    yield takeEvery(ADD_CARRIER_ASSIGNMENT, workerAddCarrierAssignment)
}

export function* watchSaveFolderUpdatesSaga() {
    yield takeEvery(SAVE_FOLDER_UPDATES, workerSaveFolderUpdates)
}

export function* watchFetchCarrierFoldersSaga() {
    yield takeLatest(FETCH_CARRIER_FOLDERS, workerFetchCarrierFolders)
}

export function* watchAddCarrierFolderSaga() {
    yield takeLatest(ADD_CARRIER_FOLDER, workerAddCarrierFolder)
}

export function* watchUpdateCarrierFolderSaga() {
    yield takeLatest(UPDATE_CARRIER_FOLDER, workerUpdateCarrierFolder)
}

export function* watchDeleteCarrierFolderSaga() {
    yield takeLatest(DELETE_CARRIER_FOLDER, workerDeleteCarrierFolder)
}

export function* watchFetchCarrierMatchingLoadsSaga() {
    yield takeLatest(FETCH_CARRIER_MATCHING_LOADS, watchFetchCarrierMatchingLoadsWorker)
}

export function* watchFetchCarrierNextLoadsSaga() {
    yield takeLatest(FETCH_CARRIER_NEXT_LOADS, watchFetchCarrierNextLoadsWorker)
}

export function* watchSelectCarrierLoadSaga() {
    yield takeLatest(SELECT_CARRIER_LOAD, watchSelectCarrierLoadWorker)
}

export function* watchAddCarrierLoadMatchEventSaga() {
    yield takeEvery(ADD_CARRIER_LOAD_MATCH_EVENT, watchAddCarrierLoadMatchWorker)
}

export function* watchRemoveCarrierLoadMatchEventSaga() {
    yield takeEvery(REMOVE_CARRIER_LOAD_MATCH_EVENT, watchRemoveCarrierLoadMatchWorker)
}

export function* watchFetchUserCarriersSaga() {
    yield takeLatest(FETCH_USER_CARRIERS, fetchUserCarriersWorker)
}

export function* watchFetchUserOwnedCarriersSaga() {
    yield takeLatest(FETCH_USER_OWNED_CARRIERS, fetchUserOwnedCarriersWorker)
}

export function* watchAddUserCarrierSaga() {
    yield takeLatest(ADD_USER_CARRIER, addUserCarrierWorker)
}

export function* watchDeleteUserCarrierSaga() {
    yield takeLatest(DELETE_USER_CARRIER, deleteUserCarrierWorker)
}

export function* watchSetCarrierDeadheadSaga() {
    yield takeLatest(SET_CARRIER_DEADHEAD, setCarrierDeadheadWorker)
}

export function* watchFetchCarrierOwnersSaga() {
    yield takeLatest(FETCH_CARRIER_OWNERS, workerFetchCarrierOwners)
}

export function* watchRevokeCarrierOwnerSaga() {
    yield takeLatest(REVOKE_CARRIER_OWNER, workerRevokeCarrierOwner)
}
