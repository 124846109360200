import { takeLatest, takeEvery, call, put, select, delay } from "redux-saga/effects"
import {
    SET_LOADS,
    FETCH_OPPORTUNITY_LOADS,
    SET_OPPORTUNITY_LOADS,
    FETCH_LOADS, 
    LOAD_SELECTED, 
    SET_SELECTED_LOAD, 
    ADD_MESSAGE,
    SET_LOAD_FILTER, 
    LOAD_UPDATED, 
    SET_LOADS_ON_LOAD_UPDATE,
    ADD_LEADS_FROM_SELECTED_LOAD, 
    FETCH_MATCHED_TRUCKS,
    SET_EXTERNAL_LOADS, 
    SET_MARKET_LOADS,
    SET_SELECTED_TRUCK, 
    ADD_EVENT_LIST_EVENT,
    FETCH_EXERNAL_LOADS_FOR_LOAD,
    SET_LOAD_ASSIGNED_USER,
    SET_LOAD_PRIORITY,
    POST_LOAD,
    UNPOST_LOAD,
    FETCH_HISTORIC_LOADS_FOR_LOAD,
    FETCH_MARKET_LOADS_FOR_LOAD,
    LOAD_TRUCK_ONLY_SELECTED,
    FETCH_ALL_EMAILS,
    SET_EMAILS,
    UPDATE_EMAILS,
    FETCH_SELECTED_LOAD,
    FETCH_QUERY_COUNTER,
    SET_QUERY_COUNTER,
    FETCH_CONNECT_LOGS,
    SET_CONNECT_LOGS,
    FETCH_INSIGHT_DATA,
    SET_INSIGHT_DATA,
    SET_ALL_CONNECT_LOGS,
    FETCH_DAT_RATES,
    SET_DAT_RATES,
    ADD_QUERY_COUNTER,
    FETCH_INSIGHT_HISTORIC_LEADS_DATA,
    FETCH_NEXT_LOADS,
    RETURN_LOADS_FOR_TRUCK,
    FETCH_AND_SET_INSIGHT_DATA,
    FETCH_AND_SET_CONNECT_LOGS,
    FETCH_AND_SET_LEADS,
    ADD_THRESHOLD_ON_LOAD,
    FETCH_AND_SET_THRESHOLD,
    FETCH_AND_SET_INCOMING_TEXT_BY_LOAD,
    UPDATE_LOAD_TEAM
} from '../actionTypes'
import { fetchLoad, createLogsLoads, fetchLoads, 
  postLoad, unpostLoad, fetchLeadData, datRatesByLoadId,
  fetchNextLoads, fetchRecentLoads, addThresholdToLoad, } from '../loadActions'
import { fetchAllEmails } from '../emailActions'
import { fetchQueryCounter, addQueryCounter  } from '../queryCounterActions'
import { fetchConnectLogs } from '../connectLogsActions'
import { fetchInsightData } from '../insightDataActions'

import { setLoadRep, toggleLoadPriority } from '../repAssignmentAction.js'
import { getLoadFilter, getSelectedLoad, getCurrentUser } from '../reducers/rootReducer'
import { isLoadMatchedByFilter } from "../../utils/load-update-helpers.js"

import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'

function addEmail(updatedEmail) {
  return axios({
      method: "post",
      url: "/api/emails/add_email",
      data: updatedEmail
  })
}

function updateLoadTeam(pro, team_color) {
  return axios({
    method: "post",
    url: `/api/loads/${pro}/team`,
    data: {
      team_color
    }
  })
}



function* workerAddQueryCounterSaga(payload) {
  try {
    // Fetch queryCounterData Data
    yield call(addQueryCounter, payload.parms)
  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}

// worker saga: makes the api call when watcher saga sees the action
function* workerFetchLoadSaga(payload) {
  try {
    // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
    yield put({ type: SET_LOADS, loads: null })
    yield put({ type: SET_LOAD_FILTER, parms: payload.parms })
    let loads = []

    // Fetch and set LOADS
    let response = yield call(fetchLoads, payload.parms)

    if (response) {
      loads = response.data
    }

    yield put({ type: SET_ALL_CONNECT_LOGS, allConnectLogs: null })
    let allConnectLogs = []
    // Fetch and set Emails
    response = yield call(fetchConnectLogs, {load_id:null})
    if (response) {
      allConnectLogs = response
    }
    yield put({ type: SET_ALL_CONNECT_LOGS, allConnectLogs })
    // Remove from all lists
    if (loads && loads.length > 0) {
      let updatedLoads = []
      _.forEach(loads, function(value, key) {
        let ab = _.filter(allConnectLogs, itx => value.id === itx.load_id)
        let qb = _.filter(ab, itx => parseInt(itx.historic_carrier_rate) < 10)
        qb = (qb && qb.length) ? qb.length : 0
        ab = (ab && ab.length) ? ab.length : 0
        value['qb_ab'] = `${qb}/${ab}`
        updatedLoads.push(value)
      });

      // Only set the loads after fully constructed
      yield put({ type: SET_LOADS, loads})

      //yield put({ type: SET_LOADS_ON_CALL_LOGS_UPDATE, params})
    }
    let allLoads = []
    if (allConnectLogs && allConnectLogs.length > 0) {
      // Fetch recent Loads
      response = yield call(fetchRecentLoads)

      if (response && response.data) {
        allLoads = response.data
      }
      let allConnectLogsUpdated = []
      _.forEach(allConnectLogs, function(value, key) {
        let currentLoads = _.filter(allLoads, itx => value.load_id === itx.id)
        let bids = _.map(currentLoads,'bids')
        bids = _.flatten(bids)
        bids = _.filter(bids, itx => itx.carrier_id === value.carrier_id && itx.user_id !== "c335ad15-634e-4b47-ba0b-980968ddd1df" )//connect userid
        if (bids && bids.length > 0) {
          value['called'] = 'YES'
        } else {
          value['called'] = 'NO'
        }
        allConnectLogsUpdated.push(value)
      });

      // This is logic is to prevent rerendering via put(null) then delay(600)
      allConnectLogsUpdated = _.map(allConnectLogsUpdated, (obj) => {
        obj.created_at = moment.utc(obj.created_at).add(1, 'ms').format()
        return obj
      })

      yield put({ type: SET_ALL_CONNECT_LOGS, allConnectLogs: allConnectLogsUpdated})

      //yield put({ type: SET_LOADS_ON_CALL_LOGS_UPDATE, params})

    }

  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}



function* workerUpdateEmailSaga(payload) {
  try {
    // // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
    // yield put({ type: SET_EMAILS, emails: null })
    let emails = payload.parms.emails
    // let updatedEmails
    let updatedEmail
    if (payload.parms.id) {
      updatedEmail = emails.filter(value => value.id === payload.parms.id)
      yield put({ type: SET_EMAILS, emails: null })
      emails.splice(emails.findIndex(item => item.id === payload.parms.id), 1)
      yield put({ type: SET_EMAILS, emails: emails })
      yield call(addEmail, updatedEmail)
    }
    // else {
    //   updatedEmails = emails
    // }
  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}

function* workerFetchEmailSaga(payload) {
  try {
    let emails = []
    // Fetch and set Emails
    let response = yield call(fetchAllEmails, payload)
    if (response) {
      emails = response.data
    }

    yield put({ type: SET_EMAILS, emails })

  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}

function* workerFetchQueryCounterSaga(payload) {
  try {
    // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
    yield put({ type: SET_QUERY_COUNTER, queryCounterData: null })
    let queryCounterData = []
    let response
    // Fetch and set Emails
    if (payload && payload.payload) {
      response = yield call(fetchQueryCounter, payload.payload)
    } else {
      response = yield call(fetchQueryCounter, payload)
    }
    
    if (response) {
      queryCounterData = response
    }
    yield put({ type: SET_QUERY_COUNTER, queryCounterData })

  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}

function* workerFetchInsightDataSaga(payload) {
  try {
    // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
    yield put({ type: SET_INSIGHT_DATA, insightData: null })

    let insightData = []
    // Fetch and set Insight Data
    let response
    if (payload && payload.parms) {
      response = yield call(fetchInsightData, payload.parms)
    } else {
      response = yield call(fetchInsightData, payload.payload.selectedLoad)
    }

    if (response && !_.isEmpty(response)) {
      insightData = response
      
    }else{
      insightData = []
    }
    yield put({ type: SET_INSIGHT_DATA, insightData })

  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}

function* workerFetchDatRatesSaga({load, timeRestricted}) {
  const loadId = load?.id
  const createdAt = load?.created_at
  let datRates = load?.rates

  try {
    if (!datRates) {
      let doFetch = true

      if (timeRestricted) {
        const createdAtPlus10Min = moment(createdAt).add(10, 'minutes')
        doFetch = moment().isAfter(createdAtPlus10Min) ? true : false
      }

      if (doFetch) {
        // Fetch and set Dat Rates Data
        let response = yield call(datRatesByLoadId, loadId)
        datRates = response && !_.isEmpty(response) ? response.data : null
      } else {
        datRates = null
      }  
    }
    
    yield put({ type: SET_DAT_RATES, datRates })
  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}

function* workerFetchConnectLogsSaga(payload) {
  try {
    // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
    yield put({ type: SET_CONNECT_LOGS, connectLogsData: null })
    let connectLogsData = []
    // Fetch and set Emails
    let response 
    if (payload && payload.payload && payload.payload.selectedLoad) {
      response = yield call(fetchConnectLogs, {load_id:payload.payload.selectedLoad.id})
    } else {
      response = yield call(fetchConnectLogs, {load_id:payload.parms.load_id})
    }
    if (response) {
      connectLogsData = response
    }
    yield put({ type: SET_CONNECT_LOGS, connectLogsData })

  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}

function* workerLoadTruckOnlySelectedSaga(payload) {
  try {
    if (payload.truck) {
      yield put({ type: SET_SELECTED_TRUCK, selectedTruck: null })
      yield delay(600)
      yield put({ type: SET_SELECTED_TRUCK, selectedTruck: payload.truck })
    }
  } catch (err) {
    console.log('workerLoadTruckOnlySelectedSaga error: ', err)
    yield put({type: ADD_MESSAGE, message: 'Load not found'})
  }
}


function* loadSelectedSaga(payload) {
  try {
    const response = yield call(fetchLoad, payload.selectedLoad.id)
    const load = response.data
    
    // Caller can pass flag 'currentOnly' to only set the selectedLoad if it is a current load
    const currentOnly = _.get(payload, 'selectedLoad.currentOnly')
    const today = moment().format('YYYY-MM-DD')
    
    // console.log(`${load.pickup_date} ${today}`)
    if (currentOnly && moment(load.pickup_date).isBefore(moment(today))) {
      throw Error('Ref pulled an old load')
    }

    yield put({ type: ADD_LEADS_FROM_SELECTED_LOAD, leadData: null})
    //TODO: Testing the notification list

    yield put({ type: ADD_EVENT_LIST_EVENT, event: `load ${load.pro} selected` })
    yield put({ type: SET_SELECTED_TRUCK, selectedTruck: null })
    
    if (load) {
      yield put({ type: SET_QUERY_COUNTER, queryCounterData: null })
      yield put({ type: SET_INSIGHT_DATA, insightData: [] })
      yield put({ type: SET_DAT_RATES, datRates: null })
      yield put({ type: SET_EXTERNAL_LOADS, loads: null })
      yield put({ type: SET_MARKET_LOADS, loads: [] })
      yield put({ type: SET_CONNECT_LOGS, connectLogsData: null })

      //yield put({type: SET_SELECTED_LOAD, selectedLoad: {...load, bids: null}})
      yield put({ type: SET_SELECTED_LOAD, selectedLoad: load })
      yield put({ type: FETCH_MATCHED_TRUCKS, loadId: load.id })
      if (payload.selectedCarrierTruck) {
        yield put({ type: SET_SELECTED_TRUCK, selectedTruck: payload.selectedCarrierTruck })
      }

      try {      
        yield call(createLogsLoads, payload.selectedLoad.id)
      } catch (err) {
        console.log('Could Not Create Log for Load id : ',payload.selectedLoad.id,' With Error: ',err)
      }
      
      yield put({ 
        type: FETCH_DAT_RATES,
        load: load,
        timeRestricted: true
      })

      // fetch insight data
      yield put({ type: FETCH_AND_SET_INSIGHT_DATA, payload: load })
      // fetch queried counter data
      yield put({ type: FETCH_QUERY_COUNTER, payload:load })
      // fetch connect logs
      yield put({ type: FETCH_AND_SET_CONNECT_LOGS, payload:load })
      // // Fetch and set Leads data
      yield put({ type: FETCH_AND_SET_LEADS, payload:load })
      // fetch and set threshold for load
      yield put({ type: FETCH_AND_SET_THRESHOLD, payload:load })
      // fetch and set texts for a load
      yield put({ type: FETCH_AND_SET_INCOMING_TEXT_BY_LOAD, selectedLoadId:load?.id })
      //TODO: here we want to also set the selected carrier truck if we have it
      if (['OPEN', 'RESERVED', 'BOUNCED'].includes(load.status)) {
        yield put({ type: FETCH_EXERNAL_LOADS_FOR_LOAD, load: load })  // Similar Loads (orig & dest search)
        yield put({ type: FETCH_MARKET_LOADS_FOR_LOAD, load: load})   // Market loads (destination ANY)  TODO: Turned off in otter
      } else {
        yield put({ type: SET_EXTERNAL_LOADS, loads: [] })
      }
      load['rangeStart'] = moment().startOf('day').subtract(1, 'month').format("MM/DD/YYYY")
      load['rangeEnd'] = moment().format("MM/DD/YYYY")
      yield put({ type: FETCH_HISTORIC_LOADS_FOR_LOAD, load: load })
    } else {
      yield put({ type: ADD_MESSAGE, message: `load ${payload.selectedLoad.id} not found` })
    }   
  } catch (err) {
    console.log('loadSelectedSaga error: ', err)
    yield put({type: ADD_MESSAGE, message: 'Load not found'})
  }
}

/**
 * This comes from pusher live load updates.
 * @param {*} payload 
 */
function* workerLoadUpdatedSaga(payload) {
  let updatedLoad = null
  let doFetchLoad = false
  let matchesFilter = false
  let loadId = null

  const load = payload.load
  const selectedLoad = yield select(getSelectedLoad)
  const filter = yield select(getLoadFilter)
  const user = yield select(getCurrentUser)

  // Determine if we should fetch the load
  if (load?.id) {    
    matchesFilter = yield call(isLoadMatchedByFilter, load, filter, user)

    if (matchesFilter || selectedLoad?.id === load.id) {
        loadId = load?.id
        doFetchLoad = true
    } else {
        doFetchLoad = false
    }
  } else if (typeof load === 'string') {  // Old method.  TODO: Spot loads creation uses this, also.  Should we change it ot send { id: id } ?
    loadId = load
    doFetchLoad = true
  } else {
    console.log('loadSaga.js - workerLoadUpdatedSaga(): Not a valid load.')
    return
  }

  // Set load we will pass to the reducer.
  if (doFetchLoad) {
      //Note: Once we get away from pusher limit, we should not need to call fetchLoad on each event
      const response = yield call(fetchLoad, loadId, true)
      if (response.status === 200) {
        updatedLoad = response.data
      } else {
        updatedLoad = null
      }
  } else {
    updatedLoad = load
  }
  
  const params = {
    updatedLoad: updatedLoad,
    filter: filter,
    user: user
  }
  
  yield put({type: SET_LOADS_ON_LOAD_UPDATE, params })

  //OK, so this is overriding the currently viewed load in load details
  if (selectedLoad && updatedLoad?.id === selectedLoad.id) {
    yield put({type: SET_SELECTED_LOAD, selectedLoad: updatedLoad})

    //TODO: Need to colorize any current trucks with bids
  }
}

function* workerSetLoadAssignedUserSaga({data}) {
  yield call(setLoadRep, data)
}

function* workerSetLoadPrioritySaga({data}) {
  yield call(toggleLoadPriority, data)
}

function* workerPostLoadSaga({load_id, data}) {
  try {
    const result = yield call(postLoad, load_id, data)
    const postings = result && result.data ? result.data : []
    let resultMessage = ''

    if (postings.length) {
      const failedPosts = postings.filter((p)=>{ return p.dat_asset_id === null})
      const numSuccessfulPosts = postings.length - failedPosts.length
      const numFailedPosts = failedPosts.length

      if (numSuccessfulPosts) {
        resultMessage = resultMessage + `${numSuccessfulPosts} SUCCESSFUL post${numSuccessfulPosts !==1 ? 's' : ''}.  \n\n`
      }

      if (numFailedPosts) {
        resultMessage = resultMessage + `${numFailedPosts} FAILED post${failedPosts.length !==1 ? 's' : ''}.`
      }
    } else {
      resultMessage = 'FAILED to post'
    }

    yield put({type: ADD_MESSAGE, message: resultMessage})
  } catch (e) {
    yield put({type: ADD_MESSAGE, message: `unable to post this load`})
  }
}

function* workerUnpostLoadSaga({load_id, post_id = null}) {
  try {
    yield call(unpostLoad, load_id, post_id)
    yield put({type: ADD_MESSAGE, message: `load posting removed`})
  } catch (e) {
    yield put({type: ADD_MESSAGE, message: `unable to remove load posting`})
  }

}

function* workerFetchSelectedLoadSaga(payload) {
  try {
    const loadx = payload.loadx
    yield put ({type: SET_SELECTED_LOAD, selectedLoad: loadx})
  } catch (e) {
    yield put({type: ADD_MESSAGE, message: `unable to remove load posting`})
  }
}


function* workerFetchInsightHistoricLeadsSaga(payload) {
  try {
    const selectedLoad = yield select(getSelectedLoad)
    yield put({ type: ADD_LEADS_FROM_SELECTED_LOAD, leadData: null})
    let insightData = []
    let response
    // Fetch and set Insight Data
    response = yield call(fetchInsightData, payload.parms)

    if (response && !_.isEmpty(response)) {
      insightData = response
    } else {
      insightData = null
    }

    yield put({ type: SET_INSIGHT_DATA, insightData })
    const responseLoad = yield call(fetchLeadData, selectedLoad,payload.parms)
    const leadData = responseLoad?.data
    if(leadData)  yield put({ type: ADD_LEADS_FROM_SELECTED_LOAD, leadData})
    selectedLoad['rangeStart'] = payload.parms.rangeStart
    selectedLoad['rangeEnd'] = payload.parms.rangeEnd
    yield put({ type: FETCH_HISTORIC_LOADS_FOR_LOAD, load: selectedLoad })
    

  } catch (e) {
    yield put({type: ADD_MESSAGE, message: `unable to fetch IHL Data`})
  }
}

function* workerFetchNextLoads(payload) {
  try {
    const {load} = payload

    if (load) {
      yield put({ type: RETURN_LOADS_FOR_TRUCK, returnLoads: null })
      const response = yield call(fetchNextLoads, load.id)
      if (response && response.data && response.data.length > 0) {
        yield put({ type: RETURN_LOADS_FOR_TRUCK, returnLoads: response.data })
      } else {
        yield put({ type: RETURN_LOADS_FOR_TRUCK, returnLoads: [] })
      }

    } else {
      yield put({ type: RETURN_LOADS_FOR_TRUCK, returnLoads: [] })
    }
  } catch (e) {
    yield put({ type: ADD_MESSAGE, message: `Unable to Fetch Return Loads` })
  }
}


function* workerFetchOpportunityLoadsSaga(payload) {
  try {
      // Fetch and set OPPORTUNITY LOADS
      let response = null
      let opportunityLoads = null

      const filter = yield select(getLoadFilter)

      let opportunityParms = Object.assign({}, filter)
      opportunityParms.opportunityLoads = true
      response = yield call(fetchLoads, opportunityParms)

      if (response) {
        opportunityLoads = response.data
      }

      yield delay(600)
      yield put({ type: SET_OPPORTUNITY_LOADS, loads: opportunityLoads })
  } catch (e) {
    yield put({type: ADD_MESSAGE, message: `unable to Fetch Opportunity Loads`})
  }
}

function* workerAddThresholdOnLoadSaga(payload) {
  try {
      const load = payload.payload.selectedLoad
      let baseRate = payload.payload.baseRate
      let percent = payload.payload.percent
      const source = payload.payload.source
      if (load) {
        const minRate = load?.rates?.low_lh_total || '100'
        baseRate = parseInt(baseRate)
        percent = parseInt(percent)
        const maxRate = parseInt(baseRate) + ((percent/100) * baseRate)
        // addThresholdToLoad(load.id,parseInt(percent),
        // parseInt(baseRate),source,parseInt(minRate),parseInt(maxRate))
        let response = yield call(addThresholdToLoad, load.id,parseInt(percent),
        parseInt(baseRate),source,parseInt(minRate),parseInt(maxRate))
        if (response && response.data) {
          yield put({ type: ADD_MESSAGE, message: 'Threshold successfully added' })
        } else {
          yield put({type: ADD_MESSAGE, message: `unable to Add Threshold on Loads`})
        }
      }
  } catch (e) {
    yield put({type: ADD_MESSAGE, message: `unable to Add Threshold on Loads`})
  }
}

function* workerSetLoadTeam(payload) {
  const {pro, teamColor} = payload
  if (pro && teamColor) {
    yield call(updateLoadTeam, pro, teamColor)
  }
}



export function* watchFetchLoadsSaga() {
    yield takeLatest(FETCH_LOADS, workerFetchLoadSaga)
}

export function* watchFetchAllEmailsSaga() {
  yield takeLatest(FETCH_ALL_EMAILS, workerFetchEmailSaga)
}

export function* watchUpdateEmailsSaga() {
  yield takeLatest(UPDATE_EMAILS, workerUpdateEmailSaga)
}


//TODO: Might need to take every???
export function* watchSelectedLoadSaga() {
    yield takeLatest(LOAD_SELECTED, loadSelectedSaga)
}

export function* watchLoadUpdatedSaga() {
    yield takeEvery(LOAD_UPDATED, workerLoadUpdatedSaga)
}

export function* watchSetLoadAssignedUserSaga() {
  yield takeEvery(SET_LOAD_ASSIGNED_USER, workerSetLoadAssignedUserSaga)
}

export function* watchFetchSelectedLoadSaga() {
  yield takeLatest(FETCH_SELECTED_LOAD, workerFetchSelectedLoadSaga)
}

export function* watchSetLoadPrioritySaga() {
  yield takeEvery(SET_LOAD_PRIORITY, workerSetLoadPrioritySaga)
}

export function* watchPostLoadSaga() {
  yield takeEvery(POST_LOAD, workerPostLoadSaga)
}

export function* watchUnpostLoadSaga() {
  yield takeEvery(UNPOST_LOAD, workerUnpostLoadSaga)
}

export function* watchLoadTruckOnlySelectedSaga() {
  yield takeEvery(LOAD_TRUCK_ONLY_SELECTED, workerLoadTruckOnlySelectedSaga)
}

export function* watchFetchQueryCounterSaga() {
  yield takeLatest(FETCH_QUERY_COUNTER, workerFetchQueryCounterSaga)
}

export function* watchFetchConnectLogsSaga() {
  yield takeLatest(FETCH_CONNECT_LOGS, workerFetchConnectLogsSaga)
}

export function* watchFetchInsightDataSaga() {
  yield takeLatest(FETCH_INSIGHT_DATA, workerFetchInsightDataSaga)
}
//TODO Move this to its own Saga
export function* watchFetchDatRatesSaga() {
  yield takeLatest(FETCH_DAT_RATES, workerFetchDatRatesSaga)
}

export function* watchAddQueryCounterSaga() {
  yield takeLatest(ADD_QUERY_COUNTER, workerAddQueryCounterSaga)
}

export function* watchFetchInsightHistoricLeadsSaga() {
  yield takeLatest(FETCH_INSIGHT_HISTORIC_LEADS_DATA, workerFetchInsightHistoricLeadsSaga)
}

export function* watchNextLoadsSaga() {
  yield takeLatest(FETCH_NEXT_LOADS, workerFetchNextLoads)
}

export function* watchFetchOpportunityLoadsSaga() {
  yield takeLatest(FETCH_OPPORTUNITY_LOADS, workerFetchOpportunityLoadsSaga)
}

export function* watchAddThresholdOnLoadSaga() {
  yield takeLatest(ADD_THRESHOLD_ON_LOAD, workerAddThresholdOnLoadSaga)
}

export function* watchSetLoadTeamSaga() {
  yield takeLatest(UPDATE_LOAD_TEAM, workerSetLoadTeam)
}
