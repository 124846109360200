import axios from 'axios'

// export async function assignCarrierRep({carrierId, userId, option}) {
//     const queryString = `option=${option}`

//     return await axios({
//         method: "post",
//         url: `/api/carriers/${carrierId}/assign?${queryString}`,
//         data: {
//             user_id: userId
//         }
//     })
// }

// export async function unassignCarrierRep(carrierId, userId) {
//     return await axios({
//         method: "delete",
//         url: `/api/carriers/${carrierId}/assign/${userId}`
//     })
// }

export async function setLoadRep({loadId, userId}) {
    const result = await axios({
        method: "patch",
        url: `/api/loads/${loadId}`,
        data: {
            assigned_rep_id: userId
        }
    })

    return result
}

export async function toggleLoadPriority({loadId, priorityLoad}) {
    const result = await axios({
          method: "patch",
          url: `/api/loads/${loadId}`,
          data: {
              priority_load: priorityLoad
          }
      })
  
      return result
  }