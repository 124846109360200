import {TRUCK_STREAM_ON, TRUCK_STREAM_OFF} from '../actionTypes'

const initialState = false

export default (state=initialState, action) => {
    
    switch(action.type) {
        case TRUCK_STREAM_ON:
            console.log(action.type)
            return true
        case TRUCK_STREAM_OFF:
            console.log(action.type)
            return false
        default:
            return state
    }
}
