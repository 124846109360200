import { SET_SELECTED_LEAD} from '../actionTypes'

const initialState = {
    selectedLead: null
}


export default (state=initialState,  payload) => {
    switch(payload.type) {
    case SET_SELECTED_LEAD:
        return {
            ...state,
            selectedLead: payload.selectedLead
        }
    default:
        return state
    }
}
