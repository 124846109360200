import React, { Component } from 'react'



class NotificationBadge extends Component {

    render() {
        const count = this.props.count || 0

        return (count > 0) ? (
            <div className="notification-badge">
              {count}
            </div>
        ) : null
    }

}

export default NotificationBadge
