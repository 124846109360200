import React from 'react'

const SimpleSelect = ({values, value, onChange}) => {

    return (
        <select onChange={onChange} defaultValue={value.value}>
            {values.map( v => {
                return (<option key={v.value}  value={v.value}>{v.label}</option>)
            })}
        </select>
    )
}

export default SimpleSelect