import { takeLatest, put, call } from "redux-saga/effects"
import {
    SET_USERS, 
    SET_USERS_WITH_STATS, 
    FETCH_USERS, 
    FETCH_USERS_WITH_ACTIVITY_STATS,
    FETCH_ACTIVE_USERS,
    FETCH_USER_LOADS,
    FETCH_LOADS_FOR_ALL_USERS,
    SET_USER_LOADS,
    CALL_TABLE_USER_SELECTED, 
    SET_USER_CALL_DATA, 
    SET_SELECTED_USER,
    FETCH_LOAD_SPREAD_FOR_ALL_USERS,
    SET_USER_LOAD_SPREAD,
    FETCH_USERS_ACTIVITY,
    SET_USER_ACTIVITY
} from '../actionTypes.js'

import { fetchActivityStats } from '../userActions.js'

import axios from "axios"
import moment from 'moment'

// API CALLS
function fetchUsersWithActivityStats(args) {
    const request = {
        method: "get",
        url: "/api/users?include=activity_stats"
    }

    request.params = {}

    for (let key in args) {
        if (args[key]) {
            request.params[key] = args[key]
        }
    }
    return axios(request) || []
}

function fetchActiveUsers(args) {
    const request = {
        method: "get",
        url: "/api/users?all_active=true"
    }

    request.params = {}

    for (let key in args) {
        if (args[key]) {
            request.params[key] = args[key]
        }
    }
    return axios(request) || []
}

function fetchUserLoads(args) {
    const request = {
        method: 'get',
        url: `/api/users/${args.userId}/loads`
    }

    request.params = {}

    for (let key in args) {
        if (args[key]) {
            request.params[key] = args[key]
        }
    }
    return axios(request) || []
}

function fetchLoadsForAllUsers(args) {
    const request = {
        method: 'get',
        url: '/api/loads/not_open_or_void'
    }

    request.params = {}

    for (let key in args) {
        if (args[key]) {
            request.params[key] = args[key]
        }
    }
    return axios(request) || []
}

function fetchUsers() {
    return axios({
        method: "get",
        url: "/api/users?include=call_stats"
    })
}

function fetchUserCallData(id) {
    const currentDate = moment().format("YYYY-MM-DD");
    return axios({
        method: "get",
        url: `/api/calls?user_id=${id}&call_date=${currentDate}`
    })
}

// WORKERS
function* workerFetchUsersWithActivityStats(action) {
    try {
        const response = yield call(fetchUsersWithActivityStats, action.payload)
        yield put({type: SET_USERS_WITH_STATS, users: response.data})
    } catch(ex) {
        // TODO: dispatch a failure action to the store with the error
        console.log('workerFetchUsersWithActivityStats error: ', ex.message)
    }
}

function* workerFetchActiveUsers(action) {
    try {
        const response = yield call(fetchActiveUsers, action.payload)

        if (response && response.data) {
            yield put({type: SET_USERS_WITH_STATS, users: response.data})
        }
    } catch (err) {
        console.log('workerFetchActiveUsers error: ', err)
    }
}

function* workerFetchUsers(action) {
    try {
        const response = yield call(fetchUsers)
        yield put({type: SET_USERS, users: response.data})
    } catch(ex) {
        // TODO: dispatch a failure action to the store with the error
        console.log('workerFetchUsers() error: ', ex.message)
    }
} 

function* workerFetchUserCallData(action) {
    let response
    let user = action.user
    try {
        response = yield call(fetchUserCallData, user.id)
        yield put({type: SET_SELECTED_USER, user: user})
        yield put({type: SET_USER_CALL_DATA, calls: response.data})
    } catch(ex) {
        console.log('workerFetchUserCallData error: ', ex.message)
    }
}

function* workerFetchUserLoads(action) {
    try {
        yield put({ type: SET_USER_LOADS, loads: null })
        const response = yield call(fetchUserLoads, action.payload)

        if (response && response.data) {
            yield put({ type: SET_USER_LOADS, loads: response.data })
        } else {
            yield put({ type: SET_USER_LOADS, loads: [] })
        }
    } catch (err) {
        console.log('workerFetchUserLoads error: ', err)
    }
}

function* workerFetchLoadsForAllUsers(action) {
    try {
        yield put({ type: SET_USER_LOADS, loads: null })
        const response = yield call(fetchLoadsForAllUsers, action.payload)

        if (response && response.data) {
            yield put({ type: SET_USER_LOADS, loads: response.data})
        } else {
            yield put({ type: SET_USER_LOADS, loads: [] })
        }
    } catch (err) {
        console.log('workerFetchLoadsForAllUsers error: ', err)
    }
}

function* workerFetchLoadSpreadForAllUsers(action) {
    try {
        yield put({ type: SET_USER_LOAD_SPREAD, loads: null })
        const response = yield call(fetchLoadsForAllUsers, action.payload)

        if (response && response.data) {
            yield put({ type: SET_USER_LOAD_SPREAD, loads: response.data})
        } else {
            yield put({ type: SET_USER_LOAD_SPREAD, loads: [] })
        }
    } catch (err) {
        console.log('workerFetchLoadsForAllUsers error: ', err)
    }
}

export function* workerFetchUserActivity(action) {
    try {
        const {rangeStart, rangeEnd} = action.payload
        const activityResponse = yield call(fetchActivityStats, rangeStart, rangeEnd)

        if (activityResponse?.data) {
            yield put({ type: SET_USER_ACTIVITY, user_activity: activityResponse.data})
        } else {
            yield put({ type: SET_USER_ACTIVITY, user_activity: [] })
        }
    } catch (err) {
        console.log('workerFetchUserActivity error: ', err)
    }
}

// WATCHERS
export function* watchFetchUsersWithActivityStatsSaga() {
    yield takeLatest(FETCH_USERS_WITH_ACTIVITY_STATS, workerFetchUsersWithActivityStats)
}

export function* watchFetchActiveUsers() {
    yield takeLatest(FETCH_ACTIVE_USERS, workerFetchActiveUsers)
}

export function* watchFetchUserLoads() {
    yield takeLatest(FETCH_USER_LOADS, workerFetchUserLoads)
}

export function* watchFetchLoadsForAllUsers() {
    yield takeLatest(FETCH_LOADS_FOR_ALL_USERS, workerFetchLoadsForAllUsers)
}
export function* watchFetchLoadSpreadForAllUsers() {
    yield takeLatest(FETCH_LOAD_SPREAD_FOR_ALL_USERS, workerFetchLoadSpreadForAllUsers)
}

export function* watchFetchUsersSaga() {
    yield takeLatest(FETCH_USERS, workerFetchUsers)
}

export function* watchCallTableUserSelectedSage() {
    yield takeLatest(CALL_TABLE_USER_SELECTED, workerFetchUserCallData)
}

export function* watchFetchUserActivitySaga() {
    yield takeLatest(FETCH_USERS_ACTIVITY, workerFetchUserActivity)
}
