/**
 * This saga takes in all events, despatches the appropriate action to be picked by other sagas,
 * and add the event to the events list
 */

import { call, takeEvery, put } from "redux-saga/effects"
import {FOLDERS_DELETE_FOLDER, FOLDERS_RENAME_FOLDER, FETCH_MY_CARRIER_ASSIGNMENTS } from '../actionTypes.js'
import { deleteFolder, renameFolder } from '../folderActions'



// WORKERS
function* workerDeleteFolder(action) {
    console.log('workerDeleteFolder() - action: ', action)
    yield call(deleteFolder, action.folder)

    yield put({ type: FETCH_MY_CARRIER_ASSIGNMENTS })

}

function* workerRenameFolder(action) {
    console.log('workerRenameFolder() - action: ', action)
    yield call(renameFolder, action.payload)

    yield put({ type: FETCH_MY_CARRIER_ASSIGNMENTS })
}

// WATCHERS
export function* watchDeleteFolder() {
    yield takeEvery(FOLDERS_DELETE_FOLDER, workerDeleteFolder)
}

export function* watchRenameFolder() {
    yield takeEvery(FOLDERS_RENAME_FOLDER, workerRenameFolder)
}
