import {ADD_BROWSER_SCREEN, REMOVE_BROWSER_SCREEN} from './actionTypes'
//TODO: add error handling and null checking on store and channel

var config = {
    store: null,
    channel: null,
    events: []
}

const DEFAULT_MESSAGES = [ADD_BROWSER_SCREEN, REMOVE_BROWSER_SCREEN]


export const configureBroadcast = (store, channelName) => {
    config.store = store
    config.channel = new BroadcastChannel(channelName) 
}


export const sendBroadcastMessage = (msg) => {
    if (config.channel) {
        config.channel.postMessage(msg)
    } else {
        console.error("cannot post a message to this channel")
    }
}



export const subscribeBroadcast = (events = []) => {
    config.events = events

    config.channel.onmessage = (msg) => {
        if (msg && msg.data) {
            const messages = (Array.isArray(msg.data)) ? msg.data : [ msg.data]
            for (let message of messages) {
                if (message.type && (DEFAULT_MESSAGES.includes(message.type) || config.events.includes(message.type))) {
                    config.store.dispatch(message)
                }
        
            }
        }
    }
}

export const unsubscribeBroadcast = () => {
    config.events = []
    config.channel.onmessage = null
}



