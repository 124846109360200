import { SET_CARRIER_BIDS, UPDATE_CARRIER_BIDS } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {
        case SET_CARRIER_BIDS:
            //this should be done on the server end, but for now keep it here
            return (payload?.bids?.length) ? payload.bids.filter(b => b.actual_bid_id === null) : []
        case UPDATE_CARRIER_BIDS:
            if (!payload.bid.actual_bid_id) {
                const updatedBids = [...state].filter(b => b.id !== payload.bid.id)
                updatedBids.push(payload.bid)
                return updatedBids
            } 
            return state
        default:
            return state
    }
}