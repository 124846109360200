import { takeEvery, call, put, select } from "redux-saga/effects"
import { ADD_MESSAGE, UPDATE_LOAD_POST, SET_LOAD_POST, SET_LOADS } from '../actionTypes'
import { updateLoadPost} from '../loadPostActions.js'
import {getSelectedLoad, getLoads} from '../reducers/rootReducer.js'
import moment from 'moment'

function* workerProcessLoadPostUpdate({data}) {
    try {
        const result = yield call(updateLoadPost, data)
        return result
    } catch (e) {
        yield put({type: ADD_MESSAGE, message: `failed to update load post`})
    }
}


function* workerSetLoadPost(payload) {
    try {
        const {post, action} = payload
        // I need to get the selected load as well
        const selectedLoad = yield select(getSelectedLoad)
        
        if (selectedLoad && (selectedLoad.id === post?.load_id)) {
            selectedLoad.posts = (selectedLoad.posts || []).filter(lp => lp.id !== post.id)
            // then we want to add it back in
            if (action === 'UPDATE') {
                selectedLoad.posts.push(post)
            }
        }

        // now update any of the load in the loads list
        const loads = yield select(getLoads)
        if (loads?.length) {
            const updatedLoads = loads.map(l => {
                if (l.id === post?.load_id) {
                    l.posts = (l.posts || []).filter(lp => lp.id !== post.id)
                    // then we want to add it back in
                    if (action === 'UPDATE') {
                        l.posts.push(post)
                    }
                    // do this to force an update.  Might be overkill, but just incase
                    l.updated_at = moment(l.updated_at).add(1, 'millisecond').toISOString()
                }
                return l
            })
            yield put({type: SET_LOADS, loads: updatedLoads})
        }
    } catch (err) {
        console.log('workerSetLoadPost error', err)
        console.log('workerSetLoadPost payload', payload)
    }
}


// WATCHERS
export function* watchLoadPostUpdateSaga() {
    yield takeEvery(UPDATE_LOAD_POST, workerProcessLoadPostUpdate)
}

export function* watchSetLoadPostSaga() {
    yield takeEvery(SET_LOAD_POST, workerSetLoadPost)
}
