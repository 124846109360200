import { takeEvery, call, put } from "redux-saga/effects"
import { FETCH_AND_SET_CONNECT_QUEUE_STATUS, SET_CONNECT_QUEUE_STATUS } from '../actionTypes'
import { fetchConnectQueueStatus} from '../connectQueueStatusActions'
// import _ from 'lodash'

function* workerFetchAndSetConnectQueueStatus() {
    try {
      const connectQueueStatus = yield call(fetchConnectQueueStatus, {mc: null, bid_id: null, load_id: null})
      yield put({type: SET_CONNECT_QUEUE_STATUS, connectQueueStatus})
    } catch (err) {
      console.log('FETCH_AND_SET_CONNECT_BIDS failed-->',err)
    }
  }

export function* watchFetchAndSetConnectQueueStatusSaga() {
    yield takeEvery(FETCH_AND_SET_CONNECT_QUEUE_STATUS, workerFetchAndSetConnectQueueStatus)
  }
  