import {SET_SELECTED_TASK} from '../actionTypes'

const initialState = {}

export default (state=initialState,  action) => {
    switch(action.type) {
        case SET_SELECTED_TASK:
            return action?.selectedTask || initialState

        default:
            return state
    }
}
