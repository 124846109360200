import { SET_CUSTOMER_CONTACTS } from '../../actionTypes'

const initialState = []

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_CUSTOMER_CONTACTS:
        return action.contacts
    default:
        return state
    }
}