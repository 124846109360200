
import { takeEvery, takeLatest, call, put, select } from "redux-saga/effects"
import {
    FETCH_AND_SET_EMAILS, SET_EMAILS, SEND_EMAILS, 
    SET_MC_FOR_EMAIL, 
    SEND_BULK_EMAILS
} from '../actionTypes'
import { fetchAllEmails, sendEmail } from '../emailActions'
import { getEmails, getSelectedLoad } from '../reducers/rootReducer'
import axios from 'axios'
import _ from 'lodash'
const moment = require('moment-timezone').tz

function addEmail(updatedEmail) {
  return axios({
      method: "post",
      url: "/api/emails/add_email",
      data: updatedEmail
  })
}

function* workerFetchAndSetEmails(payload) {
  try { 
      yield put({ type: SET_EMAILS, emails: null })

      // Fetch and set Emails
      let emails = []
      let response = yield call(fetchAllEmails, payload)
    
      if (response) {
        emails = response.data
      }

      yield put({ type: SET_EMAILS, emails })
  
  }
  catch (err) {
    console.log('FETCH_AND_SET_EMAILS failed-->',err)
  }

}

function* workerSendEmails(payload) {
    try {
        let emails = payload.parms.update.emails;
        let updatedEmail;
        if (payload.parms.update.id) {
            updatedEmail = emails.filter(
                (value) => value.id === payload.parms.update.id
            );

            emails = _.map(emails, (item) => {
                if (item.id === payload.parms.update.id) {
                    item['data']['show_buttons'] = false;
                    item['show_buttons'] = false;
                    item['updated_at'] = moment().toISOString();
                    return item;
                } else {
                    item['updated_at'] = moment().toISOString();
                    return item;
                }
            });
            yield put({ type: SET_EMAILS, emails: emails });
            if (updatedEmail) {
                yield call(addEmail, updatedEmail);
            }
        }

        let response = yield call(sendEmail, payload.parms.send);
        let resData = [];
        if (response) {
            resData = response.data;
            if (resData && resData.length > 0 && resData[0] === 'Sent') {
                if (payload.parms.update.id) {
                    emails = _.map(emails, (item) => {
                        if (item.id === payload.parms.update.id) {
                            item['data']['show_buttons'] = false;
                            item['show_buttons'] = false;
                            item['data']['sent'] = true;
                            item['sent'] = true;
                            item['updated_at'] = moment().toISOString();
                            return item;
                        } else {
                            item['updated_at'] = moment().toISOString();
                            return item;
                        }
                    });
                    yield put({ type: SET_EMAILS, emails: emails });
                }
            } else {
                emails = payload.parms.update.emails;
                emails = _.map(emails, (item) => {
                    if (item.id === payload.parms.update.id) {
                        item['data']['show_buttons'] = true;
                        item['show_buttons'] = true;
                        item['updated_at'] = moment().toISOString();
                        return item;
                    } else {
                        item['updated_at'] = moment().toISOString();
                        return item;
                    }
                });
                yield put({ type: SET_EMAILS, emails: emails });
            }
        } else {
            emails = payload.parms.update.emails;
            emails = _.map(emails, (item) => {
                item['data']['show_buttons'] = true;
                item['show_buttons'] = true;
                item['updated_at'] = moment().toISOString();
                return item;
            });
            yield put({ type: SET_EMAILS, emails: emails });
        }
    } catch (err) {
        console.log('SEND_EMAILS failed-->', err);
    }
}

function* workerSendBulkEmails(payload) {
    try {
        const selectedLoad = yield select(getSelectedLoad)
        const emailsToSend = payload.parms.emails

        // calls to update UI to show that email is processing
        if (emailsToSend && emailsToSend.length > 0) {
            const emails = yield select(getEmails)
            const emailsToSendIds = _.map(emailsToSend, (o) => { return o.id })
            let updatedEmails = _.map(emails, (item) => {
                if (emailsToSendIds.includes(item.id)) {
                    item['data']['show_buttons'] = false
                    item['show_buttons'] = false
                    item['updated_at'] = moment().toISOString()
                    return item
                } else {
                    item['updated_at'] = moment().toISOString()
                    return item
                }
            })

            // update emails so that they no longer show the buttons
            yield put({ type: SET_EMAILS, emails: updatedEmails })
            // this will call an api to trigger a pusher event
        }
        
        // send all the emails
        for (const email of emailsToSend) {
            const send = {
                uid: email.id || email['id'] || '',
                from: email.from || email['From'] || '',
                flags: email.flags || email['Flags'] || '',
                text: 1,
                loadx: selectedLoad,
                date: email.date || email['Date'] || '',
                subject: email.subject || email['Subject'] || '',
                mc: email.mc || email.Mc || email.data.Mc ||'',
                html: email.html || email['html'] || '',
                textString: email.text || email['Text'] || '',
                colour: email.colour || email['Colour'] || 'green',
                team_name: ''
            }

            let hasMC = true
            if (!(send.mc)) {
                alert(`You need provide a valid MC for this email: ${send.from}\n${send.subject}`)
                hasMC = false
            }

            let response
            if (hasMC) {
                response = yield call(sendEmail, send)
            }

            // TODO can refactor the code block below
            if (
                response && response.data && response.data.length > 0 && 
                response.data[0] === 'Sent' &&
                hasMC
            ){
                // update emails to show that it has been sent
                const emails = yield select(getEmails)
                let updatedEmails = _.map(emails, (item) => {
                    if (item.id === email.id) {
                        item['data']['show_buttons'] = false
                        item['show_buttons'] = false
                        item['data']['sent'] = true
                        item['sent'] = true
                        item['updated_at'] = moment().toISOString()
                        return item
                    } else {
                        item['updated_at'] = moment().toISOString()
                        return item
                    }
                })

                yield put({ type: SET_EMAILS, emails: updatedEmails })
            } else {
                const emails = yield select(getEmails)
                let updatedEmails = _.map(emails, (item) => {
                    if (item.id === email.id) {
                        item['data']['show_buttons'] = true
                        item['show_buttons'] = true
                        item['updated_at'] = moment().toISOString()
                        return item
                    } else {
                        item['updated_at'] = moment().toISOString()
                        return item
                    }
                })

                yield put({ type: SET_EMAILS, emails: updatedEmails })
            }
        }
    } catch (err) {
        console.log('SEND_BULK_EMAILS failed-->', err)
    }
}

function* workerSetMcForEmails(payload) {
    try {
        const targetEmail = payload.parms.email
        const mc = payload.parms.mc
        const emails = yield select(getEmails)

        const updatedEmails = _.map(emails, (o) => {
            if (o.id === targetEmail.id) {
                o['Mc'] = mc
                o['data']['Mc'] = mc
            }
            return o
        })

        yield put({ type: SET_EMAILS, emails: updatedEmails })
    } catch (err) {
        console.log('FETCH_AND_SET_EMAILS failed-->',err)
    }
}

export function* watchFetchAndSetEmailsSaga() {
    yield takeEvery(FETCH_AND_SET_EMAILS, workerFetchAndSetEmails)
}

export function* watchSendEmailsSaga() {
  yield takeEvery(SEND_EMAILS, workerSendEmails)
}

export function* watchSendBulkEmailsSaga() {
    yield takeEvery(SEND_BULK_EMAILS, workerSendBulkEmails)
}

export function* watchSetMcForEmails() {
    yield takeLatest(SET_MC_FOR_EMAIL, workerSetMcForEmails)
}
