import {SET_USERS, SET_USERS_WITH_STATS, UPDATE_USER} from '../actionTypes'
import {displaySecondsAsMinutes} from '../../utils/formatters'

const initialState = []

export default (state=initialState,  action) => {
    let users

    switch(action.type) {
    case SET_USERS:
        users = action.users.slice(0)
        
        let formattedUsers = users.map((u)=>{
                u.phone_stats.outbound_talk_time_day = displaySecondsAsMinutes(u.phone_stats.outbound_talk_time_day)
            return u
        })

        return formattedUsers || []

    case SET_USERS_WITH_STATS:
        return action.users

    case UPDATE_USER:
        let updatedUser = Object.assign({}, action.data)

        users = state.map((u) => {
            if (u.id === updatedUser.id) {
                u = updatedUser
                u.phone_stats.outbound_talk_time_day = displaySecondsAsMinutes(u.phone_stats.outbound_talk_time_day)
            }
            return u
        })
        users.sort((a,b) => (b.outbound_calls_day || 0) - (a.outbound_calls_day || 0))
        
        return users
    default:
        return state
    }
}