import { SET_QUERY_COUNTER } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case SET_QUERY_COUNTER:
        return payload.queryCounterData || null
    
    default:
        return state
    }
}