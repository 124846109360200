import {SET_CUSTOMER_LANES} from '../../actionTypes'

const initialState = []


export default (state=initialState,  action) => {


    switch(action.type) {
        case SET_CUSTOMER_LANES:
            return action.lanes || initialState
        default:
            return state
    }
}
