import { SET_RECENT_CUSTOMERS, UPDATE_RECENT_CUSTOMER_ITEM } from '../../actionTypes'
import { findIndex } from 'lodash'

const initialState = []


export default (state=initialState,  action) => {
    const validCustomer = action.customer && action.customer.id

    // we were adding to the list even if the customer was undefined.  If this is just the case, then return state
    if (! validCustomer) {
        return state
    }

    switch(action.type) {

    case SET_RECENT_CUSTOMERS:
            return action.customers

    case UPDATE_RECENT_CUSTOMER_ITEM:
        const indexOfCarrierInList = findIndex(state, (c) => { return (c.id===action.customer.id )})

        let updatedList = Array.from(state)
        updatedList[indexOfCarrierInList].customer_rep_assignments = action.customer.customer_rep_assignments

        return updatedList
    default:
        return state
    }
}
