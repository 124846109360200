import { SET_CONNECT_MAPS } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case SET_CONNECT_MAPS:
        return payload.connectMapsData || null
    
    default:
        return state
    }
}