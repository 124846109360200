import React, { lazy, Suspense } from 'react'
import { Button, Drawer, Toolbar } from 'react-md'
import { Snackbar } from 'react-md'
import { connect } from 'react-redux'
import { subscribe, unsubscribe } from 'pusher-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Authenticated from './components/Authenticated'
import NavBar from './components/NavBar'
import DrawerLink from './components/DrawerLink'
import DrawerLinkLogout from './components/DrawerLinkLogout'

import axios from 'axios'

import { NEW_APP_VERSION_EVENT, REMOVE_MESSAGE, USER_UPDATE_EVENT } from './actions/actionTypes'

//This will allow webpack to code split and load each screen as needed
//Since we pop up a new window, each screen will not need to load all of the javascript.
const LoadPricingScreen = lazy(() => import('./components/LoadPricingScreen')) 
const CallScreen = lazy(() => import('./components/CallScreen'))
const MarketScreen = lazy(() => import('./components/MarketScreen'))
const CarrierScreen = lazy(() => import('./components/CarrierScreen'))
const CustomerScreen = lazy(() => import('./components/customer/CustomerScreen'))
const TruckScreen = lazy(() => import('./components/TruckScreen'))
const ActivityReportScreen = lazy(() => import('./components/ActivityReportScreen'))
const ExternalLoadScreen = lazy(() => import('./components/ExternalLoadScreen'))
const SecretScreen = lazy(() => import('./components/SecretScreen'))
const SpreadRecapScreenTabs = lazy(() => import('./components/SpreadRecapScreenTabs'))
const SpreadRecapScreenNew = lazy(() => import('./components/SpreadRecapScreenNew'))
const CompanyDirectory = lazy(() => import('./components/CompanyDirectory'))
const HistoricLoadsScreen = lazy(() => import('./components/HistoricLoadsScreen'))
//const TVDashboardScreen = lazy(() => import('./components/TVDashboardScreen'))
//const TaskScreen = lazy(() => import('./components/tasks/TaskScreen'))
const HistoricLoads2Screen = lazy(() => import('./components/HistoricLoads2Screen'))
const LoadPostMonitorScreen = lazy(() => import(('./components/LoadPostMonitorScreen')))
const EmailScreen = lazy(() => import('./components/EmailScreen'))
const AdminScreen = lazy(() => import('./components/AdminScreen'))
const ConnectDashboard = lazy(() => import('./components/ConnectDashboard'))
const OperationsDashboard = lazy(() => import('./components/OperationsDashboard'))
const SMSQueueDashboard = lazy(() => import('./components/SMSQueueDashboard'))
const ConnectEmailsHeatMap = lazy(() => import('./components/ConnectEmailsHeatMap'))
const CarrierOpsScreen = lazy(() => import('./components/carrier-ops/CarrierOpsScreen'))
const CustomerScreenNew = lazy(() => import('./components/customer/CustomerScreenNew'))
const CarrierMatchScreen = lazy(() => import('./components/carrier/CarrierMatchScreen'))
const SystemAdminScreen = lazy(() => import('./components/admin/AdminScreen'))
const CarrierOwnershipScreen = lazy(() => import('./components/CarrierOwnershipScreen'))

const FB = (<div>Loading...</div>)


class Layout extends React.Component {
  state = {
    navLinks: [],
    drawerVisible: false
  }


  /**
   * This handles the back button in the browser
   */
  popState = () => {
    const {href} = window.location
    window.location.href = href
  }

  componentDidMount() {
    axios.get('/api/gearjammer_nav_links').then(response => {
      if (response && response.data) {
        this.setState({navLinks: response.data}) 
      }
    })

    subscribe('events', 'app', NEW_APP_VERSION_EVENT, {})
    subscribe('events', 'user', USER_UPDATE_EVENT, {})
    window.addEventListener('popstate', this.popState)
  }

  componentWillUnmount() {
    unsubscribe('events', 'app', NEW_APP_VERSION_EVENT)
    unsubscribe('events', 'user', USER_UPDATE_EVENT)
    window.removeEventListener('popstate', this.popState)
  }


  closeDrawer = () => {
    this.setState({ drawerVisible: false })
  }

  handleVisibility = () => {
    this.setState({ drawerVisible: !this.state.drawerVisible })
  }


  dismissToast = () => {
    this.props.removeMessage()
  }

  render() {
    const { drawerVisible } = this.state
    let { navLinks } = this.state

    const { inboxNotifications, currentUser} = this.props
    
    const closeBtn = <Button flat onClick={this.closeDrawer}> 
                      <FontAwesomeIcon icon={faArrowRight} size="lg"/>
                    </Button>

    let browserRouter = (<BrowserRouter>
      <Routes>
        <Route path="/loads/:pro" element={<Suspense fallback={FB}><SecretScreen/></Suspense>} />
        <Route path="/loads" element={<Suspense fallback={FB}><SecretScreen/></Suspense>} />
        <Route path="/carriers/:mc" element={<Suspense fallback={FB}><CarrierMatchScreen/></Suspense>} />
        <Route path="/carriers" element={<Suspense fallback={FB}><CarrierMatchScreen/></Suspense>} />
        <Route path="/customers" element={<Suspense fallback={FB}><CustomerScreen/></Suspense>} />
        <Route path="/customers-new" element={<Suspense fallback={FB}><CustomerScreenNew/></Suspense>} />
        <Route path="/load-pricing" element={<Suspense fallback={FB}><LoadPricingScreen/></Suspense>} />
        <Route path="/calls" element={<Suspense fallback={FB}><CallScreen/></Suspense>} />
        <Route path="/market" element={<Suspense fallback={FB}><MarketScreen/></Suspense>} />
        <Route path="/trucks" element={<Suspense fallback={FB}><TruckScreen/></Suspense>} />
        <Route path="/external-loads" element={ <Suspense fallback={FB}><ExternalLoadScreen/></Suspense>} />
        <Route path="/spread-recap" element={ <Suspense fallback={FB}><SpreadRecapScreenTabs/></Suspense>} />
        <Route path="/spread-recap-new" element={<Suspense fallback={FB}><SpreadRecapScreenNew/></Suspense>} />
        <Route path="/activity-report" element={<Suspense fallback={FB}><ActivityReportScreen/></Suspense>} />
        <Route path="/company-directory" element={<Suspense fallback={FB}><CompanyDirectory/></Suspense>} />
        <Route path="/historic-loads" element={<Suspense fallback={FB}><HistoricLoadsScreen/></Suspense>} />
        <Route path="/carrier-matches" element={<Suspense fallback={FB}><CarrierMatchScreen/></Suspense>} />
        <Route path="/historic-loads2" element={<Suspense fallback={FB}><HistoricLoads2Screen/></Suspense>} />
        <Route path="/load-post-monitor" element={ <Suspense fallback={FB}><LoadPostMonitorScreen/></Suspense>} />
        <Route path="/emails" element={<Suspense fallback={FB}><EmailScreen/></Suspense>} />
        <Route path="/admin-screen" element={<Suspense fallback={FB}><AdminScreen/></Suspense>} />
        <Route path="/admin" element={<Suspense fallback={FB}><SystemAdminScreen/></Suspense>} />
        <Route path="/admin/:option" element={<Suspense fallback={FB}><SystemAdminScreen/></Suspense>} />
        <Route path="/connect-dashboard" element={ <Suspense fallback={FB}><ConnectDashboard/></Suspense>} />
        <Route path="/operations-dashboard" element={<Suspense fallback={FB}><OperationsDashboard/></Suspense>} />
        <Route path="/sms-queue-dashboard" element={<Suspense fallback={FB}><SMSQueueDashboard/></Suspense>} />
        <Route path="/map-analytics" element={<Suspense fallback={FB}><ConnectEmailsHeatMap/></Suspense>} />
        <Route path="/carrier-ops" element={<Suspense fallback={FB}><CarrierOpsScreen/></Suspense>} />
        <Route path="/carrier-ownership" element={<Suspense fallback={FB}><CarrierOwnershipScreen/></Suspense>} />

        <Route path="/" element={<Suspense fallback={FB}><SecretScreen/></Suspense>} />
      </Routes>
    </BrowserRouter>)
    switch (currentUser?.rep_type) {
      case 'EXT_CARRIER_OPS':
        if (navLinks && navLinks.length) {
          navLinks = navLinks.filter(l => l?.show_carrier_ops)
        }
        browserRouter = (
          <BrowserRouter>
            <Routes>
              <Route path="/carriers/:mc" element={<Suspense fallback={FB}><CarrierScreen/></Suspense>} />
              <Route path="/carriers" element={<Suspense fallback={FB}><CarrierScreen/></Suspense>} />
              <Route path="/trucks" element={<Suspense fallback={FB}><TruckScreen/></Suspense>} />
              <Route path="/carrier-ops" element={<Suspense fallback={FB}><CarrierOpsScreen/></Suspense>} />
              <Route path="/" element={<Suspense fallback={FB}><CarrierOpsScreen/></Suspense>} />
            </Routes>
          </BrowserRouter>
        )
        break;
    
      default:
        break;
    }
    let lastInternalLinkIndex = -1
    let navItems = []
    if (navLinks && navLinks.length) {
      lastInternalLinkIndex = navLinks.indexOf(navLinks.filter(l => l.type.toLowerCase() === 'internal').slice(-1)[0])
      navItems = navLinks.map((link, index) => { 
        if ((link.link_text !== 'Loads') && (link.link_text !== 'Carriers')) {
          let drawerClassName = ((lastInternalLinkIndex === index) || (index === navLinks.length -1)) ? "drawer-link last-internal" : "drawer-link"
          return (
              <DrawerLink 
                  className={drawerClassName}
                  key={link.id}
                  linkUrl={link.link_url} 
                  callback={this.closeDrawer}
                  primaryText={link.link_text}></DrawerLink>
              )
        } else {
          return null
        }
      }
      ).filter(ni => ni !== null)
      navItems.push(<DrawerLinkLogout key="logout-drawer-link" className={"drawer-link last-internal"}/>)
    }

    return (
        <div id="main-wrapper">
          <NavBar navLinks={navLinks} handleVisibility={this.handleVisibility.bind(this)} notifications={inboxNotifications}/>

          <Drawer
            id="simple-drawer-example"
            type={Drawer.DrawerTypes.TEMPORARY}
            position={"right"}
            visible={drawerVisible}
            onVisibilityChange={this.handleVisibility}
            navItems={navItems}
            style={{
              'width': '350px'
            }}
            header={(
              <Toolbar
                nav={closeBtn}
                actions={null}
                className="md-divider-border md-divider-border--bottom"
              />
            )}
          />

          <div id="main">
            <Authenticated>
              {browserRouter}
            </Authenticated>
            {/* End Main */}
            <Snackbar
                    autohide={true}
                    autohideTimeout={2500}
                    style={{ fontSize: '14px' }}
                    onDismiss={this.dismissToast}
                    toasts={this.props.messages}
                />
          </div>
          {/* End Main Wrapper */}
        </div>
    )
  }
}

const mapStateToProps = state => {
    const { authenticatedUser, messages } = state
    return {
        currentUser: authenticatedUser,
        messages
    }
}

const mapDispatchToProps = dispatch => {
  return {
      removeMessage: () => { dispatch({ type: REMOVE_MESSAGE })},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
