import {SET_MATCHED_TRUCKS, UPDATE_MATCHED_TRUCKS_WITH_BID, ADD_MATCHED_TRUCK_FROM_STREAM, CLEAR_MATCHED_TRUCKS, EXPIRE_MATCHED_TRUCK_FROM_STREAM, DELETE_MATCHED_TRUCK_FROM_STREAM} from '../actionTypes'
import moment from 'moment'
import _ from 'lodash'

import { convertTruckSource } from '../../utils/truck-source-helper'

const initialState = []


const addLastBidToTruck = (bids = [], truck) => {
    if (! truck.last_bid) {
        const truck_bids = bids.filter(b => b.carrier && b.carrier.mc && truck.carrier && truck.carrier.mc && `${b.carrier.mc}` === `${truck.carrier.mc}`)
        if (truck_bids && truck_bids.length) {
            truck.last_bid = truck_bids[0]
        }
    }
    return truck
}

const addBidToTruck = (t, b) => {
    t.last_bid = b
    //Note:  This is done so the DSTable updates.  It used id and updated_at as a key
    t.updated_at = moment(t.updated_at).add(1, 's').toISOString()

    return t
}

export default (state=initialState, action) => {
    let trucks = []
    let selectedLoadBids = []


    switch(action.type) {
    case CLEAR_MATCHED_TRUCKS:
        return initialState
    case SET_MATCHED_TRUCKS:
        selectedLoadBids = (action.payload.selectedLoad) ? action.payload.selectedLoad.bids || [] : []
        trucks = action.payload.trucks
        const historicLoads = action.payload.historicLoads
        if (trucks && trucks.length) {
            trucks = trucks.map(t=> {
                t.source = convertTruckSource(t)
                t = addLastBidToTruck(selectedLoadBids, t)
                t.bid_text = ''
                t.historic_loads = []
                return t
            })
        }
        
        // Add historic loads for each truck if the carrier has done that load
        if (historicLoads && historicLoads.length && trucks && trucks.length) {
            for (const load of historicLoads) {
                trucks = trucks.map((t)=>{
                    if (t.carrier_id === load.carrier_id) {
                        t.historic_loads.push(load)
                    }
                    
                    return t
                })
            }
        }
        
        return trucks
    case UPDATE_MATCHED_TRUCKS_WITH_BID: {
        //Not sure why but tried trucks = [...state] would return undefined
        // but since we are mapping this will still work
        trucks = state
        let {bid} = action
        if (trucks && trucks.length) {
            trucks = trucks.map(t => {
                if (t.carrier_id) { // Truck from live pull
                    if (t.carrier_id === bid.carrier_id) {
                        t = addBidToTruck(t, bid)
                    }
                } else if (t.carrier && t.carrier.mc) {  // Streamed truck
                    let truckMc = typeof t.carrier.mc === 'number' ? t.carrier.mc.toString() : t.carrier.mc
                    if (truckMc === _.get(bid, 'carrier.mc', 'null')) {
                        t = addBidToTruck(t, bid)
                    }
                }
                return t
            })
        }

        return trucks
    }
    case ADD_MATCHED_TRUCK_FROM_STREAM:
        let {truck} = action

        trucks = (state) ? [...state] : []
 
        if (truck) {
            // check if equipment_type is a string if so convert to object
            if (typeof truck.equipment_type === 'string') {
                truck.equipment_type = {
                    name: truck.equipment_type
                }
            }
            truck.source = convertTruckSource(truck)
        }
        let found = false
        trucks = trucks.map(t => {
            if (t.truck_id === truck.truck_id) {
                truck.seen_count = (t.seen_count || 1) + 1
                found = true
                return truck
            }
            return t
        })
        if (! found) {
            selectedLoadBids = (action.selectedLoad) ? action.selectedLoad.bids || [] : []
            truck = addLastBidToTruck(selectedLoadBids, truck)
            trucks.push(truck)
        }
        return trucks
    case EXPIRE_MATCHED_TRUCK_FROM_STREAM:
        trucks = (state) ? [...state] : []
        let expired_truck = action.truck
        if (expired_truck) {
            trucks = trucks.map( t => {
                if (t.truck_id === expired_truck.truck_id) {
                    return expired_truck
                }
                return t
            }).filter(t => t != null)

        }
        return trucks
    case DELETE_MATCHED_TRUCK_FROM_STREAM:
        trucks = (state) ? [...state] : []
        let deleted_truck = action.truck
        if (deleted_truck) {
            trucks = trucks.map( t => {
                if (t.truck_id === deleted_truck.truck_id) {
                    return null
                }
                return t
            }).filter(t => t != null)

        }
        return trucks
    default:
        return state
    }
}
