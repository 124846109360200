import React, {Component} from 'react'

class NavBarButton extends Component {
    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        const {linkUrl, linkText} = this.props
        window.open(linkUrl, `OTR_${linkText}`)
    }

    render() {
        return(
            <div className="nav-bar-button" onClick={this.handleClick}>
                <div>{this.props.linkText}</div>
            </div>
        )

    }
}

export default NavBarButton