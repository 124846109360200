import { takeEvery, call, put, select } from "redux-saga/effects"
import { 
    NOTIFICATION_ADDED_EVENT, 
    ADD_INBOX_NOTIFICATION, 
    FETCH_INBOX_NOTIFICATIONS, 
    SET_INBOX_NOTIFICATIONS, 
    INBOX_NOTIFICATIONS_READ,
    SEND_SLACK_DM
} from '../actionTypes'
import { getCurrentUser } from '../reducers/rootReducer'
import { sendBroadcastMessage } from '../../actions/broadcast-redux'

import axios from "axios"

//const soundUrl = require('../../assets/sounds/bell.mp3')
//const notificationSound = new Audio(soundUrl)


function fetchInboxNotifications() {
    return axios.get('/api/users/notifications')
}

function sendNotificationsReceivedConfirmation(payload) {
    return axios({
        method: 'post',
        url: '/api/users/notifications_received',
        data: payload.data.map(n => n.id)
    })
}

function sendNotificationsReadConfirmation(notifications) {
    return axios({
        method: 'post',
        url: '/api/users/notifications_read',
        data: notifications.map(n => n.id)
    })
}

function sendSlackDM(args) {
    return axios({
        method: 'post',
        url: '/api/slack_messages/sendDM',
        data: args
    })
}

function* workerFetchInboxNotifications(action) {
    const currentUser = yield select(getCurrentUser)

    if (currentUser) {
        let inboxNotifications = yield call(fetchInboxNotifications)

        if (inboxNotifications) {
            // Send Otter confirmation that notifications were received
            yield call(sendNotificationsReceivedConfirmation, inboxNotifications)

            inboxNotifications = inboxNotifications.data.map(n => {
                n.delivered = true
                return n
            })

            yield put({ type: SET_INBOX_NOTIFICATIONS, inboxNotifications })
        }
    }
}

function* workerInboxNotificationAddedEvent(action) {
    const { notification } = action
    yield put({ type: ADD_INBOX_NOTIFICATION, notification })
    // Disabled, driving guys nuts, find a different sound
    //notificationSound.play()

    // Send Otter confirmation that notification was received
    yield call(sendNotificationsReceivedConfirmation, { data: [notification] })
}

function* workerInboxNotificationsRead(action) {
    let inboxNotifications = action.payload
    // Send Otter confirmation that notifications were read
    yield call(sendNotificationsReadConfirmation, inboxNotifications)

    inboxNotifications = inboxNotifications.map(n => {
        n.read = true
        return n
    })

    yield put({ type: SET_INBOX_NOTIFICATIONS, inboxNotifications })
    sendBroadcastMessage({ type: SET_INBOX_NOTIFICATIONS, inboxNotifications })
}

function* workerSendSlackDM(action) {
    const slackArgs = action.args
    yield call(sendSlackDM, slackArgs)
}


export function* watchFetchInboxNotificationsSaga() {
    yield takeEvery(FETCH_INBOX_NOTIFICATIONS, workerFetchInboxNotifications)
}

export function* watchInboxNotificationAddedEventSaga() {
    yield takeEvery(NOTIFICATION_ADDED_EVENT, workerInboxNotificationAddedEvent)
}

export function* watchInboxNotificationsReadSaga() {
    yield takeEvery(INBOX_NOTIFICATIONS_READ, workerInboxNotificationsRead)
}

export function* watchSendSlackDMSaga() {
    yield takeEvery(SEND_SLACK_DM, workerSendSlackDM)
}
