import { SET_CARRIER_OPS_TASK_DATA, RESET_CARRIER_OPS_TASK_DATA, UPDATE_CARRIER_OPS_TASK_DATA } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = {}

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case SET_CARRIER_OPS_TASK_DATA:
        return {...payload.data}
    case UPDATE_CARRIER_OPS_TASK_DATA:
        return {...state, ...payload.data}
    case RESET_CARRIER_OPS_TASK_DATA:
        return initialState
    default:
        return state
    }
}