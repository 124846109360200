import axios from 'axios'

//TODO: can we get these directly from the matcher???
export async function fetchMatches() {
    return await axios({
        method: 'get',
        url:  `/api/matches?quality=high`
    })
}

//TODO: refactor.  Locks should go away
export async function getMatchesLocks() {
    return await axios({
        method: 'get',
        url: `/api/matches/allLocks`
    })
}

export async function lockCarrier(data,user_email) {
    return await axios({
        method: 'post',
        url: `/api/matches/lockCarrier?user_email=${user_email}`,
        data: data
    })
}

export async function removeLockCarrier(carrier_id,user_name,key,mc) {
    return await axios({
        method: 'get',
        url: `/api/matches/removeLockCarrier?carrier_id=${carrier_id}&user_name=${user_name}&mc=${mc}&key=${key}`
    })
}

export async function removeLockByUser(user_email) {
    return await axios({
        method: 'get',
        url: `/api/matches/removeLockByUser?user_email=${user_email}`
    })
}
export async function checkLock(carrier_id,user_email,key,mc) {
    return await axios({
        method: 'get',
        url: `/api/matches/checkLock?carrier_id=${carrier_id}&user_email=${user_email}&mc=${mc}&key=${key}`
    })
}

//TODO: refactor, so that there is one fetch matches with optional filters
// i.e. fetchMatches({md, team_color, ...})
//TODO: Just call /api/carrier/load_matches -- you can add team color later
export async function getMatchesByMc(mc) {
    //TODO: Check this out
    return await axios({
        method: 'get',
        url: `/api/matches/${mc}`
    })
}
export async function getMatchesByTeam(parms) {
    let color
    if (parms && parms.color) {
        color = parms.color
    }
    let url = `/api/matches`
    if (color) {
        url = `/api/matches?team_color=${color}`
    }

    return await axios({
        method: 'get',
        url: url
    })
}

export async function getMatchReport(data) {
    return await axios({
        method: 'post',
        url: '/matcher/matches',
        data
    })
}