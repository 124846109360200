import { ADD_LEADS_FROM_SELECTED_LOAD, UPDATE_MATCHED_LEADS_WITH_BID} from '../actionTypes'
import moment from 'moment'

const initialState = []


export default (state=initialState,  action) => {
    let leads = []
    switch(action.type) {
    case ADD_LEADS_FROM_SELECTED_LOAD:
        // console.log('fffff',action.leadData)
        return action.leadData

    case UPDATE_MATCHED_LEADS_WITH_BID: {
            //Not sure why but tried trucks = [...state] would return undefined
            // but since we are mapping this will still work
            leads = state
            let {bid} = action

            if (leads && leads.length) {
                leads = leads.map(t => {
                    if (t.carrier.mc === bid.carrier.mc) {
                        t.last_bid = bid
                        //Note:  This is done so the DSTable updates.  It used id and updated_at as a key
                        t.updated_at = moment(t.updated_at).add(1, 's').toISOString()
                    }
                    return t
                })

            }

    
            return leads
        }
    default:
        return state
    }
}
