import { combineReducers } from 'redux'

import loads from './loadsReducer'
import opportunityLoads from './opportunityLoadsReducer'
import selectedLoad from './selectedLoadReducer'
import externalLoads from './externalLoadsReducer'
import authenticatedUser from './authenticatedUser'
import selectedLoadScreenTable from './selectedLoadScreenTableReducer'
import matchedTrucks from './matchedTrucksReducer'
import bidModal from './bidModalReducer'
import selectedCarrier from './selectedCarrierReducer'
import leadData from './leadsLoadReducer'
import carriersOwned from './carriersOwnedReducer' // TODO: REMOVE
import carrierLeads from './carrierLeadsReducer' // TODO: REMOVE
import myAssignments from './assignmentsMyAssignmentsReducer'
import carrierSearchList from './carrierSearchListReducer'
import recentCarriers from './carrierRecentCarriersReducer'
import noteModal from './noteModalReducer'
import users from './userReducer.js'
import calls from './callReducer.js'
import selectedUser from './selectedUserReducer'
import trucks from './trucksReducer'
import truckEntrySelectedCarrier from './truckEntrySelectedCarrierReducer'
import truckEntryTruckList from './truckEntryTruckListReducer'
import messages from './messagesReducer'
import loadFilter from './loadFilterReducer'
import carrierMatchingLoads from './carrierMatchingLoadsReducer'
import selectedTruck from './selectedTruckReducer'
import eventList from './eventListReducer'
import selectedCarrierTruck from './selectedCarrierTruckReducer'
import inboxNotifications from './inboxNotificationsReducer'
import historicLoads from './historicLoadsReducer'
import userLoads from './userLoadsReducer'
import userLoadSpread from './userLoadSpreadReducer'
import selectedBid from './selectedBidReducer'
import marketLoads from './marketLoadsReducer'
import historicLoadsForScreen from './historicLoadsScreenReducer'
import historicLoadsFilter from './historicLoadsFilterReducer'
import recentCustomers from './customer/customerRecentCustomersReducer'
import customerSearchList from "./customer/customerSearchListReducer"
import selectedCustomer from "./customer/selectedCustomerReducer"
import stats from './statsReducer'
import tasks from './tasksReducer'
import selectedTask from './selectedTaskReducer'
import matches from './matchesReducer'
import selectedMatch from './selectedMatchReducer'
import selectedLead from './selectedLeadReducer'
import emails from './emailsReducer'
import allTeams from './adminReducer'
import dialogs from './dialogReducer'
import queryCounterData from './queryCounterReducer'
import connectLogsData from './connectLogsReducer'
import truckStreamConnected from './truckStreamConnectedReducer'
import insightData from './insightDataReducer'
import allConnectLogs from './allConnectLogsReducer'
import datRates from './datRatesReducer'
import returnLoads from './returnLoadsForTruckReducer'
import smsQueueLogs from './smsQueueLogsReducer'
import historicLoadsByMc from './historicLoadsByMcReducer'
import connectMaps from './connectMapsReducer'
import connectMapsState from './connectMapsStateReducer'
import adminCustomersData from './adminCustomersReducer'
import connectQueueStatus from './connectQueueStatusReducer'
import carrierOpsTasks from './carrierOpsReducer'
import selectedCarrierOpsTask from './selectedCarrierOpsTaskReducer'
import modalDialog from './modalDialogReducer'
import carrierOpsTaskData from './carrierOpsTaskDataReducer'
import incomingTexts from './incomingTextsReducer'
import carrierOpsSelectedLoadTasks from './carrierOpsSelectedLoadTasksReducer'
import loadTaskFilter from './loadTaskFilterReducer'
import smsInboundNotifications from './smsInboundNotificationsReducer'
import newVersionAvailable from './newVersionAvailableReducer'
import loadTaskQuestions from './loadTaskQuestionsReducer'
import pusherConnectionStatus from './pusherConnectionStatusReducer'
import carrierFolders from './carrierFoldersReducer'
import customerLanes from './customer/customerLanesReducer'
import customerContacts from './customer/customerContactsReducer'
import customerFacilities from './customer/customerFacilitiesReducer'
import customerCustomers from './customer/customerCustomersReducer'
import carrierSelectedLoad from './carrierSelectedLoadReducer'
import carrierBids from './carrierBidsReducer'
import customerLumperData from './customerLumperDataReducer'
import userActivity from './userActivityReducer'
import truckSchedules from './truckSchedulesReducer'
import userCarriers from './userCarriersReducer'
import teams from './teamsReducer.js'
import userOwnedCarriers from './userOwnedCarriersReducer.js'
import carrierOwners from './carrierOwnersReducer.js'

const combinedReducers = combineReducers({
    loads,
    leadData,
    opportunityLoads,
    selectedLoad,
    externalLoads,
    authenticatedUser,
    selectedLoadScreenTable,
    matchedTrucks,
    bidModal, //TODO: do we need models in the store?, yes but we should use one reducer to store them all
    selectedCarrier,
    carriersOwned, // TODO: REMOVE
    carrierLeads, // TODO: REMOVE
    dialogs,  //TODO: we should use one reducer to store all dialog visibility
    myAssignments,
    carrierSearchList,
    recentCarriers,
    noteModal,  //TODO: do we need models in the store?  we should use one reducer to store all dialog visibility
    users,
    calls,  //TODO: do we need this?
    selectedUser,
    trucks,
    truckEntrySelectedCarrier,
    truckEntryTruckList,
    messages,
    loadFilter,
    carrierMatchingLoads,
    allTeams,
    selectedTruck,
    eventList,
    selectedCarrierTruck,
    selectedLead,
    inboxNotifications,  //TODO: remove this
    historicLoads,
    userLoads,
    selectedBid,
    marketLoads,
    historicLoadsForScreen,
    historicLoadsFilter,
    recentCustomers,
    customerSearchList,
    selectedCustomer,
    stats,
    tasks,
    selectedTask,
    matches,
    selectedMatch,
    emails,
    queryCounterData,
    connectLogsData,
    truckStreamConnected,
    insightData,
    allConnectLogs,
    datRates,
    returnLoads,
    userLoadSpread,
    smsQueueLogs,
    historicLoadsByMc,
    connectMaps,
    connectMapsState,
    adminCustomersData,
    connectQueueStatus,
    carrierOpsTasks,
    selectedCarrierOpsTask,
    carrierOpsTaskData,
    incomingTexts,
    modalDialog,
    carrierOpsSelectedLoadTasks,
    loadTaskFilter,
    smsInboundNotifications,
    newVersionAvailable,
    loadTaskQuestions,
    pusherConnectionStatus,
    carrierFolders,
    customerLanes,
    customerContacts,
    customerFacilities,
    customerCustomers,
    carrierSelectedLoad,
    carrierBids,
    customerLumperData,
    userActivity,
    truckSchedules,
    userCarriers,
    userOwnedCarriers,
    teams,
    carrierOwners
})

const rootReducer = (state, action) => {
  return combinedReducers(state, action)
}

//accessor functions into the store, used by yield select
export const getLoadFilter = state => state.loadFilter
export const getLoads = state => state.loads
export const getSelectedLoad = state => state.selectedLoad
export const getSelectedLead = state => state.selectedLead
export const getCarriersOwned = state => state.carriersOwned
export const getCarrierLeads = state => state.carrierLeads
export const getMatchedTrucks = state => state.matchedTrucks
export const getCurrentUser = state => state.authenticatedUser
export const getSelectedCarrier = state => state.selectedCarrier
export const getHistoricLoads = state => state.historicLoads
export const getRecentCarriers = state => state.recentCarriers
export const getRecentCustomers = state => state.recentCustomers
export const getMyAssignments = state => state.myAssignments
export const getEmails = state => state.emails
export const getQueryCounterData = state => state.queryCounterData
export const getConnectLogsData = state => state.connectLogsData
export const getAllConnectLogs = state => state.allConnectLogs
export const getCarrierMatchingLoads = state => state.carrierMatchingLoads
export const getTasks = state => state.tasks
export const getSelectedTask = state => state.selectedTask
export const getMatches = state => state.matches
export const getTruckEntryTruckList = state => state.truckEntryTruckList
export const getReturnLoads = state => state.returnLoads
export const getSmsQueueLogs = state => state.smsQueueLogs
export const getHistoricLoadsByMc = state => state.historicLoadsByMc
export const getConnectMaps = state => state.connectMaps
export const getConnectMapsState = state => state.connectMapsState
export const getConnectQueueStatus = state => state.connectQueueStatus
export const getCarrierOpsTasks = state => state.carrierOpsTasks
export const getSelectedCarrierOpsTask = state => state.selectedCarrierOpsTask
export const getCarrierOpsTaskData = state => state.carrierOpsTaskData
export const getIncomingTexts = state => state.incomingTexts
export const getCarrierOpsSelectedLoadTasks = state => state.carrierOpsSelectedLoadTasks
export const getLoadTaskFilter = state => state.loadTaskFilter
export const getLoadTaskQuestioons = state => state.loadTaskQuestions
export const getSelectedCarrierTruck = state => state.selectedCarrierTruck
export const getCarrierOwners = state => state.carrierOwners
export default rootReducer