import { SET_MARKET_LOADS } from '../actionTypes'
import { mapEqTypes } from '../loadActions'

const initialState = []


export default (state=initialState,  action) => {

    switch(action.type) {
    case SET_MARKET_LOADS:
        let {loads} = action
        
        // need to change the id of the load, because we have 2 table and the table does not update right if the 
        // id is not different between similar loads and market table
        if (loads && loads.length && loads[0].equipmentType) {
            loads = loads.map(mapEqTypes).map(l => {
                const id = l.load_id || l.id
                l.id = `M-${id}`
                return l
            })
        } else if (loads && loads.length) {
            loads = loads.map(l => {
                const id = l.load_id || l.id
                l.id = `M-${id}`
                return l
            })
        }
        return loads
    // case CLEAR_EXTERNAL_LOADS:
    //     return []
    default:
        return state
    }
    
}