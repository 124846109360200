import _ from 'lodash'
import {addContact} from '../actions/carrierActions'

export function addTruckContact(truck) {
    if (truck && truck.source === 'DAT') {
        const truckContactData = getTruckContactInfo(truck)
        if (truckContactData) {
            addContact(truckContactData)
        }
    }
}





/* 
 * Functions below are not exported 
*/


// Function that extracts contact info from a DAT contact.  If no contact info, returns null
function getTruckContactInfo(truck) {
    let contact = null

    if (truck && (truck.phone_number || truck.email || truck.notes || truck.name) ) {
        contact = {}

        contact.contact_type = 'DISPATCH'
        contact.phone = sanitizePhoneFromDatTruck(_.get(truck, 'phone_number', ''))
        contact.extension = getExtensionFromDatTruckPhone(truck.phone_number)
        contact.email = _.get(truck, 'email', '')
        contact.name = getContactNameFromDATTruckNotes(truck.notes)
        contact.carrier_id = truck.carrier.id
    }

    return contact
}



function getContactNameFromDATTruckNotes(notes) {
    let name = ''

    if (notes) {
        name = notes.replace('Callback: ', '')
        name = name.substring(0, name.indexOf('.'))
    }

    return name
}

function getExtensionFromDatTruckPhone(DATPhoneString) {
    let extension = ''

    if (DATPhoneString) {
        // Create copy of param
        let phone = (' ' + DATPhoneString).slice(1)
        phone = phone.toUpperCase()

        const extensionTokens = ['EXTENTIONS', 'EXT', 'X']
        
        // Remove phone number part
        for (const token of extensionTokens) {
            let index = phone.indexOf(token)

            if (index > 0) {
                extension = phone.slice(index)
                break
            }    
        }

        // Find first numeric character and remove anything before that
        var firstDigitIndex = extension.search(/\d/)
        extension = extension.slice(firstDigitIndex)
    }

    return extension
}

function sanitizePhoneFromDatTruck(DATPhoneString) {
    let phone = ''

    if (DATPhoneString) {
        // Create a copy of param
        phone = (' ' + DATPhoneString).slice(1)
        phone = phone.toUpperCase()

        const extensionTokens = ['EXTENTIONS', 'EXT', 'X']
        
        // Remove phone number part
        for (const token of extensionTokens) {
            let index = phone.indexOf(token)
            if (index > 0) {
                phone = phone.slice(0, index)
                break
            }    
        }
    }

    return phone
}

