import axios from "axios"

export const fetchCarriersWithFilter = (args) => {
  const request = {
      method: 'get',
      url: '/api/carriers?isFiltered=true'
  }

  request.params = {}

  for (let key in args) {
      if (args[key]) {
          request.params[key] = args[key]
      }
  }

  return axios(request) || null
}

export const fetchCarrier = (carrier_id) => {
  return axios({
      method: "get",
      url: '/api/carriers/' + carrier_id
  })
}

export const fetchCarrierByMC = (mc) => {
  return axios({
      method: 'get',
      url: `/api/carriers?mc=${mc}&withLoads=true`
  })
}

export const fetchMyCarrierAssignments = () => { 
  return axios({
    method: 'get',
    url: '/api/carrier_rep_assignments?myAssignments=true'
  })
}

export const fetchCarrierTruckMatchingLoads = (truckId) => {
  return axios({
    method: "get",
    url: `/api/trucks/${truckId}/matching_loads`
  })
}


export const postTruckRefresh = (truck) =>{
  return axios({
    method: "post",
    url: `/api/trucks/${truck?.truck_id || truck.id}/refresh`
  })
}


export const addContact = (contact) => {
  return axios({
    method: "post",
    url: `/api/carriers/${contact.carrier_id}/contacts`,
    data: contact
  })
}

export const deleteCarrierContact = async ({carrierId, contactId}) => {
  await axios({
      method: "delete",
      url: `/api/carriers/${carrierId}/contacts/${contactId}`
  })
}

// TODO: Fix this??
export const addCarrierNote = (carrierId, note) => {
  return axios({
    method: "post",
    url: `/api/carriers/${carrierId}/notes`,
    data: note
  })
}

export const deleteCarrierNote = async ({carrierId, noteId}) => {
  await axios({
      method: "delete",
      url: `/api/carriers/${carrierId}/notes/${noteId}`
  })
}

export const deleteCarrierAssignment = async ({assignment}) => {
  return await axios({
      method: "delete",
      url: `/api/carriers/${assignment.carrier_id}/carrier_rep_assignment`,
      data: assignment
  })
}

export const addCarrierAssignment = async ({assignment}) => {
  return await axios({
      method: "post",
      url: `/api/carriers/${assignment.carrier_id}/carrier_rep_assignment`,
      data: assignment
  })
}

export const saveFolderUpdates = async(updates) => {
  return await axios({
    method: 'post',
    url: `api/carrier_rep_assignment_tags/updates?returnTags=true`,
    data: updates
  })
}

export const patchCarrier = async (carrier_id, data) => {
  return await axios({
    method: 'patch',
    url: `api/carriers/${carrier_id}`,
    data
  })
}

// export const fetchLoadsNearCarrier = (trucks) => {
//   return axios({
//     method: "post",
//     url: `/api/carriers/fetchLoadsNearCarrier`,
//     data: trucks
//   })
// }

export const getCarrierFolders = () => {
  return axios({
    method: "get",
    url: `/api/carrier_folders`
  })
}

export const addCarrierFolder = (folder_name, public_folder) => {
  return axios({
    method: "post",
    url: `/api/carrier_folders`,
    data: {
      folder_name,
      public_folder
    }
  })
}

export const deleteCarrierFolder = (folder_id) => {
  return axios({
    method: "delete",
    url: `/api/carrier_folders/${folder_id}`
  })
}

export const updateCarrierFolder = (folder_id, folder_name, public_folder) => {
  return axios({
    method: "patch",
    url: `/api/carrier_folders/${folder_id}`,
    data: {
      folder_name,
      public_folder
    }
  })
}


export const getFolderCarriers = (folder_id) => axios({
  method: 'get',
  url: `/api/carrier_folders/${folder_id}/carriers`
})

export const removeCarrierFromFolder = (folder_id, carrier_id) => axios({
  method: 'delete',
  url: `/api/carrier_folders/${folder_id}/carriers/${carrier_id}`
})

export const addCarrierToFolder = (folder_id, carrier_id) => axios({
  method: 'post',
  url: `/api/carrier_folders/${folder_id}/carriers`,
  data: {
      carrier_id
  }
})

export const fetchCarrierBids = (carrier_id) => {
  return axios({
    method: 'get',
    url: `/api/carriers/${carrier_id}/bids`
  })
}

export const fetchUserCarriers = () => {
  return axios({
    method: 'get',
    url: `/api/users/carriers`
  })
}

export const addUserCarrier = (carrierId) => {
  return axios({
    method: 'post',
    url: `/api/users/carriers/${carrierId}`
  })
}

export const deleteUserCarrier = (carrierId) => {
  return axios({
    method: 'delete',
    url: `/api/users/carriers/${carrierId}`
  })
}

export const fetchUserOwnedCarriers = () => {
  return axios({
    method: 'get',
    url: `/api/users/-/owned_carriers`
  })
}

export const fetchCarrierOwners = (filter) => {
  let url = '/api/carrier_owners'

  // Don't add unused filters to query string
  for (const [q, v] of Object.entries(filter)) {  
    if (v==='') {
      delete filter[q]
    }
  }

  return axios({
    method: 'get',
    url: url,
    params: filter
  })
}

export const assignCarrierOwner = (carrier, user) => {
  return axios({
    method: 'post',
    url: `/api/carriers/${carrier.id}/owner`,
    data: {
      user: user.id
    }
  })
}

export const revokeCarrierOwner = (carrier) => {
  return axios({
    method: 'delete',
    url: `/api/carriers/${carrier.id}/owner`
  })
}
