import {
    SET_SELECTED_CUSTOMER,
    UPDATED_SELECTED_CUSTOMER_CONTACT,
    UPDATE_SELECTED_CUSTOMER_CONTACTS_LIST_AFTER_DELETE,
    UPDATE_SELECTED_CUSTOMER_NOTES_LIST_AFTER_DELETE,
    UPDATED_SELECTED_CUSTOMER_NOTE, UPDATED_SELECTED_CUSTOMER_LUMPER,
    UPDATE_SELECTED_CUSTOMER_SF_ID
} from '../../actionTypes'

import {capitalize} from '../../../utils/formatters'
import _ from 'lodash'

const initialState = {}

const cityStateZip = address => {
    if (address) {
        const city = address.city ? capitalize(address.city) : ''
        const state = address.state ? address.state : ''
        const zip = address.zip ? address.zip : ''
        const comma = address.city && address.state ? ',' : ''

        return `${city}${comma} ${state} ${zip}`
    } else {
        return '-'
    }
}

const formatPhone = phone => {
    if (phone && phone.length) {
        let formattedPhone = phone.trim().replace(/\D/g, '')
        if (formattedPhone.length === 11 && formattedPhone.charAt(0) === '1') {
            formattedPhone = formattedPhone.substring(1)
        } else if (formattedPhone.length > 10) {
            return '-'
        }

        let match = formattedPhone.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            formattedPhone = `(${match[1]}) ${match[2]}-${match[3]}`
        }
        return formattedPhone
    } else {
        return '-'
    }
}


const convertContactToListFormat = (c) => {
    c.formattedAddress = c.address ? cityStateZip(c.address) : ''
    c.formattedPhone = c.phone ? formatPhone(c.phone) : ''
    c.email = c.email ? c.email.toLowerCase() : ''
    c.contact_type = c.contact_type ? capitalize(c.contact_type) : ''
    c.name = c.name ? capitalize(c.name) : ''

    return c
}

const convertNullsToEmptyStrings = c => {
    Object.keys(c).forEach(key => {
        const stringContainsNull = (typeof c[key] === 'string' && c[key].toLowerCase().includes('null'))
        if (c[key] === null || stringContainsNull) {
            c[key] = ''
        }
    })
    return c
}


export default (state = initialState, action) => {
    let selectedCustomer = null
    switch (action.type) {
        case SET_SELECTED_CUSTOMER:
            selectedCustomer = Object.assign({}, action.selectedCustomer)

            /*if (selectedCustomer.loads) {
                // Sort loads by pickup date DESC
                selectedCustomer.loads.sort((a,b) => {
                    return moment(b.pickup_date) - moment(a.pickup_date)
                })

                selectedCustomer.loads.forEach(l => {
                    l.gross_profit = getGrossProfitForLoad(l)
                })
            }*/

            if (selectedCustomer.contacts && selectedCustomer.contacts.length) {
                selectedCustomer.contacts.forEach(c => {
                    c = convertNullsToEmptyStrings(c)
                    return convertContactToListFormat(c)
                })
            }

            return selectedCustomer

        // HERE we can add in the contact into the selected customer, dispatch the selected customer with the new contact
        // action.selectedCustomer, action.newContact
        // if there is a dummy record, then remove it
        // then return selectedCustomer
        case UPDATED_SELECTED_CUSTOMER_CONTACT:
            selectedCustomer = Object.assign({}, action.selectedCustomer)

            let newContact = convertNullsToEmptyStrings(action.newContact)
            let contacts = [...selectedCustomer.contacts]

            newContact.fadeIn = true
            const indexOfContactToReplace = contacts.findIndex(c => c.id === newContact.id)

            if (indexOfContactToReplace === -1) {
                contacts.push(convertContactToListFormat(newContact))
            } else {
                contacts[indexOfContactToReplace] = convertContactToListFormat(newContact)
            }

            selectedCustomer.contacts = contacts

            return selectedCustomer
        case UPDATE_SELECTED_CUSTOMER_CONTACTS_LIST_AFTER_DELETE:
            selectedCustomer = Object.assign({}, action.selectedCustomer)
            _.remove(selectedCustomer.contacts, (c) => c.id === action.contactId)

            return selectedCustomer
        case UPDATED_SELECTED_CUSTOMER_NOTE:
            selectedCustomer = Object.assign({}, action.selectedCustomer)
            let newNote = action.newNote
            let notes = [...selectedCustomer.notes]

            newNote.fadeIn = true

            if (newNote.id) {
                const editedNoteIndex = _.findIndex(notes, (n) => {
                    return n.id === newNote.id
                })
                if (editedNoteIndex >= 0) {
                    notes.splice(editedNoteIndex, 1)
                }

            }

            notes.push(newNote) // TODO: convert this to list format??
            selectedCustomer.notes = notes

            return selectedCustomer
        case UPDATE_SELECTED_CUSTOMER_NOTES_LIST_AFTER_DELETE:
            selectedCustomer = Object.assign({}, action.selectedCustomer)
            _.remove(selectedCustomer.notes, (c) => c.id === action.noteId)

            return selectedCustomer
        case UPDATED_SELECTED_CUSTOMER_LUMPER:
            selectedCustomer = Object.assign({}, action.selectedCustomer)
            let newLumper = action.newLumper
            let lumpers = [...selectedCustomer.lumpers]

            newLumper.fadeIn = true

            if (newLumper.id) {
                const editedNoteIndex = _.findIndex(lumpers, (n) => {
                    return n.id === newLumper.id
                })
                if (editedNoteIndex >= 0) {
                    lumpers.splice(editedNoteIndex, 1)
                }
            }

            lumpers.push(newLumper) // TODO: convert this to list format??
            selectedCustomer.lumpers = lumpers

            return selectedCustomer
        case UPDATE_SELECTED_CUSTOMER_SF_ID: 
            selectedCustomer = Object.assign({}, action.selectedCustomer)
            selectedCustomer.sf_id = action.sfId

            return selectedCustomer
        default:
            return state
    }
}