import { takeLatest, takeEvery, call, put, select } from "redux-saga/effects"
import moment from "moment"

import { 
  CHECK_LOCK, 
  REMOVE_MATCH_LOCKED_BY_USER, 
  REMOVE_MATCH_LOCKED, 
  MATCH_LOCKED, 
  FETCH_MATCHES, 
  MATCH_UPDATED, 
  REMOVE_MATCH, 
  SET_MATCHES, 
  SET_SELECTED_MATCH, 
  MATCH_SELECTED, 
  ADD_MATCH_BID, 
  ADD_BID, 
  ADD_MATCH,
  SELECT_MATCH_FOR_CARRIER_MC,
  SET_SELECTED_TASK,
  FETCH_CARRIER_OWNERS
} from '../actionTypes'

import { fetchMatches, lockCarrier, removeLockCarrier, checkLock } from '../../actions/matchesActions'
import { fetchLoad } from '../../actions/loadActions'
import { 
  getMatches, 
  getCurrentUser,
  getSelectedCarrier,
  getCarrierOwners
} from '../reducers/rootReducer'


function* workerFetchMatches(/*action*/) {
  yield put({ type: SET_MATCHES, matches: null })

  //here we are going to inline the fetch carrier owners so that we can mark
  // matches by owner
  yield put({type: FETCH_CARRIER_OWNERS})

  const user = yield select(getCurrentUser)
  const userId = user?.user_id ? user.user_id : null

  const matchesResponse = yield call(fetchMatches)
  const matches = matchesResponse?.data

  if (matches?.length) {
    const carrierOwners = yield select(getCarrierOwners)
    const modifiedMatches = (carrierOwners) ? matches.map( m => {
      const carrierOwner = carrierOwners[m.carrier.mc]
      if (carrierOwner) {
        m.carrier.owner = carrierOwner.owner
      }
      return m
    }) : matches

    yield put({ type: SET_MATCHES, matches: modifiedMatches, userId })
    const selectedCarrier = yield select(getSelectedCarrier)
    if (selectedCarrier) {
      yield put({type: SELECT_MATCH_FOR_CARRIER_MC, mc: selectedCarrier.mc})
    }
  } else {
    yield put({ type: SET_MATCHES, matches: [] })
  }
}

//TODO: check this works -- DALE
function* workerMatchUpdated(action) {
  const user = yield select(getCurrentUser)
  const userId = user?.id ? user.id : null
  
  if (action?.data?.subject) {
    if (action.data.action === 'NEW') {
      yield put({ type: ADD_MATCH, match: action.data.subject, userId})
    } else if (action.data.action === 'EXPIRED') {
      yield put({ type: REMOVE_MATCH, match: action.data.subject})
    }
  }
}


function* workerMatchSelected(action) {
  if (action) {
    // fetch the load
    if (action.selectedMatch) {
      let selectedMatch = JSON.parse(JSON.stringify(action.selectedMatch))
      let {pro} = selectedMatch.load
      const response = yield call(fetchLoad, pro)
      if (response && response.data) {
        let load = response.data
        load.invoice_total = parseFloat(load.invoice_total)
        load.carrier_rate = parseFloat(load.carrier_rate)
        let ask_bids = load.bids.filter(b => b.status === 'BID' && (! b.actual_bid_id))
        if (ask_bids.length) {
          load.last_bid = ask_bids[0]
          load.lowest_bid = ask_bids.sort((a,b) => {
            const abid = parseFloat(a.ask_price)
            const bbid = parseFloat(b.ask_price)
            if (abid > bbid) {
              return 1
            } else if (abid < bbid) {
              return -1
            }
            return 0
          })[0]

        } else {
          load.last_bid = {}
          load.lowest_bid = {}
        }
        selectedMatch.load = load
      }
      yield put({type: SET_SELECTED_MATCH, selectedMatch})
    }      
  }
}

function* workerMatchBid(action) {
  if (action) {
    const payload = {
      bidData: action.bidData,
      loadScreen: false
    }
    yield put({type: ADD_BID, payload})

    yield put({type: MATCH_SELECTED, selectedMatch: action.selectedMatch})
  }
}


//TODO: look into this matches reducer changed, new format.  Locks are out
function* workerMatchLock(action) {
  const currentUser = yield select(getCurrentUser)
  let userName = currentUser.email.replace('@loadotr.com','').replace('.',' ').toUpperCase()
  yield call(lockCarrier, action.parms,currentUser.email)
  let updatedMatches = []
  let matches = yield select(getMatches)
  matches.forEach((match) => {
    if (match.user_name && match.user_name === userName) {
      match.user_name = ''
      match.user_email = ''
    }
    if (match.carrier.id === action.parms.carrier.id) {
      match.user_name = userName
      match.user_email = currentUser.email
    }
    // This is logic is to prevent rerendering
    if (match?.age?.min_updated_date) {
      match.age.min_updated_date = moment.utc(match.age.min_updated_date).add(1, 's').format()
    }

    updatedMatches.push(match)
  })
  yield put({ type: SET_MATCHES, matches: updatedMatches })
}

function* workerRemoveMatchLock(action) {
  if (action) {
    let matches = yield select(getMatches)
    let updatedMatches = []
    matches.forEach((match) => {
      if (match.user_name && match.user_name === action?.parms?.user_name) {
        match.user_name = ''
        match.user_email = ''
      }

    // This is logic is to prevent rerendering
    if (match?.age?.min_updated_date) {
      match.age.min_updated_date = moment.utc(match.age.min_updated_date).add(1, 's').format()
    }
    
      updatedMatches.push(match)
    })
    yield call(removeLockCarrier, action?.parms?.carrier.id,action?.parms?.user_name,action?.parms?.id,action?.parms?.carrier.mc)
    yield put({ type: SET_MATCHES, matches: updatedMatches })
  }
}

function* workerRemoveMatchLockByUser(action) {
  if (action) {
    const currentUser = yield select(getCurrentUser)
    yield call(removeLockCarrier,currentUser.email)

  }
}

function* workerCheckLock(action) {
  if (action) {
    yield call(checkLock, action?.parms?.carrier.id,action?.parms?.user_email,action?.parms?.id,action?.parms?.carrier.mc)
  }
}

function* workerSelectMatchForCarrier(action) {
  if (action?.mc) {
    // get the list of matches
    const matches = yield select(getMatches)
    const task = matches.find(m => m.mc === action.mc)
    yield put({type: SET_SELECTED_TASK, selectedTask: task})
  }
}

export function* watchFetchMatchesSaga() {
    yield takeEvery(FETCH_MATCHES, workerFetchMatches)
}

export function* watchMatchUpdatedSaga() {
    yield takeEvery(MATCH_UPDATED, workerMatchUpdated)
}

export function* watchMatchSelectedSaga() {
    yield takeEvery(MATCH_SELECTED, workerMatchSelected)
}

export function* watchMatchBidSaga() {
  yield takeEvery(ADD_MATCH_BID, workerMatchBid)
}


export function* watchMatchLockSaga() {
  yield takeEvery(MATCH_LOCKED, workerMatchLock)
}

export function* watchRemoveMatchLockSaga() {
  yield takeEvery(REMOVE_MATCH_LOCKED, workerRemoveMatchLock)
}

export function* watchRemoveMatchLockByUserSaga() {
  yield takeEvery(REMOVE_MATCH_LOCKED_BY_USER, workerRemoveMatchLockByUser)
}

export function* watchCheckLockSaga() {
  yield takeEvery(CHECK_LOCK, workerCheckLock)
}

export function* watchSelectMatchForCarrierSaga() {
  yield takeLatest(SELECT_MATCH_FOR_CARRIER_MC, workerSelectMatchForCarrier)
}
