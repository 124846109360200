import axios from 'axios'

export const fetchConnectQueueStatus = async ({mc, bid_id, load_id}) => {
    let result = await axios({
        method: 'get',
        url: `/api/connect`,
        params: {
            mc: mc,
            bid_id: bid_id,
            load_id: load_id
        }
    })
    
    const data = (result && result.status === 200) ? result.data : []

    return data
}

export const fetchConnectQueueStatusByKeys = async (keyList) => {
    let result = await axios({
        method: 'get',
        url: `/api/connect`,
        params: {
            keys: keyList.join(',')
        }
    })
    
    const data = (result && result.status === 200) ? result.data : []
    return data
}