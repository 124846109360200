import axios from "axios"
import { formatDate } from '../utils/formatters'
const moment = require('moment')

export const fetchLoads = (parms) => {
    let options = {
      method: 'get',
      url: '/api/loads'
    }
  
    // RE-PACAKGE parms into options.params for otter.
    if (parms) {
      options.params = {}
  
      if (parms.equipmentGroups && parms.equipmentGroups.length) {
        options.params['equipmentGroups'] = parms.equipmentGroups.toString()
      }
        
      if (parms.equipmentTags) {
        options.params['equipmentTags'] = parms.equipmentTags.toString()
      }
  
      if (parms.historic) {
        options.params['status[in]'] = ['CONSIGNEE', 'DELIVERED', 'RELEASED'].toString()
        options.params['historic'] = true
      } else if (parms.statuses) {
        options.params['status[in]'] = parms.statuses.toString()
      }

      if (parms.shipDateFrom) {
          if (!parms.opportunityLoads) {
            options.params['ship_date_from'] = formatDate(parms.shipDateFrom, 'YYYY-MM-DD')
          } else {
            options.params['ship_date_from'] = ''
          }
      } else {
        if (parms.historic) {
          options.params['ship_date_from'] = '01/01/2015'
        }
      }

      if (parms.shipDateTo) {
        if (!parms.opportunityLoads) {
          options.params['ship_date_to'] = formatDate(parms.shipDateTo, 'YYYY-MM-DD')
        } else {
          options.params['ship_date_to'] = ''
        }
      }

      if (parms.origin && parms.origin.place_id) {
        parms.originRadius = parms.originRadius ? parms.originRadius : "50" // TODO set this constant
        options.params['originPlaceId'] = parms.origin.place_id
        options.params['originRadius'] = parms.originRadius
      }
  
      if (parms.destination && parms.destination.place_id) {
        parms.destinationRadius = parms.destinationRadius ? parms.destinationRadius : "50" // TODO set this constant
        options.params['destinationPlaceId'] = parms.destination.place_id
        options.params['destinationRadius'] = parms.destinationRadius
      }
  
      if (parms.myLoads && parms.myLoads.includes('MY_LOADS')) {
        options.params.my_loads = true
      }

      if (parms.age) {
        options.params.age = parms.age
      }

      if (parms.opportunityLoads) {
        options.params.opportunityLoads = true
      }

      if (parms.myTeams) {
        // // Select team loads (or not) based on regular business hours
        // const currentTimestamp = moment()
        // // const endOfRegularBusinessHours = moment(`${currentTimestamp.format('YYYY-MM-DD')}T16:59`)
        // const endOfRegularBusinessHours = moment(`${currentTimestamp.format('YYYY-MM-DD')}T00:03`)

        // if ((currentTimestamp.day() === 6) || (currentTimestamp.day() === 0)) { 
        //     options.params.my_teams = false
        // } else if (currentTimestamp.isAfter(endOfRegularBusinessHours)){
        //   options.params.my_teams = false
        // } else {
        //   options.params.my_teams = true
        // }

        // Select team loads based on button
        options.params.my_teams = parms.myTeams
      }
    }
  
    return axios(options);
  }
  
  export const createLogsLoads = (load_id, event_initiated = false) => {
    return axios({
      method: "get",
      url: `/api/loads/create_logs?load_id=${load_id}`,
      headers: {'x-event-initiated': (event_initiated ? 'yes' : 'no')}
    });
  }
  
  export const fetchLoad = (load_id, event_initiated = false) => {
    return axios({
      method: "get",
      url: '/api/loads/' + load_id,
      headers: {'x-event-initiated': (event_initiated ? 'yes' : 'no')}
    });
  }

  export const fetchLeadData = (selectedLoad,load) => {
    let rangeStart, rangeEnd//, days
    if (load) {
      rangeStart = load['rangeStart'] || null
      rangeEnd = load['rangeEnd'] || null
      //days = load['days'] || null
    }

    let {origin_state,origin_city,destination_state,destination_city, equipment_type, id } = selectedLoad
    let eq_type = equipment_type['name'] || null
    if (!rangeStart && !rangeEnd) {
      rangeStart = moment().startOf('day').subtract(1, 'month').format("MM/DD/YYYY")
      rangeEnd = moment().format("MM/DD/YYYY")
    }
    return axios({
      method: "get",
      // url: `/api/v2/loads?origin_state=${origin_state}&origin_city=${origin_city}&destination_state=${destination_state}&destination_city=${destination_city}&with=equipment_type,carrier`,
      url: `/api/leads?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}&origin_state=${origin_state}&origin_city=${origin_city}&destination_state=${destination_state}&destination_city=${destination_city}&equipment_type=${eq_type}&load_id=${id}`,
    });
  }

  export const postLoads = async (loads, postType) => {

    const loadData = loads.map(l =>  {
      let ld = {
        load_id: l.id,
        post: true,
        postType: postType
      }
      return ld
    })

    let result = null 
    await axios({
      method: 'post',
      url: '/api/load_postings',
      data: loadData
    }).then((response)=>{
      // console.log('response: ', response)
      // console.log('response.status: ', response.status)
      result = response
    })
    
    return result
  }

  export const unpostLoads = async (loads, postType) => {    
    const loadData = loads.map(l =>  {
      let ld = {
        load_id: l.id,
        post: false,
        postType: postType
      }
      return ld
    })

    let result = null

    await axios({
      method: 'post',
      url: '/api/load_postings',
      data: loadData
    }).then((response)=>{
      // console.log('response: ', response)
      // console.log('response.status: ', response.status)
      result = response
    })  
    
    return result
  }

  export const postLoad = (load_id, data) => {
    return axios({
      method: 'post',
      url: `/api/loads/${load_id}/load_postings`,
      data: data
    })
  }

  export const unpostLoad = (load_id, post_id = null) => {

    const post_id_parm = (post_id) ? `/${post_id}` : ''
    
    return axios({
      method: 'delete',
      url: `/api/loads/${load_id}/load_postings${post_id_parm}`
    })
  }
  export const historicalLoads = (load_id,rangeStart,rangeEnd) => {
    return axios({
      method: 'get',
      url: `/api/loads/${load_id}/history?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`

    })
  }

  export const fetchNextLoads = (load_id) => {
    return axios({
        method: 'get',
        url: `api/loads/${load_id}/next_loads`,
    })
  }
  
  export const mapEqTypes = l => {
    let mappedEqT = []

    if (l.equipmentType.toLowerCase().includes('van')) {
        mappedEqT.push('V')
    }

    if (l.equipmentType.toLowerCase().includes('reefer')) {
        mappedEqT.push('R')
    }

    if (l.equipmentType.toLowerCase().includes('flat')) {
        mappedEqT.push('F')
    }

    if (mappedEqT.length) {
        l.equipmentType = mappedEqT.join(' ')
    } else {
        l.equipmentType = '-'
    }

    return l
}

export const resetLoadAge = (load_id) => {
    return axios({
        method: 'post',
        url: `/api/loads/reset_age`,
        data: {
            load_id: load_id
        }
    })
}

export const voidBid = ({bidId, note}) => {
  return axios({
      method: 'patch',
      url: `/api/bids/${bidId}`,
      data: {
        note: note,
        void: true
      }
    })
}

export const updateBid = ({bidId, bid_amount, note}) => {
  return axios({
      method: 'post',
      url: `/api/bids/update_bid`,
      data: {
        bid_amount: bid_amount,
        bid_id: bidId,
        note: note
      }
    })
}

export const createBid = ({mc, bid_amount, ref, phone}) => {
  return axios({
      method: 'post',
      url: `/api/bids/create_bid_without_ids`,
      data: {
        bid_amount: bid_amount,
        mc: mc,
        ref: ref,
        phone: phone
      }
    })
}

export const getCarrierByMc = ({mc}) => {
  return axios({
      method: 'get',
      url: `/api/carriers/carrier_by_mc?mc=${mc}`
    })
}

export const getLoadByRef = ({ref}) => {
  return axios({
      method: 'get',
      url: `/api/loads/get_load_by_ref?ref=${ref}`
    })
}

export const datRatesByLoadId = (loadId) => {
  return axios({
    method: 'get',
    url: `/api/loads/${loadId}/rates`

  })
}

export const fetchDeadheadsForLoads = (loadList,selectedLoad) => {
  return axios({
      method: 'post',
      url: `/api/loads/listWithDeadhead`,
      data: [loadList,selectedLoad]
    })
}

export const fetchRecentLoads = () => {
  return axios({
      method: 'get',
      url: `/api/loads/fetchRecentLoads`
    })
}


export const addThresholdToLoad = (load_id,percent,baseRate,source,minRate,maxRate) => {
  return axios({
      method: 'post',
      url: `/api/loads/addThreshold`,
      data: {
          load_id: load_id,
          src: source,
          base_rate: baseRate,
          min_rate: minRate,
          max_rate: maxRate,
          percentage: percent}
    })
}

export const addThresholdToTeam = (load_id,percent,baseRate,source,minRate,maxRate,teamName) => {
  return axios({
      method: 'post',
      url: `/api/loads/addThresholdToTeam`,
      data: {
          load_id: load_id,
          src: source,
          base_rate: baseRate,
          min_rate: minRate,
          max_rate: maxRate,
          percentage: percent,
          teamName: teamName}
    })
}

export const fetchAllTeamThresholds = (teamName) => {
  return axios({
      method: "get",
      url: `/api/loads/fetchThresholdByTeam?teamName=${teamName}`,
  });
}

export const historicalLoadsByRegion = (load_id,rangeStart,rangeEnd,originRegion,destinationRegion) => {
  return axios({
    method: 'get',
    url: `/api/loads/${load_id}/history?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}&originRegion=${originRegion}&destinationRegion=${destinationRegion}`
  })
}


export const fetchThresholdToLoad = (load_id) => {
  return axios({
      method: "get",
      url: `/api/loads/${load_id}/threshold`,
      data: {
        loadId : load_id
      }
  });
}

export const bounceLoad = (load_id, bounce, soft_bounce, bounce_reason) =>{
  const data = {
    bounce,
    reason: bounce_reason,
    soft_bounce
  }
  return axios({
    method: "post",
    url: `api/loads/${load_id}/bounce`,
    data
  })
}

export const updateLoadTeams = (load_ids, color) => {
  return axios({
    method: "post",
    url: `api/teams/${color}/loads`,
    data: load_ids
  }) 
}
