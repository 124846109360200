import { SET_OPPORTUNITY_LOADS, SET_OPPORTUNITY_LOADS_ON_LOAD_UPDATE } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = []


/**
 * A load that is in the list of loads is slightly different format.  This function adds extra properties that the list may need
 * @param {*} load 
 */

// const EQUIPMENT_GROUP_FILTER_MAP = [
//     {
//         equipmentGroup: 'Other',
//         equipmentNames: [ '20CS', '20HC', '40CS', '40HC', 'CON', 'DBT', 'DD', 'LB', 'LQT', 'PO', 'SD', 'TANK', 'TL','UNKNOWN' ]
//     },
//     {   
//         equipmentGroup: 'Flatbed',
//         equipmentNames: [ 'F', 'FA', 'FM', 'FS', 'FT' ]
//     },
//     {
//         equipmentGroup: 'Reefer',
//         equipmentNames: [ 'R', 'RM', 'RZ', 'VR' ]
//     },
//     {
//         equipmentGroup: 'Van',
//         equipmentNames: [ 'V', 'VA', 'VC', 'VM', 'VR', 'VV', 'VZ' ]
//     }
// ]

// const convertToListLoad = (load) => {

//     let listLoad = {...load}

//     // before we push the load, the load needs a few updates to go into the list
//     if (typeof listLoad.equipment_type !== 'string' && listLoad.equipment_type !== null) {
//         listLoad.equipment_type = listLoad.equipment_type.name
//     }
//     if (typeof listLoad.customer !== 'string' && listLoad.customer !== null) {
//         listLoad.customer_name = listLoad.customer.name
//     }

//     if (listLoad.bids) {
//         const bids = listLoad.bids.filter(b => b.status === 'BID')
//         const declines = listLoad.bids.filter(b => b.status === 'DECLINE')

//         listLoad.bid_count = bids.length
//         listLoad.decline_count = declines.length

//         listLoad.lowestBid = (bids.length >= 0) ? bids.reduce((acc, b) => {
//             if (acc) {
//                 acc = (b.ask_price < acc) ? b.ask_price : acc
//             }
//             return acc
//         }, null): null

//     }

//     if (! listLoad.description) {
//         listLoad.description = ''
//     }

//     if (listLoad.assigned_rep) {
//         listLoad.assigned_rep_first_name = _.get(listLoad, 'assigned_rep.first_name', null)
//         listLoad.assigned_rep_last_name = _.get(listLoad, 'assigned_rep.last_name', null)
//     }

//     if (listLoad.sales_rep) {
//         listLoad.sales_rep_first_name = _.get(listLoad, 'sales_rep.first_name', null)
//         listLoad.sales_rep_last_name = _.get(listLoad, 'sales_rep.last_name', null)
//     }
    
//     return listLoad

// }

// const checkForEquipmentGroupsMatch = ({load, filter}) => {
//     if (load && load.equipment_type && load.equipment_type.name) {
//         for (const mapping of EQUIPMENT_GROUP_FILTER_MAP) {
//             if (filter.equipmentGroups.includes(mapping.equipmentGroup)) {
//                 if (mapping.equipmentNames.includes(load.equipment_type.name)) {
//                     return true
//                 }
//             }
//         }
//     }

//     return false
// }

// const checkIfAgeMatch = ({load, filter}) => {
//     let match = true

//     if (filter && filter.age) {
//         let age = moment().diff(moment(load.age_timestamp), 'minutes')

//         if (age > filter.age) {
//             match = false
//         }
//     }

//     return match
// }


// const isLoadMatchedByFilter = (load, filter = null) => {
//     let match = true

//     if (filter === null) {
//         return true
//     }

//     if (filter.statuses && !(filter.statuses.includes(load.status))) {
//         match = false
//     }

//     if (filter.equipmentGroups) {
//         if (!checkForEquipmentGroupsMatch({filter: filter, load: load})) {
//             match = false
//         }
//     }

//     if (filter.age) {
//         if (!checkIfAgeMatch({filter: filter, load: load})) {
//             match = false
//         }
//     }

//     // This should be the last filter because of the way it was written
//     if (match) {
//         const shipDateFrom = (filter.shipDateFrom) ? moment(filter.shipDateFrom, 'MM/DD/YYYY') : moment()
//         const shipDateTo = (filter.shipDateTo) ? moment(filter.shipDateTo, 'MM/DD/YYYY') : null
//         const pickupDate = moment(load.pickup_date)
        
//         if (shipDateTo) {
//             match = pickupDate.isSameOrBefore(shipDateTo) && pickupDate.isSameOrAfter(shipDateFrom)
//         } else {
//             match = pickupDate.isSameOrAfter(shipDateFrom)
//         }
//     }

//     return match
// }

//TODO:  Loads list I would like to not have a different return structure than load fetch or load update.
//       Right now this is a flat object with lowest_bid and carrier_name.
// This should change so that when we get live event updates on loads, we do not have to go through hoops to fit the data.
//  Just take it as is and splice it into the array.
export default (state=initialState,  payload) => {
    switch(payload.type) {
    case SET_OPPORTUNITY_LOADS:
        return payload.loads

    case SET_OPPORTUNITY_LOADS_ON_LOAD_UPDATE:   
        // const { updatedLoad, filter } = payload.params

        // if (state instanceof Array) {
        //     let loads = [...state]

        //     const shouldAddToList = isLoadMatchedByFilter(updatedLoad, filter)
        //     let notFoundInList = true
        //     loads = loads.map(l => {
        //         let loadToAdd = l
        //         if (l.id === updatedLoad.id) {
        //             notFoundInList = false
        //             if (shouldAddToList) {
        //                 loadToAdd = convertToListLoad(updatedLoad)
        //                 //force refresh in table hack
        //                 if (l.updated_at === loadToAdd.updated_at) {
        //                     loadToAdd.updated_at = moment(loadToAdd.updated_at).add(1, 'second')
        //                 }
        //             } else {
        //                 loadToAdd = null
        //             }
        //         }
        //         return loadToAdd
        //     }).filter(l => l != null)
    
        //     if (notFoundInList && shouldAddToList) {
        //         const loadToAdd = convertToListLoad(updatedLoad)
        //         loads.push(loadToAdd)
        //     }

        //     return loads 
        // } else {
        //     return state
        // }
        return state
    default:
        return state
    }
}