// Reducer types

// Process fetched loads
export const SET_LOADS = 'SET_LOADS'
export const SET_LOADS_ON_LOAD_UPDATE = 'SET_LOADS_ON_LOAD_UPDATE'
export const FETCH_OPPORTUNITY_LOADS = 'FETCH_OPPORTUNITY_LOADS'
export const SET_OPPORTUNITY_LOADS = 'SET_OPPORTUNITY_LOADS'
export const SET_OPPORTUNITY_LOADS_ON_LOAD_UPDATE = 'SET_OPPORTUNITY_LOADS_ON_LOAD_UPDATE'
export const SET_SELECTED_LOAD = 'SET_SELECTED_LOAD'
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER'
export const SET_SELECTED_LOAD_SCREEN_TABLE = 'SET_SELECTED_LOAD_SCREEN_TABLE'
export const SET_LOAD_FILTER = "SET_LOAD_FILTER"
export const LOAD_UPDATED = "LOAD_UPDATED"
export const SET_LOAD_ASSIGNED_USER = "SET_LOAD_ASSIGNED_USER"
export const SET_LOAD_PRIORITY = "SET_LOAD_PRIORITY"
export const SET_EMAILS = 'SET_EMAILS'
export const UPDATE_EMAILS = 'UPDATE_EMAILS'
export const FETCH_CONNECT_LOGS = 'FETCH_CONNECT_LOGS'
export const SET_CONNECT_LOGS = 'SET_CONNECT_LOGS'
export const FETCH_QUERY_COUNTER = 'FETCH_QUERY_COUNTER'
export const SET_QUERY_COUNTER = 'SET_QUERY_COUNTER'
export const FETCH_INSIGHT_DATA = 'FETCH_INSIGHT_DATA'
export const SET_INSIGHT_DATA = 'SET_INSIGHT_DATA'
export const SET_ALL_CONNECT_LOGS = 'SET_ALL_CONNECT_LOGS'
export const FETCH_DAT_RATES = 'FETCH_DAT_RATES'
export const SET_DAT_RATES = 'SET_DAT_RATES'
export const ADD_QUERY_COUNTER = 'ADD_QUERY_COUNTER'
export const FETCH_INSIGHT_HISTORIC_LEADS_DATA = 'FETCH_INSIGHT_HISTORIC_LEADS_DATA'
export const FETCH_AND_SET_EMAILS = 'FETCH_AND_SET_EMAILS'
export const FETCH_AND_SET_INSIGHT_DATA = 'FETCH_AND_SET_INSIGHT_DATA'
export const FETCH_AND_SET_CONNECT_LOGS = 'FETCH_AND_SET_CONNECT_LOGS'
export const FETCH_AND_SET_LEADS = 'FETCH_AND_SET_LEADS'
export const FETCH_SMS_QUEUE_LOGS = 'FETCH_SMS_QUEUE_LOGS'
export const SET_SMS_QUEUE_LOGS = 'SET_SMS_QUEUE_LOGS'
export const ADD_SMS_INBOUND_NOTIFICATION = 'ADD_SMS_INBOUND_NOTIFICATION'
export const REMOVE_SMS_INBOUND_NOTIFICATION = 'REMOVE_SMS_INBOUND_NOTIFICATION'
export const MARK_READ_SMS_INBOUND_NOTIFICATIONS = 'MARK_READ_SMS_INBOUND_NOTIFICATIONS'
export const ADD_SELECTED_INCOMING_TEXT = 'ADD_SELECTED_INCOMING_TEXT'
export const FETCH_AND_SET_THRESHOLD = 'FETCH_AND_SET_THRESHOLD'
export const SEND_EMAILS = 'SEND_EMAILS'
export const SEND_BULK_EMAILS = 'SEND_BULK_EMAILS'
export const SET_MC_FOR_EMAIL = 'SET_MC_FOR_EMAIL'

// Process fetched trucks
export const SET_MATCHED_TRUCKS = 'SET_MATCHED_TRUCKS'
export const UPDATE_MATCHED_TRUCKS_WITH_BID = 'UPDATE_MATCHED_TRUCKS_WITH_BID'
export const ADD_MATCHED_TRUCK_FROM_STREAM = 'ADD_MATCHED_TRUCK_FROM_STREAM'
export const CLEAR_MATCHED_TRUCKS = 'CLEAR_MATCHED_TRUCKS'
export const EXPIRE_MATCHED_TRUCK_FROM_STREAM = 'EXPIRE_MATCHED_TRUCK_FROM_STREAM'
export const DELETE_MATCHED_TRUCK_FROM_STREAM = 'DELETE_MATCHED_TRUCK_FROM_STREAM'
export const TRUCK_STREAM_LISTEN = 'TRUCK_STREAM_LISTEN'
export const TRUCK_STREAMING_HALT = 'TRUCK_STREAMING_HALT'
export const ADD_TRUCK_ENTRY_TRUCK_LIST = 'ADD_TRUCK_ENTRY_TRUCK_LIST'
export const EXPIRE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM = 'EXPIRE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM'
export const DELETE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM = 'DELETE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM'
export const TASK_SCREEN_TRUCK_STREAM_LISTEN = 'TASK_SCREEN_TRUCK_STREAM_LISTEN'

// Process fetched carriers
export const SET_CARRIERS_OWNED = 'SET_CARRIERS_OWNED'
export const SET_CARRIER_LEADS = 'SET_CARRIER_LEADS'
export const SET_MY_ASSIGNMENTS = 'SET_MY_ASSIGNMENTS'
export const SET_CARRIER_SEARCH_LIST = 'SET_CARRIER_SEARCH_LIST'
export const CLEAR_CARRIER_SEARCH_LIST = 'CLEAR_CARRIER_SEARCH_LIST'
export const SET_SELECTED_CARRIER = 'SET_SELECTED_CARRIER'
export const SET_RECENT_CARRIERS = 'SET_RECENT_CARRIERS'
export const UPDATE_AND_SHOW_RECENT_CARRIER_LIST = 'UPDATE_AND_SHOW_RECENT_CARRIER_LIST'
export const UPDATE_RECENT_CARRIER_ITEM = 'UPDATE_RECENT_CARRIER_ITEM'
export const SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS = 'SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS'
export const DELETE_CARRIER_ASSIGNMENT = 'DELETE_CARRIER_ASSIGNMENT'
export const ADD_CARRIER_ASSIGNMENT = 'ADD_CARRIER_ASSIGNMENT'
export const APPEND_TO_CARRIERS_OWNED = 'APPEND_TO_CARRIERS_OWNED'
export const APPEND_TO_CARRIER_LEADS = 'APPEND_TO_CARRIER_LEADS'
export const ADD_LEADS_FROM_SELECTED_LOAD= 'ADD_LEADS_FROM_SELECTED_LOAD'

export const FETCH_CARRIER_OWNERS = 'FETCH_CARRIER_OWNERS'
export const SET_CARRIER_OWNERS = 'SET_CARRIER_OWNERS'
export const ADD_CARRIER_OWNER = "ADD_CARRIER_OWNER"
export const REMOVE_CARRIER_OWNER = "REMOVE_CARRIER_OWNER"
export const REVOKE_CARRIER_OWNER = "REVOKE_CARRIER_OWNER"

// BID ENTRY/EDIT
export const OPEN_BID_MODAL = 'OPEN_BID_MODAL'
export const CLOSE_BID_MODAL = 'CLOSE_BID_MODAL'

export const OPEN_NOTE_MODAL = 'OPEN_NOTE_MODAL'
export const CLOSE_NOTE_MODAL = 'CLOSE_NOTE_MODAL'

// Users
export const SET_USERS = 'SET_USERS'
export const SET_USERS_WITH_STATS = 'SET_USERS_WITH_STATS'
export const UPDATE_USER = 'UPDATE_USER'
export const SET_SELECTED_USER = 'SET_SELECTED_USER'

// Call Screen
export const CALL_TABLE_USER_SELECTED = 'CALL_TABLE_USER_SELECTED'
export const SET_USER_CALL_DATA = 'SET_USER_CALL_DATA'

// Truck Entry/Carrier Screen
export const TRUCK_ENTRY_CARRIER_SELECTED = 'TRUCK_ENTRY_CARRIER_SELECTED'
export const SET_SELECTED_TRUCK_ENTRY_CARRIER = 'SET_SELECTED_TRUCK_ENTRY_CARRIER'
export const SET_TRUCK_ENTRY_TRUCK_LIST = 'SET_TRUCK_ENTRY_TRUCK_LIST'
export const SET_TRUCK_SCHEDULES = 'SET_TRUCK_SCHEDULES'
export const REMOVE_TRUCKS_ENTRY_TRUCK_LIST = 'REMOVE_TRUCKS_ENTRY_TRUCK_LIST'
export const TRUCK_ENTRY_ADD_TRUCK = 'TRUCK_ENTRY_ADD_TRUCK'
export const TRUCK_ENTRY_DELETE_TRUCK = 'TRUCK_ENTRY_DELETE_TRUCK'
export const TRUCK_ENTRY_DELETE_TRUCK_SCHEDULE = 'TRUCK_ENTRY_DELETE_TRUCK_SCHEDULE'
export const TRUCK_ENTRY_DELETE_MULTIPLE_TRUCKS = 'TRUCK_ENTRY_DELETE_MULTIPLE_TRUCKS'
export const REMOVE_TRUCK_ENTRY_TRUCK_SCHEDULE = 'REMOVE_TRUCK_ENTRY_TRUCK_SCHEDULE'
export const UPDATE_TRUCK_ENTRY_TRUCK_LIST = 'UPDATE_TRUCK_ENTRY_TRUCK_LIST'
export const FETCH_CARRIER_NEXT_LOADS = 'FETCH_CARRIER_NEXT_LOADS'

export const FETCH_CARRIERS = 'FETCH_CARRIERS'
export const FETCH_CARRIER_BY_MC = 'FETCH_CARRIER_BY_MC'
export const FETCH_CARRIER_BY_NAME = 'FETCH_CARRIER_BY_NAME'
export const FETCH_CARRIERS_WITH_FILTER = 'FETCH_CARRIERS_WITH_FILTER'
export const FETCH_MY_CARRIER_ASSIGNMENTS = 'FETCH_MY_CARRIER_ASSIGNMENTS'
export const CARRIER_SELECTED = 'CARRIER_SELECTED'
export const APPEND_TO_RECENT_CARRIERS = 'APPEND_TO_RECENT_CARRIERS'
export const SET_CARRIER_SELECTED_LOAD = 'SET_CARRIER_SELECTED_LOAD'
export const SELECT_CARRIER_LOAD = 'SELECT_CARRIER_LOAD'

export const ADD_CARRIER_NOTE = 'ADD_CARRIER_NOTE'
export const DELETE_CARRIER_NOTE = 'DELETE_CARRIER_NOTE'

//TODO @deprecate this one
export const SET_CARRIER_MATCHING_LOAD = 'SET_CARRIER_MATCHING_LOAD'
export const ADD_CARRIER_MATCHING_LOAD = 'ADD_CARRIER_MATCHING_LOAD'
export const REMOVE_CARRIER_MATCHING_LOAD = 'REMOVE_CARRIER_MATCHING_LOAD'


export const RETURN_LOADS_FOR_TRUCK = 'RETURN_LOADS_FOR_TRUCK'
export const CARRIER_TRUCK_SELECTED = 'CARRIER_TRUCK_SELECTED'
export const SET_SELECTED_CARRIER_TRUCK = 'SET_SELECTED_CARRIER_TRUCK'

export const FETCH_CARRIER_MATCHING_LOADS = 'FETCH_CARRIER_MATCHING_LOADS'

/**
 * Customer Actions
 */
export const TRUCK_ENTRY_CUSTOMER_SELECTED = 'TRUCK_ENTRY_CUSTOMER_SELECTED'
export const SET_SELECTED_TRUCK_ENTRY_CUSTOMER = 'SET_SELECTED_TRUCK_ENTRY_CUSTOMER'
export const DELETE_SELECTED_CUSTOMER_CONTACT = 'DELETE_SELECTED_CUSTOMER_CONTACT '
export const DELETE_SELECTED_CUSTOMER_NOTE = 'DELETE_SELECTED_CUSTOMER_NOTE '
export const DELETE_CUSTOMER_ASSIGNMENT = 'DELETE_CUSTOMER_ASSIGNMENT '
export const ADD_CUSTOMER_ASSIGNMENT = 'ADD_CUSTOMER_ASSIGNMENT '
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const FETCH_CUSTOMER_BY_ID = 'FETCH_CUSTOMER_BY_ID'
export const FETCH_CUSTOMER_BY_NAME = 'FETCH_CUSTOMER_BY_NAME'
export const FETCH_CUSTOMERS_WITH_FILTER = 'FETCH_CUSTOMERS_WITH_FILTER'
export const FETCH_MY_CUSTOMER_ASSIGNMENTS = 'FETCH_MY_CUSTOMER_ASSIGNMENTS'
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED'
export const ADD_USER_CUSTOMER = 'ADD_USER_CUSTOMER'
export const TOGGLE_CUSTOMER_FOLLOW_STATUS = 'TOGGLE_CUSTOMER_FOLLOW_STATUS'
export const DELETE_USER_CUSTOMER = 'DELETE_USER_CUSTOMER'
export const APPEND_TO_USER_CUSTOMERS = 'APPEND_TO_USER_CUSTOMERS'
export const REMOVE_FROM_USER_CUSTOMERS = 'REMOVE_FROM_USER_CUSTOMERS'
export const SET_CUSTOMER_SEARCH_LIST = 'SET_CUSTOMER_SEARCH_LIST'
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER '
export const UPDATE_SELECTED_CUSTOMER_CONTACTS_LIST_AFTER_DELETE = 'UPDATE_SELECTED_CUSTOMER_CONTACTS_LIST_AFTER_DELETE '
export const UPDATE_SELECTED_CUSTOMER_NOTES_LIST_AFTER_DELETE = 'UPDATE_SELECTED_CUSTOMER_NOTES_LIST_AFTER_DELETE '
export const UPDATE_RECENT_CUSTOMER_ITEM = 'UPDATE_RECENT_CUSTOMER_ITEM '
export const SET_CUSTOMER_SEARCH_LIST_TO_RECENT_CUSTOMERS = 'SET_CUSTOMER_SEARCH_LIST_TO_RECENT_CUSTOMERS '
export const SET_RECENT_CUSTOMERS = 'SET_RECENT_CUSTOMERS'
export const UPDATED_SELECTED_CUSTOMER_CONTACT = 'UPDATED_SELECTED_CUSTOMER_CONTACT'
export const ADD_CUSTOMER_NOTE = 'ADD_CUSTOMER_NOTE'
export const DELETE_CUSTOMER_NOTE = 'DELETE_CUSTOMER_NOTE'
export const CLEAR_CUSTOMER_SEARCH_LIST = 'CLEAR_CUSTOMER_SEARCH_LIST'
export const CUSTOMER_MATCHING_LOADS = 'CUSTOMER_MATCHING_LOADS'
export const UPDATED_SELECTED_CUSTOMER_NOTE = 'UPDATED_SELECTED_CUSTOMER_NOTE'
export const CUSTOMER_TRUCK_SELECTED = 'CUSTOMER_TRUCK_SELECTED'
export const SET_SELECTED_CUSTOMER_TRUCK = 'SET_SELECTED_CUSTOMER_TRUCK'
export const UPDATED_SELECTED_CUSTOMER_LUMPER = 'UPDATED_SELECTED_CUSTOMER_LUMPER'
export const UPDATED_SELECTED_CUSTOMER_SHIPPING_INFO = 'UPDATED_SELECTED_CUSTOMER_SHIPPING_INFO'
export const SET_MY_CUSTOMER_ASSIGNMENTS = 'SET_MY_CUSTOMER_ASSIGNMENTS'
export const SAVE_CUSTOMER_FOLDER_UPDATES = 'SAVE_CUSTOMER_FOLDER_UPDATES'
export const SET_CUSTOMER_FACILITES = 'SET_CUSTOMER_FACILITES'
export const SET_CUSTOMER_CUSTOMERS = 'SET_CUSTOMER_CUSTOMERS'


export const SET_CUSTOMER_LUMPER_DATA = 'SET_CUSTOMER_LUMPER_DATA'


// Truck Screen
export const FETCH_TRUCKS_WITH_FILTER = 'FETCH_TRUCKS_WITH_FILTER'
export const SET_TRUCKS = 'SET_TRUCKS'
export const REFRESH_TRUCK = 'REFRESH_TRUCK'
export const REFRESH_TRUCK_SCHEDULE = 'REFRESH_TRUCK_SCHEDULE'
export const TRUCK_SCHEDULE_REFRESHED = 'TRUCK_SCHEDULE_REFRESHED'

// External Loads Screen
export const FETCH_EXTERNAL_LOADS_WITH_FILTER = 'FETCH_EXTERNAL_LOADS_WITH_FILTER'
export const FETCH_EXERNAL_LOADS_FOR_LOAD = 'FETCH_EXERNAL_LOADS_FOR_LOAD'
export const SET_EXTERNAL_LOADS = 'SET_EXTERNAL_LOADS'
export const FETCH_MARKET_LOADS_WITH_FILTER = 'FETCH_MARKET_LOADS_WITH_FILTER'
export const FETCH_MARKET_LOADS_FOR_LOAD = 'FETCH_MARKET_LOADS_FOR_LOAD'
export const SET_MARKET_LOADS = 'SET_MARKET_LOADS'
export const CLEAR_EXTERNAL_LOADS = 'CLEAR_EXTERNAL_LOADS'

// SAGA actions
export const ADD_LEAD_BID = 'ADD_LEAD_BID'
export const UPDATE_MATCHED_LEADS_WITH_BID = 'UPDATE_MATCHED_LEADS_WITH_BID'
export const FETCH_LOADS = 'FETCH_LOADS'
export const LOAD_SELECTED = 'LOAD_SELECTED'
export const LOAD_TRUCK_ONLY_SELECTED = 'LOAD_TRUCK_ONLY_SELECTED'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const IMPERSONATE_USER = 'IMPERSONATE_USER'
export const CLEAR_IMPERSONATE_USER = 'CLEAR_IMPERSONATE_USER'
export const REFRESH_USER_TOKEN = 'REFRESH_USER_TOKEN'

export const FETCH_MATCHED_TRUCKS = 'FETCH_MATCHED_TRUCKS'
export const ADD_BID = 'ADD_BID'
export const FETCH_USERS = 'FETCH_USERS'
//deprecate this
export const FETCH_USERS_WITH_ACTIVITY_STATS = 'FETCH_USERS_WITH_ACTIVITY_STATS'
export const FETCH_USERS_ACTIVITY = 'FETCH_USERS_ACTIVITY'
export const SET_USER_ACTIVITY = 'SET_USER_ACTIVITY'
export const FETCH_ACTIVE_USERS = 'FETCH_ACTIVE_USERS'
export const FETCH_USER_LOADS = 'FETCH_USER_LOADS'
export const FETCH_LOADS_FOR_ALL_USERS = 'FETCH_LOADS_FOR_ALL_USERS'
export const SET_USER_LOADS = 'SET_USER_LOADS'
export const UPDATED_MATCHED_TRUCK_BID = "UPDATED_MATCHED_TRUCK_BID"
export const FETCH_ALL_EMAILS = "FETCH_ALL_EMAILS"
export const FETCH_NEXT_LOADS = 'FETCH_NEXT_LOADS'
export const FETCH_LOAD_SPREAD_FOR_ALL_USERS = "FETCH_LOAD_SPREAD_FOR_ALL_USERS"
export const SET_USER_LOAD_SPREAD = "SET_USER_LOAD_SPREAD"
export const FETCH_CUSTOMERS_WITH_FILTER_AND_LOADS = 'FETCH_CUSTOMERS_WITH_FILTER_AND_LOADS'

// DS Table
export const SET_LOADING_INDICATOR = 'SET_LOADING_INDICATOR'
export const CLEAR_LOADING_INDICATOR = 'CLEAR_LOADING_INDICATOR'


// CONSTANTS

// These stored in state 'selectedTable' which indicate which table to display on load screen
export const LOAD_LIST_SELECTED = "LOAD_LIST_SELECTED"
export const TRUCK_LIST_SELECTED = "TRUCK_LIST_SELECTED"

export const ADD_MESSAGE = 'ADD_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'

export const SET_SELECTED_TRUCK = "SET_SELECTED_TRUCK"
export const SET_SELECTED_LEAD = "SET_SELECTED_LEAD"
export const SET_SELECTED_TRUCK_ORIGIN = "SET_SELECTED_TRUCK_ORIGIN"
export const SET_SELECTED_TRUCK_DESTINATION = "SET_SELECTED_TRUCK_DESTINATION"
export const SELECTED_TRUCK_ORIGIN_SELECTED = "SELECTED_TRUCK_ORIGIN_SELECTED"
export const SELECTED_TRUCK_DESTINATION_SELECTED = "SELECTED_TRUCK_DESTINATION_SELECTED"
export const ADD_EVENT_LIST_EVENT = "ADD_EVENT_LIST_EVENT"

// EVENTS
export const ADD_EVENT = "ADD_EVENT"
export const BID_ADDED_EVENT = "BID_ADDED_EVENT"
export const NOTIFICATION_ADDED_EVENT = 'NOTIFICATION_ADDED_EVENT'
export const NEW_APP_VERSION_EVENT = 'NEW_APP_VERSION_EVENT'
export const USER_UPDATE_EVENT = 'USER_UPDATE_EVENT'

// INBOX NOTIFICATIONS
export const ADD_INBOX_NOTIFICATION = 'ADD_INBOX_NOTIFICATION'
export const FETCH_INBOX_NOTIFICATIONS = 'FETCH_INBOX_NOTIFICATIONS'
export const SET_INBOX_NOTIFICATIONS = 'SET_INBOX_NOTIFICATIONS'
export const INBOX_NOTIFICATIONS_READ = 'INBOX_NOTIFICATIONS_READ'

// Broadcast events for window to window communication
export const ADD_BROWSER_SCREEN = "ADD_BROWSER_SCREEN"
export const REMOVE_BROWSER_SCREEN = "REMOVE_BROWSER_SCREEN"

export const ADD_DAT_TRUCK_TO_CARRIER = "ADD_DAT_TRUCK_TO_CARRIER"


export const POST_LOAD = "POST_LOAD"
export const UNPOST_LOAD = "UNPOST_LOAD"
export const UPDATE_LOAD_POST = "UPDATE_LOAD_POST"
export const SET_LOAD_POST = "SET_LOAD_POST"

export const DELETE_SELECTED_CARRIER_CONTACT = "DELETE_SELECTED_CARRIER_CONTACT"
export const UPDATE_SELECTED_CARRIER_CONTACTS_LIST_AFTER_DELETE = "UPDATE_SELECTED_CARRIER_CONTACTS_AFTER_DELETE"
export const UPDATED_SELECTED_CARRIER_CONTACT = "UPDATED_SELECTED_CARRIER_CONTACT"

export const DELETE_SELECTED_CARRIER_NOTE = "DELETE_SELECTED_CARRIER_NOTE"
export const UPDATE_SELECTED_CARRIER_NOTES_LIST_AFTER_DELETE = "UPDATE_SELECTED_CARRIER_NOTES_LIST_AFTER_DELETE"
export const UPDATED_SELECTED_CARRIER_NOTE = "UPDATED_SELECTED_CARRIER_NOTE"

export const FETCH_HISTORIC_LOADS_FOR_LOAD = "FETCH_HISTORIC_LOADS_FOR_LOAD"
export const SET_HISTORICAL_LOADS = "SET_HISTORICAL_LOADS"
export const SET_HISTORICAL_LOADS_BY_MC = "SET_HISTORICAL_LOADS_BY_MC"

export const FETCH_HISTORIC_LOADS_FOR_SCREEN = "FETCH_HISTORIC_LOADS_FOR_SCREEN"
export const SET_HISTORIC_LOADS_FOR_SCREEN = "SET_HISTORIC_LOADS_FOR_SCREEN"
export const SET_HISTORIC_LOADS_FOR_SCREEN_FILTER = "SET_HISTORIC_LOADS_FOR_SCREEN_FILTER"

export const SET_SELECTED_BID = "SET_SELECTED_BID"

// SLACK
export const SEND_SLACK_DM = 'SEND_SLACK_DM'

export const SAVE_FOLDER_UPDATES = 'SAVE_FOLDER_UPDATES'

export const FOLDERS_DELETE_FOLDER = 'FOLDERS_DELETE_FOLDER'
export const FOLDERS_RENAME_FOLDER = 'FOLDERS_RENAME_FOLDER'

//STATS
export const STATS_SET = 'STATS_SET'
export const STATS_FETCH= 'STATS_FETCH'

//TASKS
export const SET_TASKS = 'SET_TASKS'
export const FETCH_TASKS = 'FETCH_TASKS'
export const SET_SELECTED_TASK = 'SET_SELECTED_TASK'

export const TASK_UPDATED = 'TASK_UPDATED'
export const UPDATE_TASK = 'UPDATE_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const TASK_COMPLETED = 'TASK_COMPLETED'

export const SET_MATCHES = 'SET_MATCHES'
export const UPDATE_MATCHES_LOCKS = 'UPDATE_MATCHES_LOCKS'
export const REMOVE_MATCH_LOCK = 'REMOVE_MATCH_LOCK'
export const CHECK_LOCK = 'CHECK_LOCK'

export const MATCH_UPDATED = 'MATCH_UPDATED'
export const FETCH_MATCHES = 'FETCH_MATCHES'
export const REMOVE_MATCH = 'REMOVE_MATCH'
export const ADD_MATCH = 'ADD_MATCH'
export const MATCH_SELECTED = 'MATCH_SELECTED'
export const ADD_MATCH_BID = 'ADD_MATCH_BID'
export const MATCH_LOCKED = 'MATCH_LOCKED'
export const REMOVE_MATCH_LOCKED = 'REMOVE_MATCH_LOCKED'
export const REMOVE_MATCH_LOCKED_BY_USER = 'REMOVE_MATCH_LOCKED_BY_USER'

export const ADD_CARRIER_LOAD_MATCH_EVENT = 'ADD_CARRIER_LOAD_MATCH_EVENT'
export const REMOVE_CARRIER_LOAD_MATCH_EVENT = 'REMOVE_CARRIER_LOAD_MATCH_EVENT'


export const SET_SELECTED_MATCH = 'SET_SELECTED_MATCH'
export const FETCH_SELECTED_LOAD = 'FETCH_SELECTED_LOAD'
export const FETCH_ALL_TEAMS = 'FETCH_ALL_TEAMS'
export const CREATE_DIALOG_WITH_PROPS = 'CREATE_DIALOG_WITH_PROPS'
export const EMAIL_DIALOG_CONTENT = 'EMAIL_DIALOG_CONTENT'


export const TRUCK_STREAM_ON = 'TRUCK_STREAM_ON'
export const TRUCK_STREAM_OFF = 'TRUCK_STREAM_OFF'

export const SET_CONNECT_MAPS = 'SET_CONNECT_MAPS'
export const FETCH_AND_SET_CONNECT_MAPS = 'FETCH_AND_SET_CONNECT_MAPS'
export const FETCH_AND_SET_CONNECT_MAPS_STATE = 'FETCH_AND_SET_CONNECT_MAPS_STATE'
export const SET_CONNECT_MAPS_STATE = 'SET_CONNECT_MAPS_STATE'

//ADMIN SCREEN
export const FETCH_AND_SET_ADMIN_CUSTOMERS = 'FETCH_AND_SET_ADMIN_CUSTOMERS'
export const SET_ADMIN_CUSTOMERS = 'SET_ADMIN_CUSTOMERS'
export const ADD_THRESHOLD_ON_TEAM = 'ADD_THRESHOLD_ON_TEAM'
export const ADD_THRESHOLD_ON_LOAD = 'ADD_THRESHOLD_ON_LOAD'

//CONNECT
export const FETCH_AND_SET_CONNECT_QUEUE_STATUS = 'FETCH_AND_SET_CONNECT_QUEUE_STATUS'
export const SET_CONNECT_QUEUE_STATUS = 'SET_CONNECT_QUEUE_STATUS'
export const FETCH_CONNECT_QUEUE_STATUS = 'FETCH_CONNECT_QUEUE_STATUS'

//CARRIER OPS TASK
export const FETCH_CARRIER_OPS_TASKS = 'FETCH_CARRIER_OPS_TASKS'
export const SET_CARRIER_OPS_TASKS = 'SET_CARRIER_OPS_TASKS'
export const FETCH_SELECTED_CARRIER_OPS_TASK = 'FETCH_SELECTED_CARRIER_OPS_TASK'
export const SET_SELECTED_CARRIER_OPS_TASK = 'SET_SELECTED_CARRIER_OPS_TASK'
export const UPDATE_CARRIER_OPS_TASK = 'UPDATE_CARRIER_OPS_TASK'
export const SAVE_CARRIER_OPS_TASK = 'SAVE_CARRIER_OPS_TASK'
export const FETCH_AND_SET_SELECTED_CARRIER_OPS_TASK = 'FETCH_AND_SET_SELECTED_CARRIER_OPS_TASK'
export const SET_SELECTED_CARRIER_FOR_CARRIER_OPS = 'SET_SELECTED_CARRIER_FOR_CARRIER_OPS'
export const UPDATE_CARRIER_OPS_TASK_DATA = 'UPDATE_CARRIER_OPS_TASK_DATA'
export const SET_CARRIER_OPS_TASK_DATA = 'SET_CARRIER_OPS_TASK_DATA'
export const RESET_CARRIER_OPS_TASK_DATA = 'RESET_CARRIER_OPS_TASK_DATA'
export const SET_CARRIER_OPS_TASKS_FOR_SELECTED_LOAD = 'SET_CARRIER_OPS_TASKS_FOR_SELECTED_LOAD'
export const ASSIGN_CARRIER_OPS_TASKS = 'ASSIGN_CARRIER_OPS_TASKS'
export const UPDATE_LOAD_TASK_FILTER = 'UPDATE_LOAD_TASK_FILTER'
export const RESET_LOAD_TASK_FILTER = 'RESET_LOAD_TASK_FILTER'
export const CLEAR_LOAD_TASK_FILTER = 'CLEAR_LOAD_TASK_FILTER'
export const AUTO_SAVE_TASK_DATA = 'AUTO_SAVE_TASK_DATA'

export const UPDATE_CARRIER_OPS_TASK_COLLECTED_DATA = 'UPDATE_CARRIER_OPS_TASK_COLLECTED_DATA'
export const FETCH_AND_SET_INCOMING_TEXT_BY_LOAD = 'FETCH_AND_SET_INCOMING_TEXT_BY_LOAD'
export const SET_SELECTED_INCOMING_TEXT = 'SET_SELECTED_INCOMING_TEXT'
export const UPDATE_INCOMING_TEXTS = 'UPDATE_INCOMING_TEXTS'
export const FETCH_SMS_NOTIFICATIONS = 'FETCH_SMS_NOTIFICATIONS'
export const SET_SMS_NOTIFICATIONS = 'SET_SMS_NOTIFICATIONS'
export const READ_SMS_NOTIFICATION = 'READ_SMS_NOTIFICATION'

export const SET_MODAL_DIALOG = 'SET_MODAL_DIALOG'
export const CLEAR_MODAL_DIALOG = 'CLEAR_MODAL_DIALOG'

//These are strings for ModalDialogs
export const MD_TASK_NOT_SAVED = 'MD_TASK_NOT_SAVED'
export const MD_BOUNCE_LOAD = 'MD_BOUNCE_LOAD'
export const MD_CARRIER_FOLDERS = 'MD_CARRIER_FOLDERS'
export const MD_CARRIER_CONTACTS = 'MD_CARRIER_CONTACTS'
export const MD_CARRIER_NOTES = 'MD_CARRIER_NOTES'
export const MD_CARRIER_OWNERSHIP_ADD = 'MD_CARRIER_OWNERSHIP_ADD'
export const MD_MATCH_REPORT = "MD_MATCH_REPORT"

export const SET_NEW_VERSION_AVAILABLE = 'SET_NEW_VERSION_AVAILABLE'

export const SEND_MMS_TEXT = 'SEND_MMS_TEXT'

export const SET_LOAD_TASK_QUESTIONS = 'SET_LOAD_TASK_QUESTIONS'
export const FETCH_LOAD_TASK_QUESTIONS = 'FETCH_LOAD_TASK_QUESTIONS'

export const ALL_LOAD_TASK_NAMES = ['COVERED','DISPATCHED','PICKUP','LOADED','INTRANSIT','CONSIGNEE','DELIVERED']

export const SET_CARRIER_FOLDERS = 'SET_CARRIER_FOLDERS'
export const UPDATE_CARRIER_FOLDERS = 'UPDATE_CARRIER_FOLDERS'
export const FETCH_CARRIER_FOLDERS = 'FETCH_CARRIER_FOLDERS'
export const ADD_CARRIER_FOLDER = 'ADD_CARRIER_FOLDER'
export const UPDATE_CARRIER_FOLDER = 'UPDATE_CARRIER_FOLDER'
export const DELETE_CARRIER_FOLDER = 'DELETE_CARRIER_FOLDER'

export const FETCH_USER_CARRIERS = 'FETCH_USER_CARRIERS'
export const SET_USER_CARRIERS = 'SET_USER_CARRIERS'
export const ADD_USER_CARRIER = 'ADD_USER_CARRIER'        // Kick off saga
export const DELETE_USER_CARRIER = 'DELETE_USER_CARRIER'   // Kick off saga
export const APPEND_TO_USER_CARRIERS = 'APPEND_TO_USER_CARRIERS'   // Update store
export const REMOVE_FROM_USER_CARRIERS = 'REMOVE_FROM_USER_CARRIERS'   // Update store
export const APPEND_TO_CARRIER_FOLLOWERS = 'APPEND_TO_CARRIER_FOLLOWERS'   // Update store
export const REMOVE_FROM_CARRIER_FOLLOWERS = 'REMOVE_FROM_CARRIER_FOLLOWERS'  // Update store
export const SELECTED_CARRIER_REMOVE_OWNER = "SELECTED_CARRIER_REMOVE_OWNER"
export const SET_CARRIER_DEADHEAD = 'SET_CARRIER_DEADHEAD'

export const FETCH_USER_OWNED_CARRIERS = 'FETCH_USER_OWNED_CARRIERS'
export const SET_USER_OWNED_CARRIERS = 'SET_USER_OWNED_CARRIERS'
export const REMOVE_USER_OWNED_CARRIERS = 'REMOVE_USER_OWNED_CARRIERS'

export const SET_CUSTOMER_LANES = 'SET_CUSTOMER_LANES'
export const FETCH_CUSTOMER_LANES = 'FETCH_CUSTOMER_LANES'
export const SET_CUSTOMER_CONTACTS = 'SET_CUSTOMER_CONTACTS'
export const FETCH_CUSTOMER_CONTACTS = 'FETCH_CUSTOMER_CONTACTS'
export const FETCH_SF_ACCOUNT_BY_ALJEX_ACCOUNT_NUMBER = 'FETCH_SF_ACCOUNT_BY_ALJEX_ACCOUNT_NUMBER'
export const UPDATE_SELECTED_CUSTOMER_SF_ID = 'UPDATE_SELECTED_CUSTOMER_SF_ID'

export const SET_CARRIER_BIDS = 'SET_CARRIER_BIDS'
export const UPDATE_CARRIER_BIDS = 'UPDATE_CARRIER_BIDS'


// when a carrier is select, we want to select the matching task if there is one
export const SELECT_MATCH_FOR_CARRIER_MC = 'SELECT_MATCH_FOR_CARRIER_MC'

export const FETCH_TEAMS = "FETCH_TEAMS"
export const SET_TEAMS = "SET_TEAMS"
export const UPDATE_LOAD_TEAM = "UPDATE_LOAD_TEAM"
export const UPDATE_TEAM_ON_LOADS = "UPDATE_TEAM_ON_LOADS"
