import axios from "axios"
// import { formatDate } from '../utils/formatters'

export const fetchConnectLogs = async ({load_id}) => {
    let result = await axios({
        method: 'get',
        url: `/api/connectlogs?load_id=${load_id}`
    })
    
    const data = (result && result.status === 200) ? result.data : []
    return data
}

export const updateConnectLogs = ({idx,bid_amount}) => {
    let result = axios({
        method: 'get',
        url: `/api/connectlogs/update_connect_logs?idx=${idx}&bid_amount=${bid_amount}`
    })
    
    const data = (result && result.status === 200) ? result.data : []
    return data
}

export const updateConnectUser = ({phone,ref,mc,user_name}) => {
    let result = axios({
        method: 'get',
        url: `/api/connectlogs/update_connect_logs?phone=${phone}&ref=${ref}&mc=${mc}&user_name=${user_name}`
    })
    
    const data = (result && result.status === 200) ? result.data : []
    return data
}
