import moment from 'moment'
moment.suppressDeprecationWarnings = true

// returns mm:ss for under one hour
// returns hh:mm:ss for over one hour
// can only handle durations up to up to 86399 seconds (23:59:59)
export const displaySecondsAsHHMMDD = (seconds) => {

    if ((seconds === null) || (seconds < 0) || (seconds >= 86400)) {
        return "--:--"
    }

    let formattedTime = new Date(seconds * 1000).toISOString().substring(11, 19)
    if (formattedTime.substring(0, 2) === "00") {
        formattedTime = formattedTime.substring(4, 9)
    }
    return formattedTime
}

export const dateFormatter = (dt) => {
    if (dt) {
        return moment(dt).format('MM-DD-YYYY')
    }
    return null
}

export const displaySecondsAsMinutes = (seconds) => {
    return Math.floor(seconds / 60)
}

export const displayMinutesAsHoursAndMinutes = (age) => {
    const ageInMinutes = Math.abs(age)
    const negative = (age < 0)
    const hours = (Math.floor(ageInMinutes / 60)).toString()
    let minutes = (Math.round(ageInMinutes % 60)).toString()

    if (minutes.length === 1) {
        minutes = `0${minutes}`
    }

    return (negative) ? `(${hours}:${minutes})` : `${hours}:${minutes}`
}

export const displayPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

export const displayTimeFromTimestamp = (timestamp) => {
    let formattedTime = moment(timestamp).format('h:mm A')
    return formattedTime
}

export const displayDateFromTimestamp = (timestamp) => {
    const dt = timestamp.split('T')[0]
    return moment(dt).format('l')
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
})

export const formatCurrency = (value) => {
    let num = value

    try {
        if (typeof num === 'string') {
            num = parseFloat(num)
        }
        if (num !== 0) {
            num = currencyFormatter.format(num)
        } else {
            num = '-'
        }
    } catch {
        num = '-'
    }

    return num
}

export const currencyFormatterPerMile = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

export const currencyFormatterDollars = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
})

export const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal'
})

// let's check if this is a US, or MX number
export const phoneFormatter = (phoneNumberString) => {
    let rtn = phoneNumberString
    if (phoneNumberString) {
        let [phoneNumber, ext] = phoneNumberString.split(" ext ")
        
        phoneNumber = ('' + phoneNumber).replace(/\D/g, '')
        phoneNumber = phoneNumber.trim()

        if ((phoneNumber.length === 10) || ((phoneNumber.length === 11) && (phoneNumber.charAt(0) === '1'))) {
            // this is a US or Candian phone number, so let's format appropriately
            var match = phoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                rtn =  [match[2], match[3], match[4]].join('-')
                if (ext && ext.length > 0) {
                    rtn = `${rtn} x${ext}`
                }
            }
        }
    }
    //skipping MX for now, so just return same string
    return rtn
}

export const capitalize = string => {
    let cs = ''
    string.split(' ').forEach(s => {
        let lcs = s.toLowerCase()
        if (lcs.charAt(0) === '(') {
            cs += lcs.charAt(0) + lcs.charAt(1).toUpperCase() + lcs.slice(2) + ' '
        } else if (lcs.charAt(0) === 'm' && lcs.charAt(1) === 'c' && lcs.length > 2) {
            cs += lcs.charAt(0).toUpperCase() + lcs.charAt(1) + lcs.charAt(2).toUpperCase() + lcs.slice(3) + ' '
        } else if (lcs.charAt(0) === 'm' && lcs.charAt(1) === 'a' && lcs.charAt(2) === 'c' && lcs.length > 3) {
            cs += lcs.charAt(0).toUpperCase() + lcs.charAt(1) + lcs.charAt(2) + lcs.charAt(3).toUpperCase() + lcs.slice(4) + ' '
        } else {
            cs += lcs.charAt(0).toUpperCase() + lcs.slice(1) + ' '
        }
    })

    if (cs.includes("'")) {
        const apostIndex = cs.indexOf("'")
        cs = cs.substring(0, apostIndex) + cs[apostIndex] + cs[apostIndex+1].toUpperCase() + cs.substring(apostIndex+2)
    }

    if (cs.includes('(') && cs.includes(')')) {
        const matches = cs.match(/\(([^)]+)\)/)
        const parens = cs.slice(matches.index, matches.index + matches[0].length)
        cs = cs.replace(parens, `(${capitalize(parens.replace(/[()]/g, ''))})`)
    }

    return cs.trim()
}

// Takes a "full name" and converts to initials.  Example: "Darth Vader" -> "DV" 
export const formatAsInitials = string => {
    if (!string.length) return ''
    return string.split(' ').map(s => s.charAt(0).toUpperCase()).join('')
}

export const formatDate = (date, format='L') => {
    if (date) {
        return moment(date).format(format)
    }
    return ''
}

// Extract date part only of timestamp formatted like this '2019-11-19T05:00:00.000Z'
// Not using moment which will convert to local timezone possibly changing the date.
export const extractDateFromTimestamp = (timestamp) => {
    let date = ''
    const inx = timestamp.indexOf('T')
    
    if (inx > 0) {
         date = timestamp.slice(0, inx)
    } else {
        date = timestamp
    }

    return date
}
