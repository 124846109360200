import React, { Component } from 'react'
import { connect } from 'react-redux'

import NavBarButton from './NavBarButton'
import NotificationBadge from './NotificationBadge'
import NotificationDialog from './NotificationDialog'
import UserImpersonate from './UserImpersonate'

import { faBars, faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ADD_EVENT } from '../actions/actionTypes'

import { subscribe, unsubscribe } from 'pusher-redux'



class ReloadAppButton extends Component {
    onClick = () => {
        window.location.reload(true)
    }

    render() {
        const {newVersionAvailable} = this.props

        if (newVersionAvailable) {
            return (
                <div>
                    A new version of GearJammer exists.  Click Reload to update.
                    <button className="nav-bar-reload-button" onClick={this.onClick}>Reload App</button>
                </div>
            )

        }
        return null
    }
}

class NavBar extends Component {
    state = { visible: false, notifications: [], filterByUser: false }


    //TODO: We should listen to new app version pusher events along with new incoming text message events here
    componentDidMount() {
        //TODO: get the default Fitler by user from the local store
        this.notificationsWrapper = document.getElementById('notifications-wrapper')

        const filterByUserStr = localStorage.getItem('navbar.filterByUser')
        if (filterByUserStr && filterByUserStr === 'true') {
            this.setState({filterByUser: true})
        }
        subscribe('events', 'app', ADD_EVENT)
    }

    componentDidUpdate(prevProps, prevState) {
        const { visible } = this.state
        if (visible === prevState.visible) {
          return
        }

        window[`${visible ? 'add' : 'remove'}EventListener`]('click', this.handleOutsideClick)
    }

    componentWillUnmount() {
        unsubscribe('events', 'app', ADD_EVENT)
        window.removeEventListener('click', this.handleOutsideClick)
    }

    toggleDialog = () => {
        this.setState({ visible: !this.state.visible })
    }

    handleOutsideClick = e => {
        if (!this.notificationsWrapper || !this.notificationsWrapper.contains(e.target)) {
          this.setState({ visible: false })
        }
    }

    onFilterByUserClick = (evt) => {
        evt.stopPropagation()
        //
        localStorage.setItem('navbar.filterByUser', (this.state.filterByUser) ? 'false' :  'true')
        this.setState({filterByUser: !this.state.filterByUser})
        //TODO: save this to the local store
    }

    render() {
        const {authenticatedUser, smsInboundNotifications, newVersionAvailable} = this.props
        if (! authenticatedUser) {
            return null
        }

        const notifications = (this.state.filterByUser) ? 
            smsInboundNotifications.filter(n => (n.user_ids?.length) ? n.user_ids.includes(authenticatedUser.user_id) : true) : smsInboundNotifications

        const containerClasses = ['nav-bar-container']
        if (newVersionAvailable) {
            containerClasses.push('nav-bar-new-version')
        } else if (authenticatedUser.impersonated_by_user && authenticatedUser.impersonated_by_user.user_id) {
            containerClasses.push('nav-bar-impersonate')
        }


        const containerClass = containerClasses.join(' ')
        const { visible } = this.state        
        const unreadNotifications = notifications.filter(n => n.read !== true)

        const navLinks = (this.props.navLinks || []).filter(l => l.link_text.toLowerCase() === 'loads' || l.link_text.toLowerCase() === 'carriers')

        return(
            <div className={containerClass} >
                <div className="nav-bar-version">
                    <div>{process.env.REACT_APP_APP_RELEASE_VERSION}</div>
                    <ReloadAppButton newVersionAvailable={newVersionAvailable} />
                </div>
                <UserImpersonate user={authenticatedUser}/>
                <div id="notifications-wrapper" onClick={this.toggleDialog}>
                    <NotificationBadge count={unreadNotifications.length} />
                    <div className="notifications-bell">
                        <FontAwesomeIcon className={(unreadNotifications.length) ? "notifications-bell-active": ""} icon={faBell} size={"lg"} />
                    </div>
                </div>
                <NotificationDialog visible={visible} notifications={notifications} onFilterClick={this.onFilterByUserClick} filterByUser={this.state.filterByUser}/>
                { 
                    navLinks
                    .map(link => (
                        <NavBarButton 
                            className="nav-bar-button" 
                            key={link.id}
                            linkUrl={link.link_url} 
                            linkText={link.link_text}></NavBarButton>
                    )) 
                }
                <div className="nav-bar-button drawer-toggle" onClick={this.props.handleVisibility}><FontAwesomeIcon icon={faBars}/></div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {authenticatedUser, smsInboundNotifications, newVersionAvailable} = state

    return {
        authenticatedUser,
        smsInboundNotifications,
        newVersionAvailable
    }
}

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
