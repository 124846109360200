import { takeLatest, call, put } from "redux-saga/effects"
import axios from "axios"
import { 
    FETCH_EXERNAL_LOADS_FOR_LOAD,
    FETCH_EXTERNAL_LOADS_WITH_FILTER,
    SET_EXTERNAL_LOADS,

    FETCH_MARKET_LOADS_FOR_LOAD,
    FETCH_MARKET_LOADS_WITH_FILTER,
    SET_MARKET_LOADS 
} from '../actionTypes'
import moment from 'moment'

const EXTERNAL_LOADS_ORIGIN_RADIUS = 150 // Front end calls this Similar Loads
const EXTERNAL_LOADS_DESTINATION_RADIUS = 150 // Front end calls this Similar Loads
const MARKET_LOADS_ORIGIN_RADIUS = 150

function fetchExternalLoadsWithFilter(args) {
    const request = {
        method: 'get',
        url: '/api/dat_loads'
    }

    request.params = {}

    for (let key in args) {
        if (args[key]) {
            request.params[key] = args[key]
        }
    }

    return axios(request) || []
}

function* workerFetchExternalLoadsForLoadSaga(action) {
    let {load, type} = action

    let actionType = null

    let searchQuery = {
        equipmentType: load.equipment_type.name,
        origin_lat: load.origin_lat,
        origin_lng: load.origin_lng,
        originRadius: type === FETCH_MARKET_LOADS_FOR_LOAD ? MARKET_LOADS_ORIGIN_RADIUS : EXTERNAL_LOADS_ORIGIN_RADIUS,
        dateAvailEarliest: moment(load.pickup_date).format('YYYY-MM-DD'),  // Only this is used by Otter & Hippo as of 2023-12-14
        dateAvailLatest: moment(load.pickup_date).add(1,'days').format('YYYY-MM-DD')  // This is ignored by Otter & Hippo as of 2023-12-14
    }
    
    if (type === FETCH_MARKET_LOADS_FOR_LOAD) {
        actionType = FETCH_MARKET_LOADS_WITH_FILTER
    } else if (type === FETCH_EXERNAL_LOADS_FOR_LOAD) {
        actionType = FETCH_EXTERNAL_LOADS_WITH_FILTER

        searchQuery.destination_lat = load.destination_lat
        searchQuery.destination_lng = load.destination_lng
        searchQuery.destRadius = EXTERNAL_LOADS_DESTINATION_RADIUS
        searchQuery.dateAvail = load.pickup_date
    }

    yield put ({type: actionType, payload: searchQuery})
}

function* workerFetchExternalLoadsWithFilterSaga(action) {
    try {
        let actionType = ''
        if (action.type === FETCH_EXTERNAL_LOADS_WITH_FILTER) {
            actionType = SET_EXTERNAL_LOADS
        } else if (action.type === FETCH_MARKET_LOADS_WITH_FILTER) {
            actionType = SET_MARKET_LOADS
        }      

        // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
        yield put({ type: actionType, loads: null })
        
        const response = yield call(fetchExternalLoadsWithFilter, action.payload)
        
        if (response) {
            //console.log('ext loads resp', response?.data)
            //hippo returns {data: []} and otter returns just []
            // so, this handles both
            const loads = (response?.data?.data) ? response?.data?.data : response?.data

            if (loads?.length) {
                yield put({ type: actionType, loads:loads })
            } else {
                yield put({ type: actionType, loads: [] })
            }
        }
    } catch (error) {
        console.log('workerFetchExternalLoadsWithFilterSaga error: ', error)
    }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watchFetchExternalLoadsWithFilterSaga() {
    yield takeLatest(FETCH_EXTERNAL_LOADS_WITH_FILTER, workerFetchExternalLoadsWithFilterSaga)
}

export function* watchFetchMarketLoadsWithFilterSaga() {
    yield takeLatest(FETCH_MARKET_LOADS_WITH_FILTER, workerFetchExternalLoadsWithFilterSaga)
}

export function* watchFetchExternalLoadsForLoadSaga() {
    yield takeLatest(FETCH_EXERNAL_LOADS_FOR_LOAD, workerFetchExternalLoadsForLoadSaga)
}

export function* watchFetchMarketLoadsForLoadSaga() {
    yield takeLatest(FETCH_MARKET_LOADS_FOR_LOAD, workerFetchExternalLoadsForLoadSaga)
}

