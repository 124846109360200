import {SET_TRUCK_ENTRY_TRUCK_LIST, UPDATE_TRUCK_ENTRY_TRUCK_LIST, ADD_TRUCK_ENTRY_TRUCK_LIST,
    REMOVE_TRUCKS_ENTRY_TRUCK_LIST,
    EXPIRE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM,
    DELETE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM} from '../actionTypes'

import { convertTruckSource} from '../../utils/truck-source-helper'

const initialState = []

const sortTrucks = (b1, b2) => {
    let rtn = 0
    if (b1.source === b2.source) {
        rtn = b1.availabe_date - b2.available_date
    } else {
        if (b1.source === 'OTR') {
            rtn = -1
        } else if (b2.source === 'OTR') {
            rtn = 1
        } else if (b1.source === 'DAT') {
            rtn = -1
        } else {
            rtn = 1
        }
    }

    return rtn
}


const addTruck = (truckList, truck) => {
    if (truck?.truck_id) {
        truck.source = convertTruckSource(truck)
        truckList.push(truck)
    }
    return truckList
}

const removeTrucks = (truckList, trucks) => {
    if (trucks.length) {
        const truckIds = trucks.map(t=>t?.truck_id || t.ref)
        truckList = truckList.filter(t => !truckIds.includes(t.truck_id))
    } else {
        console.log('No trucks to delete')
    }
    
    return truckList
}


export default (state=initialState, action) => {
    switch(action.type) {
        case SET_TRUCK_ENTRY_TRUCK_LIST:
            //This is a hack until we set source as CNCT in otter / hippo / matcher
            if (action?.trucks?.length) {
                action.trucks.sort(sortTrucks)
                action.trucks = action.trucks.map(t => {
                    t.source = convertTruckSource(t)
                    return t
                })   
            }
            return action.trucks
        case UPDATE_TRUCK_ENTRY_TRUCK_LIST: 
            let trucks = state

            if (trucks?.length) {
                trucks = trucks.map(t => {
                    t.source = convertTruckSource(t)
                    if (t.truck_id === action?.truck?.ref || t.id === action?.truck?.id) {
                        t = {...t, ...action.truck}
                    }
                    return t
                })
            }
            return trucks
        case ADD_TRUCK_ENTRY_TRUCK_LIST:
            return addTruck([...state], action.truck)
        case REMOVE_TRUCKS_ENTRY_TRUCK_LIST:
            return removeTrucks([...state], action.trucks)    
        case EXPIRE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM:
            trucks = (state) ? [...state] : []
            let expired_truck = action.truck
            if (expired_truck) {
                trucks = trucks.map( t => {
                    if (t.truck_id === expired_truck.truck_id) {
                        return expired_truck
                    }
                    return t
                }).filter(t => t != null)
    
            }
            return trucks
        case DELETE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM:
            trucks = (state) ? [...state] : []
            let deleted_truck = action.truck
            if (deleted_truck) {
                trucks = trucks.map( t => {
                    if (t.truck_id === deleted_truck.truck_id) {
                        return null
                    }
                    return t
                }).filter(t => t != null)
    
            }
            return trucks
        default:
            return state
    }
}