import {SET_SELECTED_TRUCK_ENTRY_CARRIER} from '../actionTypes'

const initialState = null

export default (state=initialState, action) => {
    switch(action.type) {
        case SET_SELECTED_TRUCK_ENTRY_CARRIER:
            return action.carrier || null
        default:
            return state
    }
}