import {SET_SELECTED_LOAD_SCREEN_TABLE, LOAD_LIST_SELECTED } from '../actionTypes'

const initialState = LOAD_LIST_SELECTED
// const initialState = TRUCK_LIST_SELECTED

export default (state=initialState,  payload) => {
    switch(payload.type) {
        case SET_SELECTED_LOAD_SCREEN_TABLE:
            if (payload.selectedTable) {
                return payload.selectedTable
            }
            break;
        default:
            return state
    }
}
