import { RESET_LOAD_TASK_FILTER, UPDATE_LOAD_TASK_FILTER, CLEAR_LOAD_TASK_FILTER, ALL_LOAD_TASK_NAMES } from '../actionTypes'

const CLEAR_FILTER = {
    status: ['Active'],
    assigned: '',
    day: '',
    task_names: [],
    pro: '' // this can be added from the screen and will receive updates.  We might need to add in an array here and a clear method :-)
}

const RESET_FILTER = {
    status: ['Active'],
    assigned: '',
    day: 'Today',
    task_names: ALL_LOAD_TASK_NAMES.filter(t => t !== 'DELIVERED'),
    pro: '' // this can be added from the screen and will receive updates.  We might need to add in an array here and a clear method :-)
}

const loadInitialState = () => {
    let rtn = RESET_FILTER

    try {
        const storedFilter = localStorage.getItem('loadTaskFilter')
        if (storedFilter?.length) {
            rtn = JSON.parse(storedFilter)
            if (typeof rtn.status === 'string') {
                rtn.status = (rtn.status.length) ? [rtn.status] : ['Active']
            }
        }
    } catch {
        // error
    }
    return rtn
}

const initialState = loadInitialState()


export default (state=initialState,  action) => {   
    switch(action.type) {
        case UPDATE_LOAD_TASK_FILTER:
            if (action?.filter) {
                const newFilter = {...state, ...action.filter}
                console.log('adding new filter', newFilter)
                if (action.filter?.assigned === 'My Tasks') {
                    newFilter['task_names'] = ALL_LOAD_TASK_NAMES
                    newFilter['day'] = 'Today'
                    newFilter.status = ['Active', 'Inactive', 'Complete']
                }
                if (newFilter.status.length === 0) {
                    newFilter.status = ['Active']
                }
                localStorage.setItem('loadTaskFilter', JSON.stringify(newFilter))
                return newFilter
            }
            return state
        case RESET_LOAD_TASK_FILTER:
            localStorage.setItem('loadTaskFilter', JSON.stringify(RESET_FILTER))
            return RESET_FILTER
        case CLEAR_LOAD_TASK_FILTER:
            localStorage.setItem('loadTaskFilter', JSON.stringify(CLEAR_FILTER))
            return CLEAR_FILTER
        default:
            return state
    }
}
