import { SET_CARRIER_OPS_TASKS, UPDATE_CARRIER_OPS_TASK } from '../actionTypes'

const initialState = []

export default (state=initialState,  action) => {
    switch(action.type) {
        case SET_CARRIER_OPS_TASKS:
            return action.tasks
        case UPDATE_CARRIER_OPS_TASK:
        //TODO: Should move the filter check to here instead of the saga, maybe
            //TODO: here we want to check the filter to make sure we should add / remove the task from the list
            const {task} = action
            if (task?.id) {
                // could ignore if the action.action is NEW, but just in case we already have the task, do the filter
                const tasks = state.filter(t => t.id !== task.id)

                if (action.action !== 'DELETE') {
                    if (task?.load) {
                        //verify we have a load before we add this to the tasks list
                        tasks.push(task)
                    } else {
                        console.log('task DOES NOT HAVE A LOAD ', task)
                    }
                }
                // console.log(`update ops tasks ${action.action} ${state.length} ${tasks.length}`)
                return tasks
            } else {
                console.log('update ops task, not id', action)
            }
            return state
        default:
            return state
    }
}