import { takeEvery, call, put } from "redux-saga/effects"
import {STATS_SET, STATS_FETCH} from '../actionTypes'
import {getStats} from '../../actions/statsActions'


function* workerStatsFetchEvent(action) {
    try {
        
        const { stat_name, team_name } = action

        const day_stats_result = yield call(getStats, {stat_name, team_name, interval: 'today'})
        const week_stats_result = yield call(getStats, {stat_name, team_name, interval: 'week'})

        yield put({ type: STATS_SET, stats: {day: day_stats_result.data, week: week_stats_result.data}})
    } catch (error) {
        console.log(error)
    }
}


export function* watchStatsFetchEventSaga() {
    yield takeEvery(STATS_FETCH, workerStatsFetchEvent)
}
