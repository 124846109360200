import {OPEN_BID_MODAL, CLOSE_BID_MODAL} from '../actionTypes'

const initialState = {
    open: false,
    carrier: {
        id: "",
        name: ""
    }
}

export default (state=initialState,  action) => {
    switch(action.type) {
        case OPEN_BID_MODAL:    
            let newState = Object.assign({}, state, {
                open: true,
                carrier: action.payload.carrier
            })
            return newState
        case CLOSE_BID_MODAL:
            return Object.assign({}, state, {
                open: false,
                carrier: {
                    id: "",
                    name: ""
                }
            })
        default:
            return state
     }
}
