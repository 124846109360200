import axios from "axios"

export const fetchCustomerMatchingLoads = (customerId) =>  {
  return axios({
      method: 'get',
      url: `/api/customers/${customerId}/matching_loads`
  })
}


export const fetchCustomersWithFilter = (args) => {
  const request = {
      method: 'get',
      url: '/api/customers?isFiltered=true'
  }

  request.params = {}

  for (let key in args) {
      if (args[key]) {
          request.params[key] = args[key]
      }
  }

  return axios(request) || null
}

export const fetchCustomer = (customerId) => {
  return axios({
      method: "get",
      url: '/api/customers/' + customerId
  })
}

export const fetchCustomerByAljex = (aljexNumber) => {
  return axios({
      method: 'get',
      url: `/api/customers?id=${aljexNumber}`
  })
}


export const fetchMyCustomerAssignments = () => { 
  return axios({
    method: 'get',
    url: '/api/customer_rep_assignments?myAssignments=true'
  })
}


export const deleteCustomerContact = async ({customerId, contactId}) => {
  await axios({
      method: "delete",
      url: `/api/customers/${customerId}/contacts/${contactId}`
  })
}

// TODO: Fix this??
export const addCustomerNote = (customerId, note) => {
  return axios({
    method: "post",
    url: `/api/customers/${customerId}/notes`,
    data: note
  })
}

export const deleteCustomerNote = async ({customerId, noteId}) => {
  await axios({
      method: "delete",
      url: `/api/customers/${customerId}/notes/${noteId}`
  })
}

export const updateCustomerShippingInfo = (customerId, shippingInfo) => {
    return axios({
        method: "post",
        url: `/api/customers/${customerId}/shipping_info`,
        data: shippingInfo
    })
}

export const deleteCustomerAssignment = async ({assignment}) => {
  return await axios({
      method: "delete",
      url: `/api/customers/${assignment.customer_id}/customer_rep_assignments`,
      data: assignment
  })
}

export const addCustomerAssignment = async ({assignment}) => {
  return await axios({
      method: "post",
      url: `/api/customers/${assignment.customer_id}/customer_rep_assignments`,
      data: assignment
  })
}

export const addContact = (contact) => {
    return axios({
        method: "post",
        url: `/api/customers/${contact.customer_id}/contacts`,
        data: contact
    })
}

export const saveFolderUpdates = async(updates) => {
    return await axios({
        method: 'post',
        url: `api/customer_rep_assignment_tags/updates?returnTags=true`,
        data: updates
    })
}

export const fetchCustomerLoads = async(customerId) => {
  return await axios({
      method: 'get',
      url: `/api/customers/${customerId}/loads`
  })
}

export const fetchCustomersWithFilterAndLoads = (args) => {
  const request = {
      method: 'get',
      url: '/api/customers/replica'
  }

  request.params = {}

  for (let key in args) {
      if (args[key]) {
          request.params[key] = args[key]
      }
  }

  return axios(request) || null
}

export const fetchCustomerLanes = (customer_id ) => {
  const request = {
    method: 'get',
    url: `/api/customers/${customer_id}/lanes?with=regions`
  }

  return axios(request)

}

export const fetchCustomerContacts = async (id) => {
  let rtn = []

  if (id) {
    const result = await axios({
      method: 'get',
      url: `/api/customers/${id}/contacts?source=all`
    })
  
    rtn =  result?.data?.length ? result.data : []
  } else {
    rtn = []
  }

  return rtn
}

export const fetchSfAccountByAljexAccountNumber = async (aljexAccountNumber) => {
  const request = {
    method: 'get',
    url: `/api/customers/${aljexAccountNumber}/salesforce`
  }

  return axios(request)
}

export const fetchCustomerFacilities = (customer_id ) => {
  const request = {
    method: 'get',
    url: `/api/customers/${customer_id}/facilities`
  }

  return axios(request)
}

export const fetchCustomerCustomers = (customer_id ) => {
  const request = {
    method: 'get',
    url: `/api/customers/${customer_id}/customers`
  }

  return axios(request)
}

export const fetchCustomerLumperData = (customer_id) => {
  const request = {
    method: 'get',
    url: `/api/customers/${customer_id}/lumpers`
  }

  return axios(request)
}