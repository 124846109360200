import { ADD_SMS_INBOUND_NOTIFICATION, MARK_READ_SMS_INBOUND_NOTIFICATIONS, REMOVE_SMS_INBOUND_NOTIFICATION, SET_SMS_NOTIFICATIONS } from '../actionTypes'
import moment from 'moment'

/**Sample code 
 * add this to the initial state if you want to test and see some messages without sending from pusher
const sample = [
    {
        id: 'someid',
        xload_id: 'some load id',
        load_pro: 'OPS-003',
        title: 'Incoming SMS Message',
        text: `SMS Message from driver: Load OPS-003`,
        read: false, 
        type: 'sms_inbound_text',
        user_ids: ['7209544a-88e5-49f7-9f39-aceb237054d9ss'],
        created_at: moment().toISOString()
    },
    {
        id: 'someid2',
        xload_id: 'some load id',
        load_pro: 'OPS-003',
        title: 'Incoming SMS Message',
        text: `SMS Message from driver: Load OPS-002`,
        read: false, 
        type: 'sms_inbound_text',
        user_ids: ['7209544a-88e5-49f7-9f39-aceb237054d9'],
        created_at: moment().toISOString()
    }
]
*/

const MAX_NOTIFICATIONS = 20
const initialState = []


const sortCompareNotifications = (a, b) => {
    if (a.read !== b.read) {
        // puts all unread to the bottom
        return (a.read) ? 1 : -1
    } else if (a.created_at < b.created_at) {
        return 1
    } else if (a.created_at > b.created_at) {
        return -1
    }
    return 0
}
/**
 * This stores the inbound notifications from the sms pusher events
 * and event contains the following structure
 * {
 *   id - the id of the sms messsage
 *   load_id - the load id of the load that the message is on
 *   load_pro - the pro number of the load, these 2 should be refactored to load: {id, pro}
 *   title - title of the notification
 *   text - this is a standard format
 * }
 */
export default (state=initialState,  payload) => {
    let notifications
    switch(payload.type) {
        case SET_SMS_NOTIFICATIONS: 
            notifications = payload.notifications || []
            return (notifications.length > 1) ? notifications.sort(sortCompareNotifications) : notifications
        case ADD_SMS_INBOUND_NOTIFICATION:
            const {incomingText, smsNotification} = payload
            let message = {}
            if (smsNotification) {
                message = {...smsNotification, type: 'sms_inbound_text'}
            } else if (incomingText?.id) { // This is the old way, need to remove this later
                message = {
                    id: incomingText.id,
                    load_id: incomingText.load_id,
                    title: 'Incoming SMS Message',
                    description: `SMS Message from driver: Load ${(incomingText.load_pro) ? incomingText.load_pro : incomingText.load_id}`,
                    read: false, 
                    type: 'sms_inbound_text',
                    created_at: moment().toISOString()
                }
                if (incomingText.load_pro) {
                    message.load_pro = incomingText.load_pro
                }
                if (incomingText.user_ids) {
                    message.user_ids = incomingText.user_ids
                }
            } 
            // here we want to delete out all notifications that have the same load as this message
            // No need having more than one notification on the same load
            notifications = [...state].filter(n => n.load_id !== message.load_id)
            // adds to the front of the list
            notifications.unshift(message)
            if (notifications.length > MAX_NOTIFICATIONS) {
                notifications = notifications.slice(0,MAX_NOTIFICATIONS)
            }

            return (notifications.length > 1) ? notifications.sort(sortCompareNotifications) : notifications
        case REMOVE_SMS_INBOUND_NOTIFICATION:
            //TODO: here we want to check if we have a text id or the load_id
            if (payload.id) {
                return state.filter(m => m.id === payload.id)
            } else if (payload.load_id) {
                return state.filter(m => m.load_id === payload.load_id)
            } else if (payload.pro) {
                return state.filter(m => m.pro === payload.pro)
            }
            return state
        case MARK_READ_SMS_INBOUND_NOTIFICATIONS:
            notifications = [...state].map(n => {
                if (payload.id) {
                    n.read = n.read || (n.id === payload.id)
                } else {
                    n.read = true
                }
                return n
            })
            return (notifications.length > 1) ? notifications.sort(sortCompareNotifications) : notifications
        default:
            return state
    }
}