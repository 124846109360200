import { SET_SELECTED_CARRIER_OPS_TASK } from '../actionTypes'
// import moment from 'moment'

const initialState = {}

export default (state=initialState,  action) => {
    let selectedTask = {}
    switch(action.type) {
        case SET_SELECTED_CARRIER_OPS_TASK:
            selectedTask = {...action.selectedTask}
            return selectedTask
        default:
            return state
    }
    
}