import {ADD_EVENT_LIST_EVENT} from '../actionTypes'

const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {
    case ADD_EVENT_LIST_EVENT:
        let events = [...state]
        events.unshift(payload.event)
        events = events.slice(0,10)
        return events

    default:
        return state
    }
}