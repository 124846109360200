
import axios from "axios"
// import { formatDate } from '../utils/formatters'
const moment = require('moment')

export const fetchInsightData = async (parms) => {
    let load_id = parms.id || ''
    let days = parms.days
    let rangeStart = parms.rangeStart
    let rangeEnd = parms.rangeEnd
    if (!days && !rangeStart && !rangeEnd) {
        days = 30
        rangeStart = moment().startOf('day').subtract(1, 'month').format("MM/DD/YYYY")
        rangeEnd = moment().format("MM/DD/YYYY")
    }
    let result = await axios({
        method: 'get',
        url: `/api/loads/insight_data?days=${days}&rangeStart=${rangeStart}&rangeEnd=${rangeEnd}&load_id=${load_id}&miles=${parms.miles}&origin_lat=${parms.origin_lat}&origin_lng=${parms.origin_lng}&destination_lat=${parms.destination_lat}&destination_lng=${parms.destination_lng}`
    })
    
    const data = (result && result.status === 200) ? result.data : []
    return data

}
