import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card } from 'react-md'

import { LOAD_SELECTED, FETCH_CARRIER_BY_MC, FETCH_CARRIER_OPS_TASKS, READ_SMS_NOTIFICATION } from '../actions/actionTypes'

const moment = require('moment')

class NotificationCard extends Component {
  /**
   * Right now there is only one type of notification.  That is SMS Notification
   */
  handleTextClick = () => {
    const {notification} = this.props
    const { id } = this.props.notification
    this.props.markInboxNotificationsAsRead(id)

    if (window.name === 'OTR_Carrier_Ops') {
      // then we want to search for the ops tasks by the pro #
      if (notification.load_pro || notification.load_id) {
        this.props.selectCarrierOpsTask(notification.load_id, notification.load_pro)
      }
    } 
  }
  
  markRead = (e) => {
    const { id } = this.props.notification
    e.stopPropagation()
    this.props.markInboxNotificationsAsRead(id)
  }

  render()  {
    // because attibutes in object changing does not trigger a rerender, we pass in the attribute that affects the change
    const { id, description, title, created_at } = this.props.notification
    const {read} = this.props
    const formattedText = description.replace(/ +(?= )/g,'').replace(/\n{2,}/g, '\n').replace(/\n/g, '<br/>')
    const timestamp = moment(created_at).format('llll')
    let cardClassName = 'md-cell md-cell--12 badges__notifications__notification otr-notification-card'

    if (!read) {
      cardClassName += ' otr-unread-card'
    }

    const markReadComp = (! read) ?  <div onClick={this.markRead}>Mark Read</div> : null
    return (
      <Card id={id} className={cardClassName}>
        <div className="otr-notification-card-title">
          <div >{title}</div>
          {markReadComp}
        </div>
        <button 
          onClick={this.handleTextClick}
          className="otr-notification-card-text"
          dangerouslySetInnerHTML={{ __html: formattedText }} />
        <div className="otr-notification-card-timestamp">{timestamp}</div>
      </Card>
    )
  }
}

NotificationCard.propTypes = {
  notification: PropTypes.object.isRequired,
  read: PropTypes.bool.isRequired
}


const mapDispatchToProps = dispatch => {
  return {
      dispatchLoadSelected: (load_id, truck_ref) => dispatch({ 
        type: LOAD_SELECTED, 
        selectedLoad: { id: load_id }, 
        selectedCarrierTruck: { ref: truck_ref } 
    }),
      dispatchCarrierTruckSelected: (mc, truck_ref) => dispatch({ 
        type: FETCH_CARRIER_BY_MC, 
        mc: mc, 
        selectedCarrierTruck: { ref: truck_ref } 
    }),
    selectCarrierOpsTask: (load_id, pro) => dispatch({type: FETCH_CARRIER_OPS_TASKS, load_id, pro}),
    markInboxNotificationsAsRead: id => dispatch({ type: READ_SMS_NOTIFICATION, id })
  }
}

export default connect(null, mapDispatchToProps)(NotificationCard)

