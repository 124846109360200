import axios from "axios"
// import { formatDate } from '../utils/formatters'

// export const fetchCustomerLineBarChart = async (customer_id,weeks) => {
//     let result = await axios({
//         method: 'get',
//         url: `/api/loads/historic_by_customer?customer_id=${customer_id}&weeks=${weeks}`,
//     })
//     result = (result && result.status === 200) ? result.data : []
//     return result
// }

export const fetchTeamsDataForStackedBarChart = async (weekEnd) => {
    let result = await axios({
        method: 'get',
        url: `/api/emails/fetchEmailsDataByDate?weekEnd=${weekEnd}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectDataForClusterGraph = async (weekEnd) => {
    console.log('fetchConnectDataForClusterGraph',weekEnd)
    let result = await axios({
        method: 'get',
        url: `/api/connect/fetchConnectDataByStageLastWeek?weekEnd=${weekEnd}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectDataForHeatMap = async () => {
    let result = await axios({
        method: 'get',
        url: `/api/connect/fetchConnectDataByDayHour`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectDataForZoomableRadar = async () => {
    let result = await axios({
        method: 'get',
        url: `/api/connect/fetchConnectDataByStage`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectDataForPiechart = async () => {
    let result = await axios({
        method: 'get',
        url: `/api/emails/fetchEmailsDataByTeam`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchEmailsConvertedToBidsClusterGraph = async (days,weekEnd) => {
    let result = await axios({
        method: 'get',
        url: `/api/emails/fetchEmailsConvertedToBid?days=${days}&weekEnd=${weekEnd}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchAllConnectBidsClusterGraph = async (days,weekEnd) => {
    let result = await axios({
        method: 'get',
        url: `/api/emails/fetchAllConnectBid?days=${days}&weekEnd=${weekEnd}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectDataForTrendGraph = async () => {
    let result = await axios({
        method: 'get',
        url: `/api/emails/fetchConnectDataForTrendGraph`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchCustomerListWithTrend = async () => {
    let result = await axios({
        method: 'get',
        url: `/api/customers/trend`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchRollingWeekMonthAvgAnalytics = async (customer_id,weeks) => {
    let result = await axios({
        method: 'get',
        url: `/api/customers/analytics_rolling_week?customer_id=${customer_id}&plot_points=${weeks}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchMessagesInQueueByPro = async (pro) => {
    let result = await axios({
        method: 'get',
        url: `/api/load_notification?pro=${pro}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchIncomingMessagesByPro = async (pro) => {
    let result = await axios({
        method: 'get',
        url: `/api/load_notification/avochato_messages?pro=${pro}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const removeMessageFromQueueByPro = async (pro,tts) => {
    let result = await axios({
        method: 'post',
        url: `/api/load_notification/unqueue?pro=${pro}&tts=${tts}`,
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectDataForMap = async (state,days,direction,eq_types) => {
    let url = `/api/connect/fetchConnectCallsByLocation?days=${days}&value_type=calls`
    if (state && state!== 'USA') {
        url += `&state=${state}`
    }

    url += `&direction=${direction}`

    let result = await axios({
        method: 'get',
        url: url,
        params: {
            eq_types: eq_types
        }
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectQueryCounterDataForMap = async (state,days,direction,eq_types) => {
    let url = `/api/connect/fetchConnectCallsByLocation?days=${days}&value_type=query`
    if (state && state!== 'USA') {
        url += `&state=${state}`
    }

    url += `&direction=${direction}`

    let result = await axios({
        method: 'get',
        url: url,
        params: {
            eq_types: eq_types
        }
    })
    result = (result && result.status === 200) ? result.data : []
    return result
}

export const fetchConnectLoadsDataForMap = async (state,days,direction,eq_types) => {
    let url = `/api/connect/fetchConnectCallsByLocation?days=${days}&value_type=loads`
    if (state && state!== 'USA') {
        url += `&state=${state}`
    }

    url += `&direction=${direction}`

    let result = await axios({
        method: 'get',
        url: url,
        params: {
            eq_types: eq_types
        }
    })
    result = (result && result.status === 200) ? result.data : {data: []}
    return result
}

export const fetchConnectEmailsDataForMap = async (state,days,direction,eq_types) => {
    let url = `/api/connect/fetchConnectCallsByLocation?days=${days}&value_type=emails`
    if (state && state!== 'USA') {
        url += `&state=${state}`
    }

    url += `&direction=${direction}`

    let result = await axios({
        method: 'get',
        url: url,
        params: {
            eq_types: eq_types
        }
    })
    result = (result && result.status === 200) ? result.data : {data: []}
    return result
}