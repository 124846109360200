import { SET_EXTERNAL_LOADS } from '../actionTypes'
import { mapEqTypes } from '../loadActions'
const initialState = []


export default (state=initialState,  action) => {

    switch(action.type) {
    case SET_EXTERNAL_LOADS:
        let {loads} = action
        if (loads && loads.length && loads[0].equipmentType) {
            loads = loads.map(mapEqTypes)
        } else if (loads && loads.length) {
            loads = loads.map(l => {
                l.id = l.load_id
                return l
            })
        }


        // Was getting errors on localhost. Stashing this code until we can verify that it's not occurring in production.
        // const loads = (
        //     action && action.loads && typeof action.loads === 'object' && action.loads.length
        // ) 
        // ? action.loads.map(mapEqTypes) 
        // : action.loads

        return loads
    // case CLEAR_EXTERNAL_LOADS:
    //     return []
    default:
        return state
    }
    
}