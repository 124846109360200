import { takeLatest, call, put } from "redux-saga/effects"
import {
    FETCH_HISTORIC_LOADS_FOR_SCREEN,
    SET_HISTORIC_LOADS_FOR_SCREEN,
    SET_HISTORIC_LOADS_FOR_SCREEN_FILTER
} from '../actionTypes'
import { fetchLoads } from '../loadActions'

// worker saga: makes the api call when watcher saga sees the action
function* workerFetchHistoricLoadsForScreenSaga(payload) {
  try {
    // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
    yield put({ type: SET_HISTORIC_LOADS_FOR_SCREEN, loads: null })
    yield put({ type: SET_HISTORIC_LOADS_FOR_SCREEN_FILTER, parms: payload.parms })
    let loads = []
    const response = yield call(fetchLoads, payload.parms)

    if (response) {
      loads = response.data
    }

    yield put({ type: SET_HISTORIC_LOADS_FOR_SCREEN, loads })
  } catch (error) {
    // TODO: dispatch a failure action to the store with the error
    console.log('error ', error)
  }
}


export function* watchFetchHistoricLoadsForScreenSaga() {
    yield takeLatest(FETCH_HISTORIC_LOADS_FOR_SCREEN, workerFetchHistoricLoadsForScreenSaga)
}