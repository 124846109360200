import axios from "axios"

// export const updateLoadPost = (load_post_id, data) => {
//     return axios({
//       method: 'patch',
//       url: `/api/load_postings/${load_post_id}`,
//       data: data
//     })
// }

export const updateLoadPost = (data) => {
  return axios({
    method: 'post',
    url: `/api/load_postings/patch_multiple_posts`,
    data: data
  })
}

