
import axios from "axios"

export const getStats = async ({stat_name, team_name, interval}) => {
    
    return  await axios({
        method: 'get',
        url: `/api/stats?team_name=${team_name}&stat_name=${stat_name}&interval=${interval}`
        })
}

  