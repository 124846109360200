import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'

import 'material-icons'
import './index.css'
//Needed for leaflet map
import 'leaflet/dist/leaflet.css'
import './assets/stylesheets/application.scss'

import * as serviceWorker from './serviceWorker'
import Layout from './Layout'
import store from './actions/configureStore'
import {SET_AUTHENTICATED_USER} from './actions/actionTypes'
//TODO:  may pull this in an modify for our scheme
import 'react-grid-layout/css/styles.css'

import axios from 'axios'

//Sentry.io
if (process.env.REACT_APP_SENTRY_DSN) {
    console.log('initializing sentry',  process.env.REACT_APP_SENTRY_DSN)
    Sentry.init({
        // This is gear-jammer-local
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [ new BrowserTracing()],
        tracesSampleRate: 1.0,
        release: process.env.REACT_APP_APP_RELEASE_VERSION || 'unknown',
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'unknown'
    })
}

// sets everything to user initiated.  Most things from receiving events are not user initiated.
axios.defaults.headers.common['x-user-initiated'] = 'yes'

library.add(faStroopwafel)

const authenticatedUser = localStorage.getItem('authenticatedUser')
store.dispatch({type: SET_AUTHENTICATED_USER, authenticatedUser})

const App = () => (
    <Provider store={store}>
        <Layout>
        </Layout>
    </Provider>
)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
