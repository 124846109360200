
import { takeEvery, call, put } from "redux-saga/effects"
import { FETCH_AND_SET_INSIGHT_DATA, SET_INSIGHT_DATA } from '../actionTypes'
import { fetchInsightData } from '../insightDataActions'

function* workerFetchAndSetInsightData(payload) {
  try { 
      yield put({ type: SET_INSIGHT_DATA, insightData: null })
      let insightData = []
      
      // Fetch and set Insight Data
      let response = yield call(fetchInsightData, payload.payload)
  
      if (response) {
        insightData = response
        
      }
     else {
        insightData = []
      }
      yield put({ type: SET_INSIGHT_DATA, insightData })
  
  }
  catch (err) {
    console.log('FETCH_AND_SET_EMAILS failed-->',err)
  }

}

export function* watchFetchAndSetInsightDataSaga() {
    yield takeEvery(FETCH_AND_SET_INSIGHT_DATA, workerFetchAndSetInsightData)
}
