import { APPEND_TO_RECENT_CARRIERS, SET_RECENT_CARRIERS, UPDATE_RECENT_CARRIER_ITEM } from '../actionTypes'
import { find, findIndex } from 'lodash'

const initialState = []


export default (state=initialState,  action) => {
    let newCarriers = []

    switch(action.type) {
    case APPEND_TO_RECENT_CARRIERS:
        const carrierNotInList = find(state, (c) => { return (c.id===action.carrier.id )}) ? false : true
        let rtn = state

        if (carrierNotInList) {
            newCarriers = [...state]
            newCarriers.push(action.carrier)
            rtn = newCarriers
        } else {
            // Note: should not get here because corresponding SAGA should prevent it from getting here
            rtn = state
        }

        return rtn

    case SET_RECENT_CARRIERS:
            return action.carriers

    case UPDATE_RECENT_CARRIER_ITEM:
        const indexOfCarrierInList = findIndex(state, (c) => { return (c.id===action.carrier.id )})
        let updatedList = Array.from(state)

        if (indexOfCarrierInList >= 0) {
            updatedList[indexOfCarrierInList].carrier_rep_assignments = action.carrier.carrier_rep_assignments
        } else if (indexOfCarrierInList === -1) {
            updatedList.push(action.carrier)
        }

        return updatedList
    default:
        return state
    }
}
