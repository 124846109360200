import {
    SET_USER_OWNED_CARRIERS,
    REMOVE_USER_OWNED_CARRIERS
} from '../actionTypes'

const initialState = []

export default (state=initialState,  action) => {    
    switch(action.type) {
        case SET_USER_OWNED_CARRIERS:

            return action.carriers || initialState
        case REMOVE_USER_OWNED_CARRIERS:
            const updatedCarriers = [...state].filter(oc => oc.id !== action?.carrier?.id)
            return updatedCarriers
        default:
            return state
     }
}
