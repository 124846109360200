import {
    SET_USER_CARRIERS,
    APPEND_TO_USER_CARRIERS,
    REMOVE_FROM_USER_CARRIERS
} from '../actionTypes'

const initialState = []

export default (state=initialState,  action) => {    
        let userCarriers
    if (state instanceof Array) {
        userCarriers = [...state]
    } else {
        userCarriers = []
    }
    
    switch(action.type) {
        case SET_USER_CARRIERS:

        return action.userCarriers
        case APPEND_TO_USER_CARRIERS:
            userCarriers.push(action.carrier)

            return userCarriers
        case REMOVE_FROM_USER_CARRIERS:
            userCarriers = userCarriers.filter(c=>c.id !== action.carrierId)

            return userCarriers
        default:
            return state
     }
}
