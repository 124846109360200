
import { SET_CUSTOMER_SEARCH_LIST, CLEAR_CUSTOMER_SEARCH_LIST, SET_CUSTOMER_SEARCH_LIST_TO_RECENT_CUSTOMERS } from '../../actionTypes'

const initialState = []

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_CUSTOMER_SEARCH_LIST:
        return action.customers

    case SET_CUSTOMER_SEARCH_LIST_TO_RECENT_CUSTOMERS:
        const recentCustomers = action.recentCustomers
    
        return recentCustomers ? recentCustomers : []

    case CLEAR_CUSTOMER_SEARCH_LIST:
        return []


    default:
        return state
    }
}
