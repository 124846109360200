
import axios from "axios"

// TODO: add action to impersonate a user

export const getUserImpersonationList = async () => {
    let result = await axios({
        method: 'get',
        url: `/api/users/impersonation_list`,
    })

    const users = (result && result.status === 200) ? result.data : []

    return users
}

export const getUserList = async (q) => {
    let result = await axios({
        method: 'get',
        url: `/api/users`,
        params: q
    })

    const users = (result && result.status === 200) ? result.data : []

    return users
}


export const impersonateUser = async (user_id) => {
    const data = {
        user_id
    }
    return axios({
        method: 'post',
        url: `/api/auth/impersonate`,
        data: data
      })
}


export const clearImpersonateUser = async () => {
    return axios({
        method: 'delete',
        url: `/api/auth/impersonate`,
      })
}

export const fetchActivityStats = async (rangeStart, rangeEnd) => {
    return axios({
        method: 'get', 
        url: '/api/user_activity',
        params: {
            rangeStart,
            rangeEnd
        }
    })
}
  