import { SET_CUSTOMER_LUMPER_DATA } from '../actionTypes'


const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {
        case SET_CUSTOMER_LUMPER_DATA:
            return payload?.customerLumperData 
        default:
            return state
    }
}