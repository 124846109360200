import { 
    SET_CARRIERS_OWNED,
    APPEND_TO_CARRIERS_OWNED
    // APPEND_TO_USER_CARRIERS, 
    // REMOVE_FROM_USER_CARRIERS 
} from '../actionTypes'

import _ from 'lodash'

const initialState = []

export default (state=initialState,  action) => {
    // let newCarriers = []

    switch(action.type) {
    case SET_CARRIERS_OWNED:
        return action.carriersOwned
    case APPEND_TO_CARRIERS_OWNED:
        const assignment = action.assignment
        let rtn = state
        let updatedAssignmentList = null

        const carrierNotInList = _.find(state, (a) => { 
                                                        return (a.carrier_id===assignment.carrier_id 
                                                                    && a.user_id === assignment.user_id)
                                                                }) ? false : true

        
        
        if (carrierNotInList) {
            updatedAssignmentList = _.cloneDeep(state)
            updatedAssignmentList.push(assignment)
            rtn = updatedAssignmentList
        } else {
            // Note: should not get here because corresponding SAGA should prevent it from getting here
            rtn = state
        }

        return rtn
    
    // case REMOVE_FROM_USER_CARRIERS:
    //     newCarriers = state.filter((c) => { return c.id !== action.carrier_id })
    //     return newCarriers
    default:
        return state
    }
}
