import { SET_USER_LOAD_SPREAD } from '../actionTypes'
import jsonwebtoken from 'jsonwebtoken'

const initialState = []

//here fetch the user from the store
const userDetails = localStorage.getItem('authenticatedUser')
let decodedUser = jsonwebtoken.decode(userDetails)

const calcSpread = (revenue, expense) => {
	let spread = revenue - expense
	
	if (!decodedUser?.roles.includes('CAN-VIEW-ACTUAL-SPREAD-RECAP')) {
		if (spread < 0) {
			spread = 10
		}
	}

	return spread
}

const calcMargin = (revenue, expense) => {
	return(revenue && expense) ? ((1 - expense/revenue) * 100) : null
}

const normalizeLoadData = loads => {
	return loads.map(l => {
		if (!l.carrier) l.carrier = { legal_name: '-' }
		if (!l.carrier_rep) l.carrier_rep = { full_name: '-' }
		if (!l.sales_rep) l.sales_rep = { full_name: '-' }
		
		// For Actual Spread Recap
		const invoiceTotal = l.invoice_total ? parseFloat(l.invoice_total) : parseFloat(0)  // Bottom line rate
		const carrierTotal = l.carrier_total_rate ? parseFloat(l.carrier_total_rate) : parseFloat(0)  // Bottom line rate
		l.spread_actual = calcSpread(invoiceTotal, carrierTotal)
		l.margin_actual = calcMargin(invoiceTotal, carrierTotal)

		// For Load Spread Recap
		const customerRate = l.customer_rate ? parseFloat(l.customer_rate) : parseFloat(0)  // LH Plus fuel
		const carrierLH = l.carrier_rate ? parseFloat(l.carrier_lh) : parseFloat(0) // LH
		l.spread_lh = calcSpread(customerRate, carrierLH)
		l.margin_lh = calcMargin(customerRate, carrierLH)

		if (!carrierTotal) l.carrier_rate = 0
		if (!invoiceTotal) l.invoice_total = 0

		return l	
	})
}

export default (state=initialState,  action) => {
    switch(action.type) {
	case SET_USER_LOAD_SPREAD:
	    let loads = action.loads
	    
    	if (action.loads && action.loads.length) {
	    	loads = normalizeLoadData(action.loads)
    	}
        return loads
    default:
        return state
    }
}
