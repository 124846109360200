
import { takeEvery, call, put } from "redux-saga/effects"
import { FETCH_AND_SET_CONNECT_LOGS, SET_CONNECT_LOGS } from '../actionTypes'
import { fetchConnectLogs } from '../connectLogsActions'

function* workerFetchAndSetConnectLogs(payload) {
  try { 
      yield put({ type: SET_CONNECT_LOGS, insightData: null })
      let connectLogsData = []

      // Fetch and set Emails
      let response = yield call(fetchConnectLogs, {load_id:payload.payload.id})
      if (response) {
        connectLogsData = response
      }
      yield put({ type: SET_CONNECT_LOGS, connectLogsData })
  
  }
  catch (err) {
    console.log('FETCH_AND_SET_EMAILS failed-->',err)
  }

}

export function* watchFetchAndSetConnectLogsSaga() {
  yield takeEvery(FETCH_AND_SET_CONNECT_LOGS, workerFetchAndSetConnectLogs)
}