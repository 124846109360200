import {SET_CARRIER_FOLDERS, UPDATE_CARRIER_FOLDERS} from '../actionTypes'
import _ from 'lodash'

const initialState = []


export default (state=initialState,  data) => {
    switch(data.type) {
        case SET_CARRIER_FOLDERS:
            const {carrierFolders} = data

            return (carrierFolders?.length) ?  _.sortBy(carrierFolders, [f=>f.public_folder, (f) => f.folder_name.toLowerCase()]) : initialState
        case UPDATE_CARRIER_FOLDERS:
            const {action, folder} = data
            let folders = state

            if ((action === 'delete') || (action === 'update')) {
                folders = state.filter(f => f.id !== folder.id)
            }
            if ((action === 'add')  || (action === 'update')) {
                    folders.push(folder)
            }

            return _.sortBy(folders, [(f)=>f.public_folder, (f) => f.folder_name.toLowerCase()])
    default:
        return state
    }
}
