import React, {Component} from 'react'
import Login from './Login'
import {connect} from 'react-redux'

class Authenticated extends Component {
    render() {
        const {authenticatedUser} = this.props

        if (authenticatedUser) {
            return (
                <>
                    { this.props.children }
                </>
            )
        } else {
            return (
                <Login/>
            )
        }

    }
}

function mapStateToProps(state) {
    let {authenticatedUser} = state
    return {
        authenticatedUser
    }
}
/*
const mapDispatchToProps = dispatch => {
    return {
      fetchLoads: () => dispatch({ type: FETCH_LOADS }),
      setSelectedLoad: (selectedLoad) => dispatch({type: LOAD_SELECTED, selectedLoad})
    };
  };
*/

export default connect(mapStateToProps, null)(Authenticated)