
import React, {Component} from 'react'
import { connect } from 'react-redux'
//import PropTypes from 'prop-types'
import { getUserImpersonationList} from '../actions/userActions'
import { IMPERSONATE_USER, CLEAR_IMPERSONATE_USER } from '../actions/actionTypes'

import SimpleSelect from './SimpleSelect'


class UserImpersonate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectUsers: false,
            impersonateList: []
        }
    }


    /**
     * This action captures a click, so that when you click the user name, it will switch to a list of users to impersonate.
     * @param {*} value 
     */
    clickToSelect = async (value) => {
        // let's fetch the users here
        let users = await getUserImpersonationList()
        if (users && users.length) {

            const userOptions = users.map(u => {
                return { label: u.full_name, value: u.id }
            })

            this.setState({
                selectUsers: true,
                impersonateList: userOptions
            })
        } else {
            this.setState({
                selectUsers: false,
                impersonateList: []
            })
        }

    }

    onUserSelect = async (evt) => {
        const {value} = evt.target
        
        this.props.dispatchImpersonateUser(value)
        this.setState({selectUsers: false})
    }

    onClick = async () => {
        this.props.dispatchClearImpersonation()
    }

    render() {
        const {user} = this.props
        if (! user) {
            return null
        }
        const {selectUsers, impersonateList} = this.state

        const user_name = (user.name) ? `${user.name.first_name} ${user.name.last_name}` : ''
        const can_impersonate = (user.roles && user.roles.length && user.roles.includes('IMPERSONATE-USERS'))
        const is_being_impersonated = (user.impersonated_by_user) ? true : false
        const containerClass = (can_impersonate) ? 'user-impersonate-container user-impersonate-can' : 'user-impersonate-container'

        let comp = null
        if (selectUsers) {
            const userSelect = {label: `${user.name.first_name} ${user.name.last_name}`, value: user.user_id}
            comp = <SimpleSelect values={impersonateList} value={userSelect} onChange={this.onUserSelect}/>
        } else if (is_being_impersonated) {
            comp = (<div onClick={this.onClick}>Impersonating {user_name}</div>)
        } else if (can_impersonate) {
            // put in a spy type icon
            comp = (<div onClick={this.clickToSelect}>{user_name}</div>)

        } else {
            comp = (<div>{user_name}</div>)
        }


        return (
            <div className={containerClass}>
                {comp}
            </div>
        )

    }
}


// const mapStateToProps = state => {
//     const user = state.authenticatedUser
//     const user_name = (user && user.name) ? `${user.name.first_name} ${user.name.last_name}` : ''
//     const can_impersonate = (user && user.roles && user.roles.length && user.roles.includes('IMPERSONATE-USERS'))
//     const is_being_impersonated = (user && user.impersonated_by_user) ? true : false

//     return {
//         user,
//         user_name,
//         can_impersonate,
//         is_being_impersonated
//     }
// }

const mapStateToProps = null

const mapDispatchToProps = dispatch => {
	return {
        dispatchImpersonateUser: (user_id) => dispatch({ type: IMPERSONATE_USER, user_id }),
        dispatchClearImpersonation: () => dispatch({ type: CLEAR_IMPERSONATE_USER })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserImpersonate)
