import { SET_CONNECT_QUEUE_STATUS } from '../actionTypes'
const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case SET_CONNECT_QUEUE_STATUS:
        return payload.connectQueueStatus || null
    
    default:
        return state
    }
}