import { SET_USER_ACTIVITY } from '../actionTypes'

const initialState = []


//TODO: change this from error to message or notify

export default (state=initialState,  action) => {
    switch(action.type) {
        case SET_USER_ACTIVITY:
            return action.user_activity
        default:
            return state
     }
}
