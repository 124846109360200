import { SET_HISTORIC_LOADS_FOR_SCREEN } from '../actionTypes'

const initialState = []

export default (state=initialState,  action) => {

    switch(action.type) {
    case SET_HISTORIC_LOADS_FOR_SCREEN:
        return action.loads
    default:
        return state
    }
    
}