import { SET_TEAMS } from '../actionTypes'

const initialState = []


export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_TEAMS:
      return action.teams
    default:
        return state
    }
    
}