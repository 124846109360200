import { SET_CARRIER_LEADS, APPEND_TO_CARRIER_LEADS } from '../actionTypes'
import _ from 'lodash'

const initialState = []


export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_CARRIER_LEADS:
        return action.carrierLeads
    case APPEND_TO_CARRIER_LEADS:
        const assignment = action.assignment
        let rtn = state
        let updatedAssignmentList = null

        const carrierNotInList = _.find(state, (a) => { 
                                                        return (a.carrier_id===assignment.carrier_id
                                                            && a.user_id===assignment.user_id)
                                                    }) ? false : true
                                            
        if (carrierNotInList) {
            updatedAssignmentList = _.cloneDeep(state)
            updatedAssignmentList.push(assignment)
            rtn = updatedAssignmentList
        } else {
            // TODO: Send slack message
            // Note: should not get here
            rtn = state
        }

        return rtn
    default:
        return state
    }
}
