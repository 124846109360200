import { takeEvery, call, put } from "redux-saga/effects"
import { FETCH_AND_SET_THRESHOLD, SET_SELECTED_LOAD } from '../actionTypes'
import { fetchThresholdToLoad } from '../loadActions'

function* workerFetchAndSetLoadThreshold(payload) {
  try { 
      let thresholdData = []
      // Fetch Threshold for Load
      let response = yield call(fetchThresholdToLoad, payload.payload.id)
      if (response) {
        thresholdData = response.data
      }
      let load = payload.payload
      load['threshold'] = thresholdData
      yield put({ type: SET_SELECTED_LOAD, selectedLoad: load })
  }
  catch (err) {
    console.log('FETCH_AND_SET_THRESHOLD failed-->',err)
  }

}

export function* watchFetchAndSetLoadThresholdSaga() {
    yield takeEvery(FETCH_AND_SET_THRESHOLD, workerFetchAndSetLoadThreshold)
}