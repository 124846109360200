import {SET_HISTORIC_LOADS_FOR_SCREEN_FILTER} from '../actionTypes'
import moment from 'moment'

let initialState = {}

let shipDateTo = moment().format('L')
let shipDateFrom = moment().subtract(1, 'week').format('L')
let quickDaySelect = moment().format('dddd').slice(0,3).toUpperCase()


// Values for initial load filter (not button states)
initialState = {
    equipmentGroups: [],
    equipmentTags: [],    
    statuses: ['CONSIGNEE', 'DELIVERED', 'RELEASED'],
    shipDateFrom: shipDateFrom,
    shipDateTo: shipDateTo,
    origin: "",
    originRadius: "100", // TODO: make this a const or env variable!!
    destination: "",
    destinationRadius: "100", // TODO: make this a const or env variable!!
    myLoads: [], // set to ['MY_LOADS'] to default to true.
    quickDaySelect: [quickDaySelect],
    age: 0 // will not filter on age if 0 or null
}

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_HISTORIC_LOADS_FOR_SCREEN_FILTER:
        const {parms} = action
        const loadFilter = {
            ...parms
        }
        return loadFilter
    default:
        return state
    }
}
