import { 
    SET_CARRIER_OWNERS,
    REMOVE_CARRIER_OWNER,
    ADD_CARRIER_OWNER
} from '../actionTypes'


const formatCarrierOwners = (carrierOwners) => carrierOwners.reduce((map, co) => {
    map[co.mc] = co
    return map
}, {})

const removeCarrierFromOwners = (carrierOwners, carrier) => {
    const updatedCarrierOwners = {...carrierOwners}
    delete updatedCarrierOwners[carrier?.mc]

    return updatedCarrierOwners
}

const addCarrierOwner = (carrierOwners, carrierOwner) => {
    const updatedCarrierOwners = {...carrierOwners}
    if (carrierOwner?.mc) {
        updatedCarrierOwners[carrierOwner.mc] = carrierOwner
    }
    return updatedCarrierOwners
}

const initialState = {}

export default (state=initialState,  action) => {

    switch(action.type) {
    case SET_CARRIER_OWNERS:
        return (action.carrierOwners) ? formatCarrierOwners(action.carrierOwners) : null
    case REMOVE_CARRIER_OWNER:
        return removeCarrierFromOwners(state, action.carrier)
    case ADD_CARRIER_OWNER:
        return addCarrierOwner(state, action.carrierOwner)
    default:
        return state
    }
}
