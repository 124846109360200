const _ = require('lodash')

export const isAdmin = user => {
	return user.roles && user.roles.includes('ADMIN')
}

export const canAssignCarrierLeads = user => {
	return user.roles && user.roles.includes('ASSIGN-CARRIER-LEADS')
}

export const canAssignCarrierProspects = user => {
	return user.roles && user.roles.includes('ASSIGN-CARRIER-PROSPECTS')
}

export const canViewSpreadRecapOfOtherUsers = user => {
	let canView = false
	if (user.roles.includes('VIEW-COMPANY-GP')) {
		canView = true
	}

	return canView
}

export const canAssignLoads = user => {
	let canAssignLoads = false

	if (user.roles.includes('ASSIGN-LOADS')) {
		canAssignLoads = true
	}

	return canAssignLoads
}

export const getUserFullName = user => {
	if (user && user.full_name) {
		return user.full_name
	} else if (user && user.label) {
		return user.label
	} else {
		const first = _.get(user, 'name.first_name', null)
		const last = _.get(user, 'name.last_name', null)
		return (first && last) ? `${first} ${last}` : 'Current User'
	}
}

export const getUserId = user => {
	if (user && 'id' in user) {
		return user.id
	} else {
		return _.get(user, 'user_id', null)
	}
}

export const getUserAssignmentForCarrier = (userId, assignments) => {
	let rtn = null
	const userAssignments = []

	for (const a of assignments) {
		if (a.user_id === userId) {
			userAssignments.push(a)
		}
	}

	if (userAssignments.length === 0) {
		rtn = null
	} else {
		rtn = userAssignments[0]
	}

	return rtn
}

export const getOtherUserOwnershipRecord = (userId, assignments) => {
	let rtn = null
	let otherUserOwnershipRecord = []

	for (const a of assignments) {
		if (a.assignment_type === 'OWNERSHIP' && a.user_id !== userId) {
			otherUserOwnershipRecord.push(a)
		}
	}

	if (otherUserOwnershipRecord.length === 0) {
		rtn = null
	} else {
		rtn = otherUserOwnershipRecord[0]
	}

	return rtn
}

export const canPostLoadsForRestrictedCustomers = user => {
	return user.roles && user.roles.includes('POST-LOADS-FOR-RESTRICTED-CUSTOMERS')
}

export const canDeleteCarrierNotes = user => {
	return user.roles && user.roles.includes('DELETE-CARRIER-NOTES')
}

export const canModifyCarrierDomain = user => {
	return user.roles && user.roles.includes('MODIFY-CARRIER-DOMAIN')
}

export const datTrucksRestrictedExceptOnSpotLoadsForUser = user => {
	return user.roles && user.roles.includes('RESTRICT-DAT-TRUCKS-EXCEPT-ON-SPOT-LOADS')
}

export const connectTrucksRestricedForUser = user => {
	return user.roles && user.roles.includes('RESTRICT-CONNECT-TRUCKS')
}

export const canSeeAllMatches = user => {
	// Take the out for now as users cannot see DAT matches where no one is following
	// We could add in if no-one is following then show the match as well.
	return true
	//return user.roles && user.roles.includes('CAN-SEE-ALL-MATCHES')
}

export const userHasRole = (userRoles, role) => {
	let rtn

	if (userRoles?.length && userRoles.includes(role)) {
		rtn = true
	} else {
		rtn = false
	}

	return rtn
}

// Returns true if user can do ANY type of MANUAL post 
export const userCanLoadPostManual = userRoles => {
    const userCanLoadPostManualPhone = userHasRole(userRoles, 'LOAD-POST-MANUAL-PHONE')
    const userCanLoadPostManualEmail = userHasRole(userRoles, 'LOAD-POST-MANUAL-EMAIL')
    const userCanLoadPostManualConnect = userHasRole(userRoles, 'LOAD-POST-MANUAL-CONNECT')
    
    return userCanLoadPostManualPhone || userCanLoadPostManualEmail || userCanLoadPostManualConnect
}

// Returns true if user do ANY type of MULTI post 
export const userCanLoadPostMulti = userRoles => {
    const userCanLoadPostMultiPhone = userHasRole(userRoles, 'LOAD-POST-MULTI-PHONE')
    const userCanLoadPostMultiEmail = userHasRole(userRoles, 'LOAD-POST-MULTI-EMAIL')
    const userCanLoadPostMultiConnect = userHasRole(userRoles, 'LOAD-POST-MULTI-CONNECT')

    return userCanLoadPostMultiPhone || userCanLoadPostMultiEmail || userCanLoadPostMultiConnect
}

export const userCanAssignCarrierOwner = user => {
	return user.roles.includes('CARRIER-OWNER-ADMIN') || ((user.email === 'it@loadotr.com') && (user?.name?.first_name === 'OTR') && (user?.name?.last_name === 'ADMIN'))
}
