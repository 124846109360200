import {FETCH_ALL_TEAMS} from '../actionTypes'

const initialState = {
allTeams: []
}

export default (state=initialState,  action) => {
    switch(action.type) {
        case FETCH_ALL_TEAMS:
        return {
            ...state,
            allTeams: action.allTeams
        }
        default:
            return state
     }
}
