import {CONNECTED, DISCONNECTED} from 'pusher-redux'
import moment from 'moment'

const initialState = {}

export default (state=initialState,  action) => {
    switch(action.type) {
    case CONNECTED:
        console.log('PUSHER connected at ', moment().toString())
        return {...state, connected: true}
    case DISCONNECTED:
        console.log('PUSHER disconnected', moment().toString())
        return {...state, connected: false}
    default:
        return state
    }
}