import { SET_SMS_QUEUE_LOGS } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case SET_SMS_QUEUE_LOGS:
        return payload.smsQueueLogs || null
    
    default:
        return state
    }
}