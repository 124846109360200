import { takeLatest, call, put } from 'redux-saga/effects'
import axios from 'axios'
import {  FETCH_TEAMS, SET_TEAMS } from '../actionTypes'


// function that makes the api request and returns a Promise for response
function fetchTeams() {
  return axios({
    method: "get",
    url: `/api/v2/teams`
  })
}



// worker saga: makes the api call when watcher saga sees the action
function* workerFetchTeams() {
  try {
    const response = yield call(fetchTeams)

    if (response?.data?.length) {
      yield put({type: SET_TEAMS, teams: response.data}) 
    } 
  
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error on truck saga ", error)
  }
}



export function* watchFetchTeamSaga() {
    yield takeLatest(FETCH_TEAMS, workerFetchTeams)
}


