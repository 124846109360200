// This is the reducer for notifications to be displayed in a user's inbox
import { ADD_INBOX_NOTIFICATION, SET_INBOX_NOTIFICATIONS } from '../actionTypes'

const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {
    case ADD_INBOX_NOTIFICATION:
        let inboxNotifications = [...state]
        inboxNotifications.unshift(payload.notification)
        inboxNotifications = inboxNotifications.slice(0, 20)
        return inboxNotifications

    case SET_INBOX_NOTIFICATIONS:
    	return payload.inboxNotifications

    default:
        return state
    }
}