import {SET_LOAD_FILTER} from '../actionTypes'
import moment from 'moment'

// TODO: where should these go?
const FRIDAY = 5
const SATURDAY = 6
const UPCOMING_SUNDAY = 7

let initialState = {}
let shipDateTo = null
let quickDaySelect = ''
const dayOfWeek = moment().day()

if (dayOfWeek === FRIDAY || dayOfWeek === SATURDAY) {
    const offsetForSunday = UPCOMING_SUNDAY - dayOfWeek
    shipDateTo = moment().add(offsetForSunday, 'day').format('L')
    quickDaySelect='WEEKEND'
} else {
    shipDateTo = moment().format('L')
    quickDaySelect = moment().format('dddd').slice(0,3).toUpperCase()
}

// Values for initial load filter (not button states)
initialState = {
    equipmentGroups: ['Reefer', 'Van', 'Flatbed', 'Other'],
    equipmentTags: [],    
    statuses: ['OPEN', 'RESERVED', 'BOUNCED'],
    shipDateFrom: moment().format('L'),
    shipDateTo: shipDateTo,
    origin: "",
    originRadius: "50", // TODO: make this a const or env variable!!
    destination: "",
    destinationRadius: "50", // TODO: make this a const or env variable!!
    myLoads: [], // set to ['MY_LOADS'] to default to true.
    quickDaySelect: [quickDaySelect],
    age: 0, // will not filter on age if 0 or null
    myTeams: false
}

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_LOAD_FILTER:
        const {parms} = action
        const loadFilter = {
            ...parms
        }
        return loadFilter
    default:
        return state
    }
}
