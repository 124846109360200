import { SET_SELECTED_MATCH } from '../actionTypes'

const initialState = null

export default (state=initialState,  action) => {

    switch(action.type) {
    case SET_SELECTED_MATCH:
        return action.selectedMatch
    default:
        return state
    }
    
}