import axios from "axios"

export const deleteFolder = async (f) => {
    const result = await axios({
        method: "post",
        url: '/api/carrier_rep_assignment_tags/delete_folder',
        data: f
    })

    return result
}

export const renameFolder = async (payload) => {
    const result = await axios({
        method: "post",
        url: '/api/carrier_rep_assignment_tags/rename_folder',
        data: payload // has folder and new name
    })

    return result
}