import { CREATE_DIALOG_WITH_PROPS, EMAIL_DIALOG_CONTENT } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = {
    emailDialog: false,
    emailContent: null
}

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case CREATE_DIALOG_WITH_PROPS:
        return {
            ...state,
            emailDialog: !state.emailDialog
        }

    case EMAIL_DIALOG_CONTENT: 
    console.log('fgr',payload)
        return {
            ...state,
            emailContent: payload.emailContent
        }

    
    default:
        return state
    }
}