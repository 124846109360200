import { SET_HISTORICAL_LOADS } from '../actionTypes'

const initialState = []

export default (state=initialState,  action) => {

    switch(action.type) {
    case SET_HISTORICAL_LOADS:
        return action.loads
    default:
        return state
    }
    
}