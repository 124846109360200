import { SET_CARRIER_OPS_TASKS_FOR_SELECTED_LOAD } from '../actionTypes'
// import moment from 'moment'

const initialState = []

export default (state=initialState,  action) => {
    let carrierOpsSelectedLoadTasks = []
    switch(action.type) {
        case SET_CARRIER_OPS_TASKS_FOR_SELECTED_LOAD:
            carrierOpsSelectedLoadTasks = action.carrierOpsSelectedLoadTasks
            return carrierOpsSelectedLoadTasks
        default:
            return state
    }
    
}