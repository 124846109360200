import {SET_SELECTED_USER} from '../actionTypes'

const initialState = {}


export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_SELECTED_USER:
        return action.user
    default:
        return state
    }
}
