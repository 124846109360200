import { takeEvery, takeLatest, put, select, call } from "redux-saga/effects"
import { 
    FETCH_CARRIER_OPS_TASKS, SET_CARRIER_OPS_TASKS, SET_SELECTED_CARRIER_OPS_TASK,
    FETCH_AND_SET_SELECTED_CARRIER_OPS_TASK,
    SAVE_CARRIER_OPS_TASK,
    SET_SELECTED_CARRIER_FOR_CARRIER_OPS, SET_SELECTED_CARRIER,
    FETCH_AND_SET_INCOMING_TEXT_BY_LOAD,
    SET_SELECTED_INCOMING_TEXT,
    UPDATE_INCOMING_TEXTS,
    ADD_SELECTED_INCOMING_TEXT,
    SET_CARRIER_OPS_TASK_DATA,
    ADD_MESSAGE,
    SET_CARRIER_OPS_TASKS_FOR_SELECTED_LOAD,
    ASSIGN_CARRIER_OPS_TASKS,
    SEND_MMS_TEXT,
    AUTO_SAVE_TASK_DATA,
    FETCH_SMS_NOTIFICATIONS,
    SET_SMS_NOTIFICATIONS,
    READ_SMS_NOTIFICATION,
    MARK_READ_SMS_INBOUND_NOTIFICATIONS,
    FETCH_LOAD_TASK_QUESTIONS,
    SET_LOAD_TASK_QUESTIONS
} from '../actionTypes'
import { 
    getSelectedCarrierOpsTask,
    getCurrentUser,
    getLoadTaskFilter,
    getCarrierOpsTaskData
} from '../reducers/rootReducer'
import {
    fetchCarrier,
    fetchCarrierByMC
} from '../carrierActions'
import { fetchTask, updateCarrierOpsTask, fetchIncomingTextByLoad, 
        sendTextByLoad, fetchActiveTaskByLoadId, assignTasksToUser, 
        unassignTasks, fetchLoadTasksByFilter , sendMmsByLoad, fetchSmsNotifications, markSmsNotificationRead,
        fetchLoadTaskQuestions} from "../carrierOpsActions"
import { fetchLoad } from "../loadActions"
import _ from 'lodash'


//TODO: rework this function so that we use better filters
function* workerFetchCarrierOpsTasks(payload) {
    const {pro, load_id} = payload
    if (pro?.length || load_id?.length) {
        let load = {id: load_id, pro}
        if (! load.id) {
            const loadResponse = yield call(fetchLoad, pro)
            load = loadResponse?.data
        }
        if (load?.id) {
            //TODO: Think about this.  We should fetch the task for this load and set the selected task
            // Look for something that is active, assigned first, then inactive.
            const taskResponse = yield call(fetchActiveTaskByLoadId, load.id)
            const task = taskResponse?.data
            if (task?.id) {
                yield put({ type: FETCH_AND_SET_SELECTED_CARRIER_OPS_TASK, selectedTask: task, ingnoreTasksFetch: true})
            } else {
                yield put({type: ADD_MESSAGE, message: `Task not found for pro: ${pro}`})
            }
        } else {
            yield put({ type: SET_CARRIER_OPS_TASKS_FOR_SELECTED_LOAD, carrierOpsSelectedLoadTasks: [] })
            yield put({type: ADD_MESSAGE, message: `Load not found for pro: ${pro}`})
        }
    } else {
        const filter = yield select(getLoadTaskFilter)
        const response = yield call(fetchLoadTasksByFilter, filter)
        if (response?.data?.length) {
            yield put({ type: SET_CARRIER_OPS_TASKS, tasks: response.data })
        } else {
            yield put({ type: SET_CARRIER_OPS_TASKS, tasks: [] })
        }
    }
}

// Here we want to rework this function to check if their is currently a task that has unsaved data
function* workerFetchAndSetSelectedCarrierOpsTask(action) {
    const targetTaskId = action.selectedTask.id
    const task = yield call(fetchTask, targetTaskId)
    yield put({type: SET_SELECTED_CARRIER_OPS_TASK, selectedTask: task})
    yield put({type: SET_CARRIER_OPS_TASK_DATA, data: task.collected_data || {}})

    // here we want to fetch the carrier and the incoming texts
    yield put({type: SET_SELECTED_CARRIER, selectedCarrier: task?.load?.carrier})
//    yield put ({type: SET_SELECTED_CARRIER_FOR_CARRIER_OPS, selectedCarrier: {id: task?.load?.carrier_id}})
    yield put({type: FETCH_AND_SET_INCOMING_TEXT_BY_LOAD, selectedLoadId: task?.load?.id})

    // if (! action.ingnoreTasksFetch && task?.load?.pro) {
    //     yield put({type: FETCH_CARRIER_OPS_TASKS, pro: task.load.pro, setTask: 'no'})
    // }
}

function* workerSaveCarrierOpsTask(action) {
    const currentSelectedTask = yield select(getSelectedCarrierOpsTask)
    let task
    let updateData = {}

    // Get the action of the update
    // action can be: ASSIGN, UNASSIGN, COMPLETE, CANCEL, VOID
    if (action?.parms?.action) {
        updateData['action'] = action.parms.action
    }
    if (action?.parms?.reason) {
        updateData['reason'] = action.parms.reason
    }
    if (action?.parms?.data) {
        updateData['data'] = action.parms.data
    }
    if (action?.parms?.active_dt) {
        updateData['active_dt'] = action.parms.active_dt
        if (action.parms?.no_answer) {
            updateData['no_answer'] = action.parms.no_answer
        }
    }

    try {
        if (updateData && action?.parms?.id) {
            task = yield call(updateCarrierOpsTask,
                action?.parms?.id,
                updateData
            )
            if (task?.id) {
                yield put({type: ADD_MESSAGE, message: 'Task Saved Successfully'})

                if (currentSelectedTask && currentSelectedTask.id === task.id) {
                    yield put({type: SET_SELECTED_CARRIER_OPS_TASK, selectedTask: task})
                }
            } else {
                yield put({type: ADD_MESSAGE, message: 'Error Saving Task'})
            }
        }
    }
    catch (e) {
        console.log('workerSaveCarrierOpsTask failed to fetch task-->', e)
    }
}

/**
 * 
 * @param {*} payload should have an array of task ids and a user id to assign these tasks and an assign flag: true to assign and false to unassign
 */
function* workerAssignCarrierOpsTasksSaga(action) {
    const assign = action?.parms?.assign
    const task_ids = action?.parms?.task_ids
    let user_id = action?.parms?.user_id

    if (assign && (! user_id)) {
        const user = yield select(getCurrentUser)
        user_id = user.user_id
    }
    
    (assign) ? yield call(assignTasksToUser, task_ids, user_id) : yield call(unassignTasks, task_ids) 
}

function* workerSetSelectedCarrierForCarrierOps(payload) {
    try {
        let carrierId = _.get(payload, 'carrierAssignment.carrier_id', null) ? _.get(payload, 'carrierAssignment.carrier_id', null) : _.get(payload, 'selectedCarrier.id', null)
        let response
        if (!carrierId) {
            carrierId = _.get(payload,'selectedCarrier.mc')
            response = yield call(fetchCarrierByMC, carrierId)

        }
        else {
            response = yield call(fetchCarrier, carrierId)
        }
        const carrier = response.data
        yield put({type: SET_SELECTED_CARRIER, selectedCarrier: {...carrier, loads: null, trucks: null, contacts: null}})
        yield put({type: SET_SELECTED_CARRIER, selectedCarrier: carrier})
    } catch (err) {
        console.log('workerSetSelectedCarrierForCarrierOps error: ', err)
    }
}

function* workerFetchAndSetIncomingTextForCarrierOps(action) {
    try {
        let selectedLoadId = action?.selectedLoadId
        let response
        if (selectedLoadId) {
            response = yield call(fetchIncomingTextByLoad, selectedLoadId)

        }
        const incomingTexts = response?.data
        yield put({ type: SET_SELECTED_INCOMING_TEXT, incomingTexts: incomingTexts})
    } catch (err) {
        console.log('workerFetchAndSetIncomingTextForCarrierOps error: ', err)
    }
}

function* workerUpdateIncomingTextForCarrierOps(action) {
    try {
        const selectedLoadId = action?.payload?.selectedLoadId
        const newMessage = action?.payload?.newMessage
        if (selectedLoadId && newMessage?.message?.length) {
            newMessage['load_id'] = selectedLoadId
            const response = yield call(sendTextByLoad, selectedLoadId, newMessage['to_phone'], newMessage['message'])
            if (response?.status === 200) {
                const outgoingText = response?.data
                yield put ({type: ADD_SELECTED_INCOMING_TEXT, incomingText: outgoingText})
            } else {
                yield put ({type: ADD_MESSAGE, message: 'Failed to send message'})
            }

            //TODO: Here we want to assign the task to the user, if there is one selected
            const selectedTask = yield select(getSelectedCarrierOpsTask)
            if (selectedTask && selectedTask.load_id === selectedLoadId) {
                const user = yield select(getCurrentUser)
                if (user && (user.id !== selectedTask.assigned_user_id)) {
                    // we have a user and a task that is not assigned to the user
                    // a message was sent, so now we are going to assign the user to the task
                    yield call(updateCarrierOpsTask, selectedTask.id, {action: 'ASSIGN'})
                }
            }
        }
    } catch (err) {
        console.log('workerUpdateIncomingTextForCarrierOps error: ', err)
    }
}

/*
pseudo code
Upload the files to the server and send the message
*/
function* workerSendCarrierOpsMMS(action) {
    if (action?.files?.length) {
        const task = yield select(getSelectedCarrierOpsTask)
        if (task?.load?.id) {
            const phone = action.phone
            if (phone?.length) {
                for (const file of action.files) {
                    const response = yield call(sendMmsByLoad, task.load.id, phone, file)
                    if (response?.status === 200) {
                        const outgoingText = response?.data
                        yield put({ type: ADD_SELECTED_INCOMING_TEXT, incomingText: outgoingText })
                    } else {
                        yield put({ type: ADD_MESSAGE, message: 'Failed to send message' })
                    }
                }
            } else {
                yield put ({type: ADD_MESSAGE, message: 'Failed to send file, no phone number'})
            }
        } else {
            yield put ({type: ADD_MESSAGE, message: 'Failed to send file, no task / load selected'})
        }
    }
}

/*
pseudo code
Upload the files to the server and send the message
*/
function* workerAutoSaveCarrierOpsData() {
    const task = yield select(getSelectedCarrierOpsTask)
    if (task?.id) {
        const data = yield select(getCarrierOpsTaskData)

        if (Object.keys(data).length) {
            if (! _.isEqual(task.collected_data, data)) {
                const updatedTask = yield call(updateCarrierOpsTask,
                    task.id,
                    {data}
                )
                if (updatedTask?.id) {
                    //TODO: take this out when done testing
                   // yield put({type: SET_SELECTED_CARRIER_OPS_TASK, selectedTask: task})
                } else {
                    yield put({type: ADD_MESSAGE, message: 'Error Auto-Saving Task'})
                }
            }
        }
    }
}

/**
 * Fetches the notifications based on the user
 */
function *workerFetchSmsNotifications() {
    const user = yield select(getCurrentUser)

    const notifications = yield call(fetchSmsNotifications, user.user_id)

    if (notifications?.length) {
        yield put({type: SET_SMS_NOTIFICATIONS, notifications})
    }

}

function *workerMarkSmsNotificationRead(action) {
    const {id} = action
    const user = yield select(getCurrentUser)

    const response = yield call(markSmsNotificationRead, id, user.user_id)
    if (response) {
        yield put({type: MARK_READ_SMS_INBOUND_NOTIFICATIONS, id})
    }
}

function *workerFetchLoadTaskQuestions() {
    const response = yield call(fetchLoadTaskQuestions)

    if (response && (response.status === 200) && response.data?.length) {
        yield put({type: SET_LOAD_TASK_QUESTIONS, questions: response.data})
    }
}

export function* watchFetchCarrierOpsTasksSaga() {
    yield takeEvery(FETCH_CARRIER_OPS_TASKS, workerFetchCarrierOpsTasks)
}

export function* watchFetchAndSetSelectedCarrierOpsTask(action) {
    yield takeEvery(FETCH_AND_SET_SELECTED_CARRIER_OPS_TASK, workerFetchAndSetSelectedCarrierOpsTask)
}

export function* watchSaveCarrierOpsTaskSaga() {
    yield takeEvery(SAVE_CARRIER_OPS_TASK, workerSaveCarrierOpsTask)
}

export function* watchAssignCarrierOpsTasksSaga() {
    yield takeLatest(ASSIGN_CARRIER_OPS_TASKS, workerAssignCarrierOpsTasksSaga)
}

export function* watchSetSelectedCarrierForCarrierOps() {
    yield takeLatest(SET_SELECTED_CARRIER_FOR_CARRIER_OPS, workerSetSelectedCarrierForCarrierOps)
}

export function* watchFetchAndSetIncomingTextForCarrierOps() {
    yield takeLatest(FETCH_AND_SET_INCOMING_TEXT_BY_LOAD, workerFetchAndSetIncomingTextForCarrierOps)
}

export function* watchUpdateIncomingTextForCarrierOps() {
    yield takeLatest(UPDATE_INCOMING_TEXTS, workerUpdateIncomingTextForCarrierOps)
}

export function* watchSendCarrierOpsMMSSaga() {
    yield takeLatest(SEND_MMS_TEXT, workerSendCarrierOpsMMS)
}

export function* watchAutoSaveCarrierOpsDataSaga() {
    yield takeLatest(AUTO_SAVE_TASK_DATA, workerAutoSaveCarrierOpsData)
}


//TODO: This should be moved into it's own saga, but for now we are keeping it here as there is nothing else that uses it
export function* watchFetchSmsNotificationsSaga() {
    yield takeLatest(FETCH_SMS_NOTIFICATIONS, workerFetchSmsNotifications)
}

export function* watchMarkSmsNotificationReadSaga() {
    yield takeLatest(READ_SMS_NOTIFICATION, workerMarkSmsNotificationRead)
}

export function* watchFetchLoadTaskQuestionsSaga() {
    yield takeLatest(FETCH_LOAD_TASK_QUESTIONS, workerFetchLoadTaskQuestions)
}