import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Dialog } from 'react-md'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import NotificationCard from './NotificationCard'

const NotificationDialogTitle = ({filterByUser, onClick}) => {
    const style = (filterByUser) ? { marginRight: '10px', color: 'orange'} : { marginRight: '10px'}
    const title = (filterByUser) ? 'Your Notifications' : 'All Notifications'
    return (
      <div className="otr-notification-card-title">
        <div>{title}</div>
        <div style={{marginLeft: '40px'}}>
          <FontAwesomeIcon id="filter_by_user" icon={faUser} style={style} onClick={onClick}/>
        </div>
      </div>
    )
}


const NotificationDialog = ({ visible, notifications, filterByUser, onFilterClick } ) =>{
    const empty = !notifications.length

    // here we are going to filter by the last user

    const content = (empty) ? <div className="otr-no-notifications">You have no notifications at this time.</div> :
      notifications.map((n, i) => (<NotificationCard notification={n} key={n.id} read={n.read}/>))
  
    return (
      <Collapse collapsed={!visible}  springConfig={{stiffness: 400, damping: 30}}>
        <Dialog
          id="notifications-dialog"
          title={<NotificationDialogTitle  onClick={onFilterClick} filterByUser={filterByUser}/>}
          autopadContent={false}
          height={508}
          width={350}
          className="md-background badges__notifications__dialog"
        >
        {content}
        </Dialog>
      </Collapse>
    )
}

NotificationDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  filterByUser: PropTypes.bool.isRequired,
  onFilterClick: PropTypes.func.isRequired
}

export default NotificationDialog