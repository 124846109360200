import {SET_SELECTED_CARRIER_TRUCK} from '../actionTypes'

const initialState = null

export default (state=initialState, payload) => {
    switch(payload.type) {
    case SET_SELECTED_CARRIER_TRUCK:
        return payload.truck
    default:
        return state
    }
}
