import { takeEvery, takeLatest, call, put, delay, select } from 'redux-saga/effects'
import axios from 'axios'
import { FETCH_MATCHED_TRUCKS, SET_MATCHED_TRUCKS, FETCH_TRUCKS_WITH_FILTER, SET_TRUCKS, ADD_MESSAGE, UPDATED_MATCHED_TRUCK_BID, 
  ADD_DAT_TRUCK_TO_CARRIER, TRUCK_STREAMING_HALT, TRUCK_STREAM_LISTEN, ADD_MATCHED_TRUCK_FROM_STREAM } from '../actionTypes'
import { getHistoricLoads, getSelectedLoad, getCurrentUser } from '../reducers/rootReducer'
import { datTrucksRestrictedExceptOnSpotLoadsForUser, connectTrucksRestricedForUser } from '../../utils/user-helpers'
import _ from 'lodash'

// function that makes the api request and returns a Promise for response
function fetchTrucks(loadId) {
  return axios({
    method: "get",
    url: `/api/loads/${loadId}/trucks`,
    debug: true
  })
}

function fetchTrucksWithFilter(args) {
    const request = {
        method: 'get',
        url: '/api/trucks?isFiltered=true'
    }

    request.params = {}

    for (let key in args) {
        if (args[key]) {
            request.params[key] = args[key]
        }
    }

    return axios(request) || []
}

function addDATTruckToCarrier(truck) {
  return axios({
    method: "post",
    url: `/api/trucks`,
    data: truck
  })
}

function fetchStreamInfo(loadId, include_expired = false) {
  let url = `/api/loads/${loadId}/trucks/stream_info`
  
  if (include_expired === true) {
    url = `${url}?include_expired=true`
  }

  return axios({
    method: "get",
    url: url
  })
}

function searchCachedTrucks(host = "", queryStr = "") {
  const url2 = (host && host !== "") ? `${host}/hippo/api/search/trucks?${queryStr}` :  `/hippo/api/search?${queryStr}`
  return axios.get(url2)
}

function fetchDoNotUseCarriersMCs(customer_id) {
  let url = `/api/customers/${customer_id}/restricted_carriers`
  

  return axios({
    method: "get",
    url: url
  })
}

function* filterDoNotUseCarriers(trucks, customer_id) {
  let results = trucks
  try {
    // here we want to check the customer to see if there are certain carriers we cannot use
    if (trucks && trucks.length && customer_id) {
      const doNotUseCarriersResponse = yield call(fetchDoNotUseCarriersMCs, customer_id)
      if (doNotUseCarriersResponse && doNotUseCarriersResponse.data && doNotUseCarriersResponse.data.length) {
        const mcs = doNotUseCarriersResponse.data.map(c => c.mc).filter(mc => mc !== null)
        results = trucks.filter(t => (! mcs.includes(t.carrier.mc)))
      }
    }
  } catch (excFT) {
    console.err(excFT)
  }
  return results
}

function* filterTrucksBasedOnUserRoles(trucks) {
  const user = yield select(getCurrentUser)
  const load = yield select(getSelectedLoad)
  let filteredTrucks = [...trucks]

  if (datTrucksRestrictedExceptOnSpotLoadsForUser(user) && !load?.spot_load) {
    filteredTrucks = filteredTrucks.filter((t)=>t.source === 'OTR')
  }

  if (connectTrucksRestricedForUser(user)) {
    filteredTrucks = filteredTrucks.filter(t=>t.source !== 'OTR' || (t.source === 'OTR' && t?.user?.first_name !== 'AWS' && t?.user?.last_name !== 'Connect'))
  }

  return filteredTrucks
}

// worker saga: makes the api call when watcher saga sees the action
function* workerFetchMatchedTrucks(payload) {
  try {
    yield put({ type: SET_MATCHED_TRUCKS, payload: {trucks: [], historicLoads: []} })

    // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
    yield put({ type: SET_MATCHED_TRUCKS, payload: {trucks: null, historicLoads: []} })

    const response = yield call(fetchTrucks, payload.loadId)

    if (response) {
      // Helps smooth out the animations on the loads screen
      yield delay(600)

      //TODO: We should do this on the click of the historical tab, to save a call -- DALE
      const historicLoads = yield select(getHistoricLoads)

      let trucks = (response.data || [])
      // dispatch a success action to the store with the new trucks
      yield put({ type: TRUCK_STREAMING_HALT})

      const includeExpired = payload.includeExpired ? true : false
      const streamInfoResponse = yield call (fetchStreamInfo, payload.loadId, includeExpired)  // do not fetch expired

      const selectedLoad = yield select(getSelectedLoad)
      if (streamInfoResponse && streamInfoResponse.data && streamInfoResponse.data.use_stream) {
        const cachedTrucksResult = yield call (searchCachedTrucks, streamInfoResponse.data.stream_host, streamInfoResponse.data.search_query_str)
        
        if (cachedTrucksResult) {
          trucks = trucks.concat(cachedTrucksResult.data.data)

          trucks = yield call(filterDoNotUseCarriers, trucks, selectedLoad.customer_id)
          trucks = yield call(filterTrucksBasedOnUserRoles, trucks)
          yield put({ type: SET_MATCHED_TRUCKS, payload: {trucks: trucks, historicLoads: historicLoads, selectedLoad}})

          yield put({
              type: TRUCK_STREAM_LISTEN, 
              dispatchType: ADD_MATCHED_TRUCK_FROM_STREAM,
              stream_uri:  _.get(cachedTrucksResult, 'data.stream_uri', null),
              host: streamInfoResponse.data.stream_host, 
              selectedLoad
          })
        }
      } else {
        trucks = yield call(filterDoNotUseCarriers, trucks, selectedLoad.customer_id)
        yield put({ type: SET_MATCHED_TRUCKS, payload: {trucks: trucks, historicLoads: historicLoads, selectedLoad}})
      }
    }
  
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error on truck saga ", error)
  }
}

function* workerFetchTrucksWithFilterSaga(action) {
    try {
        // Wipe out table before re-rendering, so loading indicator does not interfere with table animation
        yield put({ type: SET_TRUCKS, trucks: null })
        const response = yield call(fetchTrucksWithFilter, action.payload)

        if (response) {
            const trucks = response.data
            yield put({ type: SET_TRUCKS, trucks })

            if (trucks.length === 0) {
                yield put({type: ADD_MESSAGE, message: 'No matching trucks found!'})
            }
        }

    } catch (error) {
        console.log('workerFetchTrucksWithFilterSaga error: ', error)
    }
}

function workerMatchedTruckBidSaga(action) {
  //console.log("hey we got a bid that came in", action)

  // loop through the bids to add a bid on to the matched truck
  //TODO: Implement this later
}

function* workerAddDATTruckToCarrierSaga(action) {
  yield call(addDATTruckToCarrier, action.truck)

}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watchFetchMatchedTrucks() {
    yield takeLatest(FETCH_MATCHED_TRUCKS, workerFetchMatchedTrucks)
}

export function* watchFetchTrucksWithFilterSaga() {
    yield takeLatest(FETCH_TRUCKS_WITH_FILTER, workerFetchTrucksWithFilterSaga)
}

export function* watchMatchedTruckBidSaga() {
    yield takeEvery(UPDATED_MATCHED_TRUCK_BID, workerMatchedTruckBidSaga)

}

export function* watchAddDATTruckToCarrierSaga() {
    yield takeEvery(ADD_DAT_TRUCK_TO_CARRIER, workerAddDATTruckToCarrierSaga)
}


