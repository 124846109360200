import {put, call, takeLatest, takeEvery} from 'redux-saga/effects'
import axios from 'axios'

import {
    TRUCK_ENTRY_CARRIER_SELECTED, 
    SET_SELECTED_TRUCK_ENTRY_CARRIER, 
    SET_TRUCK_ENTRY_TRUCK_LIST, 
    TRUCK_ENTRY_ADD_TRUCK, 
    TRUCK_ENTRY_DELETE_TRUCK,
    TRUCK_ENTRY_DELETE_MULTIPLE_TRUCKS,
    TRUCK_ENTRY_DELETE_TRUCK_SCHEDULE,
    ADD_MESSAGE, 
    REMOVE_TRUCKS_ENTRY_TRUCK_LIST,
    ADD_TRUCK_ENTRY_TRUCK_LIST,
    FETCH_CARRIER_MATCHING_LOADS,
    SET_CARRIER_SELECTED_LOAD,
    SET_TRUCK_SCHEDULES,
    REMOVE_TRUCK_ENTRY_TRUCK_SCHEDULE,
    REFRESH_TRUCK_SCHEDULE,
    TRUCK_SCHEDULE_REFRESHED
} from '../actionTypes'

import { deleteMultipleTrucks, postTruckScheduleRefresh } from '../truckActions'

// async function fetchCarrierTrucks(carrierId) {
//     return await axios({
//         method: 'get',
//         url: `/api/carriers/${carrierId}/trucks?current=true`
//     })
// }

async function postTruck(truck) {
    return await axios({
        method: "post",
        url: `/api/trucks`,
        data: truck
    })
}

async function deleteTruck(truck) {
    const id = (truck.id) ? truck.id : truck.truck_id
    let url = `/api/trucks/${id}`

    return await axios({
        method: "delete",
        url: url
    })
}

async function inactivateTrucksAndDeleteSchedule(scheduleId) {
    return await axios({
        method: "delete",
        url: `/api/truck_schedules/${scheduleId}`
    })
}

function fetchStreamInfo(mc) {
    const loadId = 'loadId'
    return axios({
      method: "get",
      url: `/api/loads/${loadId}/trucks/stream_info?mc=${mc}`,
    })
}


function searchCachedTrucks(host = "", queryStr = "") {
    let url2 = (host && host !== "") ? `${host}/hippo/api/search/trucks?${queryStr}` :  `/hippo/api/search?${queryStr}`
    return axios.get(url2)
}

async function fetchTruckSchedules(carrierId) {
    const truckSchedules = await axios({
        method: 'get',
        url: `/api/carriers/${carrierId}/truck_schedules`
    })

    return truckSchedules?.data ? truckSchedules.data : []
}

// convert otr truck from db to one from the cache
const convertOTRToCacheTruck = (truck) => {
    const newTruck = {
        truck_id: truck.ref,
        available_date: truck.available_date.length <= 10 ? `${truck.available_date}T12:00:00.000Z` : truck.available_date, // This should be ISO format
        source: truck.source,
        carrier: {
            dot: truck.carrier.dot,
            mc: truck.carrier.mc,
            name: truck.carrier.name,
            state: truck.carrier.state,
            phone_number: truck.carrier.main_phone
        },
        carrier_home_state: truck.carrier.state,
        carrier_name: truck.carrier.name,
        equipment_type: truck.equipment_type.name,
        equipment_type_description: truck.equipment_type.description,
        lat: truck.lat,
        lng: truck.lng,
        mc: truck.carrier.mc,
        notes: truck.notes,
        phone_number: truck.carrier.main_phone,
        region: "", //TODO: Should get this from the server
        city: truck.city,
        state: truck.state,
        destination: truck.destination,
        updated_at: truck.updated_at,
        user: {
            first_name: truck.user.first_name,
            last_name: truck.user.last_name,
            id: truck.user.id,
            full_name: truck.user.full_name
        },
        truck_schedule_id: truck?.truck_schedule_id ? truck.truck_schedule_id : null
    }
    return newTruck
}

/**
 * Simple crow flies calculation.  
 * TODO: Replace this with google or pc*miler
 * @param {*} origin_lat 
 * @param {*} origin_lng 
 * @param {*} destination_lat 
 * @param {*} destination_lng 
 */

function* workerSetTruckEntryCarrier(action) {
    // try {
        //yield put({type: RETURN_LOADS_FOR_TRUCK})
        yield put({type: SET_TRUCK_ENTRY_TRUCK_LIST, trucks: null})
        yield put({type: SET_SELECTED_TRUCK_ENTRY_CARRIER, carrier: action.carrier})
        yield put({type: SET_TRUCK_SCHEDULES, truckSchedules: null})
        // yield put({ type: TRUCK_STREAMING_HALT})
        //Fetching otr's trucks from database

        const truckMap = {}

        // REMOVED TOO SLOW on some carriers -- Dale
        // //TODO: Let's change this up a bit.  Pull trucks from the cache since they should all be there.
        // //      As a fail safe, we will pull all OTR trucks from the DB just in case
        // const dbTrucksResult = yield call(fetchCarrierTrucks, action.carrier.id)
        // if (dbTrucksResult?.data?.length) {
        //     for (let truck of dbTrucksResult.data) {
        //         truck.truck_id = truck.id
        //         truckMap[`${truck.source}:${truck.ref}`] = truck
        //     }
        //     // add these into a map
        // }
        // let's fetch the cached trucks
        const streamInfoResponse = yield call (fetchStreamInfo, action.carrier.mc)
        const cachedTrucksResult = yield call (searchCachedTrucks, streamInfoResponse.data.stream_host, streamInfoResponse.data.search_query_str)

        // TODO: Later, add in the streaming for trucks back to this page
        if (cachedTrucksResult.data?.data?.length) {
            for (let truck of cachedTrucksResult.data.data) {
                truckMap[`${truck.source}:${truck.truck_id}`] = truck
            }
        }

        const allTrucks = []
        for (let key in truckMap) {
            allTrucks.push(truckMap[key])
        }


        yield put({type: SET_TRUCK_ENTRY_TRUCK_LIST, trucks: allTrucks})
        yield put({type: SET_CARRIER_SELECTED_LOAD})

        let truckSchedules = null
        if (action?.carrier?.id) {
            truckSchedules = yield call(fetchTruckSchedules, action.carrier.id)
        } else {
            truckSchedules = []
        }

        yield put({type: SET_TRUCK_SCHEDULES, truckSchedules: truckSchedules})

        
        // This will do all of the logic to pull in the matching loads
        yield put({type: FETCH_CARRIER_MATCHING_LOADS})

}

function* workerTruckEntryAddTruck(action) {
    try {
        const { truck } = action.truckData

        const truck_response = yield call(postTruck, truck)

        if (truck_response.data?.id) {
            yield put({type: ADD_TRUCK_ENTRY_TRUCK_LIST, truck: convertOTRToCacheTruck(truck_response.data)})
        } else if (truck_response.data?.length) {
            for (let addedTruck of truck_response.data) {
                yield put({type: ADD_TRUCK_ENTRY_TRUCK_LIST, truck: convertOTRToCacheTruck(addedTruck)})
            }

            const carrierId = truck_response.data[0]?.carrier_id
            const scheduledTrucksAdded = truck_response.data[0]?.truck_schedule_id

            if (scheduledTrucksAdded && carrierId) {
                // TODO: change Otter & GJ to send back truckSchedule after adding truck(s) to avoid this extra API call
                const truckSchedules = yield call(fetchTruckSchedules, carrierId)
                yield put({type: SET_TRUCK_SCHEDULES, truckSchedules: truckSchedules})
            }

        }

    } catch(ex) {
        yield put({ type: ADD_MESSAGE, message: 'Oops! There was a problem adding that truck.\nIf this problem persists, please notify tech support.' })
        console.log(ex)
    }
}

function* workerTruckEntryDeleteTrucks(action) {
    try {
         if (action.type === 'TRUCK_ENTRY_DELETE_TRUCK') {
            const deleteTruckResp = yield call(deleteTruck, action.truck)
            if (deleteTruckResp.status === 200) {
                // then let's remove it from the truck entry list
                yield put({type: REMOVE_TRUCKS_ENTRY_TRUCK_LIST, trucks: [action.truck]})
                // This will do all of the logic to pull in the matching loads
                //yield put({type: FETCH_CARRIER_MATCHING_LOADS})
            }
        } else if (action.type === 'TRUCK_ENTRY_DELETE_MULTIPLE_TRUCKS') {
            const deleteTrucksResp = yield call(deleteMultipleTrucks, action.trucks)

            if (deleteTrucksResp?.status === 201) {
                yield put({type: REMOVE_TRUCKS_ENTRY_TRUCK_LIST, trucks: action.trucks})
                yield put({ type: ADD_MESSAGE, message: 'Trucks Deleted' })
            } else {
                yield put({ type: ADD_MESSAGE, message: 'Please select the truck(s) you would like to post, using the checkboxes.' })
            }
        }
    } catch (ex) {
        console.log(ex)
    }
}

function* workerTruckEntryDeleteTruckSchedule(action) {
    try {
        const result = yield call(inactivateTrucksAndDeleteSchedule, action.scheduleId)
    
        if (result?.status === 201) {
            if (result?.data?.length) {
                yield put({type: REMOVE_TRUCKS_ENTRY_TRUCK_LIST, trucks: result.data})
            }

            yield put({ type: REMOVE_TRUCK_ENTRY_TRUCK_SCHEDULE, scheduleId: action.scheduleId})
            yield put({ type: ADD_MESSAGE, message: 'Trucks Schedule Deleted'})
        } else {
            yield put({ type: ADD_MESSAGE, message: 'Failed to Delete Trucks Schedule'})
        }

    } catch (e) {
        console.log('workerTruckEntryDeleteTruckSchedule() - e.message', e.message)
    }
    
}

function* workerTruckScheduleRefresh(action) {
    try {
        const response = yield call(postTruckScheduleRefresh, action.truckSchedule)
        if (response.status !== 200) {
          yield put({ type: ADD_MESSAGE, message: 'Failed to refresh' })
        } else {
            yield put({ type: TRUCK_SCHEDULE_REFRESHED, truckSchedule: response.data })
        }
    } catch(e) {
        console.log('ERROR in truckEntrySaga.js - workerTruckScheduleRefresh(): ', e.message)
    }
}

export function* watchTruckEntryCarrierSelected() {
    yield takeLatest(TRUCK_ENTRY_CARRIER_SELECTED, workerSetTruckEntryCarrier)
}

export function* watchTruckEntryAddTruck() {
    yield takeEvery(TRUCK_ENTRY_ADD_TRUCK, workerTruckEntryAddTruck)
}

export function* watchTruckEntryDeleteTruck() {
    yield takeEvery(TRUCK_ENTRY_DELETE_TRUCK, workerTruckEntryDeleteTrucks)
}

export function* watchTruckEntryDeleteMultipleTrucks() {
    yield takeEvery(TRUCK_ENTRY_DELETE_MULTIPLE_TRUCKS, workerTruckEntryDeleteTrucks)
}

export function* watchTruckEntryDeleteTruckSchedule() {
    yield takeEvery(TRUCK_ENTRY_DELETE_TRUCK_SCHEDULE, workerTruckEntryDeleteTruckSchedule)
}

export function* watchTruckScheduleRefreshSaga() {
    yield takeEvery(REFRESH_TRUCK_SCHEDULE, workerTruckScheduleRefresh)
}
