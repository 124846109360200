import { SET_EMAILS } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = []

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case SET_EMAILS:
        return payload.emails || null
    
    default:
        return state
    }
}