import {SET_NEW_VERSION_AVAILABLE} from '../actionTypes'

const initialState = false

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_NEW_VERSION_AVAILABLE:
        return true
    default:
        return state
    }
}