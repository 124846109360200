import { takeEvery, call, put } from "redux-saga/effects"
import axios from "axios"
import {ADD_CARRIER_NOTE, DELETE_CARRIER_NOTE, CARRIER_SELECTED} from '../actionTypes'

// watcher saga: watches for actions dispatched to the store, starts worker saga

// function that makes the api request and returns a Promise for response
function addNote({type, payload}) {
    const { selectedCarrier, note, rating, note_type } = payload
    
    return axios({
        method: "post",
        url: "/api/carriers/" + selectedCarrier.id + "/notes",
        data: {
            note: note,
            rating: rating,
            note_type: note_type
        }
    })
}

function deleteNote({type, payload}) {
    const { id, carrier_id } = payload
    const requestUrl = `/api/carriers/${carrier_id}/notes/${id}`

    return axios({
        method: "delete",
        url: requestUrl
    })
}


// worker saga: makes the api call when watcher saga sees the action
function* workerAddNote(action) {
    const carrier = action.payload.selectedCarrier

    try {
        yield call(addNote, action)

        // TODO: Feature is obsolete. Notes are now added inline.
        // yield put({type: CLOSE_NOTE_MODAL})
        
        // Force refresh of Note's table
        yield put({type: CARRIER_SELECTED, selectedCarrier: carrier})
    } catch (error) {
        // TODO: dispatch a failure action to the store with the error
    }
}

function* workerDeleteNote(action) {
    const carrier = action.payload.selectedCarrier

    try {
        yield call(deleteNote, action)

        // Force refresh of Note's table
        yield put({type: CARRIER_SELECTED, selectedCarrier: carrier})

    } catch (e) {
        console.log('workerDeleteNote() error: ', e.message)
        // TODO: dispatch a failure action to the store with the error
    }
}

export function* watchAddNoteSaga() {
    yield takeEvery(ADD_CARRIER_NOTE, workerAddNote)
}

export function* watchDeleteNoteSaga() {
    yield takeEvery(DELETE_CARRIER_NOTE, workerDeleteNote)
}

