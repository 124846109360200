
import { takeEvery, call, put } from "redux-saga/effects"
import { FETCH_AND_SET_ADMIN_CUSTOMERS, SET_ADMIN_CUSTOMERS, ADD_THRESHOLD_ON_TEAM, ADD_MESSAGE } from '../actionTypes'
import { fetchAllCustomers } from '../emailActions'
import { addThresholdToTeam } from '../loadActions'

function* workerFetchAndSetAdminCustomers(payload) {
  try { 
    yield put({ type: SET_ADMIN_CUSTOMERS, adminCustomersData: null})
    const response = yield call(fetchAllCustomers, payload.params.rangeStart,payload.params.rangeEnd)
    let adminCustomersData
    if (response) {
        adminCustomersData = response
    } else {
        adminCustomersData = []
    }
    if(adminCustomersData)  yield put({ type: SET_ADMIN_CUSTOMERS, adminCustomersData})
  }
  catch (err) {
    console.log('FETCH_AND_SET_ADMIN_CUSTOMERS failed-->',err)
  }

}

function* workerAddThresholdOnTeamSaga(payload) {
    try {
        let baseRate = payload.payload.baseRate
        let percent = payload.payload.percent
        const source = payload.payload.source
        const teamName = payload.payload.teamName
        if (teamName) {
          const minRate = '100'
          baseRate = parseInt(baseRate)
          percent = parseInt(percent)
          const maxRate = parseInt(baseRate) + ((percent/100) * baseRate)
          // addThresholdToLoad(load.id,parseInt(percent),
          // parseInt(baseRate),source,parseInt(minRate),parseInt(maxRate))
          let response = yield call(addThresholdToTeam, 'load.id',parseInt(percent),
          parseInt(baseRate),source,parseInt(minRate),parseInt(maxRate),teamName)
          if (response && response.data) {
            yield put({ type: ADD_MESSAGE, message: 'Threshold successfully added' })
          } else {
            yield put({type: ADD_MESSAGE, message: `unable to Add Threshold on Loads`})
          }
        }
    } catch (e) {
      yield put({type: ADD_MESSAGE, message: `unable to Add Threshold on Loads`})
    }
  }
  
export function* watchFetchAndSetAdminCustomersSaga() {
    yield takeEvery(FETCH_AND_SET_ADMIN_CUSTOMERS, workerFetchAndSetAdminCustomers)
}

export function* watchAddThresholdOnTeamSaga() {
    yield takeEvery(ADD_THRESHOLD_ON_TEAM, workerAddThresholdOnTeamSaga)
}
