import React, {Component} from 'react'

class DrawerLink extends Component {
    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        const {linkUrl, primaryText, callback} = this.props
        if (callback) {
            callback()
        }
        window.open(linkUrl, `OTR_${primaryText}`)
    }

    render() {
        return(
            <div className={this.props.className || "drawer-link"} onClick={this.handleClick}>
                <div>{this.props.primaryText}</div>
            </div>
        )
    }
}

export default DrawerLink