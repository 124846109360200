import {SET_USER_CALL_DATA} from '../actionTypes'
import {displayDateFromTimestamp, displayTimeFromTimestamp, displayPhoneNumber, displaySecondsAsHHMMDD} from '../../utils/formatters'

const initialState = []

export default (state=initialState,  action) => {
    // console.log('call log reducer. action: ', action) // comment out to suppress pusher console.logs

    let {calls} = action
    
    switch(action.type) {
    case SET_USER_CALL_DATA:
        if (calls && calls.length > 0) {
            let formattedCalls = calls.slice(0)

            // sort calls
            formattedCalls.sort((a,b) => {
                let timestampA = new Date(a.call_begin)
                let timestampB = new Date(b.call_begin)
        
                return timestampB - timestampA
            })

            // format data
            formattedCalls = formattedCalls.map((call)=>{
                return {
                    id: call.id,
                    direction: call.direction,
                    number: displayPhoneNumber(call.external_phone_number),
                    duration: displaySecondsAsHHMMDD(call.duration),
                    call_begin: displayTimeFromTimestamp(call.call_begin),
                    call_date: displayDateFromTimestamp(call.call_date),
                    recording_url: call.recording_url
                }
            })

            return formattedCalls
        }
        else {
            return []
        }
    default:
        return state
    }
}