import { 
    SET_MY_ASSIGNMENTS
    // APPEND_TO_CARRIERS_OWNED
} from '../actionTypes'


const initialState = []

export default (state=initialState,  action) => {
    // let newCarriers = []

    switch(action.type) {
    case SET_MY_ASSIGNMENTS:
        return action.assignments
    default:
        return state
    }
}
