import axios from "axios"

export const fetchTasks = async (status) => {
    // status can either be ACTIVE, COMPLETED, ALL
    let response = await axios({
        method: "get",
        url: "/api/carrier_ops",
        params: {
            status
        }
    })

    const tasks = (response && response.status === 200) ? response.data : []
    return tasks
  }

export const fetchTask = async (taskId) => {
    let response = await axios({
        method: "get",
        url: `/api/carrier_ops/${taskId}`
    })

    const task = (response && response.status === 200) ? response.data : {}
    return task
  }

export const updateCarrierOpsTask = async (task_id, updateData) => {
    let response = await axios({
        url: `/api/carrier_ops/${task_id}`,
        method: 'put',
        data: updateData
    })

    const task = (response && response.status === 200) ? response.data : {}
    return task
}

export const getTaskLoadInfo = async (task_id) => {
    return axios({
        url: `/api/carrier_ops/${task_id}`,
        method: 'get'
    })
}


export const fetchIncomingTextByLoad = (load_id) => {
    return axios({
        method: "get",
        url: `/api/loads/${load_id}/sms_messages`,
        data: {
          loadId : load_id
        }
    });
  }

export const sendTextByLoad = (load_id, phone, message) => {
    return axios({
        method: "post",
        url: `/api/loads/${load_id}/sms_messages`,
        data: {
            load_id : load_id,
            phone: phone,
            message: message
        }
    });
  }


export const sendMmsByLoad = (load_id, phone, file) => {
    const formData = new FormData()
    formData.append('phone', phone)
    formData.append('file', file, file.name)
    return axios.post(`/api/loads/${load_id}/sms_messages`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const fetchAttachment = async (messageId, attachmentId) => {
    let response = await axios({
        method: "get",
        url: `/api/sms_messages/${messageId}/attachments/${attachmentId}`
    })

    const attachment = (response && response.status === 200) ? response.data : {}
    return attachment
}

export const fetchSmsNotifications = async (user_id) => {
    const response = await axios({
        method: "get",
        url: `/api/users/${user_id}/sms_notifications`
    })

    const notifications = (response && response.status === 200) ? response.data : []
    return notifications
}

export const markSmsNotificationRead = async(notification_id, user_id) => {
    const response = await axios({
        method: "patch",
        url: `/api/users/${user_id}/sms_notifications/${notification_id}`
    })

    const result = (response && response.status === 200) ? true : false
    return result   
}

export const fetchAllTasksByLoadId = (load_id) => {
    return axios({
        method: "get",
        url: `/api/loads/${load_id}/carrier_ops`
    })
}

export const fetchActiveTaskByLoadId = (load_id) => {
    return axios({
        method: "get",
        url: `/api/loads/${load_id}/carrier_ops?active=true`
    })
}

export const assignTasksToUser = (task_ids, user_id) => {
    return axios({
        method: 'post',
        url: `/api/users/${user_id}/carrier_ops_tasks`,
        data: task_ids

    })
}

export const unassignTasks = (task_ids) => {
    return axios({
        method: 'delete',
        url: `/api/users/NO_USER_ID/carrier_ops_tasks`,
        data: task_ids
    })
}

export const fetchLoadTasksByFilter = (filter) => {
    const task_names = (filter?.task_names?.length) ? filter.task_names.join(',') : null
    const params = {}
    if (task_names) {
        params.task_names = task_names
    }
    if (filter?.status?.length) {
        params.statuses = filter.status.join(',')
    } else {
        params.statuses = 'Active'
    }
    if (filter?.assigned) {
        switch (filter?.assigned) {
            case 'My Tasks':
                params.assigned = 'my_tasks'
            break
            case 'Assigned':
                params.assigned = 'true'
            break
            case 'UnAssigned':
                params.assigned = 'false'
            break
        default:
            // ignore default
        }
    }

    if (filter?.day?.length) {
        params.day = filter.day
    }
    
    return axios({
        method: 'get',
        url: '/api/carrier_ops',
        params
    })
}

export const fetchLoadTaskQuestions = () => {
    return axios({
        method: 'get',
        url: '/api/load_task_questions'
    })
}