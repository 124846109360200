import { takeLatest, takeEvery, call, put, delay, select } from 'redux-saga/effects'
import {
    FETCH_MY_CUSTOMER_ASSIGNMENTS,
    FETCH_CUSTOMER_BY_ID,
    FETCH_CUSTOMERS_WITH_FILTER,
    CUSTOMER_SELECTED,
    SET_MY_ASSIGNMENTS,
    SET_SELECTED_CUSTOMER,
    ADD_MESSAGE,
    DELETE_SELECTED_CUSTOMER_CONTACT,
    UPDATE_SELECTED_CUSTOMER_CONTACTS_LIST_AFTER_DELETE,
    DELETE_SELECTED_CUSTOMER_NOTE,
    UPDATE_SELECTED_CUSTOMER_NOTES_LIST_AFTER_DELETE,
    UPDATE_RECENT_CUSTOMER_ITEM,
    SET_CUSTOMER_SEARCH_LIST_TO_RECENT_CUSTOMERS,
    SET_CUSTOMER_SEARCH_LIST,
    DELETE_CUSTOMER_ASSIGNMENT,
    ADD_CUSTOMER_ASSIGNMENT,
    SAVE_CUSTOMER_FOLDER_UPDATES,
    SET_LOADS,
    FETCH_CUSTOMERS_WITH_FILTER_AND_LOADS,
    FETCH_CUSTOMER_LANES,
    SET_CUSTOMER_LANES,
    SET_CUSTOMER_CONTACTS,
    FETCH_CUSTOMER_CONTACTS,
    FETCH_SF_ACCOUNT_BY_ALJEX_ACCOUNT_NUMBER,
    UPDATE_SELECTED_CUSTOMER_SF_ID,
    SET_CUSTOMER_FACILITES,
    SET_CUSTOMER_CUSTOMERS,
    SET_CUSTOMER_LUMPER_DATA
} from '../actionTypes'

import {
    fetchCustomersWithFilter,
    fetchMyCustomerAssignments,
    fetchCustomer,
    deleteCustomerContact,
    deleteCustomerNote,
    deleteCustomerAssignment,
    addCustomerAssignment,
    fetchCustomerByAljex,
    saveFolderUpdates,
    fetchCustomerLoads,
    fetchCustomersWithFilterAndLoads,
    fetchCustomerLanes,
    fetchCustomerContacts,
    fetchSfAccountByAljexAccountNumber,
    fetchCustomerFacilities,
    fetchCustomerCustomers,
    fetchCustomerLumperData
} from '../customerActions'

import { 
    getCurrentUser,
    getMyAssignments,
    getRecentCustomers,
} from '../reducers/rootReducer'

import _ from 'lodash'

const openLinkInNewTab = (link) => {
    if (link && window?.open) {
        window.open(link, '_blank')
    }
}

function* refreshRecentCustomersList(customerId) {
    console.log('refreshRecentCustomersList() - customerId: ', customerId)
    // Update Recent List because Icon should change
    const customerResult = yield call(fetchCustomer, customerId)
    yield put({ type: UPDATE_RECENT_CUSTOMER_ITEM, customer: customerResult.data})

    // // The above isn't enough to make react rerender so let's force it.
    const recentCustomers = yield select(getRecentCustomers)
    yield put({ type: SET_CUSTOMER_SEARCH_LIST_TO_RECENT_CUSTOMERS, recentCustomers: null})
    yield put({ type: SET_CUSTOMER_SEARCH_LIST_TO_RECENT_CUSTOMERS, recentCustomers: recentCustomers})
}


/*
  OK lets start here.  This is kinda messy.

  Fetch customer, if we found one, then
  Fetch the customer loads.  Not sure what all this truck stuff is
*/
function* workerFetchCustomerByIDSaga(payload) {
    const {id} = payload
    console.log('fetch customer by id ', payload)
    try {
        yield put({type: SET_LOADS, loads: null})
        yield put({type: SET_CUSTOMER_CONTACTS, contacts: null})
        yield put({type: SET_CUSTOMER_LUMPER_DATA, customerLumperData: null})

        const response = yield call(fetchCustomerByAljex, id)

        if (response.data) {
            const customer = response.data

            yield put({type: SET_SELECTED_CUSTOMER, selectedCustomer: customer})
            if (! payload.suppress_loads) {
                const customerLoadsResponse = yield call(fetchCustomerLoads, customer.id)
    
                // using loads reducer.  a load is a load is a load :-)
                yield put({type: SET_LOADS, loads: customerLoadsResponse.data})
            }

            if (payload.fetch_facilities) {
                const customerFacilitiesResponse = yield call(fetchCustomerFacilities, customer.id)
                yield put({type: SET_CUSTOMER_FACILITES, facilities: customerFacilitiesResponse.data})
            }

            if (payload.fetch_customers) {
                const customerCustomersResponse = yield call(fetchCustomerCustomers, customer.id)
                yield put ({type: SET_CUSTOMER_CUSTOMERS, customers: customerCustomersResponse.data})
            }

            yield put ({type: FETCH_CUSTOMER_CONTACTS, id: customer.id })

            const lumperDataResponse = yield call(fetchCustomerLumperData, customer.id)
            yield put({type: SET_CUSTOMER_LUMPER_DATA, customerLumperData: lumperDataResponse?.data || []})

        } else {
            yield put({type: ADD_MESSAGE, message: 'No matching customer found!'})
        }
    } catch (error) {
        console.log('workerFetchCustomerByIDSaga error: ', error)
        yield put({type: SET_LOADS, loads: []})
    }
}

function* workerFetchCustomersWithFilterSaga(action) {
    try {
        yield put({ type: SET_CUSTOMER_SEARCH_LIST, customers: null})
        const response = yield call(fetchCustomersWithFilter, action.payload)

        if (response) {
            const customers = response.data

            yield put({ type: SET_CUSTOMER_SEARCH_LIST, customers})

            if (customers.length === 1) {

                // here fetch the customer
                const newAction = {...action}
                delete newAction.payload
                delete newAction.type
                newAction.id = customers[0].aljex_account_number

                console.log('fetch returned 1 customer', {type: FETCH_CUSTOMER_BY_ID, ...newAction})
                yield put({type: FETCH_CUSTOMER_BY_ID, ...newAction})

                // // note search no longer does an eager on customer model, so we need to refetch the customer
                // // but it is by id, so the query is fast
                // const customerId = customers[0].id
                // const customerResponse = yield call(fetchCustomer, customerId)
                // const customer = customerResponse.data

                // yield put({type: SET_SELECTED_CUSTOMER, selectedCustomer: customer})

                // yield put({type: SET_LOADS, loads: null})
                // yield put({type: SET_CUSTOMER_CONTACTS, contacts: null})
                // delay(200)

                // const customerLoadsResponse = yield call(fetchCustomerLoads, customer.id)
                // // using loads reducer.  a load is a load is a load :-)
                // yield put({type: SET_LOADS, loads: customerLoadsResponse.data})
                // yield put({type: FETCH_CUSTOMER_CONTACTS, id: customer.id})
            }

            if (customers.length === 0) {
                yield put({type: ADD_MESSAGE, message: 'No matching customers found!'})
            }
        }

    } catch (error) {
        console.log('workerFetchCustomersWithFilterSaga error: ', error)
        yield put({type: SET_LOADS, loads: []})
    }
}


function* workerFetchCustomersWithFilterAndLoadsSaga(action) {
    try {
        yield put({ type: SET_CUSTOMER_SEARCH_LIST, customers: null})
        const response = yield call(fetchCustomersWithFilterAndLoads, action.payload)
        if (response) {
            const customers = response.data
            
            yield put({ type: SET_CUSTOMER_SEARCH_LIST, customers})

            if (customers.length === 1) {
                // note search no longer does an eager on customer model, so we need to refetch the customer
                // but it is by id, so the query is fast
                const customerId = customers[0].id
                const customerResponse = yield call(fetchCustomer, customerId)
                const customer = customerResponse.data
                yield put({type: SET_SELECTED_CUSTOMER, selectedCustomer: customer})

                yield put({type: SET_LOADS, loads: null})
                yield put({type: SET_CUSTOMER_CONTACTS, contacts: null})
                delay(200)

                const customerLoadsResponse = yield call(fetchCustomerLoads, customer.id)

                // using loads reducer.  a load is a load is a load :-)
                yield put({type: SET_LOADS, loads: customerLoadsResponse.data})
                yield put({type: FETCH_CUSTOMER_CONTACTS})
            }

            if (customers.length === 0) {
                yield put({type: ADD_MESSAGE, message: 'No matching customers found!'})
            }
        }

    } catch (error) {
        console.log('workerFetchCustomersWithFilterSaga error: ', error)
        yield put({type: SET_LOADS, loads: []})
    }
}


function* workerFetchMyCustomerAssignmentsSaga() {
    try {
        const response = yield call(fetchMyCustomerAssignments)
        if (response) {
            const assignments = response.data

            yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
            yield put({ type: SET_MY_ASSIGNMENTS, assignments: assignments})
        }
    } catch(err) {
        console.log('workerFetchMyCustomerAssignmentsSaga() error: ', err)
    }
}

function* workerCustomerSelectedSaga(payload) {

    try {
        const customerId = _.get(payload, 'customerAssignment.customer_id', null) ? _.get(payload, 'customerAssignment.customer_id', null) : _.get(payload, 'selectedCustomer.id', null)
        yield put({type: SET_CUSTOMER_CONTACTS, contacts: null})
        yield put({type: SET_CUSTOMER_LUMPER_DATA, customerLumperData: []})

        const response = yield call(fetchCustomer, customerId)
        const customer = response.data

        yield put({type: SET_SELECTED_CUSTOMER, selectedCustomer: customer})

        yield put({type: SET_LOADS, loads: null})
        delay(200)
        const customerLoadsResponse = yield call(fetchCustomerLoads, customer.id)

        // using loads reducer.  a load is a load is a load :-)
        yield put({type: SET_LOADS, loads: customerLoadsResponse.data})

        // yield put({type: SET_CUSTOMER_LANES, lanes: []})

        yield put ({type: FETCH_CUSTOMER_CONTACTS, id: customerId })
        const lumperDataResponse = yield call(fetchCustomerLumperData, customer.id)
        yield put({type: SET_CUSTOMER_LUMPER_DATA, customerLumperData: lumperDataResponse?.data || []})
    } catch (err) {
        console.log('workerCustomerSelectedSaga error: ', err)
        yield put({type: SET_LOADS, loads: []})
    }
}

function* workerFetchCustomerContacts(action) {
    const contacts = yield(call(fetchCustomerContacts, action?.id ? action.id : []))
    yield put( {type: SET_CUSTOMER_CONTACTS, contacts})
}


function* workerDeleteSelectedCustomerContact(action) {
    yield call(deleteCustomerContact, {customerId: action.selectedCustomer.id, contactId: action.contactId})
    yield put({ type: UPDATE_SELECTED_CUSTOMER_CONTACTS_LIST_AFTER_DELETE, selectedCustomer: action.selectedCustomer, contactId: action.contactId })
}

function* workerDeleteSelectedCustomerNote(action) {
    yield call (deleteCustomerNote, {customerId: action.selectedCustomer.id, noteId: action.noteId})
    yield put({ type: UPDATE_SELECTED_CUSTOMER_NOTES_LIST_AFTER_DELETE, selectedCustomer: action.selectedCustomer, noteId: action.noteId })
}

function* workerDeleteCustomerAssignment(action) {
    const assignment = action.payload
    const result = yield call(deleteCustomerAssignment, {assignment: assignment})

    if (result && result.data) {
        // Fetch My Customers
        // NOTE:  THIS Works, but let's not call OTTER.  Let's just manually remove from list instead
        // const response = yield call(fetchMyCustomerAssignments)
        // let myCustomers = response.data
        // let myCustomersUpdated = myCustomers
        // console.log('myCustomers: ', myCustomers)

        // Get My Customers list
        let myCustomers = yield select(getMyAssignments)

        // Remove from all lists
        for (let group of Object.keys(myCustomers)) {
            for(let listName of Object.keys(myCustomers[group])) {
                myCustomers[group][listName]['assignments'] = myCustomers[group][listName]['assignments'].filter(a => a.id !== result.data.id)
            }
        }
    
        // Update the store
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: myCustomers})

        // Refresh Recent customers list
        yield call(refreshRecentCustomersList, result.data.customer_id)
       
    }
}

function* workerAddCustomerAssignment(action) {    
    // Add assignment to DB
    let assignment = Object.assign({}, action.payload)
    const result = yield call(addCustomerAssignment, {assignment: assignment})
  
    const currentUser = yield select(getCurrentUser)

    if (currentUser.user_id === assignment.user_id) {
        // OLD WAY
        // Append to Lead or Owned list
    
        // const appendActionType = assignment.assignment_type === 'OWNERSHIP' ? APPEND_TO_CUSTOMERS_OWNED : APPEND_TO_CUSTOMER_LEADS
        // yield put({type: appendActionType, assignment: result.data.assignment})

        // Add to default list
        
        // Get assignments
        let myCustomers = yield select(getMyAssignments)

        let addToThisList = null

        if (assignment.assignment_type === 'OWNERSHIP') {
            addToThisList = myCustomers['default']['defaultOwnership']['assignments']

            //remove from lead list if there
            myCustomers['default']['defaultLeads']['assignments'] = myCustomers['default']['defaultLeads']['assignments'].filter(a => {
                if (a.customer_id !== result.data.assignment.customer_id || a.user_id !== result.data.assignment.user_id) {
                    return true
                } else {
                    return false
                }
            })
        } else {
            addToThisList = myCustomers['default']['defaultLeads']['assignments']
        }

        addToThisList.push(result.data.assignment)
        

        // Update the store
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
        yield put({ type: SET_MY_ASSIGNMENTS, assignments: myCustomers})
    }
    
    // Refresh Recent customers list
    yield call(refreshRecentCustomersList, result.data.customer.id)

    // TODO: Possible refresh selected customer here if owner changed.  need to reflect in details
    // let customer = yield call(getSelectedCustomer)
    // yield put({type: SET_SELECTED_CUSTOMER, selectedCustomer: result.data.customer})
}

function* workerSaveFolderUpdates(action) {
    const result = yield call(saveFolderUpdates, action.updates)
    const myCustomers = result.data

    // Update the store
    yield put({ type: SET_MY_ASSIGNMENTS, assignments: null})
    yield put({ type: SET_MY_ASSIGNMENTS, assignments: myCustomers})
}

function* workerFetchCustomerLanes(action) {
    const {customer_id} = action

    if (customer_id) {
        const results = yield call(fetchCustomerLanes, customer_id)

        yield put({type: SET_CUSTOMER_LANES, lanes: results.data || []})
    }
}

function* workerFetchSfAccountByAljexAccountNumber(action) {
    const result = yield call(fetchSfAccountByAljexAccountNumber, action.aljexAccountNumber)
    const sfId = result?.data?.Id
    const sfLink =  `https://otr.lightning.force.com/lightning/r/Account/${sfId}/view`
    
    if (sfId) {
        yield put({type: UPDATE_SELECTED_CUSTOMER_SF_ID, selectedCustomer: action.customer, sfId: sfId })
        if (window?.open) {
            yield call(openLinkInNewTab, sfLink)
        }
    } else {
        yield put({type: UPDATE_SELECTED_CUSTOMER_SF_ID, selectedCustomer: action.customer, sfId: '' })
    }
}

export function* watchFetchCustomerByIDSaga() {
    yield takeLatest(FETCH_CUSTOMER_BY_ID, workerFetchCustomerByIDSaga)
}

export function* watchFetchCustomersWithFilterSaga() {
    yield takeLatest(FETCH_CUSTOMERS_WITH_FILTER, workerFetchCustomersWithFilterSaga)
}

export function* watchFetchCustomersWithFilterAndLoadsSaga() {
    yield takeLatest(FETCH_CUSTOMERS_WITH_FILTER_AND_LOADS, workerFetchCustomersWithFilterAndLoadsSaga)
}

export function* watchSelectedCustomerSaga() {
    yield takeLatest(CUSTOMER_SELECTED, workerCustomerSelectedSaga)
}

export function* watchFetchMyCustomerAssignmentsSaga() {
    yield takeLatest(FETCH_MY_CUSTOMER_ASSIGNMENTS, workerFetchMyCustomerAssignmentsSaga)
}



export function* watchDeleteSelectedCustomerContact() {
    yield takeEvery(DELETE_SELECTED_CUSTOMER_CONTACT, workerDeleteSelectedCustomerContact)
}

export function* watchDeleteSelectedCustomerNote() {
    yield takeEvery(DELETE_SELECTED_CUSTOMER_NOTE, workerDeleteSelectedCustomerNote)
}


export function* watchDeleteCustomerAssignment() {
    yield takeEvery(DELETE_CUSTOMER_ASSIGNMENT, workerDeleteCustomerAssignment)
}

export function* watchAddCustomerAssignment() {
    yield takeEvery(ADD_CUSTOMER_ASSIGNMENT, workerAddCustomerAssignment)
}

export function* watchSaveCustomerFolderUpdatesSaga() {
    yield takeEvery(SAVE_CUSTOMER_FOLDER_UPDATES, workerSaveFolderUpdates)
}

export function* watchFetchCustomerLanesSaga() {
    yield takeLatest(FETCH_CUSTOMER_LANES, workerFetchCustomerLanes)
}

export function* watchFetchCustomerContacts() {
    yield takeLatest(FETCH_CUSTOMER_CONTACTS, workerFetchCustomerContacts)
}

export function* watchFetchSfAccountByAljexAccountNumber() {
    yield takeLatest(FETCH_SF_ACCOUNT_BY_ALJEX_ACCOUNT_NUMBER, workerFetchSfAccountByAljexAccountNumber)
}