import { takeEvery, call, put, select } from "redux-saga/effects"
import { SELECTED_TRUCK_ORIGIN_SELECTED, SELECTED_TRUCK_DESTINATION_SELECTED, SET_SELECTED_TRUCK_ORIGIN, SET_SELECTED_TRUCK_DESTINATION} from '../actionTypes'
import { getSelectedLoad } from '../reducers/rootReducer'
import { geocodeFromGooglePlace } from '../../utils/location-utils'
import _ from 'lodash'
import axios from 'axios'

const fetchMiles = async ({origin_city, origin_state, destination_city, destination_state}) => {
    const queryString = `origin_city=${origin_city}&origin_state=${origin_state}&destination_city=${destination_city}&destination_state=${destination_state}`
    const result = await axios.get('/api/google/distance?' + queryString)
    return result.data.distance
}

function* workerSelectedTruckOriginSelected(action) {
    const truckLocationCity = _.get(action, 'googlePlace.terms[0].value', '')
    const truckLocationState = _.get(action, 'googlePlace.terms[1].value', '')
    const googlePlace = _.get(action, 'googlePlace', null)
    
    let truckLocationLat = null
    let truckLocationLng = null

    if (googlePlace && googlePlace.description) {
        const result = yield call(geocodeFromGooglePlace, googlePlace)
        truckLocationLat = _.get(result, 'data.Coords.Lat', null)
        truckLocationLng = _.get(result, 'data.Coords.Lon', null)
    }

    // get selected load
    const selectedLoad = yield select(getSelectedLoad)

    // fetch miles from otter
    const deadhead = yield call(fetchMiles, {
        origin_city: truckLocationCity,
        origin_state: truckLocationState,
        destination_city: selectedLoad.origin_city,
        destination_state: selectedLoad.origin_state
    })

    const selectedTruckUpdate = {
        city: truckLocationCity,
        state: truckLocationState,
        lat: truckLocationLat,
        lng: truckLocationLng,
        deadhead: deadhead,
        originSelected: true,
        notes: '',
        available_date: ''  
    }
    
    yield put({ type: SET_SELECTED_TRUCK_ORIGIN, selectedTruckUpdate})
}

function* workerSelectedTruckDestinationSelected(action) {
    const truckDestinationCity = _.get(action, 'googlePlace.terms[0].value', '')
    const truckDestinationState = _.get(action, 'googlePlace.terms[1].value', '')
    const googlePlace = _.get(action, 'googlePlace', null)
    
    let truckLocationLat = null
    let truckLocationLng = null

    if (googlePlace && googlePlace.description) {
        const result = yield call(geocodeFromGooglePlace, googlePlace)
        truckLocationLat = _.get(result, 'data.Coords.Lat', null)
        truckLocationLng = _.get(result, 'data.Coords.Lon', null)
    }

    // get selected load
    const selectedLoad = yield select(getSelectedLoad)
  
    // fetch miles from otter
    const deadhead = yield call(fetchMiles, {
        origin_city: selectedLoad.destination_city,
        origin_state: selectedLoad.destination_state,
        destination_city: truckDestinationCity,
        destination_state: truckDestinationState
    })

    const selectedTruckUpdate = {
        destination_city: truckDestinationCity,
        destination_state: truckDestinationState,
        destination_lat: truckLocationLat,
        destination_lng: truckLocationLng,
        destination_deadhead: deadhead,
        destinationSelected: true,
        notes: '',
        available_date: '' 
    }

    yield put({ type: SET_SELECTED_TRUCK_DESTINATION, selectedTruckUpdate})
}

export function* watchTruckOriginSelectedSaga() {
    yield takeEvery(SELECTED_TRUCK_ORIGIN_SELECTED, workerSelectedTruckOriginSelected)
}

export function* watchTruckDestinationSelectedSaga() {
    yield takeEvery(SELECTED_TRUCK_DESTINATION_SELECTED, workerSelectedTruckDestinationSelected)
}