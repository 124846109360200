
import {TRUCK_STREAM_ON, TRUCK_STREAM_OFF, EXPIRE_MATCHED_TRUCK_FROM_STREAM, 
    TRUCK_STREAM_LISTEN, TRUCK_STREAMING_HALT, DELETE_MATCHED_TRUCK_FROM_STREAM,
    EXPIRE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM,
    DELETE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM,
    TASK_SCREEN_TRUCK_STREAM_LISTEN} from '../actions/actionTypes'



const truckStreamingMiddleware = () => {
    let eventSource = null
    let dispatchType = null
    let selectedLoad = null

    const onOpen = store => (event) => {
        console.log("stream open")
        store.dispatch({type: TRUCK_STREAM_ON})

    }


    const onError = store => (event) => {
        console.log("error on stream")
        console.log(event)
        store.dispatch({type: TRUCK_STREAM_OFF})
    }
    const truckAdded = store => (event) => {
        const truck = JSON.parse(event.data)
        // here we want to dispatch out to the new
        store.dispatch({type: dispatchType, truck, selectedLoad})

    }

    const truckExpired = store => (event) => {
        const truck = JSON.parse(event.data)
        // here we want to dispatch out to the new
        store.dispatch({type: EXPIRE_MATCHED_TRUCK_FROM_STREAM, truck})
    }

    const truckDeleted = store => (event) => {
        const truck = JSON.parse(event.data)
        store.dispatch({type: DELETE_MATCHED_TRUCK_FROM_STREAM, truck})
    }

    const truckExpiredTask = store => (event) => {
        const truck = JSON.parse(event.data)
        // here we want to dispatch out to the new
        store.dispatch({type: EXPIRE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM, truck})
    }

    const truckDeletedTask = store => (event) => {
        const truck = JSON.parse(event.data)
        store.dispatch({type: DELETE_TASK_SCREEN_MATCHED_TRUCK_FROM_STREAM, truck})
    }
    return store => next => action => {
        switch (action.type) {
            case TRUCK_STREAM_LISTEN:
                if (eventSource !== null) {
                    eventSource.close()
                    eventSource = null
                }

                if (action.stream_uri) {
                    const url = (action.host && action.host !== "") ? `${action.host}${action.stream_uri}` : action.stream_uri
                    eventSource = new EventSource(url)
                    eventSource.onopen = onOpen(store)
                    eventSource.onerror = onError(store)
                    eventSource.addEventListener('truck', truckAdded(store), false)
                    eventSource.addEventListener('truck-expire', truckExpired(store), false)
                    eventSource.addEventListener('truck-delete', truckDeleted(store), false)


                    //TODO: do an extra check to see if already open and if so then just send the update filter
                    dispatchType = action.dispatchType
                    selectedLoad = action.selectedLoad

                    store.dispatch({type: TRUCK_STREAM_ON})
                }
                break
            case TASK_SCREEN_TRUCK_STREAM_LISTEN:
                if (eventSource !== null) {
                    eventSource.close()
                    eventSource = null
                }
                let urlTask = (action.host && action.host !== "") ? `${action.host}${action.stream_uri}` : action.stream_uri
                eventSource = new EventSource(urlTask)
                eventSource.onopen = onOpen(store)
                eventSource.onerror = onError(store)
                eventSource.addEventListener('truck', truckAdded(store), false)
                eventSource.addEventListener('truck-expire', truckExpiredTask(store), false)
                eventSource.addEventListener('truck-delete', truckDeletedTask(store), false)


                //TODO: do an extra check to see if already open and if so then just send the update filter
                dispatchType = action.dispatchType
                selectedLoad = action.selectedLoad

                store.dispatch({type: TRUCK_STREAM_ON})

                break
            case TRUCK_STREAMING_HALT:
                if (eventSource !== null) {
                    eventSource.close()
                }
                eventSource = null
                dispatchType = null
                store.dispatch({type: TRUCK_STREAM_OFF})
                break
            default:
                return next(action)
        }
    }
}


export default truckStreamingMiddleware()