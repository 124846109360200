import { SET_DAT_RATES } from '../actionTypes'
// import moment from 'moment'
// import _ from 'lodash'

const initialState = null

export default (state=initialState,  payload) => {
    switch(payload.type) {

    case SET_DAT_RATES:
        return payload.datRates || null
    
    default:
        return state
    }
}