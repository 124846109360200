import { ADD_MESSAGE, REMOVE_MESSAGE } from '../actionTypes'

const initialState = []


//TODO: change this from error to message or notify

export default (state=initialState,  action) => {
    switch(action.type) {
        case ADD_MESSAGE:
            let messages = state.slice()
            messages.push({text: action.message})
            return messages

        case REMOVE_MESSAGE: 
            return []
        default:
            return state
     }
}
