import React, {Component} from 'react'
import {connect} from 'react-redux'
import {LOGIN_USER} from '../actions/actionTypes'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCogs } from '@fortawesome/free-solid-svg-icons';
import GoogleLogin from 'react-google-login';

class Login extends Component {
    constructor(props) {
        super(props)
        this.googleResponse = this.googleResponse.bind(this)
        this.handleOnFailure = this.handleOnFailure.bind(this)
    }

    handleOnFailure(error) {
       console.log('failure on google', error)
    }

    googleResponse(response) {
        // here we want to pass this on to the sagas
        //console.log("got a response from google", response)
        this.props.authenticateUser(response.accessToken)
    }

    //TODO inject the google id in the build process
    render() {
        return (
            <div className="login-main">
                <div>
                    <div className="login-main-title"> <FontAwesomeIcon icon={faCogs}/> Gear Jammer</div>
                    <div>Click the Google Login Button below to get started.</div>
                </div>
                    <GoogleLogin
                        render={renderProps => (
                            <button className="login-main-button" onClick={renderProps.onClick}>Login</button>
                            )}
                        clientId="312757062424-1aams9vg13vu5uv3lle3h59qbf7b4e20.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={this.googleResponse}
                        onFailure={this.handleOnFailure}
                    />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        authenticateUser: (accessToken) => dispatch({ type: LOGIN_USER, accessToken })
    }
}


export default connect(null, mapDispatchToProps)(Login)