
import axios from "axios"

export const deleteMultipleTrucks = async (trucks) => {
    if (trucks && trucks.length) {
        const trucksData = trucks.map(t =>  {
            let truck = {
                id: t?.truck_id ? t?.truck_id : t?.id
            }
            
            return truck
        })
    
        let result = null 

        await axios({
            method: 'delete',
            url: `/api/trucks/delete_multiple_trucks`,
            data: trucksData
        }).then((response)=>{
            result = response
        })
    
        return result
    }
}

  
export const fetchLoadsNearTruck = async(truck) => {
    return await axios({
        method: 'post',
        url: `api/trucks/fetchLoadsNearTruck`,
        data: truck
    })
}

export const fetchReturnLoads = async(truck) => {
    return await axios({
        method: 'post',
        url: `api/trucks/fetchLoadsNearTruck`,
        data: truck
    })
}

export const postTruckScheduleRefresh = async(truckSchedule) => {
    return await axios({
        method: "post",
        url: `/api/truck_schedules/${truckSchedule.id}/refresh`
    })
}