import { takeEvery, call, put, select } from "redux-saga/effects"
import {ADD_BID, ADD_MESSAGE, BID_ADDED_EVENT, SET_SELECTED_LOAD, 
    SET_LOADS, UPDATE_MATCHED_TRUCKS_WITH_BID, UPDATE_MATCHED_LEADS_WITH_BID, 
    SET_SELECTED_LEAD, ADD_LEAD_BID, SET_ALL_CONNECT_LOGS, 
    UPDATE_CARRIER_BIDS} from '../actionTypes'
import {getSelectedLoad, getLoads, getSelectedLead, getAllConnectLogs, getSelectedCarrier} from '../reducers/rootReducer'
import axios from "axios"
import _ from 'lodash'
import {addTruckContact} from '../../utils/dat-truck-utils'

// watcher saga: watches for actions dispatched to the store, starts worker saga

// function that makes the api request and returns a Promise for response
function addBid(bid,leadB) {
    return axios({
        method: "post",
        url: "/api/bids",
        data: bid
    })
}

function* workerUpdateSelectedLoadBids({selectedLoad, newBid},lead) {
    console.log('processing new bid', newBid)
    if (selectedLoad && (newBid.load_id === selectedLoad.id)) {
        // update the selected load with the additional bid
        const updatedLoad = {...selectedLoad}
        
        const bids = (updatedLoad.bids && updatedLoad.bids.length) ? [...updatedLoad.bids] : []
        const bidIndex = bids.findIndex(b => b.id === newBid.id)

        if (bidIndex === -1) { 
            bids.push(newBid)
        } else {
            bids[bidIndex] = newBid
        }


        updatedLoad.bids = bids
        // updated lowest bid
        yield put ({type: SET_SELECTED_LOAD, selectedLoad: updatedLoad})
        if (lead) {
            yield put ({type: SET_SELECTED_LEAD, selectedLead: updatedLoad})
        }
    }
}

function* workerBidAddedEvent(action) {
    try {
        const { bid } = action
        const selectedLoad = yield select(getSelectedLoad)
        const {selectedLead} = yield select(getSelectedLead)
        if (selectedLoad && (selectedLoad.id === bid.load_id)) {
            let updateArgs = {
                selectedLoad: selectedLoad,
                newBid: bid
            }
            
            yield call(workerUpdateSelectedLoadBids, updateArgs)
            //OK, the bid is coming in from the selected load, so we need to mark all matching trucks / carriers
            //    with this bid
            yield put({type: UPDATE_MATCHED_TRUCKS_WITH_BID, bid})
    
    
        }
    
        if(selectedLead && (selectedLead?.carrier.mc === bid.carrier.mc)){
            let updateArgs = {
                selectedLead: selectedLead,
                newBid: bid
            }
            yield call(workerUpdateSelectedLoadBids, updateArgs,true)
    
            //OK, the bid is coming in from the selected load, so we need to mark all matching trucks / carriers
            //    with this bid
            yield put({type: UPDATE_MATCHED_LEADS_WITH_BID, bid})
    
        }
    
        //here we want to check if the load is in our list.  If it is, then we want to update the lowest_bid if it is the lowest bid
        if (bid.status === 'BID') {
            let loads = yield select(getLoads)
            loads = [...loads]
    
            let load = loads.find(l => l.id === bid.load_id)
            if (load) {
    
                //TODO: update the bid counts here
    
                //check if there is a lower bid
                if (load.lowest_bid === null || (parseFloat(bid.ask_price) < parseFloat(load.lowest_bid))) {
                    load = {...load, lowest_bid: bid.ask_price, updated_at: bid.updated_at}
                    //remove the old load and update the new one
                    loads = loads.filter(l => l.id !== bid.load_id)
                    loads.push(load)
                    yield put({type: SET_LOADS, loads: loads})
                }
            }
        }
    }
    catch(err) {
        console.log('BID added event failed workerBidAddedEvent()-->',err)
    }
}

function* workerAddBid(action) {
    try {
        const { truck } = action.payload
        const response = yield call(addBid, action.payload.bidData)
        const bid = _.get(response, 'data', null)
        

        if (bid) {
            // Check to see if selectedLoad already has this bid. If not, add it.
            const selectedLoad = yield select(getSelectedLoad)

            if (selectedLoad) {
                if (!selectedLoad.bids.find(b => b.id === bid.id)) {
                    const updateArgs = {
                        selectedLoad: selectedLoad,
                        newBid: bid
                    }
                    yield call(workerUpdateSelectedLoadBids, updateArgs)
                }
            }

            const selectedCarrier = yield select(getSelectedCarrier)
            if (selectedCarrier) {
                yield put({type: UPDATE_CARRIER_BIDS, bid})
            }

            if (truck) {
                yield call(addTruckContact, truck)
            }

            yield put({ type: ADD_MESSAGE, message: 'Bid successfully added' })
            if (action.payload.bidData.connect) {
                let allConnectLogs = yield select(getAllConnectLogs)
                allConnectLogs.splice(allConnectLogs.findIndex(item => 
                    ((item.data.load_id === action.payload.bidData.load_id) &&
                    (item.data.carrier_id === action.payload.bidData.carrier_id))
                    ), 1)
                yield put({ type: SET_ALL_CONNECT_LOGS, allConnectLogs })
            }
        } else {
            yield put({ type: ADD_MESSAGE, message: 'Bid unsuccessful' })
        }
    } catch (error) {
        // TODO: dispatch a failure action to the store with the error
        yield put({ type: ADD_MESSAGE, message: 'Bid unsuccessful' })
    }
}

function* workerAddLeadBid(action){
    try {

        const response = yield call(addBid, action.payload.bidData,action.payload?.leadB)
        let bid = _.get(response, 'data', null)
        if (bid) {
            // Check to see if selectedLoad already has this bid. If not, add it.
            const selectedLoad = yield select(getSelectedLoad)

            if (selectedLoad) {
                if (!selectedLoad.bids.find(b => b.id === bid.id)) {
                    const updateArgs = {
                        selectedLoad: selectedLoad,
                        newBid: bid
                    }
                    yield call(workerUpdateSelectedLoadBids, updateArgs)
                }
            }

            yield put({ type: ADD_MESSAGE, message: 'Bid successfully added' })
        } else {
            yield put({ type: ADD_MESSAGE, message: 'Bid unsuccessful' })
        }
    } catch (error) {
        // TODO: dispatch a failure action to the store with the error
        yield put({ type: ADD_MESSAGE, message: 'Bid unsuccessful' })
    }
}

export function* watchAddBidSaga() {
    yield takeEvery(ADD_BID, workerAddBid)
}

export function* watchAddLeadBidSaga() {
    yield takeEvery(ADD_LEAD_BID, workerAddLeadBid)
}

export function* watchBidAddedEventSaga() {
    yield takeEvery(BID_ADDED_EVENT, workerBidAddedEvent)
}