import { 
    SET_SELECTED_CARRIER, UPDATED_SELECTED_CARRIER_CONTACT, UPDATE_SELECTED_CARRIER_CONTACTS_LIST_AFTER_DELETE, 
    UPDATE_SELECTED_CARRIER_NOTES_LIST_AFTER_DELETE, UPDATED_SELECTED_CARRIER_NOTE, APPEND_TO_CARRIER_FOLLOWERS, REMOVE_FROM_CARRIER_FOLLOWERS
    ,SELECTED_CARRIER_REMOVE_OWNER
} from '../actionTypes'

import { capitalize } from '../../utils/formatters'
import moment from 'moment'
import _ from 'lodash'

const initialState = {}

const cityStateZip = address => {
    if (address) {
        const city = address.city ? capitalize(address.city) : ''
        const state = address.state ? address.state : ''
        const zip = address.zip ? address.zip : ''
        const comma = address.city && address.state ? ',' : ''

        return `${city}${comma} ${state} ${zip}`
    } else {
        return '-'
    }
}

const formatPhone = phone => {
    if (phone && phone.length) {
        let formattedPhone = phone.trim().replace(/\D/g,'')
        if (formattedPhone.length === 11 && formattedPhone.charAt(0) === '1') {
            formattedPhone = formattedPhone.substring(1)
        } else if (formattedPhone.length > 10) {
            return '-'
        }

        let match = formattedPhone.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            formattedPhone = `(${match[1]}) ${match[2]}-${match[3]}`
        }
        return formattedPhone
    } else {
        return '-'
    }
}

const calcGrossProfit = (carrierRate, customerRate) => {
    if (customerRate && carrierRate) {
        return parseFloat(customerRate - carrierRate).toFixed(2)
    } else {
        return '-'
    }
}

const getGrossProfitForLoad = l => {
    const carrierRate = l.carrier_rate ? l.carrier_rate : (l.carrier_total_rate ? l.carrier_total_rate : null)
    const customerRate = l.customer_rate ? l.customer_rate : (l.invoice_total ? l.invoice_total : null)
    return (carrierRate && customerRate) ? calcGrossProfit(carrierRate, customerRate) : null
}

const convertContactToListFormat = (c) => {
    c.formattedAddress = c.address ? cityStateZip(c.address) : ''
    c.formattedPhone = c.phone ? formatPhone(c.phone) : ''
    c.email = c.email ? c.email.toLowerCase() : ''
    c.contact_type = c.contact_type ? capitalize(c.contact_type) : ''
    c.name = c.name ? capitalize(c.name) : ''

    return c
}

const convertNullsToEmptyStrings = c => {
    Object.keys(c).forEach(key => {
        const stringContainsNull = (typeof c[key] === 'string' && c[key].toLowerCase().includes('null'))
        if (c[key] === null || stringContainsNull) {
            c[key] = ''
        }
    })
    return c
}


export default (state=initialState,  action) => {
    let selectedCarrier = null
    switch(action.type) {
    case SET_SELECTED_CARRIER:
        selectedCarrier = Object.assign({}, action.selectedCarrier)

        if (selectedCarrier.loads) {
            // Sort loads by pickup date DESC
            selectedCarrier.loads.sort((a,b) => {
                return moment(b.pickup_date) - moment(a.pickup_date)
            })

            selectedCarrier.loads.forEach(l => {
                l.gross_profit = getGrossProfitForLoad(l)
            })
        }

        if (selectedCarrier.contacts && selectedCarrier.contacts.length) {
            selectedCarrier.contacts.forEach(c => { 
                c = convertNullsToEmptyStrings(c)
                return convertContactToListFormat(c)
            })
        }

        return selectedCarrier
    
    // HERE we can add in the contact into the selected carrier, dispatch the selected carrier with the new contact 
    // action.selectedCarrier, action.newContact
    // if there is a dummy record, then remove it
    // then return selectedCarrier
    case SELECTED_CARRIER_REMOVE_OWNER:
        selectedCarrier = Object.assign({}, state)
        selectedCarrier.owner = null
        selectedCarrier.owner_id = null
        return selectedCarrier
    case UPDATED_SELECTED_CARRIER_CONTACT:
        selectedCarrier = Object.assign({}, action.selectedCarrier)

        let newContact = convertNullsToEmptyStrings(action.newContact)
        let contacts = [...selectedCarrier.contacts]

        newContact.fadeIn = true
        const indexOfContactToReplace = contacts.findIndex(c => c.id === newContact.id)

        if (indexOfContactToReplace === -1) {
            contacts.push(convertContactToListFormat(newContact))
        } else {
            contacts[indexOfContactToReplace] = convertContactToListFormat(newContact)
        }

        selectedCarrier.contacts = contacts

        return selectedCarrier
    case UPDATE_SELECTED_CARRIER_CONTACTS_LIST_AFTER_DELETE:
        selectedCarrier = Object.assign({}, action.selectedCarrier)
        _.remove(selectedCarrier.contacts, (c) => c.id === action.contactId)

        return selectedCarrier
    case UPDATED_SELECTED_CARRIER_NOTE:
        selectedCarrier = Object.assign({}, action.selectedCarrier)
        let newNote = action.newNote
        let notes = [...selectedCarrier.notes]

        newNote.fadeIn = true

        if (newNote.id) {
            const editedNoteIndex = _.findIndex(notes, (n)=>{ return n.id === newNote.id })
            if (editedNoteIndex >= 0) {
                notes.splice(editedNoteIndex, 1)
            }
        }

        notes.push(newNote) // TODO: convert this to list format??
        selectedCarrier.notes = notes

        return selectedCarrier
    case UPDATE_SELECTED_CARRIER_NOTES_LIST_AFTER_DELETE:
        selectedCarrier = Object.assign({}, action.selectedCarrier)
        _.remove(selectedCarrier.notes, (c) => c.id === action.noteId)
        
        return selectedCarrier
    case APPEND_TO_CARRIER_FOLLOWERS:
        selectedCarrier = Object.assign({}, action.selectedCarrier)
        selectedCarrier.users.push(action.user)
    
        return selectedCarrier
    case REMOVE_FROM_CARRIER_FOLLOWERS:
        selectedCarrier = Object.assign({}, state)
        let followers = selectedCarrier.users
        selectedCarrier.users = followers.filter(u=>u.id !== action.userId)

        return selectedCarrier
    default:
        return state
    }

}