import moment from 'moment'
import _ from 'lodash'
import { ADD_SELECTED_INCOMING_TEXT, SET_SELECTED_INCOMING_TEXT } from '../actionTypes'

const initialState = []

const addSentAtDate = (text) => {
    if (text?.sent_at && (!text?.sent_at_date)) {
        text.sent_at_date = moment(text.sent_at).format('MM/DD/YYYY')
        text.sent_at_time = moment(text.sent_at).format('HH:mm')
    }
    return text
}

export default (state=initialState,  action) => {
    switch(action.type) {
        case SET_SELECTED_INCOMING_TEXT:
            const incomingTexts = (action.incomingTexts ||[]).map(addSentAtDate)
            return _.sortBy(incomingTexts, 'sent_at')
        case ADD_SELECTED_INCOMING_TEXT:  // add handles add and update.  update is just a text we already have
            if (action.incomingText) {
                const incomingText = addSentAtDate(action.incomingText)
                const texts = [...state].filter(t => t.id !== incomingText.id)
                return _.sortBy([...texts, incomingText], 'sent_at')
            } else {
                return state
            }
        default:
            return state
    }
}