import {SET_MODAL_DIALOG, CLEAR_MODAL_DIALOG} from '../actionTypes'

const initialState = {name: ''}


/**
 * This reducer just sets an objectg for the modal dialog screen to show.
 * From the screen it's visible flag is based on matching the string set in this reducer.
 * Example:  bounce dialog would have visible={this.props.modelDialog?.name === "bounce_dialog"}
 * This moved from a string to an object, so extra data can be passed into the store.
 * Example maybe a save data confirmation with a next_item field
 */
export default (state=initialState,  action) => {
    switch(action.type) {
        case SET_MODAL_DIALOG:    
            return action.modal_dialog
        case CLEAR_MODAL_DIALOG:
            return {...initialState}
        default:
            return state
     }
}
