
import { call, put, takeLatest } from "redux-saga/effects"
import { FETCH_AND_SET_CONNECT_MAPS, SET_CONNECT_MAPS, SET_CONNECT_MAPS_STATE, FETCH_AND_SET_CONNECT_MAPS_STATE } from '../actionTypes'
import { 
    fetchConnectDataForMap,
    fetchConnectQueryCounterDataForMap,
    fetchConnectLoadsDataForMap,
    fetchConnectEmailsDataForMap} from '../dashboardActions'
import _ from 'lodash'
function* workerFetchAndSetConnectMaps(payload) {
  try { 
      yield put({ type: SET_CONNECT_MAPS, connectMapsData: null })
      let connectMapsData = []
      let queryCounterData = []
      let loadsMapData = []
      let emailsMapData = []
      let mapsGeoJson = []
      // Fetch and set Emails
      let response = yield call(fetchConnectDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        connectMapsData = response.data
        mapsGeoJson = response.geojson
      }
      response = yield call(fetchConnectQueryCounterDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        queryCounterData = response.data
      }
      response = yield call(fetchConnectLoadsDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        loadsMapData = response.data
      }
      response = yield call(fetchConnectEmailsDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        emailsMapData = response.data
      }

      let data = []

      _.forEach(mapsGeoJson.features, function(state, key) {
        try {
          const connect = _.find(connectMapsData, function(o) { return o.id === state.id; }) || {value: 0}
          const query = _.find(queryCounterData, function(o) { return o.id === state.id; }) || {value: 0}
          const load = _.find(loadsMapData, function(o) { return o.id === state.id; })|| {value: 0}
          const email = _.find(emailsMapData, function(o) { return o.id === state.id; }) || {value: 0}

          let diff = 0
          if (query.value >= 0 && connect.value >= 0 && load.value >= 0 && email.value >= 0) {
            diff =  connect.value +  query.value + load.value + email.value //(state.value / query.value) * 100
          }      
          let newState = {}
          newState['id'] = state.id
          newState['tooltip'] = `C-${Math.ceil(connect.value)} / Q-${Math.ceil(query.value)} / L-${Math.ceil(load.value)} / E-${Math.ceil(email.value)}`
          newState['value'] = Math.ceil(diff)
          data.push(newState)
        } catch (err) {
          console.log(err.message)
        }
      });

      let dataWithState = [payload.parms.state,mapsGeoJson,data]
      yield put({ type: SET_CONNECT_MAPS, connectMapsData: dataWithState })
  
  }
  catch (err) {
    console.log('FETCH_AND_SET_EMAILS failed-->',err)
  }

}

function* workerFetchAndSetConnectMapsState(payload) {
  try { 
      yield put({ type: SET_CONNECT_MAPS_STATE, connectMapsData: null })
      let connectMapsData = []
      let queryCounterData = []
      let loadsMapData = []
      let emailsMapData = []
      let mapsGeoJson = []
      // Fetch and set Emails
      let response = yield call(fetchConnectDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        connectMapsData = response.data
        mapsGeoJson = response.geojson
      }
      response = yield call(fetchConnectQueryCounterDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        queryCounterData = response.data
      }
      response = yield call(fetchConnectLoadsDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        loadsMapData = response.data
      }
      response = yield call(fetchConnectEmailsDataForMap, payload.parms.state, payload.parms.days, payload.parms.direction, payload.parms.eq_types)
      if (response) {
        emailsMapData = response.data
      }

      let data = []
      _.forEach(mapsGeoJson.features, function(state, key) {
        try {
          const connect = _.find(connectMapsData, function(o) { return o.id === state.id; }) || {value: 0}
          const query = _.find(queryCounterData, function(o) { return o.id === state.id; }) || {value: 0}
          const load = _.find(loadsMapData, function(o) { return o.id === state.id; }) || {value: 0} // there's a problem where if absolutely don't get records, geopandas will error and return nothing
          const email = _.find(emailsMapData, function(o) { return o.id === state.id; }) || {value: 0}

          let diff = 0
          if (query.value >= 0 && connect.value >= 0 && load.value >= 0 && email.value >= 0) {
            diff = connect.value +  query.value + load.value + email.value 
          }      
          let newState = {}
          newState['id'] = state.id
          newState['tooltip'] = `C-${Math.ceil(connect.value)} / Q-${Math.ceil(query.value)} / L-${Math.ceil(load.value)} / E-${Math.ceil(email.value)}`
          newState['value'] = Math.ceil(diff)
          data.push(newState)
        } catch (err) {
          console.error(`Error in workerFetchAndSetConnectMapsState ${err.message}`)
        }
      });

      let dataWithState = [payload.parms.state,mapsGeoJson,data]
      yield put({ type: SET_CONNECT_MAPS_STATE, connectMapsData: dataWithState })
  
  }
  catch (err) {
    console.log('FETCH_AND_SET_EMAILS failed-->',err)
  }

}

export function* watchFetchAndSetConnectMapsSaga() {
    yield takeLatest(FETCH_AND_SET_CONNECT_MAPS, workerFetchAndSetConnectMaps)
}

export function* watchFetchAndSetConnectMapsStateSaga() {
    yield takeLatest(FETCH_AND_SET_CONNECT_MAPS_STATE, workerFetchAndSetConnectMapsState)
}
