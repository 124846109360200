
import { SET_CARRIER_SELECTED_LOAD } from '../actionTypes'

const initialState = null

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_CARRIER_SELECTED_LOAD:
        return action?.load || null

    default:
        return state
    }
}
