import { takeEvery, call, put, select } from "redux-saga/effects"
import axios from "axios"
import { FETCH_TASKS, SET_TASKS, TASK_UPDATED, UPDATE_TASK, DELETE_TASK } from '../actionTypes'
import _ from 'lodash'
import { getCurrentUser } from '../reducers/rootReducer'
//TODO: move this to the .env file
const HIPPO_BASE_URL = (process.env.HIPPO_BASE_URL) ? process.env.HIPPO_BASE_URL : 'https://hippo.loadotr.com'

function fetchTask(id) {
  return axios({
    method: "get",
    url: `/api/tasks/${id}`
  })
}

function searchCachedTrucks() {
  let url2 = `${HIPPO_BASE_URL}/hippo/api/carriers`
  return axios.get(url2)
}

function* workerFetchTasks(/*action*/) {
  yield put({ type: SET_TASKS, tasks: null })
  // const response = yield call(fetchTasks)
  // if (response) {
  //   yield delay(600)
  //   yield put({ type: SET_TASKS, tasks: response.data })
  // }
  const cachedTrucksResult = yield call (searchCachedTrucks)
  if (cachedTrucksResult && cachedTrucksResult.data) {
    yield put({type: SET_TASKS, tasks: cachedTrucksResult.data})

  }
}

function* workerTaskUpdated(action) {
  try { 
    //console.log('hey we got a task update here in saga', action)
    // we should check if the task has a user id.  if null then we can process,
    // if not null then this should be the user id
    //yield put( {type: TASK_COMPLETED})
    const task = _.get(action, 'data.subject')
    const action_type = _.get(action, 'data.action')
    
    if ((action_type === 'NEW') || (action_type === 'UPDATE')) { 
      let fetch_this_task = true
      if (task.assigned_to_user_id) {
        const currentUser = yield select(getCurrentUser)
        fetch_this_task =  (task.assigned_to_user_id !== currentUser.id)
      }
  
      if (fetch_this_task) {
        const response = yield call(fetchTask, task.id)
        if (response) {
          yield put( {type: UPDATE_TASK, task: response.data})
        }
      }
    } else if ((action_type === 'COMPLETE') || (action_type === 'DELETE')) {
      yield put( {type: DELETE_TASK, task})
    }
  
  }
  catch (err) {
    console.log('TASK UPDATED failed-->',err)
  }

}

export function* watchFetchTasksSaga() {
    yield takeEvery(FETCH_TASKS, workerFetchTasks)
}

export function* watchTaskUpdatedSaga() {
    yield takeEvery(TASK_UPDATED, workerTaskUpdated)
}


