import {RETURN_LOADS_FOR_TRUCK} from '../actionTypes'

const initialState = []


export default (state=initialState,  action) => {

    switch(action.type) {
    case RETURN_LOADS_FOR_TRUCK:
        let {returnLoads} = action

        return (returnLoads === undefined) ? null : returnLoads
    default:
        return state
    }
}
