import { STATS_SET } from '../actionTypes'

const initialState = []


export default (state=initialState,  action) => {
    switch(action.type) {
    case STATS_SET:
        return action.stats

    default:
        return state
    }
    
}