
import { takeEvery, call, put } from "redux-saga/effects"
import { FETCH_AND_SET_LEADS, ADD_LEADS_FROM_SELECTED_LOAD } from '../actionTypes'
import { fetchLeadData } from '../loadActions'

function* workerFetchAndSetLeads(payload) {
  try { 
    yield put({ type: ADD_LEADS_FROM_SELECTED_LOAD, leadData: null})
    const responseLoad = yield call(fetchLeadData, payload.payload,null)
    const leadData = responseLoad?.data
    if(leadData)  yield put({ type: ADD_LEADS_FROM_SELECTED_LOAD, leadData})
  }
  catch (err) {
    console.log('FETCH_AND_SET_EMAILS failed-->',err)
  }

}

export function* watchFetchAndSetLeadsSaga() {
    yield takeEvery(FETCH_AND_SET_LEADS, workerFetchAndSetLeads)
}
