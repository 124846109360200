import {SET_CARRIER_MATCHING_LOAD, ADD_CARRIER_MATCHING_LOAD, REMOVE_CARRIER_MATCHING_LOAD} from '../actionTypes'
import moment from 'moment'
import _ from 'lodash'

const initialState = []

/**
 * Create a load object to add to the array
 * Load will be the load from the match, plus any truck ids that match to the load
 * @param {*} carrierMatchingLoads 
 * @param {*} load 
 * @returns 
 */
const addCarrierMatchingLoadTo = (carrierMatchingLoads, match, selectedCarrierTruck) => {
    if (selectedCarrierTruck && selectedCarrierTruck.truck_id !== match.truck.truck_id) {
        return carrierMatchingLoads
    } else if (selectedCarrierTruck && selectedCarrierTruck.truck_id === match.truck.truck_id) {
        // lets set the deadhead
        match.load = {...match.load, origin_deadhead: match.origin_deadhead, destination_deadhead: match.destination_deadhead, quality_match: match.quality_match
                    ,match_score: match.match_score}
    }

    let load = carrierMatchingLoads.find(l => l.pro === match.load.pro)
    if (load) {
        // load exists in the list, let's add the truck id to it
        load.truck_ids = _.uniq([...load.truck_ids, match.truck.truck_id])
        load.updated_at = moment().toISOString()

    } else {
        load = {...match.load, updated_at: moment().toISOString(), truck_ids: [match.truck.truck_id]}
        carrierMatchingLoads.push(load)
    }

    return carrierMatchingLoads
}

const removeCarrierMatchingLoadFrom = (carrierMatchingLoads, match) => {
    let load = carrierMatchingLoads.find(l => l.pro === match.load.pro)

    if (load) {
        load = {...load, truck_ids: load.truck_ids.filter(t => t !== match.truck.truck_id)}
        carrierMatchingLoads = carrierMatchingLoads.filter(l => l.pro !== match.load.pro)
        if (load.truck_ids.length > 0) {
            carrierMatchingLoads.push(load)
        }
    }

    return carrierMatchingLoads
}

export default (state=initialState,  action) => {
    let loads = [...state]

    switch(action.type) {
        case SET_CARRIER_MATCHING_LOAD:
            if (action?.matches?.length >= 0) {
                loads = []
                for (let match of action.matches) {
                    loads = addCarrierMatchingLoadTo(loads, match, action.selectedCarrierTruck)
                }
            }
            break
        case ADD_CARRIER_MATCHING_LOAD:
            loads = addCarrierMatchingLoadTo([...loads], action.match, action.selectedCarrierTruck)
            break
        case REMOVE_CARRIER_MATCHING_LOAD:
            loads = removeCarrierMatchingLoadFrom([...loads], action.match)
            break
        default:
            return state
    }
    return loads
}


        //TODO: add this logic in later, for the selected truck
        /*
        const carrierMatchingLoads = _.uniqBy(matchingLoadsResponse.data.map(m => {
            const updated_at = moment(m.updated_at).add(1, 'millisecond').toISOString()
            if (selectedCarrierTruck && selectedCarrierTruck.truck_id === m.truck.truck_id) {
                return {...m.load, origin_deadhead: m.origin_deadhead, destination_deadhead: m.destination_deadhead, updated_at, quality_match: m.quality_match}
            } else if (selectedCarrierTruck) {
                return null
            }
            return {...m.load, origin_deadhead: null, destination_deadhead: null, updated_at}
        }).filter(l => l !== null), 'id')
        */