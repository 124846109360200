import { takeLatest, call, put } from "redux-saga/effects"
import { SET_SMS_QUEUE_LOGS, FETCH_SMS_QUEUE_LOGS } from '../actionTypes'
import { fetchMessagesInQueueByPro } from '../dashboardActions'

function* workerFetchSmsQueueLogsSaga(payload) {
    try {
        yield put({ type: SET_SMS_QUEUE_LOGS, smsQueueLogs: null })
        let smsQueueLogs = []
        let response = yield call(fetchMessagesInQueueByPro, payload.payload.pro)
        if (response) {
        smsQueueLogs = response
        }

        yield put({ type: SET_SMS_QUEUE_LOGS, smsQueueLogs: smsQueueLogs })

    } catch (error) {
        // TODO: dispatch a failure action to the store with the error
        console.log('error ', error)
    }
}
  
export function* watchFetchSmsQueueLogsSaga() {
    yield takeLatest(FETCH_SMS_QUEUE_LOGS, workerFetchSmsQueueLogsSaga)
  }
  