import { SET_TASKS , UPDATE_TASK, DELETE_TASK, TASK_COMPLETED} from '../actionTypes'
import moment from 'moment'

const initialState = []


export default (state=initialState,  action) => {
    let tasks = []
    switch(action.type) {
        case SET_TASKS:
            tasks = action.tasks
            if (tasks) {
                // //filter out tasks with old loads matching
                // const yesterday = moment().subtract(1, 'days')
                // tasks = tasks.filter( 
                //     t => (moment(t.load.pickup_date).isSameOrAfter(yesterday) && t.task_type === 'LOAD_CARRIER_MATCH')
                //             || t.task_type !== 'LOAD_CARRIER_MATCH'
                // )
            }
            return tasks
        case UPDATE_TASK:
            tasks = [...state]
            
            tasks = tasks.filter(t => t.id !== action.task.id)
            action.task.updated_at = moment(action.task.updated_at).add(1, 'second')
            tasks.push(action.task)
            return tasks
        case DELETE_TASK: 
            tasks = [...state]
            tasks = tasks.filter(t => t.id !== action.task.id)
            return tasks
        case TASK_COMPLETED:
            tasks = [...state]
            let task = tasks.find(t => t.id = action.task.id)
            if (task) {
                tasks = tasks.filter(t => t.id !== action.task.id)
                task.complete = true
                task.updated_at = moment(task.updated_at).add(1, 'second')
                tasks.push(task)
            }
            return tasks

        default:
            return state
    }
    
}