import { SET_LOADS, SET_LOADS_ON_LOAD_UPDATE } from '../actionTypes'
import { isLoadMatchedByFilter, convertToListLoad } from '../../utils/load-update-helpers'
import moment from 'moment'

const initialState = []


//TODO:  Loads list I would like to not have a different return structure than load fetch or load update.
//       Right now this is a flat object with lowest_bid and carrier_name.
// This should change so that when we get live event updates on loads, we do not have to go through hoops to fit the data.
//  Just take it as is and splice it into the array.
export default (state=initialState,  payload) => {
    switch(payload.type) {
    case SET_LOADS:
        return payload.loads

    case SET_LOADS_ON_LOAD_UPDATE:   
        const { updatedLoad, filter, user } = payload.params

        if (state instanceof Array) {
            let loads = [...state]

            const shouldAddToList = isLoadMatchedByFilter(updatedLoad, filter, user)
            let notFoundInList = true
            loads = loads.map(l => {
                let loadToAdd = l
                if (l.id === updatedLoad.id) {
                    notFoundInList = false
                    if (shouldAddToList) {
                        loadToAdd = convertToListLoad(updatedLoad)
                        //force refresh in table hack
                        if (l.updated_at === loadToAdd.updated_at) {
                            loadToAdd.updated_at = moment(loadToAdd.updated_at).add(1, 'second')
                        }
                    } else {
                        loadToAdd = null
                    }
                }
                return loadToAdd
            }).filter(l => l != null)
    
            if (notFoundInList && shouldAddToList) {
                const loadToAdd = convertToListLoad(updatedLoad)
                loads.push(loadToAdd)
            }

            return loads 
        } else {
            return state
        }
    default:
        return state
    }
}