import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers/rootReducer'
import rootSaga from './sagas/rootSaga'
import createSagaMiddleware from 'redux-saga'
import axios from 'axios'
import {SET_AUTHENTICATED_USER} from './actionTypes'
import {configurePusher} from 'pusher-redux'
import  {configureBroadcast} from './broadcast-redux'
import truckStreamingMiddleware from './truckStreamingSSEMiddleware'


const sagaMiddleware = createSagaMiddleware()


const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware, truckStreamingMiddleware)
)

sagaMiddleware.run(rootSaga)


// Set these in a local file called .env.local for local development
// if (process.env.REACT_APP_PUSHER_KEY) {
//     console.log(`configuring pusher to ${process.env.REACT_APP_PUSHER_ENV || 'development'}`)
//     configurePusher(store, process.env.REACT_APP_PUSHER_KEY, {cluster: process.env.REACT_APP_PUSHER_CLUSTER, forceTLS: true})
// } else {
//     // default to production.  Remove this, when we test out the deployment with .env.production
//     console.log('pusher config not set, defaulting to production pusher key and cluster')
//     configurePusher(store, 'f6fa9ef156087026a013', {cluster: 'us2', forceTLS: true})  
// }


//TODO: Here I would like to fetch the pusher config from the server, so we don't have to worry about which pusher we need

// we cam just do this unauthenticated as you really won't be getting data if your not logged in
axios.get('/api/config/pusher').then(r => {
    if (r?.data?.pusher_key && r?.data?.pusher_cluster) {
        console.log(`configuring pusher to ${r.data.pusher_key} on cluster ${r.data.pusher_cluster}`)
    
        configurePusher(store, r.data.pusher_key, {cluster: r.data.pusher_cluster, forceTLS: true})
    }
}).catch(e => console.log(e))

configureBroadcast(store, 'gear-jammer-channel')

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error && error.response && error.response.status === 401) {
        store.dispatch({
            type: SET_AUTHENTICATED_USER,
            authenticatedUser: null
        })
        //TODO: I wonder if we should set the window.location to foce the browser to go to the loging screen
        //      Not sure yet.
    } else {
        return error
    }
})



export default store