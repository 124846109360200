import {SET_LOAD_TASK_QUESTIONS} from '../actionTypes'

const initialState = []

export default (state=initialState,  action) => {
    switch(action.type) {
        case SET_LOAD_TASK_QUESTIONS:
            return (action?.questions?.length) ? 
                action.questions.reduce((pv, c) => {
                    pv[c.question_key] = c
                    return pv
                }, {})
                : state
    default:
        return state
    }
}