/**
 * This saga takes in all events, despatches the appropriate action to be picked by other sagas,
 * and add the event to the events list
 */

 import { getTeamsEmail } from '../emailActions'
 
 import { call, takeEvery, put, select, takeLatest } from "redux-saga/effects"
 import { ADD_EVENT, SET_EMAILS,  ADD_EVENT_LIST_EVENT, LOAD_UPDATED, 
     BID_ADDED_EVENT, NOTIFICATION_ADDED_EVENT, FETCH_CARRIER_BY_MC, FETCH_MY_CARRIER_ASSIGNMENTS, 
     SET_CONNECT_QUEUE_STATUS,
     UPDATE_CARRIER_OPS_TASK,
     FETCH_AND_SET_SELECTED_CARRIER_OPS_TASK,
     ADD_SMS_INBOUND_NOTIFICATION, ADD_SELECTED_INCOMING_TEXT, SET_NEW_VERSION_AVAILABLE, NEW_APP_VERSION_EVENT,
    ADD_CARRIER_LOAD_MATCH_EVENT,
    REMOVE_CARRIER_LOAD_MATCH_EVENT,
    USER_UPDATE_EVENT,
    REFRESH_USER_TOKEN,
    SET_LOAD_POST,
    UPDATE_CARRIER_BIDS} from '../actionTypes.js'
 import { getCurrentUser, getSelectedCarrier, getEmails,
     getConnectQueueStatus,  getSelectedCarrierOpsTask, getLoadTaskFilter } from '../reducers/rootReducer'
 import moment from 'moment'
 const _ = require('lodash')



/**
 * 
 * @param {*} task 
 * @param {*} filter  {
    status: '',
    assigned: '',
    task_definition_names: [],
    pro: '' // this can be added from the screen and will receive updates.  We might need to add in an array here and a clear method :-)
}
 * @param {*} user_id -- current logged in user
 * @returns 
 */
const matchesLoadTaskFilter = (task, filter, user_id) => {
    let matches = true

    if (filter.task_names.length) {
        matches = matches && filter.task_names.includes(task.name)
    }

    if (filter.assigned.length) {
        switch (filter.assigned) {
            case 'Assigned':
                matches = matches && task.assigned_user_id?.length > 0
            break
            case 'UnAssigned':
                matches = matches && ((task.assigned_user_id?.length || 0) === 0)
            break
            case 'My Tasks':
                matches = matches && ((task.assigned_user_id === user_id) || (task.load.booking_rep_id === user_id) || (task.load.sales_rep_id === user_id))
                // and match today
                matches = matches && moment().isSameOrAfter(moment(task.updated_at).subtract(1, 'day'))
            break
            default:
        }
    }

    matches = matches && filter.status.map(s => s.toUpperCase()).includes(task.status)

    if (filter.assigned !== 'My Tasks') {
        //TODO: Add this back in with the statuses

    
        if ((filter.day === 'Today') && matches) {
            if (task.load?.pickup_date && task.load?.delivery_date) {
                const nowStr = moment().format('YYYY-MM-DD')
                const puStr = moment(task.load.pickup_date).format('YYYY-MM-DD')
                const delStr = moment(task.load.delivery_date).format('YYYY-MM-DD')

                matches = (matches && (puStr <= nowStr) && (nowStr <= delStr))
            }
        }
    }

    //This is more of an or logic.  If we don't have a match but do have a pro entered and the pro matches then we are good to go
    if (!matches && filter.pro?.length) {
        matches = matches || (filter.pro === task.load?.pro)
    }

    return matches
}

function* workerProcessNewAppVersion(action) {
    const newVersion = action?.data?.version

    if (newVersion && (newVersion !== process.env.REACT_APP_APP_RELEASE_VERSION)) {
        // a new version exists, pop up the banner
        yield put({type: SET_NEW_VERSION_AVAILABLE})
    }
}

function* workUserUpdate(action) {
    // check to see if the user updates is me
    const me = yield select(getCurrentUser)

    if (action?.data?.subject?.user_id && (action.data.subject.user_id === me?.user_id)) {
        console.log('i am the user that changes, so call refresh and update auth')
        yield put({type: REFRESH_USER_TOKEN})
    }

    console.log(action, me)

}


// WORKERS
function* workerProcessEvent(action) {
    const evt = action.data

    switch (evt.event_type) {
        case 'LOAD':
            //TODO: the load should be sending minimal data.  We don't always want to go fetch the load
            // load should come in as {id, status} -- minimally, so if it does not match our criteria, then we can ignore
            let load = evt.subject

            if (load) {
                yield put({type: LOAD_UPDATED, load: load})
            }
            break
        case 'LOAD_POST':
            //console.log('received load post event', evt)
            yield put({type: SET_LOAD_POST, post: evt.subject, action: evt.action})
            break
        case 'BID':
            if (action.additionalParams?.carrierEvents) {
                //Must have a load on the bid or the render down stream on carrier bids table will fail
                if (evt.subject.load) {
                    const selectedCarrier = yield select(getSelectedCarrier)
                    if (selectedCarrier.id === evt.subject.carrier_id) {
                        yield put ({type: UPDATE_CARRIER_BIDS, bid: evt.subject})
                    }
                }
            } else {
                yield put({type: BID_ADDED_EVENT, bid: evt.subject})
            }
            break
        case 'EMAIL':
            //TODO: add to reducer, no need to fetch all emails from the store to process, that is what a reducer is for
            try {
                const emails = yield select(getEmails)
                const currentUser = yield select(getCurrentUser)
                yield put({ type: SET_EMAILS, emails: null })
                if (evt.subject && evt.subject.email && evt.subject.email.id) {
                    try {
                        let team_email
                        if (currentUser && currentUser.teams && currentUser.teams.length > 0) {
                            team_email = currentUser.teams[0].email
                        } else {
                            let response = yield call(getTeamsEmail, {user_email:currentUser.email})
                            team_email = response.data.team_email
                        }
                        let to_email
                        if (Array.isArray(evt.subject.email.To)) {
                            to_email = evt.subject.email.To[0]
                        }
                        else {
                            to_email = evt.subject.email.To
                        }
                        if (to_email === team_email) {
                            emails.unshift(evt.subject.email)
                        }
                    }
                    catch (err) {
                        console.log(`Error Fetching Team Email for ${currentUser.email}`)
                    }
                }

                yield put({ type: SET_EMAILS, emails })

            } catch (error) {
                // TODO: dispatch a failure action to the store with the error
                console.log('error ', error)
            }
            break
        case 'EMAIL_DROP':
            //TODO: add to reducer, no need to fetch all emails from the store to process, that is what a reducer is for
            try {
                let emails = yield select(getEmails)

                const currentUser = yield select(getCurrentUser)
                if (evt.subject && evt.subject.email && evt.subject.email.id) {
                    try {
                        let team_email
                        if (currentUser && currentUser.teams && currentUser.teams.length > 0) {
                            team_email = currentUser.teams[0].email
                        } else {
                            let response = yield call(getTeamsEmail, {user_email:currentUser.email})
                            team_email = response.data.team_email
                        }

                        let to_email
                        if (Array.isArray(evt.subject.email.To)) {
                            to_email = evt.subject.email.To[0]
                        }
                        else {
                            to_email = evt.subject.email.To
                        }

                        if (to_email === team_email) {
                            emails = _.map(emails, (item) => {
                                if (item.id === evt.subject.email.id) {
                                    item['data']['show_buttons'] = false
                                    item['show_buttons'] = false
                                    item['updated_at'] = moment().toISOString()
                                    return item
                                    } else {
                                    item['updated_at'] = moment().toISOString()
                                    return item
                                    }
                                })
                            yield put({ type: SET_EMAILS, emails })
                        }
                    }
                    catch (err) {
                        console.log(`Error Fetching Team Email for ${currentUser.email}`)
                    }
                }

            } catch (error) {
                // TODO: dispatch a failure action to the store with the error
                console.log('error ', error)
            }
            break
        case 'MATCH':
            if (evt.action === 'NEW') {
                yield put({type: ADD_CARRIER_LOAD_MATCH_EVENT, match: evt.subject})
            } else if (evt.action === 'EXPIRED') {
                yield put({type: REMOVE_CARRIER_LOAD_MATCH_EVENT, match: evt.subject})
            }
            break
        case 'ASSIGNMENT':
            //TODO: Do we still need this?
            if (evt.action === 'ASSIGN') {
                const currentUser = yield select(getCurrentUser)
                const matchRecipientId = _.get(evt, 'subject.recipientId', null)

                if (matchRecipientId && currentUser.user_id === matchRecipientId) {
                    const notification = _.get(evt, 'subject', null)

                    if (notification) {
                        yield put({ type: NOTIFICATION_ADDED_EVENT, notification: notification })
                        yield put({ type: FETCH_MY_CARRIER_ASSIGNMENTS })
                    }
                }
                break
            } else if (evt.action === 'UNASSIGN') {
                const currentUser = yield select(getCurrentUser)
                const matchUnassignedUserId = _.get(evt, 'subject.unassignedUserId', null)
                const matchRequesterId = _.get(evt, 'subject.requesterId', null)

                if (matchUnassignedUserId === currentUser.user_id || matchRequesterId === currentUser.user_id) {
                    const notification = _.get(evt, 'subject', null)
                    if (notification) {
                        yield put({ type: FETCH_MY_CARRIER_ASSIGNMENTS })
                    }
                }
                break
            }
            break
        case 'CARRIER':
            const selectedCarrier = yield select(getSelectedCarrier)
            if (selectedCarrier && selectedCarrier.mc === evt.subject) {
                yield put({type: FETCH_CARRIER_BY_MC, mc: evt.subject })
            }
            break
        case 'CONNECT_CALL':
            const connectQueueStatus = yield select(getConnectQueueStatus)
            const newBid = evt.subject
            if (!connectQueueStatus) {
                break
            }
            const updatedConnectQueueStatus = _.map(connectQueueStatus, (o) => {
                return (o.bid_id === newBid.bid_id) ? newBid : o
            })
            yield put({type: SET_CONNECT_QUEUE_STATUS, connectQueueStatus: updatedConnectQueueStatus})
            break
        case 'LOAD_TASK': // for CARRIER OPS TASKS
            // check that the task matches our filter
            
            const task = yield select(getSelectedCarrierOpsTask)
            if (evt.action === 'DELETE') {
                yield put({type: UPDATE_CARRIER_OPS_TASK, task: evt.subject, action: 'DELETE'})
                if (task.id === evt.subject.id) {
                    task.status = 'DELETED'
                    yield put({type: UPDATE_CARRIER_OPS_TASK, task: task, action: 'UPDATED'})
                }
            } else {
                const loadTaskFilter = yield select(getLoadTaskFilter)
                const user = yield select(getCurrentUser)
                const matchesFilter = matchesLoadTaskFilter(evt.subject, loadTaskFilter, user?.user_id)

                if (matchesFilter) {
                    yield put({type: UPDATE_CARRIER_OPS_TASK, task: evt.subject, action: evt.action})
                } else {
                    //TODO: May need to delete out this if it is a selected task as well.
                    yield put({type: UPDATE_CARRIER_OPS_TASK, task: evt.subject, action: 'DELETE'})
                    // no need to set the status here, this is just deleting it out of the list
                }
            }

            if (task.load_id === evt.subject?.load_id) {
                // if we have a new task that matches the one we are looking at, let's replace it
                // This way we can move to the next task of the load
                yield put({type: FETCH_AND_SET_SELECTED_CARRIER_OPS_TASK, selectedTask: evt.subject})
            }
            break
        case 'LOAD_SMS_MESSAGE':
            const selectedTask = yield select(getSelectedCarrierOpsTask)

            if (selectedTask?.load?.id === evt?.subject?.load_id) {
                yield put ({type: ADD_SELECTED_INCOMING_TEXT, incomingText: evt.subject})
            } else if (evt?.subject?.direction === 'IN') {
                // we only care about inbound texts for notifications, hence ADD_SMS_INBOUND_NOTIFICATION
                //Removing this because we are getting events from SMS_NOTIFICATION below
                // yield put({type: ADD_SMS_INBOUND_NOTIFICATION, incomingText: evt.subject})
            }
            //TODO: we might want to notify that a message failed if we are not on that load
            break
        case 'SMS_NOTIFICATION':
            yield put({type: ADD_SMS_INBOUND_NOTIFICATION, smsNotification: evt.subject})
            break
        default:
            break
    }

    yield put({type: ADD_EVENT_LIST_EVENT, event:  `${evt.action} ${evt.event_type}`})
}

// WATCHERS
export function* watchEventSaga() {
    yield takeEvery(ADD_EVENT, workerProcessEvent)
}

export function* watchProcessNewAppVersionSaga() {
    yield takeEvery(NEW_APP_VERSION_EVENT, workerProcessNewAppVersion)
}

export function* watchUserUpdateSaga() {
    yield takeLatest(USER_UPDATE_EVENT, workUserUpdate)
}