import {SET_SELECTED_BID} from '../actionTypes'

const initialState = null

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_SELECTED_BID:
        return action.payload
    default:
        return state
    }
}