import {SET_SELECTED_TRUCK, SET_SELECTED_TRUCK_ORIGIN, SET_SELECTED_TRUCK_DESTINATION} from '../actionTypes'

const initialState = {}

export default (state=initialState,  action) => {
    let updatedTruck

    switch(action.type) {

    case SET_SELECTED_TRUCK:
        return action.selectedTruck
    case SET_SELECTED_TRUCK_ORIGIN:
    case SET_SELECTED_TRUCK_DESTINATION:
        updatedTruck = Object.assign({}, state, action.selectedTruckUpdate)

        return updatedTruck   
    default:
        return state
    }
}
