import axios from "axios"
// import { formatDate } from '../utils/formatters'

export const fetchQueryCounter = async (parms) => {
    let load_id = parms.id || ''
    let result = await axios({
        method: 'get',
        url: `/api/querycounter?load_id=${load_id}`
    })
    
    const data = (result && result.status === 200) ? result.data : []
    
    return data
}

export const addQueryCounter = async (parms) => {
    let ref = parms.ref || ''
    let result = await axios({
        method: 'get',
        url: `/api/loads/create_logs?ref=${ref}`
    })
    
    const data = (result && result.status === 200) ? result.data : []
    
    return data
}
