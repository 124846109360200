import {SET_SELECTED_LOAD} from '../actionTypes'

const initialState = null

const BID_STATUS_ORDER = ['BID', 'HOLD', 'EMAILED', 'DECLINE']

const sortBids = (b1, b2) => {
    let rtn = 0
    if (b1.status === b2.status) {
        rtn = new Date(b2.updated_at) - new Date(b1.updated_at)
    } else {
        const b1Inx = BID_STATUS_ORDER.findIndex(b => b === b1.status)
        const b2Inx = BID_STATUS_ORDER.findIndex(b => b === b2.status)
        return (b1Inx - b2Inx) > 0 ? 1 : -1
    }

    return rtn;
}

export default (state=initialState,  payload) => {
    switch(payload.type) {
    case SET_SELECTED_LOAD:
        if (payload.selectedLoad?.bids?.length) {
            // quick fix, some email bids say actual but have an actual_bid_id
            payload.selectedLoad.bids = payload.selectedLoad.bids.map(b => {
                if (b.actual_bid && b.actual_bid_id) {
                    b.actual_bid = false
                }
                return b
            })
        }

        if (payload.selectedLoad?.bids?.length > 1) {
            const actualBids = payload.selectedLoad.bids.filter(b => b.actual_bid)
            const relatedBids = payload.selectedLoad.bids.filter(b => !b.actual_bid)
            actualBids.sort(sortBids)
            relatedBids.sort(sortBids)
            payload.selectedLoad.bids = [...actualBids, ...relatedBids]
        }
        return payload.selectedLoad
    default:
        return state
    }
}
