import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import {LOGIN_USER, SET_AUTHENTICATED_USER, IMPERSONATE_USER, CLEAR_IMPERSONATE_USER, REFRESH_USER_TOKEN} from '../actionTypes'

import { impersonateUser, clearImpersonateUser } from '../userActions'

function fetchUserToken(accessToken) {
  return axios.post('/api/auth/google', {access_token: accessToken})
}

function refreshToken() {
  return axios.get('/api/auth/refresh_token')
}



function* workerUserLoginSaga(payload) {
  try {
    //console.log('authUser Saga ', payload)
    //const response = yield call(fetchLoad, payload.selectedLoad.id);
    //const load = response.data;

    let {accessToken} =  payload
    //console.log("payload ", payload)

    const response = yield call(fetchUserToken, accessToken)
    
    //console.log("response ", response)
    const authenticatedUser = response.headers['x-auth-token']

    yield put({type: SET_AUTHENTICATED_USER, authenticatedUser})
  } catch (error) {
    console.log("error logging in", error)
  }

}

function* workerImpersonateUser(payload) {
  const response = yield call(impersonateUser, payload.user_id)
  const authenticatedUser = response.headers['x-auth-token']
  if (authenticatedUser) {
    yield put({type: SET_AUTHENTICATED_USER, authenticatedUser})
    window.location.reload(false)
  }
}

function* workerClearImpersonateUser(payload) {
  const response = yield call(clearImpersonateUser)
  const authenticatedUser = response.headers['x-auth-token']
  if (authenticatedUser) {
    yield put({type: SET_AUTHENTICATED_USER, authenticatedUser})
    window.location.reload(false)
  }
}

function* workerRefreshUserToken() {
  console.log('refreshing token')
  //TODO: if user is inactive we should pass back user inactive and log them out.
  const response = yield call(refreshToken)
  console.log(response)
  if (response?.status === 200) {
    const authenticatedUser = response.headers['x-auth-token']
    
    yield put({type: SET_AUTHENTICATED_USER, authenticatedUser})
    window.location.reload(false)
  }
}


export function* watchUserLoginSaga() {
    yield takeLatest(LOGIN_USER, workerUserLoginSaga)
}

export function* watchImpersonate() {
    yield takeLatest(IMPERSONATE_USER, workerImpersonateUser)
}

export function* watchClearImpersonateUser() {
  yield takeLatest(CLEAR_IMPERSONATE_USER, workerClearImpersonateUser)
}

export function* watchRefreshUserTokenSaga() {
  yield takeLatest(REFRESH_USER_TOKEN, workerRefreshUserToken)
}