import moment from 'moment'
import _ from 'lodash'
import { SET_MATCHES, REMOVE_MATCH, ADD_MATCH } from '../actionTypes'

import { convertMatchTruckSource } from '../../utils/truck-source-helper'

const initialState = []

/*
  Matches are {matchid, carrier, load}
  combine the matches by carrier so taht it will look like
  {carrier, matches: [{truck, load}, ...]}

  TODO: change this to {carrier, trucks: [ {...truck, loads: []}]}
*/

const getLastUpdated = (dtArray) => {

    const ary = dtArray.filter(item => item).sort()

    if (ary.length) {
        return ary[ary.length-1]
    }

    return moment().toISOString()
}

/**
 * Mutates the matches array by adding a match, you will need to copy it later components update
 * @param {*} matches - array of matches
 * @param {*} match - new match to add
 */
const addMatchTo = ({matches, match, today = moment().format('YYYY-MM-DD'), userId = null}) => {
    let rtnMatches = (matches?.length) ? matches : []
    if (match?.carrier?.mc && match?.quality_match === 'high') {
        const carrierLoadMatch = rtnMatches.find(m => `${m.mc}` === `${match.carrier.mc}`)
        const matchInfo = {...match}
        const carrier_owner = matchInfo.carrier.owner
        delete matchInfo.carrier

        if (carrierLoadMatch) {
            carrierLoadMatch.matches.push(matchInfo)
            carrierLoadMatch.match_count++
            carrierLoadMatch.match_score = carrierLoadMatch.matches.reduce((r, item) => (item.match_score > r) ? item.match_score : r, 0)
            carrierLoadMatch.match_updated_at = getLastUpdated([carrierLoadMatch.match_updated_at , matchInfo.load.updated_at, matchInfo.truck.updated_at])
            carrierLoadMatch.match_today = carrierLoadMatch.match_today || (matchInfo.load.pickup_date === today)
            carrierLoadMatch.match_source = convertMatchTruckSource(carrierLoadMatch.match_source, matchInfo.truck)
            carrierLoadMatch.earliest_match_date = _.min([carrierLoadMatch.earliest_match_date, matchInfo.load.pickup_date])
            carrierLoadMatch.updated_at = moment(carrierLoadMatch.updated_at).add(1, 'second').toISOString()
            carrierLoadMatch.user_match = updateUserMatchFlag(carrierLoadMatch, matchInfo, userId) || (userId && userId === (carrier_owner?.id))
            if (carrier_owner) {
                carrierLoadMatch.carrier_owner = carrier_owner
            } else if (matchInfo.truck?.source === 'OTR') {
                carrierLoadMatch.carrier_owner = matchInfo.truck.user
            }

            carrierLoadMatch.min_deadhead = (carrierLoadMatch.min_deadhead > matchInfo.origin_deadhead) ? matchInfo.origin_deadhead : carrierLoadMatch.min_deadhead



        } else { // no matches, add one
            let newCarrierLoadMatch = {
                                            id: match.carrier.mc,
                                            mc: match.carrier.mc,
                                            name: (match.carrier.carrier_name) ? match.carrier.carrier_name : match.carrier.name,
                                            updated_at: (match.carrier.update_at) ? match.carrier.updated_at : moment().toISOString(),
                                            match_count: 1,
                                            match_score: match.match_score, 
                                            matches: [match]
                                        }
            
            newCarrierLoadMatch.match_today = (matchInfo.load.pickup_date === today)
            newCarrierLoadMatch.match_updated_at = getLastUpdated([matchInfo.load.updated_at, matchInfo.truck.updated_at])
            newCarrierLoadMatch.match_source = convertMatchTruckSource(newCarrierLoadMatch.match_source, matchInfo.truck)
            newCarrierLoadMatch.earliest_match_date = _.min([newCarrierLoadMatch.earliest_match_date, matchInfo.load.pickup_date])
            newCarrierLoadMatch.updated_at = moment().toISOString()
            newCarrierLoadMatch.user_match = updateUserMatchFlag(newCarrierLoadMatch, matchInfo, userId) || (userId && userId === (carrier_owner?.id))
            newCarrierLoadMatch.min_deadhead = matchInfo.origin_deadhead
            if (carrier_owner) {
                newCarrierLoadMatch.carrier_owner = carrier_owner
            } else if (matchInfo.truck?.source === 'OTR') {
                newCarrierLoadMatch.carrier_owner = matchInfo.truck.user
            }
    
            rtnMatches.push(newCarrierLoadMatch)
        }
    }

    return rtnMatches
}

const removeMatchFrom = (matches, match, today = moment().format('YYYY-MM-DD')) => {
    const mc = (match?.carrier?.mc) ? match.carrier.mc : match.id.split(':')[2]
    if (mc) {
        const carrierLoadMatch = matches.find(m => `${m.mc}` === `${mc}`)

        if (carrierLoadMatch) {
            carrierLoadMatch.matches = carrierLoadMatch.matches.filter(m => m.id !== match.id)
            if (carrierLoadMatch.matches.length === 0) {
                matches = matches.filter(m => m.mc !== carrierLoadMatch.mc)
            } else {
                carrierLoadMatch.match_count = carrierLoadMatch.matches.length
                carrierLoadMatch.match_today = false
                carrierLoadMatch.updated_at = moment().toISOString()
                carrierLoadMatch.min_deadhead = 10000
                for (let clm of carrierLoadMatch.matches) {
                    carrierLoadMatch.match_updated_at = getLastUpdated([carrierLoadMatch.updated_at , clm.load.updated_at, clm.truck.updated_at])
                    carrierLoadMatch.match_today = (carrierLoadMatch.match_today  || (clm.load.pickup_date === today))

                    carrierLoadMatch.earliest_match_date = _.min([carrierLoadMatch.earliest_match_date, clm.load.pickup_date])
                    carrierLoadMatch.match_source = convertMatchTruckSource(carrierLoadMatch.match_source, clm.truck)
                    carrierLoadMatch.min_deadhead = (carrierLoadMatch.min_deadhead < clm.origin_deadhead) ? carrierLoadMatch.min_deadhead : clm.origin_deadhead
                    console.log('remove', carrierLoadMatch, clm)
                }
            }
        }
    }

    return matches
}

/*
    Uses previous flag value in the match and a new match being processed.  Returns new flag value.
    As long as one of the matches has an entered truck by current user, this flag will be marked as true.
*/
const updateUserMatchFlag = (carrierLoadMatch, matchInfo, userId) => {
    let rtn = carrierLoadMatch?.user_match ? carrierLoadMatch.user_match : false

    if (matchInfo?.truck?.user?.id === userId) {
        rtn = true
    }

    return rtn
}

export default (state=initialState,  action) => {
    // deep clone
    let matches = (state) ? JSON.parse(JSON.stringify(state)) : []
    const userId = action?.userId ? action.userId : null

    switch(action.type) {
        case SET_MATCHES:
            if (action.matches?.length >= 0) {
                matches = []
                // so here we want to mutate the matches into the structure above
                const today = moment().format('YYYY-MM-DD')
                if (action?.matches?.length) {
                    for (let match of action.matches) {
                        matches = addMatchTo({matches, match, today, userId})
                    }
                }
            }
            break
        case ADD_MATCH:
            matches = addMatchTo({matches, match: action.match, userId})
            break
        case REMOVE_MATCH:
            matches = removeMatchFrom(matches, action.match)
            break
        default:
            return state
    }

    return matches
}