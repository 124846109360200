
import { SET_CARRIER_SEARCH_LIST, CLEAR_CARRIER_SEARCH_LIST, SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS } from '../actionTypes'

const initialState = []

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_CARRIER_SEARCH_LIST:
        return action.carriers

    case SET_CARRIER_SEARCH_LIST_TO_RECENT_CARRIERS:
        const recentCarriers = action.recentCarriers
    
        return recentCarriers ? recentCarriers : []

    case CLEAR_CARRIER_SEARCH_LIST:
        return []


    default:
        return state
    }
}
