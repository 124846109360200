import axios from "axios"
// import { formatDate } from '../utils/formatters'


export const fetchAllTeams = async (rangeStart, rangeEnd) => {
    let result = await axios({
        method: 'get',
        url: `/api/teams?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
    })
    const teams = (result && result.status === 200) ? result.data : []
    
    return teams
}


export const fetchAllCustomers = async (rangeStart, rangeEnd) => {
    let result = await axios({
        method: 'get',
        url: `/api/teams/get_customers?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
    })

    const users = (result && result.status === 200) ? result.data : []

    return users
}

export const fetchAllUsers = async () => {
    let result = await axios({
        method: 'get',
        url: `/api/teams/get_users`,
    })

    const users = (result && result.status === 200) ? result.data : []

    return users
}

export const updateTeamsUser = ({team_name,current_team_name,email,teams}) => {
    return axios({
        method: 'post',
        url: `/api/teams/change_teams_users?team_name=${team_name}&email=${email}&current_team_name=${current_team_name}`,
        data: {'teams':teams}
    })

}

export const updateTeamsCustomer = ({team_name,current_team_name,aljex_account_number}) => {
    return axios({
        method: 'get',
        url: `/api/teams/change_teams_customers?team_name=${team_name}&aljex_account_number=${aljex_account_number}&current_team_name=${current_team_name}`,
    })

}

export const fetchAllEmails = () => {
    return axios({
        method: "get",
        url: `/api/emails`,
    });
}

export const sendEmail = ({uid,from,flags,text,date,loadx,subject,mc,html,textString,colour,team_name}) => {
    let subject_new = encodeURIComponent(subject)
    let text_new = encodeURIComponent(text)
    loadx['html'] = html
    loadx['text'] = textString
    return axios({
        method: "post",
        url: encodeURI(`/api/emails?mc=${mc}&id=${uid}&from=${from}&flags=${flags}&date=${date}&subject=${subject_new}&text=${text_new}&colour=${colour}&team_name=${team_name}`),
        data: loadx
    });
}

export const getTeamsEmail = ({user_email}) => {
    return axios({
        method: "get",
        url: `/api/emails/getTeamsEmail?email=${user_email}`,
    });
}

export const checkForMc = ({mc}) => {
    return axios({
        method: "get",
        url: `/api/emails/checkForMc?mc=${mc}`,
    });
}

export const updateTeamsUserAdmin = ({team_id,user_id,admin}) => {
    return axios({
        method: "post",
        url: `/api/teams/change_teams_users_admin?team_id=${team_id}&user_id=${user_id}&admin=${admin}`,
    });
}

export const updateTeamsUserSendBidEmail = ({team_id,user_id,admin}) => {
    return axios({
        method: "post",
        url: `/api/teams/change_teams_users_send_bid_email?team_id=${team_id}&user_id=${user_id}&admin=${admin}`,
    });
}
