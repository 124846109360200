import { SET_CUSTOMER_FACILITES } from '../../actionTypes'

const initialState = []

export default (state=initialState,  action) => {
    switch(action.type) {
    case SET_CUSTOMER_FACILITES:
        return action.facilities || []
    default:
        return state
    }
}